import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgArrowUp = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M1.293 6.293a1 1 0 0 0 1.414 1.414L7 3.414V15a1 1 0 1 0 2 0V3.414l4.293 4.293a1 1 0 1 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowUp;

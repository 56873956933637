import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgList = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 14"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.636}
      d="M1.893 10.748h.71M1.893 6.84h.71M1.893 2.931h.71"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.636}
      d="M4.731 10.748h8.517M4.731 6.84h8.517M4.731 2.931h8.517"
    />
  </svg>
);
export default SvgList;

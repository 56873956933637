import ListingNewsContainer, { VARIANTS } from "./listingNews";

const ListingArticleContainer = ({ ...props }) => (
  <ListingNewsContainer
    variant={VARIANTS.ARTICLES}
    totalMatchesLabel=""
    noResultMessage=""
    errorActionMessage=""
    errorMessage=""
    {...props}
  />
);

export default ListingArticleContainer;

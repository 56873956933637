import Breadcrumbs from "@@/components/Breadcrumb";
import { SnwButtonType } from "@@/components/Button/buttonProps";
import SnwButtonContainer from "@@/components/Button/SnwButtonContainer";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import styles from "./index.module.scss";

type BranchPageHeroEditableProperties = {
  header: string;
  intro?: string;
};

type BranchPageHeroOwnProps = {
  label: string;
  buttons?: SnwButtonType[];
  breadcrumbItems: LinkData[];
};

export type BranchPageHeroProps = BranchPageHeroEditableProperties &
BranchPageHeroOwnProps;

const BranchPageHero = ({
  label,
  header,
  intro,
  buttons,
  breadcrumbItems,
}: BranchPageHeroProps) => (
  <Container size="full" pageGutter={0}>
    <Box className={styles.hero}>
      <Container className={styles.wrapper} pageGutterY={0} component="div">
        <Box
          pb={[14, 18, 18]}
          pt={[14, 18, 18]}
          textAlign="center"
        >
          <Typography variant="heading5" asElement="div">
            {label}
          </Typography>
          <hr className={styles.center_align} />
          <EpiProperty<BranchPageHeroEditableProperties> name="header">
            <Typography mb={0} asElement="h1" variant="heading1">
              {header}
            </Typography>
          </EpiProperty>
          {intro && (
            <EpiProperty<BranchPageHeroEditableProperties> name="intro">
              <Box mt={8}>
                <Typography variant="intro">{intro}</Typography>
              </Box>
            </EpiProperty>
          )}
          {buttons && buttons.length > 0 && (
            <Box
              className={styles.button_wrapper}
              gap={5}
              justifyContent="center"
              alignItems="center"
              mt={8}
            >
              <SnwButtonContainer buttons={buttons} />
            </Box>
          )}
        </Box>
      </Container>
      <Box
        alignSelf="center"
        className={styles.hero__notch_wrapper}
        justifyContent="center"
      >
        <Box
          mx={3}
          px={5}
          pt={2}
          className={styles.hero__notch}
          alignItems="center"
          gap={2}
          color="PrimaryGreyTint01"
        >
          <Box className={styles.breadcrumb_wrapper}>
            <Breadcrumbs breadcrumbItems={breadcrumbItems} />
          </Box>
        </Box>
      </Box>
    </Box>
  </Container>
);

export type ProvidedBranchPageHeroProps =
  OptimizelyBlockProps<BranchPageHeroEditableProperties> &
  BranchPageHeroProps;

const ProvidedBranchPageHero = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedBranchPageHeroProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <BranchPageHero {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedBranchPageHero;

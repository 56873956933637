import Breadcrumbs from "@@/components/Breadcrumb";
import { SnwButtonType } from "@@/components/Button/buttonProps";
import SnwButtonContainer from "@@/components/Button/SnwButtonContainer";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import styles from "./index.module.scss";

type ListingPageHeroEditableProperties = {
  header: string;
  intro?: string;
  image?: PictureData;
};

type ListingPageHeroOwnProps = {
  label: string;
  buttons?: SnwButtonType[];
  breadcrumbItems: LinkData[];
};

export type ListingPageHeroProps = ListingPageHeroEditableProperties &
ListingPageHeroOwnProps;

const ListingPageHero = ({
  label,
  header,
  intro,
  image,
  buttons,
  breadcrumbItems,
}: ListingPageHeroProps) => {
  const renderContent = () => (
    <Box
      pb={!image ? [14, 18, 18] : null}
      pt={image ? [10, 10, 14] : [14, 18, 18]}
      textAlign={!image ? "center" : "left"}
    >
      <Typography variant="pretitle" asElement="div">
        {label}
      </Typography>
      <hr className={image ? styles.left_align : styles.center_align} />
      <EpiProperty<ListingPageHeroEditableProperties> name="header">
        <Typography mb={0} asElement="h1" variant="heading1">
          {header}
        </Typography>
      </EpiProperty>
      {intro && (
        <EpiProperty<ListingPageHeroEditableProperties> name="intro">
          <Box mt={8}>
            <Typography variant="intro">{intro}</Typography>
          </Box>
        </EpiProperty>
      )}
      {buttons && buttons.length > 0 && (
        <Box
          className={styles.button_wrapper}
          gap={5}
          justifyContent={!image ? "center" : "flex-start"}
          alignItems={!image ? "center" : "flex-start"}
          mt={!image ? 8 : 10}
        >
          <SnwButtonContainer buttons={buttons} />
        </Box>
      )}
    </Box>
  );
  return (
    <Container size="full" pageGutter={0}>
      <Box className={styles.hero}>
        {image && (
          <>
            <EpiProperty<ListingPageHeroEditableProperties> name="image">
              <Picture
                imageData={image}
                aspectRatio={["16/9", "21 / 9", "4 / 1"]}
                sizes={[
                  [300, 600, 900, 1200],
                  [300, 600, 900, 1200, 1800],
                  [900, 1200, 1800, 2400, 3000, 3840],
                ]}
                lazySizes={false}
                loading="eager"
                sourcesSizes="(min-widht: 1920px) 1920px, 100vw"
              />
            </EpiProperty>
          </>
        )}
        {!image && (
          <Container className={styles.wrapper} pageGutterY={0} component="div">
            {renderContent()}
          </Container>
        )}
        <Box
          mt={image ? -10 : 0}
          alignSelf="center"
          className={styles.hero__notch_wrapper}
          justifyContent="center"
        >
          <Box
            mx={3}
            px={5}
            pt={2}
            className={styles.hero__notch}
            alignItems="center"
            gap={2}
            color="PrimaryGreyTint01"
          >
            <Box className={styles.breadcrumb_wrapper}>
              <Breadcrumbs breadcrumbItems={breadcrumbItems} />
            </Box>
          </Box>
        </Box>
      </Box>
      {image && (
        <Container
          className={styles.content_wrapper}
          pageGutterY={0}
          component="div"
        >
          {renderContent()}
        </Container>
      )}
    </Container>
  );
};

export type ProvidedListingPageHeroProps =
  OptimizelyBlockProps<ListingPageHeroEditableProperties> &
  ListingPageHeroProps;

const ProvidedListingPageHero = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedListingPageHeroProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <ListingPageHero {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedListingPageHero;

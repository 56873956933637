import { RefObject, useEffect } from "react";
import useThrottledState from "../react/use-throttled-state";

export type ElementSize = {
  readonly clientHeight?: number;
  readonly clientWidth?: number;
  readonly scrollHeight?: number;
  readonly scrollWidth?: number;
};

const useElementSize = (ref: RefObject<HTMLElement>) => {
  const [size, setSize] = useThrottledState<ElementSize>({}, 100);

  useEffect(() => {
    if (typeof (ResizeObserver) === "undefined") {
      return undefined;
    }

    const resizeObserverRef = new ResizeObserver((entries = []) => {
      // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        entries.forEach((entry: ResizeObserverEntry) => {
          const {
            clientHeight, clientWidth, scrollHeight, scrollWidth,
          } = entry.target;
          setSize({
            clientHeight,
            clientWidth,
            scrollHeight,
            scrollWidth,
          });
        });
      });
    });
    if (ref.current) {
      resizeObserverRef.observe(ref.current);
    }
    return () => { resizeObserverRef.disconnect(); };
  }, []);

  return size;
};

export default useElementSize;

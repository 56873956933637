import useUpdateEffect from "@@/hooks/useUpdateEffect";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {
  useEffect, useRef, useState,
} from "react";
import { Location } from "../BranchSearch/BranchList/card";
import useDeepCompareEffectForMaps from "./hooks";
import styles from "./styles.module.scss";

type Props = {
  locations?: Location[];
  selectedLocations?: string[];
  hasBoundsChanged?: boolean;
  setHasBoundsChanged?: (args) => void;
  setVisibleLocations?: ({ ...args }) => void;
  markedLocation?: Location;
  setMarkedLocation?: (args) => void;
  hoveredLocation?: string;
  currentLocation?: google.maps.LatLngLiteral;
  initialPosition?: { lat: number, lng: number };
  minimumZoomLevel?: number;
  googleMapsId: string;
  isLoaded: boolean;
  error: boolean;
};

const GoogleMap = ({
  locations,
  currentLocation,
  selectedLocations,
  setVisibleLocations,
  markedLocation,
  setMarkedLocation,
  hoveredLocation,
  hasBoundsChanged,
  setHasBoundsChanged,
  initialPosition = { lat: 57.7089, lng: 11.9746 },
  minimumZoomLevel = 11,
  googleMapsId,
  isLoaded,
  error,
  ...options
} : Props) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState<Map<string, google.maps.Marker>>(null);
  // Bounds of all locations
  const [markerBounds, setMarkerBounds] = useState(null);
  const [cluster, setCluster] = useState(null);
  const [hoveredCluster, setHoveredCluster] = useState(null);

  const [mapInitialBounds, setMapInitialBounds] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);

  // Bounds visible on map (initilised as bounds of all locations)
  const [currentBounds, setCurrentBounds] = useState<google.maps.LatLngBounds>();

  const setBoundsToInitialState = () => {
    map.fitBounds(markerBounds);
    const zoom = map.getZoom();
    map.setZoom(zoom > minimumZoomLevel ? minimumZoomLevel : zoom);
  };

  const ICON = {
    // eslint-disable-next-line global-require
    url: (require("../../assets/googlemaps/google_pin.svg")),
    scale: 5,
  };

  const ICON_SELECTED = {
    // eslint-disable-next-line global-require
    url: (require("../../assets/googlemaps/google_pin_selected.svg")),
    scale: 5,
  };

  const setMarkersAndBounds = () => {
    const newBounds = new google.maps.LatLngBounds();
    const newMarkers = new Map<string, google.maps.Marker>();

    locations.forEach((location, i) => {
      var myLatLng = new google.maps.LatLng(location.position.lat, location.position.lng);
      const duplicateLocations = locations.filter(x => x.position.lat === location.position.lat && x.position.lng === location.position.lng);
      if (duplicateLocations.length > 1) {
        var placement = 360.0 / duplicateLocations.length;
        var newLat = Number(location.position.lat) + -.00005 * Math.cos((+placement * i) / 180 * Math.PI);
        var newLng = Number(location.position.lng) + -.00009 * Math.sin((+placement * i) / 180 * Math.PI);
        myLatLng = new google.maps.LatLng(newLat, newLng);
      }
      const marker = new google.maps.Marker({
        position: myLatLng,
        map,
        title: location.name,
        icon: ICON,
      });

      if (setMarkedLocation) {
        marker.addListener("click", () => {
          setMarkedLocation(location);
        });
      }

      newBounds.extend(marker.getPosition());
      newMarkers.set(location.id, marker);
    });

    const renderer = {
      render({ count, position }) {
        return new google.maps.Marker({
          label: {
            text: String(count), color: "#A46351", fontSize: "10px", fontWeight: "700",
          },
          icon: {
            // eslint-disable-next-line global-require
            url: (require("../../assets/googlemaps/cluster_pin.svg")),
          },
          position,
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
      },
    };

    const rendererHovered = {
      render({ count, position }) {
        return new google.maps.Marker({
          label: {
            text: String(count), color: "#327EA5", fontSize: "14px", fontWeight: "700",
          },
          icon: {
            // eslint-disable-next-line global-require
            url: (require("../../assets/googlemaps/cluster_pin_selected.svg")),
          },
          position,
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + 1000,
        });
      },
    };

    // eslint-disable-next-line no-new
    const newClusters = new MarkerClusterer({ markers: Array.from(newMarkers.values()), map, renderer });
    const newHoveredCluster = new MarkerClusterer({ markers: [], map, renderer: rendererHovered });
    setCluster(newClusters);
    setHoveredCluster(newHoveredCluster);
    setCurrentBounds(newBounds);
    setMarkerBounds(newBounds);
    setMarkers(newMarkers);
  };

  const setClosestBounds = () => {
    const currentLocationLatLng = currentLocation;
    let closestLocationLatLng = new google.maps.LatLng(locations[0].position.lat, locations[0].position.lng);
    let closestDistance = google.maps.geometry.spherical.computeDistanceBetween(currentLocationLatLng, closestLocationLatLng);
    locations.forEach((location) => {
      const locationLatLng = new google.maps.LatLng(location.position.lat, location.position.lng);
      const locationDistance = google.maps.geometry.spherical.computeDistanceBetween(currentLocationLatLng, locationLatLng);
      if (locationDistance < closestDistance) {
        closestDistance = locationDistance;
        closestLocationLatLng = locationLatLng;
      }
    });

    const closestLocationBounds = new google.maps.LatLngBounds();
    closestLocationBounds.extend(currentLocationLatLng);
    closestLocationBounds.extend(closestLocationLatLng);
    setCurrentBounds(closestLocationBounds);
  };

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
      if (locations && locations.length) {
        setMarkersAndBounds();
      } else {
        map.setCenter(initialPosition);
      }
    }
  }, [map, options, locations]);

  useEffect(() => {
    if (markers && setVisibleLocations) {
      map.addListener("bounds_changed", () => {
        setMapBounds(map.getBounds());
        const visibleLocations = locations.filter((location) => map.getBounds().contains(markers.get(location.id).getPosition()));
        setVisibleLocations(visibleLocations);
      });
    }
  }, [markers]);

  useEffect(() => {
    if (map) {
      if (!mapInitialBounds) setMapInitialBounds(mapBounds);
      if (mapInitialBounds) {
        setHasBoundsChanged(!mapBounds.equals(mapInitialBounds));
      }
    }
  }, [mapBounds, mapInitialBounds]);

  useUpdateEffect(() => {
    if (!hasBoundsChanged) setBoundsToInitialState();
  }, [hasBoundsChanged]);

  const setZoom = () => {
    const currentZoom = map.getZoom();
    if (currentZoom < minimumZoomLevel) map.setZoom(minimumZoomLevel);
    map.setZoom(currentZoom > minimumZoomLevel ? minimumZoomLevel : currentZoom);
  };

  useEffect(() => {
    if (map && currentBounds) {
      let init = false;
      map.addListener("bounds_changed", () => {
        if (!init) {
          setZoom();
          init = true;
        }
      });
      map.fitBounds(currentBounds);
      setZoom();
    }
  }, [currentBounds]);

  useEffect(() => {
    if (map && currentLocation && locations) {
      setClosestBounds();
    } else if (map) {
      setBoundsToInitialState();
    }
  }, [currentLocation]);

  useEffect(() => {
    if (map && markers && selectedLocations && selectedLocations.length) {
      const selectedLocationsBounds = new google.maps.LatLngBounds();
      selectedLocations.forEach((selectedLocation) => {
        selectedLocationsBounds.extend(markers.get(selectedLocation).getPosition());
      });
      setCurrentBounds(selectedLocationsBounds);
    } else if (map && markers && selectedLocations && !selectedLocations.length) {
      setBoundsToInitialState();
    }
  }, [selectedLocations]);

  useEffect(() => {
    if (markers) {
      hoveredCluster.clearMarkers();
      locations.forEach((location) => {
        if ((markedLocation && markedLocation.id === location.id) || (hoveredLocation === location.id)) {
          const clusterWithMarker = cluster.clusters.find((mapCluster) => mapCluster.markers.length > 1 && mapCluster.markers.some((marker) => marker === markers.get(location.id)));
          if (clusterWithMarker) {
            hoveredCluster.addMarkers(clusterWithMarker.markers);
          }
          markers.get(location.id).setIcon(ICON_SELECTED);
        } else {
          markers.get(location.id).setIcon(ICON);
        }
      });
    }
  }, [hoveredLocation]);

  useEffect(() => {
    if (markers) {
      locations.forEach(location => {
        if (markedLocation && markedLocation.id === location.id) {
          markers.get(location.id).setIcon(ICON_SELECTED);
        } else {
          markers.get(location.id).setIcon(ICON);
        }
      });
    }
  }, [markedLocation]);

  useEffect(() => {
    if (isLoaded && mapRef.current && !map) {
      setMap(new window.google.maps.Map(mapRef.current, {
        mapId: googleMapsId,
        zoom: minimumZoomLevel,
        center: initialPosition,
      }));
    }
  }, [mapRef, map, isLoaded]);

  if (isLoaded) {
    return <div
      ref={mapRef}
      className={styles.map} />;
  }
  if (error) return <p>Map failed to load...</p>;
  return <></>;
};

export default GoogleMap;

import Box from "@@/elements/Box";
import NavButton from ".";
import styles from "./index.module.scss";

const NavButtonWrapper = () => (
  <>
    <Box order={[0, 1, 1]} className={styles.button_wrapper}>
      <NavButton direction="left" />
    </Box>
    <Box order={2} className={styles.button_wrapper}>
      <NavButton direction="right" />
    </Box>
  </>
);

export default NavButtonWrapper;

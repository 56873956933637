import Breadcrumbs from "@@/components/Breadcrumb";
import Tags from "@@/components/Tags";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import styles from "./heroContentPageStyles.module.scss";

type HeroContentPageOwnProps = {
  categories?: string[];
  image?: PictureData;
  pretitle: string;
  header: string;
  breadcrumbItems: LinkData[];
};

type HeroContentPageEditableProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  hero: HeroContentPageOwnProps;
  // For now we will add epi edit for whole component since epi couldn't handle the on
  // page edit for components thats not placed inside a content block.
};

type HeroContentPageProps = HeroContentPageOwnProps;

export const HeroContentPage = ({
  header,
  image,
  pretitle,
  categories,
  breadcrumbItems,
}: HeroContentPageProps) => {
  const renderContent = () => (
    <Box
      className={styles.hero_content__wrapper}
      alignSelf="center"
      pb={!image ? 18 : null}
      pt={image ? [10, 10, 14] : 18}
    >
      <div className={styles.hero_content__pretitle}>
        <div className={styles.hero_content__br}>
          {/* <EpiProperty<HeroContentPageEditableProps> name="pretitle"> */}
          <Typography textcolor="dark" variant="pretitle" asElement="div">
            {pretitle}
          </Typography>
          {/* </EpiProperty> */}
          <hr />
        </div>
      </div>
      {/* <EpiProperty<HeroContentPageEditableProps> name="header"> */}
      <Typography textcolor="dark" variant="heading1" asElement="h1">
        {header}
      </Typography>
      {/* </EpiProperty> */}
      {categories && (
        <Box pt={6} pb={6}>
          <Tags tags={categories} />
        </Box>
      )}
    </Box>
  );

  return (
    <EpiProperty<HeroContentPageEditableProps> name="hero">
      <Container size="full" pageGutter={0} backgroundColor={!image ? "PrimaryGreyTint03" : null}>
        <Box className={styles.hero}>
          {image && (
            <>
              {/* <EpiProperty<HeroContentPageEditableProps> name="image"> */}
              <Picture
                imageData={image}
                aspectRatio={["16 / 9", "21 / 9", "4 / 1"]}
                sizes={[
                  [300, 600, 900, 1200],
                  [300, 600, 900, 1200, 1800],
                  [1800, 2400, 3000, 3840],
                ]}
                lazySizes={false}
                loading="eager"
                sourcesSizes="(min-widht: 1920px) 1920px, 100vw"
              />
              {/* </EpiProperty> */}
            </>
          )}
          {!image && (
            <Container pageGutterY={0} component="div">
              {renderContent()}
            </Container>
          )}
          <Box
            mt={image ? -10 : 0}
            alignSelf="center"
            className={styles.hero__notch_wrapper}
            justifyContent="center"
          >
            <Box
              mx={3}
              px={5}
              pt={2}
              className={styles.hero__notch}
              alignItems="center"
              gap={2}
              color="PrimaryGreyTint01"
            >
              <Box className={styles.breadcrumb_wrapper}>
                <Breadcrumbs breadcrumbItems={breadcrumbItems} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Container pageGutterY={0} component="div">
          <Box>{image && renderContent()}</Box>
        </Container>
      </Container>
    </EpiProperty>
  );
};

export type ProvidedHeroContentPageProps =
  OptimizelyBlockProps<HeroContentPageProps> & HeroContentPageProps;

const ProvidedHeroContentPage = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedHeroContentPageProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <HeroContentPage {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedHeroContentPage;

import { ArrowRight } from "@@/elements/Icons/icons";
import { SnwButtonType } from "./buttonProps";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import TertiaryButton from "./TertiaryButton";
import TextButtonIcon from "./TextButtonIcon";

const SnwButton = ({
  link,
  variant,
  color,
  disabled,
  onClick,
}: SnwButtonType) => {
  if (!link) {
    return <></>;
  }
  switch (variant) {
    case "primary":
      return (
        <PrimaryButton
          title={link.title}
          label={link.text}
          target={link.target}
          href={link.href}
          disabled={disabled}
          onClick={onClick}
        />
      );
    case "secondary":
      return (
        <SecondaryButton
          title={link.title}
          label={link.text}
          target={link.target}
          href={link.href}
          disabled={disabled}
          onClick={onClick}
        />
      );

    case "tertiary":
      return (
        <TertiaryButton
          title={link.title}
          label={link.text}
          target={link.target}
          href={link.href}
          disabled={disabled}
          onClick={onClick}
          color={color}
        />
      );
    case "arrowRight":
      return (
        <TextButtonIcon
          direction="right"
          label={link.text}
          target={link.target}
          link={link}
          disabled={disabled}
          onClick={onClick}
        >
          <ArrowRight />
        </TextButtonIcon>
      );
    default:
      return null;
  }
};
export default SnwButton;

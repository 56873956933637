import ResponsiveValue from "@@/base/responsive-value";
import SnwButtonContainer from "@@/components/Button/SnwButtonContainer";
import { SnwButtonType } from "@@/components/Button/buttonProps";
import VideoPlayer, { VideoPlayerProps } from "@@/components/VideoPlayer";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Picture from "@@/elements/Picture";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import Typography from "../../elements/Typography";
import Lightbox from "../Media/Lightbox";
import RichText from "../RichText";
import styles from "./index.module.scss";

const TextMediaBlock = ({
  variant,
  image,
  videoUrl,
  header,
  intro,
  body,
  buttons,
  zoom,
  closeLabel,
  closeTitle,
  zoomTitle,
  zoomOutTitle,
  zoomInTitle,
}: TextMediaBlockProps) => {
  const classNames = cn(styles.article, {
    [(styles as any)[`article_${variant}`]]: variant,
  });

  const isVideoPlayer = videoUrl && videoUrl !== "";

  const hasHeaderOrIntro = !!header?.length || !!intro?.length;
  const hasBody = !!body?.length;
  const smallVariant = variant === "left-small" || variant === "right-small";

  const getAspectRatio = (): ResponsiveValue<string> => {
    switch (variant) {
    case "full":
      return ["1/1", "21/9", "4/1"];
    case "left-small":
    case "right-small":
      return ["3/2", "5/3", "3/2"];
    default:
      return ["1/1", "5 / 3", "3 / 2"];
    }
  };

  const getBorderRadius = (): ResponsiveValue<number> => {
    switch (variant) {
    case "full":
      return [0, 0, 1];
    case "left-small":
    case "right-small":
      return 1;
    default:
      return [0, 1, 1];
    }
  };

  return (
    <article>
      <Container pageGutterX={variant === "full" ? [0, 0, 10] : [0, 10, 10]}>
        <Box className={classNames}>
          <Box
            className={cn(styles.img_container, {
              [styles.img_container__small]: smallVariant,
            })}
          >
            <EpiProperty<VideoPlayerProps> name="videoUrl">
              {videoUrl && videoUrl !== "" && (
                <VideoPlayer videoUrl={videoUrl} />
              )}
            </EpiProperty>
            <EpiProperty<TextMediaBlockProperties> name="imageUrl">
              {zoom && image && image.imageUrl && (
                <Lightbox
                  closeTitle={closeTitle}
                  zoomTitle={zoomTitle}
                  zoomOutTitle={zoomOutTitle}
                  zoomInTitle={zoomInTitle}
                  closeLabel={closeLabel}
                  variant="portrait"
                >
                  <Picture
                    imageData={image}
                    aspectRatio={getAspectRatio()}
                    sizes={[
                      [700, 1080, 1920],
                      [700, 1080, 1920],
                      [700, 1080, 1920],
                    ]}
                    br={getBorderRadius()}
                  />
                </Lightbox>
              )}
              {!zoom && image && image.imageUrl && (
                <Picture
                  imageData={image}
                  aspectRatio={getAspectRatio()}
                  sizes={[
                    [600, 1120, 2320],
                    [600, 1120, 2320],
                    [600, 1120, 2320],
                  ]}
                  br={getBorderRadius()}
                  lazySizes={false}
                  sourcesSizes={
                    variant === "full"
                      ? "100vw"
                      : "(max-widht: 900px) 100vw, (min-widht: 901px) 50vw, 100vw"
                  }
                />
              )}
            </EpiProperty>
          </Box>
          <Box
            className={cn(styles.text_container, {
              [styles.text_container__wide]: !hasHeaderOrIntro || !hasBody,
              [styles.text_container__small]: smallVariant,
              [styles.hasvideo]: isVideoPlayer,
            })}
            container
            flexDirection="column"
          >
            {hasHeaderOrIntro && (
              <div className={styles.heading}>
                <EpiProperty<TextMediaBlockProperties> name="header">
                  {!!header?.length && (
                    <Typography
                      variant="heading1"
                      asElement="h2"
                      mb={5}>
                      {header}
                    </Typography>
                  )}
                </EpiProperty>
                <EpiProperty<TextMediaBlockProperties> name="intro">
                  {!!intro?.length && (
                    <Typography
                      variant="intro"
                      mb={5}>
                      {intro}
                    </Typography>
                  )}
                </EpiProperty>
              </div>
            )}
            <div>
              <EpiProperty<TextMediaBlockProperties> name="body">
                {!!body?.length && <Box mb={5}>
                  <RichText body={body} />
                </Box>}
              </EpiProperty>
              <div className={styles.button_container}>
                <SnwButtonContainer buttons={buttons} />
              </div>
            </div>
          </Box>
        </Box>
      </Container>
    </article>
  );
};

type Variant = "right" | "left" | "full" | "right-small" | "left-small";

type TextMediaBlockProperties = {
  header: string;
  intro: string;
  body: string;
  imageUrl: string;
};

type TextMediaBlockProps = {
  header: string;
  intro: string;
  body: string;
  image?: PictureData;
  videoUrl?: string;
  variant: Variant;
  buttons: SnwButtonType[];
  zoom?: boolean;
  closeLabel?: string;
  closeTitle?: string;
  zoomTitle?: string;
  zoomOutTitle?: string;
  zoomInTitle?: string;
};

export type ProvidedTextMediaBlockCardProps =
  OptimizelyBlockProps<TextMediaBlockProperties> & TextMediaBlockProps;

const ProvidedTextMediaBlockCard = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedTextMediaBlockCardProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <TextMediaBlock
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedTextMediaBlockCard;

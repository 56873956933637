import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";

type LatestUpdateProps = {
    text: string;
    date: string;
    addSpacingBottom?: boolean;
};

const LatestUpdate = ({ text, date, addSpacingBottom = false }: LatestUpdateProps) => (
  <Container
    mb={!!addSpacingBottom ? [14, 18] : 0}
    pageGutterY={0}>
    <Box textAlign="left">
      <Typhography
        variant="information"
        asElement="span">
        {text + ": " + date}
      </Typhography>
    </Box>
  </Container>
);

export type ProvidedLatestUpdateProps = OptimizelyBlockProps<LatestUpdateProps> &
LatestUpdateProps;

const ProvidedLatestUpdate = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedLatestUpdateProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <LatestUpdate
      {...rest}
      {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedLatestUpdate;
import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import Accordion from "..";
import styles from "../accordionStyles.module.scss";

type SizeVolumeProps = {
  header: string;
  width?: Measurement;
  height?: Measurement;
  depth?: Measurement;
  volume?: Measurement;
  weight?: Measurement;
  flexibleMeasurment?: Measurement[];
};

interface Measurement {
  label: string;
  value: string;
  unit: string;
}

const AccordionSizeVolume = ({
  header,
  width,
  height,
  depth,
  volume,
  weight,
  flexibleMeasurment,
}: SizeVolumeProps) => {
  const superscriptLastNumber = (string: string) => {
    const lastChar = string.charAt(string.length - 1);
    if (!isNaN(parseInt(lastChar))) {
      const supString = string.slice(0, -1) + `<sup>${lastChar}</sup>`;
      return <span dangerouslySetInnerHTML={{ __html: supString }} />;
    }
    return string;
  };

  return (
    <Accordion backgroundColor="light-blue" header={header}>
      <Box className={styles.sizes_list} component="ul">
        {width.value && (
          <li>
            <Typhography variant="body">
              {width.label + ":"} {width.value}
              {superscriptLastNumber(width.unit)}
            </Typhography>
          </li>
        )}
        {height.value && (
          <li>
            <Typhography variant="body">
              {height.label + ":"} {height.value}
              {superscriptLastNumber(height.unit)}
            </Typhography>
          </li>
        )}
        {depth.value && (
          <li>
            <Typhography variant="body">
              {depth.label + ":"} {depth.value}
              {superscriptLastNumber(depth.unit)}
            </Typhography>
          </li>
        )}
        {volume.value && (
          <li>
            <Typhography variant="body">
              {volume.label + ":"} {volume.value}
              {superscriptLastNumber(volume.unit)}
            </Typhography>
          </li>
        )}
        {weight.value && (
          <li>
            <Typhography variant="body">
              {weight.label + ":"} {weight.value}
              {superscriptLastNumber(weight.unit)}
            </Typhography>
          </li>
        )}

        {flexibleMeasurment &&
          flexibleMeasurment.map((measurment) => (
            <li>
              {measurment.value && (
                <Typhography variant="body">
                  {measurment.label + ":"} {measurment.value}
                  {superscriptLastNumber(measurment.unit)}
                </Typhography>
              )}
            </li>
          ))}
      </Box>
    </Accordion>
  );
};

export default AccordionSizeVolume;

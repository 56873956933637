import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramBuildings01 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.496 17.26a1.5 1.5 0 0 1 1.499-1.445h9.68a1.5 1.5 0 0 1 1.499 1.446l1.305 36.201L52.611 39.57a1.5 1.5 0 0 1 2.444 1.165v11.762l15.978-12.928a1.5 1.5 0 0 1 2.444 1.166v11.763l15.988-12.93a1.5 1.5 0 0 1 2.443 1.167v53.95a1.5 1.5 0 0 1-1.5 1.5H21.592a1.5 1.5 0 0 1-1.5-1.5V56.203l.001-.054zm2.946 1.555-1.35 37.414v36.956h65.816V43.876L72.92 56.806a1.5 1.5 0 0 1-2.443-1.167V43.877L54.499 56.805a1.5 1.5 0 0 1-2.444-1.166V43.882L35.427 57.367a1.5 1.5 0 0 1-.945.335h-.405a1.5 1.5 0 0 1-1.499-1.446l-1.35-37.441z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M32.939 68.875a1 1 0 0 1 1-1H45.84a1 1 0 0 1 1 1v8.524a1 1 0 0 1-1 1H33.94a1 1 0 0 1-1-1zm2 1v6.524h9.902v-6.524zM49.049 68.875a1 1 0 0 1 1-1H61.95a1 1 0 0 1 1 1v8.524a1 1 0 0 1-1 1H50.05a1 1 0 0 1-1-1zm2 1v6.524h9.902v-6.524zM65.169 68.875a1 1 0 0 1 1-1H78.07a1 1 0 0 1 1 1v8.524a1 1 0 0 1-1 1H66.17a1 1 0 0 1-1-1zm2 1v6.524h9.902v-6.524z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramBuildings01;

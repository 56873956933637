import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramMiscellaneous10 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3.27}
      d="m61.136 87.111 3.555 13.728 6.025-13.037 12.543 7.506-7.407-26.469M38.124 68.938l-3.951 34.963 13.333-12.148 10.963 14.913 3.852-30.814"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M47.901 10.173c.988.395 2.47.098 3.16-.692l3.556-3.753c.79-.79 1.976-.79 2.667 0L60.74 9.68c.691.79 2.173 1.086 3.16.691l4.938-2.074c.988-.395 2.075.099 2.47 1.087l1.58 4.642c.395.987 1.58 1.876 2.666 1.876L80.79 16c1.086 0 1.877.889 1.778 1.975l-.395 5.037c-.099 1.087.592 2.37 1.58 2.864l4.445 2.075c.987.493 1.382 1.679.888 2.567l-2.469 4.642c-.494.988-.395 2.37.395 3.26l3.358 3.95c.692.79.593 1.976-.296 2.667L85.926 48c-.889.691-1.383 1.975-.988 3.062l1.482 4.84c.296 1.086-.296 2.073-1.383 2.37l-4.938 1.284c-1.087.296-1.976 1.382-2.173 2.469l-.593 5.037c-.098 1.086-1.086 1.777-2.172 1.58l-5.136-.988c-1.087-.197-2.37.395-2.963 1.383l-2.568 4.444c-.494.988-1.68 1.186-2.568.593l-4.346-3.062c-.889-.592-2.37-.691-3.259 0l-4.346 3.062c-.889.593-2.074.296-2.666-.593l-2.568-4.148c-.593-.889-1.877-1.481-2.963-1.284l-5.136.988c-1.087.197-2.074-.494-2.173-1.58l-.691-5.235c-.099-1.086-1.087-2.173-2.173-2.469l-4.938-1.185c-1.087-.296-1.68-1.284-1.383-2.37l1.58-4.939c.296-1.086-.099-2.469-.988-3.062l-4.049-3.061c-.889-.692-.988-1.877-.296-2.667l3.26-3.654c.69-.79.888-2.272.394-3.26l-2.469-4.74c-.494-.988-.099-2.173.889-2.568l4.444-2.074c.988-.494 1.68-1.778 1.58-2.864l-.493-5.334C29.037 16.89 29.827 16 30.914 16l5.234.099c1.087 0 2.272-.79 2.568-1.877l1.383-4.05c.79-1.58 2.37-2.27 3.852-1.678z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.766}
      d="M49.383 16.988c.987.395 2.469.098 3.16-.692l2.766-2.864a.955.955 0 0 1 1.382 0l2.667 3.062c.691.79 2.173 1.086 3.16.691l3.852-1.58c.494-.198 1.087 0 1.284.593l1.284 3.555c.395.988 1.58 1.877 2.667 1.877l4.05.098c.592 0 .987.494.888.988l-.395 4.938c0 .593.296 1.185.79 1.383l3.457 1.679c.988.494 1.383 1.679.889 2.568l-1.877 3.555c-.296.494-.197 1.186.198 1.58l2.667 3.062c.69.79.592 1.976-.297 2.667l-3.16 2.37c-.395.297-.692.988-.494 1.482l1.383 4.74a.877.877 0 0 1-.692 1.186l-3.852.987c-1.086.297-1.975 1.383-2.172 2.47l-.494 3.95c-.395.593-.89.89-1.383.79l-3.95-.79c-1.087-.197-2.37.395-2.963 1.383l-1.976 3.457c-.296.494-.889.592-1.284.296l-3.358-2.271c-.889-.593-2.37-.692-3.26 0l-3.357 2.271c-.494.296-1.087.198-1.383-.296l-2.469-4.05c-.296-.493-.988-.79-1.481-.691l-3.951.79c-1.087.198-2.074-.494-2.173-1.58l-.593-4.148c-.098-.494-.592-1.087-1.086-1.185l-3.852-.988c-1.086-.296-1.679-1.284-1.383-2.37l1.186-3.852c.197-.494-.099-1.185-.494-1.58l-3.852-2.963c-.395-.297-.494-.89-.099-1.383l2.568-2.864c.692-.79.89-2.272.395-3.26l-1.876-3.654c-.297-.494-.1-1.086.395-1.284l4.345-2.074c.494-.198.89-.889.79-1.383l-.395-4.05c-.098-1.085.692-1.974 1.778-1.974h4.05c.592 0 1.086-.395 1.284-.89l1.284-3.851c.296-.988 1.481-1.482 2.469-1.087z"
    />
  </svg>
);
export default SvgPictogramMiscellaneous10;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgContact = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.25 2.939C.25 1.633 1.25.5 2.493.5h11.014c1.281 0 2.243 1.136 2.243 2.439v8.256c0 1.345-.964 2.439-2.243 2.439H7.229l-2.834 2.213h-.001a.72.72 0 0 1-.831.039.81.81 0 0 1-.376-.744v-1.508h-.694c-1.24 0-2.243-1.091-2.243-2.439zm4.416 2.603c0-.346.28-.625.625-.625h5.417a.625.625 0 1 1 0 1.25H5.29a.625.625 0 0 1-.625-.625m.625 1.875a.625.625 0 1 0 0 1.25h3.75a.625.625 0 0 0 0-1.25z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgContact;

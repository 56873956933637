import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClose = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 16"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="m15.202 2.4-12.8 12.8a1.131 1.131 0 0 1-1.6-1.6L13.602.8a1.131 1.131 0 0 1 1.6 1.6"
    />
    <path
      fill="currentColor"
      d="m.798 2.4 12.8 12.8a1.131 1.131 0 0 0 1.6-1.6L2.398.8a1.131 1.131 0 0 0-1.6 1.6"
    />
  </svg>
);
export default SvgClose;

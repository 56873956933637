import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGlobe = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 15 16"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="m7.275 1.45 4.113.384 2.987 4.379-4.25 2.6-1.362.375-.663 1.784v3.553l-4.453-.028L.625 9.013l3.579-.9 3.04 1.3L7.8 6.9l1.288-.912-2.875-1.963zm.225.3c-3.45 0-6.25 2.8-6.25 6.25s2.8 6.25 6.25 6.25 6.25-2.8 6.25-6.25-2.8-6.25-6.25-6.25M7.5.5C11.637.5 15 3.863 15 8s-3.363 7.5-7.5 7.5A7.506 7.506 0 0 1 0 8C0 3.863 3.363.5 7.5.5"
    />
  </svg>
);
export default SvgGlobe;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramOffice02 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 113 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M101.3 81.81H12.5c-.83 0-1.5-.67-1.5-1.5v-47.8a1.54 1.54 0 0 1 .35-.98c.13-.16.3-.28.47-.37.19-.09.4-.15.62-.16h88.93c.21 0 .41.06.59.15s.35.22.49.38q.18.21.27.48c.05.16.08.33.08.49v47.81c0 .83-.67 1.5-1.5 1.5m-87.3-3h85.8V35.52l-42.37 32.1a1.5 1.5 0 0 1-1.82 0L14 35.55zM16.9 34l39.62 30.53L96.84 34z"
    />
    <path
      fill="currentColor"
      d="M100.38 81.5 69.07 57.21l1.84-2.37 31.31 24.29zm-86.96 0-1.85-2.36 31.06-24.32 1.85 2.36-31.06 24.31z"
    />
  </svg>
);
export default SvgPictogramOffice02;

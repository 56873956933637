import ErrorAlert from "@@/components/Error/ErrorAlert";
import Pagination from "@@/components/Pagination";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import useUpdateEffect from "@@/hooks/useUpdateEffect";
import { useEffect, useState } from "react";
import { scroller } from "react-scroll";
import ProductCards from "./Cards";
import useFetchProductsData from "./useFetchProductsData";

type Props = {
  totalMatchesLabel: string;
  errorMessage: string;
  searchRequest: {
    language: string;
    parentpageid: number;
    page: number;
  },
};

const ListingProductContainer = ({
  totalMatchesLabel,
  errorMessage,
  searchRequest,
} : Props) => {
  const PARAMETERS = {
    PAGE: "p",
  };
  const [selectedPage, setSelectedPage] = useState(searchRequest.page ?? 1);
  const { fetchData, loading, error } = useFetchProductsData(selectedPage, searchRequest);

  useEffect(() => {
    const { search, hash } = window.location;
    const params = new URLSearchParams(search);

    if (selectedPage > 1) {
      params.set(PARAMETERS.PAGE, selectedPage.toString());
    } else {
      params.delete(PARAMETERS.PAGE);
    }
    
    let url = window.location.pathname;
    if (params.size > 0 || hash.length > 0) {
      url = `?${params.toString()}${hash}`;
    }
    window.history.replaceState(null, null, url);
  }, [selectedPage]);

  useUpdateEffect(() => {
    scroller.scrollTo("listing-page-scroll", { duration: 200, smooth: true, offset: -100 });
  }, [selectedPage]);

  if (error) return <ErrorAlert message={errorMessage} />;

  return (
    <Container
      backgroundColor="ComplementaryBeigeTint04"
      size="full"
      id="listing-page-scroll"
    >
      <Container pageGutter={0}>
        <ProductCards
          items={fetchData?.items}
          label={`${totalMatchesLabel}: ${fetchData?.totalMatching}`}
          loading={loading}
        />
        <Box mt={[7, 10, 10]}>
          <Pagination
            selectedValue={selectedPage}
            setSelectedValue={setSelectedPage}
            totalPages={fetchData?.totalPage}
            loading={loading}
          />
        </Box>
      </Container>
    </Container>
  );
};

export default ListingProductContainer;

import ResponsiveValue, { arrayFromResponiveValue, FilledResponsiveValue } from "@@/base/responsive-value";
import cn from "classnames";
import { useMemo } from "react";
import css from "./index.module.scss";

const breakpointAbbrevations = [
  "sm",
  "md",
  "lg",
];

const aspectRatioClasses = (aspectRatio: FilledResponsiveValue<string>) => cn({
  [css["ui-sm-aspect-ratio"]]: aspectRatio[0] !== undefined,
  [css["ui-md-aspect-ratio"]]: aspectRatio[1] !== undefined,
  [css["ui-lg-aspect-ratio"]]: aspectRatio[2] !== undefined,
});

const aspectRatioStyles = (aspectRatio: FilledResponsiveValue<string>) =>
  Object.fromEntries(breakpointAbbrevations.reduce((acc, bp, index) => {
    if (aspectRatio[index] === undefined) {
      return acc;
    }
    acc.push([`--ui-${bp}--aspect-ratio`, aspectRatio[index]]);
    return acc;
  }, [] as Array<[PropertyKey, any]>));

const useAspectRatio = (aspectRatio: ResponsiveValue<string>) => useMemo(() => {
  const values = arrayFromResponiveValue(aspectRatio);
  return {
    className: aspectRatioClasses(values),
    style: aspectRatioStyles(values),
  };
}, [aspectRatio]);

export default useAspectRatio;

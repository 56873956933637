import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFacebook = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 35 35"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M0 0h35v35H0z" />
    <g clipPath="url(#facebook_svg__a)">
      <path d="M-602-2775h2336v7565H-602z" />
      <path d="M-394-299h768v937h-768z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 17.5C0 7.835 7.835 0 17.5 0S35 7.835 35 17.5 27.165 35 17.5 35 0 27.165 0 17.5m17.5-8.75c4.813 0 8.75 3.938 8.75 8.75a8.83 8.83 0 0 1-7.547 8.75v-6.234h2.078l.438-2.516h-2.407v-1.64c0-.657.329-1.313 1.422-1.313h1.094v-2.188s-.984-.218-1.969-.218c-1.968 0-3.28 1.203-3.28 3.39V17.5H13.89v2.516h2.187v6.125c-4.156-.657-7.328-4.266-7.328-8.641 0-4.812 3.938-8.75 8.75-8.75"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="facebook_svg__a">
        <path fill="#fff" d="M-602-2775h2336v7565H-602z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFacebook;

import Box from "@@/elements/Box";
import Container, { PageGutterValue } from "@@/elements/Container";
import { Expand } from "@@/elements/Icons/icons";
import { useScrollLock } from "@@/hooks/use-scroll-lock";
import { ReactElement, useEffect, useState } from "react";
import { MediaVariant } from "../Media";
import LightboxModal from "./Modal";
import styles from "./styles.module.scss";

type Props = {
  closeLabel: string;
  closeTitle: string;
  zoomTitle: string;
  zoomOutTitle: string;
  zoomInTitle: string;
  // Only accepts exactly one Picture-component
  children: ReactElement;
  variant?: MediaVariant;
};

const Lightbox = ({
  closeLabel,
  closeTitle,
  zoomTitle,
  zoomOutTitle,
  zoomInTitle,
  children,
  variant,
}: Props) => {
  const [openLightbox, setOpenLightbox] = useState<boolean>(false);
  const { lock, unlock } = useScrollLock();

  const getGutter = (): PageGutterValue => {
    switch (variant) {
    case "fullwidth-landscape":
      return null;
    case "landscape":
      return [5, 0, 0];
    case "square":
      return 0;
    case "portrait":
      return 0;
    default:
      return null;
    }
  };

  useEffect(() => {
    if (openLightbox) {
      lock();
    } else {
      unlock();
    }
  }, [openLightbox]);

  return (
    <Box className={styles.wrapper}>
      {children}
      <button
        title={zoomTitle}
        className={styles.zoom_container}
        onClick={() => setOpenLightbox(true)}
      >
        <Container
          className={styles.zoom_wrapper}
          pageGutterY={0}
          pageGutterX={getGutter()}
          component="div"
        >
          <div className={styles.zoom_icon}>
            <Expand />
          </div>
        </Container>
      </button>
      {!!children.props.imageData && (
        <LightboxModal
          open={openLightbox}
          close={() => setOpenLightbox(false)}
          closeLabel={closeLabel}
          image={children.props.imageData}
          closeTitle={closeTitle}
          zoomOutTitle={zoomOutTitle}
          zoomInTitle={zoomInTitle}
        />
      )}
    </Box>
  );
};

export default Lightbox;

import { useEffect, useState } from "react";
import axios from "axios";
import useUpdateEffect from "@@/hooks/useUpdateEffect";

export enum ErrorCodes {
  NO_RESULT = "NO_RESULT",
  OTHER = "OTHER",
}

enum VARIANTS {
  NEWS = "news",
  ARTICLES = "articles",
}

enum ENDPOINTS {
  NEWS = "/api/search/newspages",
  ARTICLES = "/api/search/articlepages"
}

type UseFetchNewsDataReturn = {
  items: any[];
  page: number;
  categoryFacets: any[];
  yearFacets: any[];
  totalMatching: number;
  totalPage: number;
};

function useFetchNewsData(selectedPage: number, categories: number[], years: number[], variant: VARIANTS, searchRequest) {
  const [fetchData, setFetchData] = useState<UseFetchNewsDataReturn>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const url = variant === VARIANTS.NEWS ? ENDPOINTS.NEWS : ENDPOINTS.ARTICLES;
  const params = {
    ...searchRequest,
    page: selectedPage,
    categories,
    years,
  };

  const fetchNewsData = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.post(url, params);
      setFetchData(response.data);
    } catch (e) {
      if (e?.response?.status === 404) {
        setError(ErrorCodes.NO_RESULT);
      } else {
        setError(ErrorCodes.OTHER);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsData();
  }, [selectedPage]);

  useUpdateEffect(() => {
    if (selectedPage === 1) fetchNewsData();
  }, [categories, years]);

  return {
    fetchData, loading, error,
  };
}
export default useFetchNewsData;

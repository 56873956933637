import Box from "@@/elements/Box";
import Typography from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import styles from "./index.module.scss";

export type CareerContactPersonCardProps = {
  name?: string;
  title?: string;
  phoneNumber?: string;
  email?: string;
};

const CareerContactPersonCard = ({
  name,
  title,
  phoneNumber,
  email,
}: CareerContactPersonCardProps) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);
  function addLineBreak(str) {
    return str.split("@").map((part, index, array) => (
      <>
        {index > 0 && <wbr />}
        {part}
        {index < array.length - 1 && "@"}
      </>
    ));
  }

  return (
    <Box
      className={styles.card}
      py={[10, 10, 12]}
      px={6}
      backgroundColor="ComplementaryBeigeTint04"
    >
      <Box mb={8} className={styles.name_wrapper}>
        {name && <Typography className={styles.name_width} asElement="span" variant="heading5">{name}</Typography>}
        {title && (
        <Typography  className={styles.name_width} mt={1} variant="information">
          {title}
        </Typography>
        )}
      </Box>
      <Box width="full" flexDirection="column" alignItems="center">
        {phoneNumber && !isSmall && <Typography asElement="p" variant={isSmall ? "heading5" : "button"}>{phoneNumber}</Typography>}
        {phoneNumber && isSmall && <a className={styles.phonenumber} href={`tel:${phoneNumber}`}><Typography asElement="p" color="PrimaryGrey" variant={isSmall ? "heading5" : "button"}>{phoneNumber}</Typography></a>}

      </Box>
      <Box width="full" flexDirection="column" alignItems="center">

        {email && (
        <a href={`mailto:${email}`}>
          <Typography mt={2} variant="information" asElement="span">
            {addLineBreak(email)}
          </Typography>
        </a>

        )}
      </Box>
    </Box>
  );
};

export default CareerContactPersonCard;

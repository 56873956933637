/* eslint-disable react/no-array-index-key */
import { TokenValue as BreakpointsTokenValue } from "@@/design-tokens/breakpoints";
import { PictureData } from "@@/models/pictureData";
import { getAspectRatioHeight } from "./helpers";

export type SourceProps = {
  imageData: PictureData,
  aspectRatio: string,
  sizes: number[],
  webp: boolean,
  index: number,
  lazySizes?: boolean;
  contain: boolean,
  sourcesSizes?: string,
};

const Source = (props: SourceProps) => {
  const {
    imageData,
    aspectRatio,
    sizes,
    webp,
    index,
    lazySizes,
    contain,
    sourcesSizes,
  } = props;

  if (!aspectRatio || !sizes || sizes.length === 0) return null;

  const toSrcSet = (hasWebp?: boolean) => sizes
    .map((width) => {
      const quality = 80;
      const height = getAspectRatioHeight(width, aspectRatio);

      let srcSet = `${imageData?.imageUrl}?`;
      srcSet += `${hasWebp ? "format=webp&" : ""}`;
      srcSet += `width=${width}&height=${height}&`;
      srcSet += `${imageData?.focusX && imageData?.focusY ? `rxy=${imageData?.focusX},${imageData?.focusY}&` : ""}`;
      srcSet += contain ? "rmode=pad&" : "",
      srcSet += `quality=${quality} ${width}w`;
      return srcSet;

      // For testing with https://source.unsplash.com/collection/8678360 images
      // return `${imageData.imageUrl}/${width}x${height} ${width}w`;
    })
    .join(", ");

  const getBreakpoint = (i: Number) => {
    switch (i) {
      // sm
      case 2: return null;
      // md
      case 1: return `(min-width: ${BreakpointsTokenValue.Medium}px) `;
      // lg
      case 0: return `(min-width: ${BreakpointsTokenValue.Large}px) `;
      default: return null;
    }
  };

  return (
    <>
      { webp && (
        <source
          data-srcset={lazySizes ? toSrcSet(webp) : undefined}
          srcSet={lazySizes ? undefined : toSrcSet(webp)}
          sizes={lazySizes ? undefined : sourcesSizes}
          media={getBreakpoint(index)}
          type="image/webp"
        />
      )}
      <source
        data-srcset={lazySizes ? toSrcSet() : undefined}
        srcSet={lazySizes ? undefined : toSrcSet()}
        sizes={lazySizes ? undefined : sourcesSizes}
        media={getBreakpoint(index)}
        type={`image/${imageData?.format}`}
      />
    </>
  );
};

export default Source;

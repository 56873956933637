import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import { ArrowRight } from "@@/elements/Icons/icons";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import SecondaryButton from "../../Button/SecondaryButton";
import { CardColors } from "../CardProps";
import cardStyles from "../cardStyles.module.scss";
import styles from "./index.module.scss";

export type InfoCardVariant = "image" | "color";

export type InfoCardProperties = {
  header: string;
  body: string;
  image?: PictureData;
};

export type InfoCardOwnProps = {
  variant: InfoCardVariant;
  color?: CardColors;
  link?: LinkData;
};

export type InfoCardProps = InfoCardOwnProps & InfoCardProperties;

export const InfoCard = ({
  header,
  variant,
  color,
  body,
  link,
  image,
}: InfoCardProps) => {
  const cardClassNames = cn(cardStyles.card, {
    [(cardStyles as any)[`card_${color}`]]: color,
    [(styles as any)[`info_card_${variant}`]]: variant,
  });

  return (
    <Box
      height="full"
      width="full"
      justifyContent="center">
      {variant === "image" && (
        <Box
          width="full"
          height="full"
          flexDirection="column">
          <EpiProperty<InfoCardProperties> name="image">
            <Picture
              className={styles.info_card_image__image}
              imageData={image}
              aspectRatio={"4 / 3"}
              sizes={[200, 400, 600, 800, 1200]}
            />
          </EpiProperty>
          <Box
            flexDirection="column"
            className={styles.text_content}
            textAlign="left"
            gap={6}
          >
            <Box>
              <EpiProperty<InfoCardProperties> name="header">
                <Typography
                  variant="heading5"
                  asElement="h3"
                  mb={3}
                >
                  {header}
                </Typography>
              </EpiProperty>
              <EpiProperty<InfoCardProperties> name="body">
                <Typography variant="information">{body}</Typography>
              </EpiProperty>
            </Box>
            {link && link.text && (
              <Box>
                <TextButtonIcon
                  link={link}
                  dense
                  direction="right"
                >
                  <ArrowRight />
                </TextButtonIcon>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {variant === "color" && (
        <Box
          className={cn(cardClassNames, styles.info_card_color)}
          textAlign="center"
          alignContent="center"
          alignItems="center"
          justifyContent={link?.text ? "space-between" : "flex-start"}
          color="White"
          height="full"
          width="full"
          p={[4, 4, 10]}
        >
          <Box p={2}>
            <EpiProperty<InfoCardProperties> name="header">
              <Box mb={2}>
                <Typography
                  textcolor="light"
                  variant="heading5"
                  asElement="h3">
                  {header}
                </Typography>
              </Box>
            </EpiProperty>
            <EpiProperty<InfoCardProperties> name="body">
              <Typography
                textcolor="light"
                variant="information">
                {body}
              </Typography>
            </EpiProperty>
          </Box>
          {link && link.text && (
            <Box className={styles.button_info}>
              <SecondaryButton
                color="white"
                label={link.text}
                href={link.href}
                target={link.target}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export type ProvidedInfoCardProps = OptimizelyBlockProps<InfoCardProperties> &
InfoCardProps;

const ProvidedInfoCard = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedInfoCardProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <InfoCard
      {...rest}
      {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedInfoCard;

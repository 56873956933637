import Box from "@@/elements/Box";
import classNames from "classnames";
import styles from "./index.module.scss";
import loadingStyles from "../../../elements/Picture/index.module.scss";

const SkeletonSublevelCard = () => {
  return (
    <Box className={styles.skeleton_card}>
      <Box width="full" justifyContent="center" p={6}>
        <Box
          className={classNames(styles.skeleton_header, [
            loadingStyles["stena-picture--loading"],
          ])}
        />
      </Box>
      <Box
        className={classNames(styles.skeleton_image, [
          loadingStyles["stena-picture--loading"],
        ])}
      />
      <Box width="full" p={10}>
        <Box
          className={classNames(styles.skeleton_intro, [
            loadingStyles["stena-picture--loading"],
          ])}
        />

        <Box
          className={classNames(styles.skeleton_button, [
            loadingStyles["stena-picture--loading"],
          ])}
        />
      </Box>
    </Box>
  );
};

export default SkeletonSublevelCard;

import Scroller, { ScrollerItem } from "@@/components/Scroller";
import VideoItem, { VideoItenProperties } from "@@/components/VideoItem";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiContent from "@@/integrations/optimizly/Content";
import EpiProperty from "@@/integrations/optimizly/Property";
import cn from "classnames";
import { useEffect, useState } from "react";
import { TokenValue as bps } from "../../design-tokens/breakpoints";
import styles from "./index.module.scss";

export type BackgroundColors = "beige" | "white";

export type VideoContainerProperties = {
  header?: string;
  intro?: string;
  backgroundColor?: BackgroundColors;
  videos: VideoItenProperties[];
};

export type VideoContainerOwnProps = {
  sliderDesktop?: boolean;
  sliderMobile?: boolean;
};

export type VideoContainerProps = VideoContainerOwnProps &
  VideoContainerProperties;

const VideoContainer = ({
  intro,
  header,
  backgroundColor,
  videos,
  sliderDesktop,
  sliderMobile,
}: VideoContainerProps) => {
  const [slider, setSlider] = useState<boolean>(false);
  useEffect(() => {
    const checkIfSlider = () => {
      if (
        (sliderMobile && window.innerWidth < bps.Large) ||
        (sliderDesktop && window.innerWidth >= bps.Large)
      ) {
        setSlider(true);
      } else setSlider(false);
    };
    checkIfSlider();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIfSlider);

      return () => {
        window.removeEventListener("resize", checkIfSlider);
      };
    }
  }, []);

  const getBackgroundColor = (color: string) => {
    switch (color) {
    case "beige":
      return "ComplementaryBeigeTint03";
    default:
      return "White";
    }
  };

  const getVideoItemBackgroundColor = (color: string) => {
    switch (color) {
    case "beige":
      return "White";
    default:
      return "ComplementaryBeigeTint03";
    }
  };

  return (
    <Container
      backgroundColor={getBackgroundColor(backgroundColor)}
      size="full"
      pageGutter={0}
    >
      <Container>
        {(header || intro) && (
          <Box
            alignItems="center"
            flexDirection="column"
            textAlign="center"
            mb={10}
          >
            <EpiProperty<VideoContainerProperties> name="header">
              <Typhography
                variant="heading1"
                asElement="h2"
                mb={8}>
                {header}
              </Typhography>
            </EpiProperty>
            <EpiProperty<VideoContainerProperties> name="intro">
              <Typhography
                variant="intro"
                asElement="p">
                {intro}
              </Typhography>
            </EpiProperty>
          </Box>
        )}
        {videos?.length === 1 && (
          <Box>
            {videos.map((video, i) => (
              <VideoItem
                key={i}
                backgroundColor={getBackgroundColor(backgroundColor)}
                videoUrl={video.videoUrl}
                header={video.header}
                body={video.body}
                link={video.link}
              />
            ))}
          </Box>
        )}
        {slider && videos?.length !== 1 && (
          <EpiContent>
            <Box>
              <Scroller scollerClassName={styles.scroller}>
                {videos.map((video, index) => (
                  <ScrollerItem
                    key={index}
                    className={cn(styles.scroller_item)}
                  >
                    <VideoItem
                      backgroundColor={getVideoItemBackgroundColor(
                        backgroundColor,
                      )}
                      videoUrl={video.videoUrl}
                      header={video.header}
                      body={video.body}
                      isScrollItem
                      link={video.link}
                    />
                  </ScrollerItem>
                ))}
              </Scroller>
            </Box>
          </EpiContent>
        )}
        {!slider && videos?.length !== 1 && (
          <EpiContent>
            <Box
              className={cn(styles.grid, {
                [styles.simplegrid]: videos.length < 3,
              })}
            >
              {videos.map((video, index) => (
                <Box
                  key={index}
                  className={cn(styles.scroller_item)}>
                  <VideoItem
                    responsive={false}
                    backgroundColor={getVideoItemBackgroundColor(
                      backgroundColor,
                    )}
                    videoUrl={video.videoUrl}
                    header={video.header}
                    body={video.body}
                    isScrollItem
                    link={video.link}
                  />
                </Box>
              ))}
            </Box>
          </EpiContent>
        )}
      </Container>
    </Container>
  );
};

export type ProvidedVideoContainerProps =
  OptimizelyBlockProps<VideoContainerProps> & VideoContainerProps;

const ProvidedVideoContainer = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedVideoContainerProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <VideoContainer
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedVideoContainer;

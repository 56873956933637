import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNewsMedia = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 15"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M4.39 1v12.36c0 .625-.23 1.198-.6 1.64h12.633c.995 0 1.69-.72 1.69-1.75V1zm8.022 2.188h3.378a.43.43 0 0 1 .422.437.43.43 0 0 1-.422.437h-3.378a.43.43 0 0 1-.422-.437.43.43 0 0 1 .422-.437M.38 3.625v9.734c0 .91.706 1.641 1.583 1.641s1.584-.732 1.584-1.64V3.624zm6.333 0h3.378v3.062H6.712zm5.7 2.625h3.378a.43.43 0 0 1 .422.437.43.43 0 0 1-.422.438h-3.378a.43.43 0 0 1-.422-.438.43.43 0 0 1 .422-.437m-5.7 2.844h9.078a.43.43 0 0 1 .422.437.43.43 0 0 1-.422.438H6.712a.43.43 0 0 1-.422-.438.43.43 0 0 1 .422-.437m0 2.844h9.078a.43.43 0 0 1 .422.437.43.43 0 0 1-.422.437H6.712a.43.43 0 0 1-.422-.437.43.43 0 0 1 .422-.437"
    />
  </svg>
);
export default SvgNewsMedia;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramMiscellaneous02 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.659 21.503a1.5 1.5 0 0 1 1.5-1.5h21.483c12.691 0 22.983 10.292 22.983 22.983S42.333 65.97 29.642 65.97 6.66 55.678 6.66 42.986zm3 1.5v19.983c0 11.034 8.949 19.984 19.983 19.984s19.983-8.95 19.983-19.984-8.949-19.983-19.983-19.983z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31.152 44.436a1.5 1.5 0 0 1 2.018-.651c17.154 8.785 28.888 26.4 28.888 46.712a1.5 1.5 0 0 1-3 0c0-19.12-11.043-35.739-27.255-44.042a1.5 1.5 0 0 1-.651-2.019M85.124 35.123c-9.504 0-17.208 7.704-17.208 17.207s7.704 17.208 17.208 17.208 17.207-7.704 17.207-17.208V35.123zM64.916 52.33c0-11.16 9.047-20.207 20.208-20.207h18.707a1.5 1.5 0 0 1 1.5 1.5V52.33c0 11.16-9.047 20.208-20.207 20.208S64.916 63.491 64.916 52.33"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M85.145 52.377a1.5 1.5 0 0 1-.65 2.02c-13.29 6.81-22.338 20.428-22.338 36.1a1.5 1.5 0 1 1-3 0c0-16.865 9.74-31.477 23.969-38.77a1.5 1.5 0 0 1 2.019.65"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramMiscellaneous02;

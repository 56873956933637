import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import PrimaryButton from "../Button/PrimaryButton";
import RichText from "../RichText";
import styles from "./index.module.scss";

type CookieProps = {
  header: string;
  body: string;
  buttonLabel: string;
  backgroundColor: "PrimaryBlueTint03" | "White";
};

const CookieConsentComponent = ({
  header,
  body,
  buttonLabel,
  backgroundColor,
}: CookieProps) => {
  const amount = useIsBreakpointOrBelow(ScreenSize.SMALL) ? 2 : 4;

  return (
    <Container pageGutter={0}>
      <Box
        width="full"
        alignItems="center"
        flexDirection="column">
        <Box
          backgroundColor={
            backgroundColor === "PrimaryBlueTint03"
              ? "PrimaryGreyTint03"
              : "White"
          }
          br={1}
          mb={10}
          customStyle={{ maxWidth: "450px", height: "25px", width: "80%" }}
        />
      </Box>
      <Box
        mb={[6, 14, 14]}
        className={styles.form_grid}>
        {[...Array(amount)].map((i) => {
          return (
            <Box
              key={i}
              alignItems="flex-start"
              flexDirection="column">
              <Box
                backgroundColor={
                  backgroundColor === "PrimaryBlueTint03"
                    ? "PrimaryGreyTint03"
                    : "White"
                }
                br={1}
                mb={3}
                customStyle={{ width: "100px", height: "16px" }}
              />
              <Box
                backgroundColor={
                  backgroundColor === "PrimaryBlueTint03"
                    ? "PrimaryGreyTint03"
                    : "White"
                }
                br={1}
                // mb={[3, 6, 6]}
                customStyle={{ width: "100%", height: "48px" }}
              />
            </Box>
          );
        })}
      </Box>

      <Box
        backgroundColor={backgroundColor}
        p={12}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typhography
          mb={4}
          variant="heading5">
          {header}
        </Typhography>
        <Box mb={6}>
          <RichText body={body} />
        </Box>
        <PrimaryButton
          href="javascript: Cookiebot.show()"
          label={buttonLabel}
        />
      </Box>
    </Container>
  );
};

export default CookieConsentComponent;

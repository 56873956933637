import SublevelCard from "@@/components/Card/Sublevel";
import SkeletonSublevelCard from "@@/components/Card/Sublevel/skeleton";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import axios from "axios";
import { useEffect, useState } from "react";
import styles from "../cardContainer.module.scss";
import { BackgroundColor, colorMapper } from "../service-description";

type SearchRequest = {
  language: string;
  parentPageId: string;
};

export type SublevelListingProps = {
  searchRequest: SearchRequest;
  backgroundColor: BackgroundColor;
};

type SearchResponseType = {
  page: number;
  pageSize: number;
  totalPage: number;
  totalMatching: number;
  language: string;
  items: HitItem[];
  categoryFacets: FacetItem[];
  yearFacets: FacetItem[];
};

type HitItem = {
  publishdate: string;
  header: string;
  intro: string;
  image: PictureData;
  link: LinkData;
  categories: string[];
};

type FacetItem = {
  name: string;
  count: number;
  id: number;
};
const SublevelListingContainer = ({
  searchRequest,
  backgroundColor,
}: SublevelListingProps) => {
  const [responseData, setResponseData] = useState<SearchResponseType>(null);
  const [loading, setLoading] = useState<boolean>(true);
  // const [error, setError] = useState(null);
  const skeletonCards = new Array(3).fill([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.post("/api/search/subpages", searchRequest);
        setResponseData(data);
      } catch (error) {
        // setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box mt={18} backgroundColor={colorMapper(backgroundColor)}>
      <Container
        className={styles.sublevel_container}
        pageGutterX={0}
        size="xlarge"
      >
        <Box className={styles.grid_sublevel}>
          {loading && skeletonCards.map(() => <SkeletonSublevelCard />)}
          {!loading &&
            responseData &&
            responseData.items.map((card) => (
              <SublevelCard
                header={card.header}
                image={card.image}
                intro={card.intro}
                link={card.link}
                backgroundColor={backgroundColor}
              />
            ))}
        </Box>
      </Container>
    </Box>
  );
};

export default SublevelListingContainer;

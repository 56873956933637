import Dropdown from "@@/components/Dropdown";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import FilterLoader from "../../Shared/FilterLoader";
import ResetButton from "../../Shared/ResetButton";
import withoutCountZero from "../../Shared/filterHelpers";
import styles from "./styles.module.scss";

const ListingCareerFilter = ({
  filterLabel,
  clearFilterLabel,
  companyFacets,
  selectedCompanies,
  setSelectedCompanies,
  companyLabel,
  countryFacets,
  selectedCountries,
  setSelectedCountries,
  countryLabel,
  cityFacets,
  selectedCities,
  setSelectedCities,
  cityLabel,
  loading,
  reset,
  resetDisabled,
  occupationFacets,
  selectedOccupations,
  setSelectedOccupations,
  occupationLabel,
}) => {
  const companyFacetsWithoutCountZero = withoutCountZero(companyFacets?.items);
  const countryFacetsWithoutCountZero = withoutCountZero(countryFacets?.items);
  const cityFacetsWithoutCountZero = withoutCountZero(cityFacets?.items);
  const occupationFacetsWithoutCountZero = withoutCountZero(occupationFacets?.items);

  if (
    !companyFacetsWithoutCountZero &&
    !countryFacetsWithoutCountZero &&
    !cityFacetsWithoutCountZero &&
    !occupationFacetsWithoutCountZero &&
    !loading
  ) return <></>;

  return (
    <Container
      pageGutterY={0}
      mb={[6, 10, 12]}>
      {!loading && (
        <>
          <Typhography
            variant="label"
            mb={3}>
            { `${filterLabel}:` }
          </Typhography>
          <Box className={styles.dropdown_wrapper}>
            {!!companyFacets.items && <Dropdown
              options={companyFacetsWithoutCountZero}
              selectedValues={selectedCompanies}
              setSelectedValues={setSelectedCompanies}
              placeholder={companyLabel}
              clearButtonText={clearFilterLabel}
              singleSelect={!companyFacets?.mother}
              disabled={!companyFacetsWithoutCountZero || !companyFacetsWithoutCountZero.length}
              fullWidth
              truncation
            />}

            {!!countryFacets.items && <Dropdown
              options={countryFacetsWithoutCountZero}
              selectedValues={selectedCountries}
              setSelectedValues={setSelectedCountries}
              placeholder={countryLabel}
              clearButtonText={clearFilterLabel}
              singleSelect={!countryFacets?.mother}
              disabled={!countryFacetsWithoutCountZero || !countryFacetsWithoutCountZero.length}
              fullWidth
              truncation
            />}

            {!!cityFacets.items && <Dropdown
              options={cityFacetsWithoutCountZero}
              selectedValues={selectedCities}
              setSelectedValues={setSelectedCities}
              placeholder={cityLabel}
              clearButtonText={clearFilterLabel}
              singleSelect={!cityFacets?.mother}
              disabled={!cityFacetsWithoutCountZero || !cityFacetsWithoutCountZero.length}
              fullWidth
              truncation
            />}

            {!!occupationFacets.items && <Dropdown
              options={occupationFacetsWithoutCountZero}
              selectedValues={selectedOccupations}
              setSelectedValues={setSelectedOccupations}
              placeholder={occupationLabel}
              clearButtonText={clearFilterLabel}
              singleSelect={!occupationFacets?.mother}
              disabled={!occupationFacetsWithoutCountZero || !occupationFacetsWithoutCountZero.length}
              fullWidth
              truncation
            />}
          </Box>
          <ResetButton
            onClick={reset}
            disabled={resetDisabled}
            label={clearFilterLabel} />
        </>
      )}
      {loading && <FilterLoader />}
    </Container>
  );
};

export default ListingCareerFilter;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramNumbers08 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 226.77 226.77"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M142.38 127.33c0 4.25-.76 8.06-2.28 11.44s-3.57 6.24-6.17 8.58-5.68 4.14-9.23 5.39c-3.55 1.26-7.33 1.89-11.31 1.89s-7.86-.63-11.38-1.89c-3.51-1.25-6.56-3.05-9.17-5.39s-4.66-5.2-6.17-8.58c-1.52-3.38-2.27-7.19-2.27-11.44 0-2.86.37-5.42 1.1-7.67s1.71-4.29 2.92-6.11 2.58-3.42 4.1-4.81q2.28-2.085 4.74-3.51c-3.03-1.91-5.68-4.53-7.93-7.86-2.25-3.34-3.38-7.48-3.38-12.42 0-3.9.72-7.45 2.15-10.66q2.145-4.815 5.85-8.25c2.47-2.3 5.37-4.05 8.71-5.27 3.34-1.21 6.91-1.82 10.73-1.82s7.39.61 10.73 1.82c3.33 1.21 6.24 2.97 8.71 5.27q3.705 3.45 5.85 8.25c1.43 3.21 2.15 6.76 2.15 10.66 0 4.94-1.13 9.08-3.38 12.42s-4.9 5.96-7.93 7.86c1.65.95 3.23 2.12 4.75 3.51s2.88 2.99 4.09 4.81 2.19 3.86 2.93 6.11c.73 2.25 1.1 4.81 1.1 7.67Zm-13.52-.26c0-2.17-.39-4.22-1.17-6.17s-1.86-3.62-3.25-5c-1.39-1.39-3.03-2.49-4.94-3.31s-3.94-1.23-6.11-1.23-4.31.41-6.17 1.23-3.49 1.93-4.88 3.31c-1.39 1.39-2.47 3.05-3.25 5s-1.17 4.01-1.17 6.17.39 4.21 1.17 6.11c.78 1.91 1.86 3.55 3.25 4.94s3.01 2.49 4.88 3.32c1.86.83 3.92 1.24 6.17 1.24s4.2-.41 6.11-1.24c1.91-.82 3.55-1.93 4.94-3.32 1.39-1.38 2.47-3.03 3.25-4.94s1.17-3.94 1.17-6.11m-1.56-41.73c0-4.16-1.28-7.61-3.83-10.34-2.56-2.73-5.92-4.09-10.07-4.09s-7.52 1.36-10.08 4.09-3.83 6.18-3.83 10.34 1.28 7.58 3.83 10.27c2.56 2.69 5.92 4.03 10.08 4.03s7.52-1.34 10.07-4.03c2.56-2.69 3.83-6.11 3.83-10.27"
    />
  </svg>
);
export default SvgPictogramNumbers08;

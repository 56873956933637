import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgYoutube = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 35 35"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="currentColor" d="m20.344 17.5-4.594-2.625v5.25z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 17.5C0 7.835 7.835 0 17.5 0S35 7.835 35 17.5 27.165 35 17.5 35 0 27.165 0 17.5m24.281-5.797a2.17 2.17 0 0 1 1.532 1.531c.437 1.422.437 4.266.437 4.266s0 2.844-.328 4.266a2.17 2.17 0 0 1-1.531 1.53c-1.422.329-6.891.329-6.891.329s-5.578 0-6.89-.328a2.17 2.17 0 0 1-1.532-1.531C8.75 20.344 8.75 17.5 8.75 17.5s0-2.844.219-4.266a2.17 2.17 0 0 1 1.531-1.53c1.422-.329 6.89-.329 6.89-.329s5.579 0 6.891.328"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgYoutube;

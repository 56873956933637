import Box from "@@/elements/Box";
import { Add } from "@@/elements/Icons/icons";
import SvgMinus from "@@/elements/Icons/icons/Minus";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import { screenSizeIsSmall } from "@@/hooks/device/use-breakpoint";
import { PaddingValue } from "@@/hooks/ui/use-spacing";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import SnwButtonContainer from "../Button/SnwButtonContainer";
import TextButtonIcon from "../Button/TextButtonIcon";
import { SnwButtonType } from "../Button/buttonProps";
import { BackgroundColors } from "../Quote";
import RichText from "../RichText";
import styles from "./styles.module.scss";

type TestimonialItemEditableProperties = {
  image: PictureData;
  header: string;
  body: string;
};

type TestimonialItemOwnProps = {
  backgroundColor: BackgroundColors;
  circularMask?: boolean;
  multi?: boolean;
  buttons?: SnwButtonType[];
  showAllText: string;
  showLessText: string;
};

export type TestimonialData = TestimonialItemEditableProperties &
TestimonialItemOwnProps;

const TestimonialItem = ({
  circularMask,
  image,
  header,
  body,
  backgroundColor,
  buttons,
  multi,
  showAllText,
  showLessText,
}: TestimonialData) => {
  const [open, setOpen] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [smallScreen, setSmallScreen] = useState<boolean>(false);
  const componentRef = useRef<HTMLHeadingElement>(null);
  const containerRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (screenSizeIsSmall()) {
      setSmallScreen(true);
    }
  }, []);

  const colorMapper = (color) => {
    switch (color) {
      case "grey":
        return "PrimaryGreyTint03";
      case "green":
        return "PrimaryGreenTint03";
      case "blue":
        return "PrimaryBlueTint03";
      case "orange":
        return "ComplementaryOrangeTint04";
      case "beige":
        return "ComplementaryBeigeTint03";
      default:
        return "PrimaryBlueTint03";
    }
  };

  const clickReadMore = () => {
    setOpen(!open);
  };

  const renderButtons = () => {
    if (buttons) {
      if (buttons.length === 1) {
        return "flex-end";
      }
      if (multi && buttons.length > 1) {
        return "space-between";
      }
      if (!multi && buttons.length > 1) {
        return "center";
      }
    }
    return "center";
  };
  const contentWrapperClassNames = cn(styles.content_wrapper, {
    [styles.content_wrapper__open]: open,
    [styles.content_wrapper__closed]: !open,
  });

  const richTextFadeClassNames = cn(
    { [styles.richText_fade]: showReadMore && !open },
    { [styles[`richText_fade__${backgroundColor}`]]: showReadMore && !open },
  );

  const handleResize = () => {
    const container = containerRef.current.clientHeight;
    const component = componentRef.current.clientHeight;
    if (!open) setShowReadMore(component >= container);
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [componentRef, containerRef, open]);

  const paddingContent: PaddingValue = [10, multi ? 10 : 20, multi ? 30 : 0];

  return (
    <Box className={styles.item_wrapper} mt={3}>
      <Box className={styles.image_wrapper}>
        <EpiProperty<TestimonialItemEditableProperties> name="image">
          <Picture
            circular={circularMask}
            aspectRatio={circularMask ? "1 / 1" : "7 / 2"}
            imageData={image}
            className={
              circularMask ? styles.circular_picture : styles.square_picture
            }
            imgClassName={
              circularMask ? styles.circular_image : styles.square_image
            }
            sizes={[[200, 400, 600], []]}
          />
        </EpiProperty>
      </Box>

      <Box
        className={contentWrapperClassNames}
        backgroundColor={colorMapper(backgroundColor)}
        alignItems="center"
        pb={[10, 10, 18]}
        pt={[23, 23, 31]}
        flexDirection="column"
      >
        <Box
          ref={containerRef}
          className={styles.richText_wrapper}
          px={paddingContent}
        >
          <Box className={richTextFadeClassNames} />
          <div ref={componentRef}>
            <EpiProperty<TestimonialItemEditableProperties> name="header">
              <Typhography
                variant="heading3"
                mb={6}
                textAlign={multi ? "left" : "center"}
              >
                {header}
              </Typhography>
            </EpiProperty>
            <EpiProperty<TestimonialItemEditableProperties> name="body">
              <RichText body={body} />
            </EpiProperty>
          </div>
        </Box>
        {showReadMore && (
          <Box
            className={styles.readMoreButton}
            justifyContent="flex-start"
            width="full"
            px={paddingContent}
            mt={4}
          >
            <TextButtonIcon
              onClick={clickReadMore}
              label={open ? showLessText : showAllText}
              dense
              direction="right"
            >
              {open ? <SvgMinus /> : <Add />}
            </TextButtonIcon>
          </Box>
        )}
        {buttons && buttons.length > 0 && (
        <Box
          className={styles.buttons_wrapper}
          pt={[10, 10, 14]}
          gap={4}
          justifyContent={!smallScreen ? renderButtons() : "center"}
          alignItems="center"
          flexWrap="wrap-reverse"
          width="full"
          px={paddingContent}
        >
          <SnwButtonContainer buttons={buttons} />
        </Box>)}
      </Box>
    </Box>
  );
};

type ProvidedTestimonialItemProps =
  OptimizelyBlockProps<TestimonialItemEditableProperties> & TestimonialData;

const ProvidedTestimonialItem = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedTestimonialItemProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <TestimonialItem {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedTestimonialItem;

import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import styles from "./styles.module.scss";

type Props = {
  suggestions?: string[];
  suggestionMessage?: string;
  noMatchesMessage: string;
  noResultMessage: string;
  noMatches: boolean;
  setQuery?: Function;
  searchQuery: string;
};

const HelpMessage = ({
  suggestions,
  suggestionMessage,
  noMatchesMessage,
  noResultMessage,
  noMatches,
  setQuery,
  searchQuery,
}: Props) => (
  <Box>
    { !!suggestions?.length && (
    <Box>
      <Typhography variant="intro" className={styles.word_wrapper}>
        { suggestionMessage }
      </Typhography>
      {suggestions.map((suggestion, i) => (
        <Box key={i} className={styles.word_wrapper}>
          <button type="button" className={styles.suggestion_button} onClick={() => setQuery(suggestion)}>
            <Typhography variant="intro">
              { suggestion }
            </Typhography>
          </button>
          {i !== suggestions.length - 1 && ","}
        </Box>
      ))}
      <Typhography variant="intro" className={styles.word_wrapper}>
        ?
      </Typhography>
    </Box>
    )}
    { (!suggestions?.length && noMatches) && (
    <Typhography variant="intro">
      { noResultMessage }
    </Typhography>
    )}
    { noMatches && (
    <Box flexDirection="row" mt={4}>
      <Typhography variant="body">
        { noMatchesMessage }
      </Typhography>
      <Typhography variant="body" ml={1} color="Danger">
        {searchQuery}
      </Typhography>
    </Box>
    )}
  </Box>
);

export default HelpMessage;

import useColors, { ColorProps } from "@@/hooks/ui/use-colors";
import cn from "classnames";
import React from "react";
import ResponsiveValues, {
  arrayFromResponiveValue,
} from "../../base/responsive-value";
import useSpacing, { SpacingProps } from "../../hooks/ui/use-spacing";
import css from "./index.module.scss";

export type PageGutterValue = ResponsiveValues<
0 | 3 | 5 | 10 | 14 | 20 | "7-5p"
>;

export type ContainerSize = ResponsiveValues<
"xlarge" | "large" | "medium" | "small" | "xsmall" | "full"
>;

type Element = "div" | "article" | "section" | "header";

export type ContainerProps = SpacingProps &
ColorProps & {
  className?: string;
  pageGutterX?: PageGutterValue;
  pageGutterY?: PageGutterValue;
  pageGutter?: PageGutterValue;
  positionRoot?: boolean;
  component?: Element;
  size?: ContainerSize;
  children: React.ReactNode | React.ReactNode[];
  hero?: boolean;
  footer?: boolean;
  customStyle?: React.CSSProperties;
  id?: string;
};

const Container = React.forwardRef<HTMLElement, ContainerProps>(
  (
    {
      className,
      pageGutterX,
      pageGutterY,
      pageGutter,
      positionRoot,
      component,
      children,
      hero,
      size,
      customStyle,
      id,
      ...props
    },
    ref,
  ) => {
    const spacing = useSpacing(props);
    const guttersX =
      pageGutterX !== undefined
        ? arrayFromResponiveValue(pageGutterX)
        : arrayFromResponiveValue(pageGutter);
    const guttersY =
      pageGutterY !== undefined
        ? arrayFromResponiveValue(pageGutterY)
        : arrayFromResponiveValue(pageGutter);
    const sizes = arrayFromResponiveValue(size);
    const colors = useColors(props);

    return React.createElement(
      component || "section",
      {
        "data-epi-edit": props["data-epi-edit"],
        className: cn(
          css["stena-container"],
          colors.className,
          {
            [css["stena-container--dgx"]]: !pageGutterX || !guttersX.length,
            [css["stena-container--dgy"]]: !pageGutterY || !guttersY.length,
            [css["stena-container--root"]]: positionRoot,
            [(css as any)[`stena-container--sm-size-${sizes[0]}`]]:
              typeof sizes[0] !== "undefined",
            [(css as any)[`stena-container--md-size-${sizes[1]}`]]:
              typeof sizes[1] !== "undefined",
            [(css as any)[`stena-container--lg-size-${sizes[2]}`]]:
              typeof sizes[2] !== "undefined",

            [(css as any)[`stena-container--sm-gx-${guttersX[0]}`]]:
              typeof guttersX[0] !== "undefined",
            [(css as any)[`stena-container--md-gx-${guttersX[1]}`]]:
              typeof guttersX[1] !== "undefined",
            [(css as any)[`stena-container--lg-gx-${guttersX[2]}`]]:
              typeof guttersX[2] !== "undefined",

            [(css as any)[`stena-container--sm-gy-${guttersY[0]}`]]:
              typeof guttersY[0] !== "undefined",
            [(css as any)[`stena-container--md-gy-${guttersY[1]}`]]:
              typeof guttersY[1] !== "undefined",
            [(css as any)[`stena-container--lg-gy-${guttersY[2]}`]]:
              typeof guttersY[2] !== "undefined",
          },
          spacing.className,
          className,
        ),
        style: {
          ...spacing.style,
          ...customStyle,
        },
        id,
        ref,
      },
      children,
    );
  },
);

export default Container;

import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import Accordion from "..";
import styles from "../accordionStyles.module.scss";

type MaterialProps = {
    header: string,
    materials: string[]
}
const AccordionMaterials = ({header, materials}: MaterialProps ) => {
    return (
<Accordion header={header} backgroundColor="light-blue">
    <Box flexWrap="wrap" flexDirection="row">
        {materials.map((material, index) => (
            <Box br={1} py={1} className={styles.materials} key={index} backgroundColor="ComplementaryBeigeTint04">
                <Typhography variant="information" asElement="div">{material}</Typhography>
            </Box>
        ))}
    </Box>
</Accordion>
)}

export default AccordionMaterials;
import React from "react";

export type OptimizlyContextValue = {
  inEditMode: boolean,
};

const OptimizlyContext = React.createContext<OptimizlyContextValue>({
  inEditMode: false,
});

export default OptimizlyContext;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramArrows01 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.337 22.9c9.04-10.007 22.117-16.292 36.658-16.292 27.28 0 49.397 22.117 49.397 49.387s-22.107 49.387-49.387 49.387S6.618 83.275 6.618 55.995c0-8.184 1.985-15.904 5.51-22.699a1.482 1.482 0 1 1 2.63 1.364c-3.31 6.382-5.177 13.636-5.177 21.335 0 25.643 20.78 46.424 46.424 46.424s46.424-20.79 46.424-46.424c0-25.633-20.79-46.424-46.434-46.424-13.668 0-25.957 5.904-34.46 15.316a1.482 1.482 0 0 1-2.198-1.987"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.003 29.969a1.48 1.48 0 0 1 1.899.885l4.247 11.655a1.482 1.482 0 0 1-2.784 1.014l-3.74-10.263L3.37 36.99a1.482 1.482 0 1 1-1.013-2.784z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramArrows01;

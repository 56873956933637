import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import {
  ArrowRight,
  Calender,
  Case,
  FindOutlined,
} from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import styles from "./styles.module.scss";

export type Props = {
  company: string;
  title: string;
  locationLabel: string;
  location: string;
  dateLabel: string;
  date: string;
  occupation: string;
  occupationLabel: string;
  link: LinkData;
};

const CareerCard = ({
  company,
  title,
  locationLabel,
  location,
  dateLabel,
  date,
  link,
  occupation,
  occupationLabel,
}: Props) => (
  <Box
    className={styles.card}
    pt={10}
    px={8}
    pb={8}
    backgroundColor="White"
    flexDirection="column"
    color="PrimaryGrey"
    justifyContent="space-between"
  >
    {link && link.href && (
      <a href={link.href} title={link.title} target={link.target}>
        <span className={styles.link} />
      </a>
    )}
    <Box height="full" flexDirection="column" alignItems="flex-start">
      <Box>
        <Typhography variant="link">{company}</Typhography>
        <div className={styles.divider} />

        <Box className={styles.title_height}>
          <Typhography asElement="h3" variant="heading5">
            {title}
          </Typhography>
        </Box>
      </Box>
      {location && (
        <>
          <Box mt={10} flexDirection="row" gap={2}>
            <FindOutlined height={16} width={16} />
            <Typhography variant="link">{locationLabel}</Typhography>
          </Box>
          <Typhography
            variant="information"
            mt={2}
            color="ComplementaryCharcoalTint02"
          >
            {location}
          </Typhography>
        </>
      )}
      {occupation && (
        <>
          <Box mt={6} flexDirection="row" gap={2}>
            <Case height={16} width={16} />
            <Typhography variant="link">{occupationLabel}</Typhography>
          </Box>
          <Typhography
            variant="information"
            mt={2}
            color="ComplementaryCharcoalTint02"
          >
            {occupation}
          </Typhography>
        </>
      )}
      {date && (
        <>
          <Box mt={6} flexDirection="row" gap={2}>
            <Calender height={16} width={16} />
            <Typhography variant="link">{dateLabel}</Typhography>
          </Box>
          <Typhography
            variant="information"
            mt={2}
            color="ComplementaryCharcoalTint02"
          >
            {date}
          </Typhography>
        </>
      )}
    </Box>
    {link && link.href && (
      <Box pt={8} mb={2}>
        <TextButtonIcon link={link} dense direction="right">
          <ArrowRight />
        </TextButtonIcon>
      </Box>
    )}
  </Box>
);

export default CareerCard;


import ResponsiveValue, { arrayFromResponiveValue } from "../../../base/responsive-value";
import cn from "classnames";
import css from "./use-spacing.module.scss";
import { useMemo } from "react";

export type MarginValue = ResponsiveValue<number>;
export type PaddingValue = ResponsiveValue<number>;

export type SpacingProps = {
  m?: MarginValue; // margin
  mt?: MarginValue; // margin-top
  mr?: MarginValue; // margin-right
  mb?: MarginValue; // margin-bottom
  ml?: MarginValue; // margin-left
  mx?: MarginValue; // margin-left and margin-right
  my?: MarginValue; // margin-top and margin-bottom
  p?: PaddingValue; // padding
  pt?: PaddingValue; // padding-top
  pr?: PaddingValue; // padding-right
  pb?: PaddingValue; // padding-bottom
  pl?: PaddingValue; // padding-left
  px?: PaddingValue; // padding-left and padding-right
  py?: PaddingValue; // padding-top and padding-bottom
};

type CssKeys = keyof SpacingProps;

const breakpointAbbrevations = [
  "sm", 
  "md", 
  "lg"
];

const propNames = [
  "m",
  "mt",
  "mr",
  "mb",
  "ml",
  "mx",
  "my",
  "p",
  "pt",
  "pr",
  "pb",
  "pl",
  "px",
  "py"] as unknown as [CssKeys];

const spacingClasses = (props: SpacingProps) => {
  const propClasses = (name: CssKeys) => {
    const prop = props[name];
    if (typeof prop === "undefined") {
      return null;
    }

    const spacings = arrayFromResponiveValue(prop);

    return cn({
        [(css as any)[`ui-sm-${name}`]]: spacings[0] !== undefined,
        [(css as any)[`ui-md-${name}`]]: spacings[1] !== undefined,
        [(css as any)[`ui-lg-${name}`]]: spacings[2] !== undefined,
      });
  };

  return cn(propNames.map(propClasses));
};

const spacingStyles = (props: SpacingProps) =>
  Object.fromEntries(propNames.reduce((acc, name) => {
    const prop = props[name];
    if (typeof prop === "undefined") {
      return acc;
    }

    const spacings = arrayFromResponiveValue(prop);
    for (var i = 0; i < breakpointAbbrevations.length; i += 1) {
      if (spacings[i] !== undefined) {
        acc.push([`--ui-${breakpointAbbrevations[i]}-${name}`, spacings[i]]);
      }
    }

    return acc;
  }, [] as Array<[PropertyKey, any]>));

const useSpacing = (props: SpacingProps) => ({
  className: useMemo(() => spacingClasses(props), propNames.map((name) => props[name])),
  style: useMemo(() => spacingStyles(props), propNames.map((name) => props[name])),
});

export default useSpacing;
import Loader from "@@/components/Loader/Skeleton/Cards";
import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import ProductCard, { Props as ProductCardProps } from "./card";
import styles from "./styles.module.scss";

type Props = {
  items: ProductCardProps[];
  label: string;
  loading: boolean;
};

const ProductCards = ({ items, label, loading } : Props) => {
  if (loading || !items) {
    return (
      <Box pt={5}>
        <Loader amount={12} />
      </Box>
    );
  }

  return (
    <>
      <Typhography
        variant="label"
        mb={4}
        uppercase
      >
        {label}
      </Typhography>
      <Box className={styles.wrapper}>
        {items.map((item, i) => <ProductCard key={i} {...item} />)}
      </Box>
    </>
  );
};

export default ProductCards;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWebOrder = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 15"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M17.74 1.5H3.12C2.8.62 1.94 0 .96 0H.5C.22 0 0 .22 0 .5s.22.5.5.5h.48c.12 0 .24.02.34.06.26.08.5.24.66.44.12.16.22.36.26.58l1.5 9c.04.24.24.42.5.42h12c.24 0 .46-.18.5-.42l1.5-9c.02-.14-.02-.3-.12-.4a.5.5 0 0 0-.38-.18"
    />
    <path
      fill="#62636A"
      d="M13.742 13.5c0-.82.68-1.5 1.5-1.5s1.5.68 1.5 1.5-.68 1.5-1.5 1.5-1.5-.68-1.5-1.5M3.742 13.5c0-.82.68-1.5 1.5-1.5s1.5.68 1.5 1.5-.68 1.5-1.5 1.5-1.5-.68-1.5-1.5"
    />
  </svg>
);
export default SvgWebOrder;

import Box from "@@/elements/Box";
import Typography from "@@/elements/Typography";
import { useIsBreakpointOrBelow, ScreenSize } from "@@/hooks/device/use-breakpoint";
import { LinkData } from "@@/models/linkdata";
import classNames from "classnames";
import styles from "../index.module.scss";

export type SmallCompanyContactCardProps = {
  label: string;
  header: string;
  address?: string;
  locationLink?: LinkData;
  postalAddressLabel?: string;
  postalAddress?: string;
  phoneNumber?: string;
  emailLink?: LinkData;
  websiteLink?: LinkData;
};

const SmallCompanyContactCard = ({
  label,
  header,
  address,
  locationLink,
  postalAddressLabel,
  postalAddress,
  phoneNumber,
  emailLink,
  websiteLink,
}: SmallCompanyContactCardProps) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);

  return (
    <Box
      className={classNames(styles.company_card)}
      backgroundColor="ComplementaryBeigeTint04"
      width="full"
      height="full"
      flexDirection="column"
      br={1}
      color="PrimaryGrey"
      p={8}
    >
      <Box mb={6}>
        <Typography variant="link">{label}</Typography>
        <hr className={styles.card_line} />
        <Typography variant="heading5" asElement="h3">
          {header}
        </Typography>
      </Box>

      <Box mb={address || locationLink ? 6 : 0}>
        {address && <Typography variant="information">{address}</Typography>}
        {locationLink && (
        <a
          href={locationLink.href}
          title={locationLink.title}
          target={locationLink.target}
        >
          <Typography color="Info" variant="information">
            {locationLink.text}
          </Typography>
        </a>
        )}
      </Box>
      {postalAddress && (
      <Box mb={6}>
        <Typography variant="link">{postalAddressLabel}</Typography>
        <Typography variant="information">{postalAddress}</Typography>
      </Box>
      )}

      <Box>
        {phoneNumber && !isSmall && <Typography asElement="p" variant={isSmall ? "heading5" : "button"}>{phoneNumber}</Typography>}
        {phoneNumber && isSmall && <a className={styles.phonenumber} href={`tel:${phoneNumber}`}><Typography color="PrimaryGrey" asElement="p" variant={isSmall ? "heading5" : "button"}>{phoneNumber}</Typography></a>}
        {emailLink.text && emailLink.href && (
          <Box pt={1}>
            <a
              href={`mailto:${emailLink.href}`}
              title={emailLink.title}
              target={emailLink.target}
            >
              <Typography color="Info" variant="information">
                {emailLink.href}
              </Typography>
            </a>
          </Box>
        )}
        {websiteLink && (
          <Box pt={1}>
            <a
              href={websiteLink.href}
              title={websiteLink.title}
              target={websiteLink.target}
            >
              <Typography color="Info" variant="information">
                {websiteLink.text}
              </Typography>
            </a>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default SmallCompanyContactCard;

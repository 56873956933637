import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLinkdin = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 35 35"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M35 17.5C35 27.165 27.165 35 17.5 35S0 27.165 0 17.5 7.835 0 17.5 0 35 7.835 35 17.5M9.944 25.25V14.884h3.294V25.25zm1.647-11.819c-1.066 0-1.841-.775-1.841-1.84a1.84 1.84 0 0 1 1.84-1.841c1.066 0 1.841.775 1.841 1.84 0 1.066-.775 1.841-1.84 1.841M25.056 25.25h-3.1v-5.619c0-1.647-.678-2.131-1.647-2.131-.968 0-1.937.775-1.937 2.228v5.522h-3.294V14.884h3.1v1.453c.29-.678 1.453-1.743 3.1-1.743 1.84 0 3.778 1.065 3.778 4.262z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLinkdin;

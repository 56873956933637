import Box from "@@/elements/Box";
import { PaddingValue } from "@@/hooks/ui/use-spacing";
import cn from "classnames";
import { useContext } from "react";
import ScrollerContext from "../context";
import NavButtonWrapper from "../nav-button/buttonWrapper";
import css from "./index.module.scss";

export type SegmentedBarProps = {
  className?: string;
  barPx?: PaddingValue;
};

const SegmentedBar = ({
  className,
  barPx,
}: SegmentedBarProps) => {
  const { items, contrast, setTargetItem } = useContext(ScrollerContext);
  return (
    <Box flexDirection="row" width="full" pt={6} flexWrap={["nowrap", "wrap", "wrap"]} justifyContent="space-between" gap={[6, 8, 8]}>

      <Box aria-hidden="true" px={barPx} order={[1, 0, 0]} width="full" className={cn(css["hbd-scroller__segmentconainer"])}>
        <ol className={cn(css["hbd-scroller__segments"], className)}>
          {items.map((item, index) => (
            <li
              key={index}
              style={{ width: `${(100 / items.length)}%` }}
              className={cn(css["hbd-scroller__segment"])}
            >
              <button
                type="button"
                aria-label="Scroll to section"
                tabIndex={-1}
                className={cn(css["hbd-scroller__segment-button"], {
                  [css["hbd-scroller__segment-button--active"]]: item === "fully",
                  [css["hbd-scroller__segment-button--partial"]]: item === "partial",
                  [css["hbd-scroller__segment-button--contrast"]]: contrast,
                })}
                onClick={() => setTargetItem(index)}
              />
            </li>
          ))}
        </ol>
      </Box>
      <NavButtonWrapper />
    </Box>
  );
};

export default SegmentedBar;

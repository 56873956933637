import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { Info, Warning } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import classNames from "classnames";
import RichText from "../RichText";
import Styles from "./bannerStyles.module.scss";

type EditableProps = {
  header?: string;
  body: string;
};
type BannerProps = EditableProps & {
  color: "danger" | "warning" | "information";
  icon: "warning" | "information";
};

const Banner = ({ header, body, color, icon } : BannerProps) => {
 
  const isMobileDevice = useIsBreakpointOrBelow(ScreenSize.SMALL);
  const warningIcon = <Box
    flexDirection="row"
    alignSelf="flex-start"><Warning /></Box>;
  const infoIcon = <Box
    flexDirection="row"
    alignSelf="flex-start"
    mb={1}><Info /></Box>;
  const iconComponent = icon === "warning" ?  warningIcon : infoIcon;

  const bannerClasses = classNames(Styles.banner, {
    [Styles.banner_danger]: color === "danger",
    [Styles.banner_warning]: color === "warning",
    [Styles.banner_information]: color === "information",
  });

  const renderHeadline = () => {
    if (header) {
      return (
        <EpiProperty<EditableProps> name="header">
          <Typhography
            variant="heading6"
            asElement="p"
          >{header}</Typhography>
        </EpiProperty>
      );
    }
    return (
      <>
        <EpiProperty<EditableProps> name="header">
          <></>
        </EpiProperty>
        <EpiProperty<EditableProps> name="body">
          <RichText body={body} />
        </EpiProperty>
      </>
    );
  };
  const renderMessage = () => {
    if (header)
      return (
        <EpiProperty<EditableProps> name="body">
          <Box className={Styles.banner__body}>
            <RichText body={body} />
          </Box>
        </EpiProperty>
      );
  };

  const renderMobileBanner = () => (
    <Box
      flexDirection="row"
      gap={3}
      alignItems="flex-start">
      {iconComponent}
      <Box flexDirection="column">
        {renderHeadline()}
        {renderMessage()}
      </Box>
    </Box>
  );

  const renderDesktopBanner = () => (
    <Box className={Styles.banner__container}>
      <Box className={Styles.banner__headline}>
        {iconComponent}
        {renderHeadline()}
      </Box>
      {renderMessage()}
    </Box>
  );

  return (
    <Box
      className={bannerClasses}>
      <Container pageGutter={0}>
        {isMobileDevice && renderMobileBanner()}
        {!isMobileDevice && renderDesktopBanner()}
      </Container>
    </Box>
  );
};

export type ProvidedBannerProps = OptimizelyBlockProps<EditableProps> & BannerProps;

const ProvidedBanner = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedBannerProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <Banner
      {...rest}
      {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedBanner;
import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramFinance11 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M36.903 42.374c0-8.663 8.928-15.082 19.092-15.082s19.092 6.42 19.092 15.082a1.5 1.5 0 1 1-3 0c0-6.331-6.818-12.082-16.092-12.082s-16.092 5.75-16.092 12.082c0 3.05 1.646 5.277 4.646 7.2 3.057 1.959 7.267 3.429 11.916 4.967 4.577 1.51 9.162 3.083 12.594 5.281 3.49 2.235 6.028 5.278 6.028 9.725 0 8.662-8.928 15.081-19.092 15.081S36.903 78.22 36.903 69.547a1.5 1.5 0 0 1 3 0c0 6.34 6.817 12.081 16.092 12.081s16.092-5.75 16.092-12.081c0-3.05-1.646-5.278-4.645-7.198-3.058-1.958-7.268-3.426-11.917-4.96h-.001c-4.576-1.514-9.162-3.09-12.594-5.29-3.49-2.236-6.027-5.279-6.027-9.725"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M55.995 16.872a1.5 1.5 0 0 1 1.5 1.5v9.384a1.5 1.5 0 0 1-3 0v-9.384a1.5 1.5 0 0 1 1.5-1.5M55.995 82.745a1.5 1.5 0 0 1 1.5 1.5v9.383a1.5 1.5 0 0 1-3 0v-9.383a1.5 1.5 0 0 1 1.5-1.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramFinance11;

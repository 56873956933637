import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramContainers051 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M66.568 21.926h12.444c1.58 0 2.963 1.284 2.963 2.963 0 1.58-1.284 2.963-2.963 2.963h-.493v14.913h.493c1.58 0 2.963 1.284 2.963 2.963 0 1.58-1.284 2.963-2.963 2.963l-.493-.099.098 14.914h.395c1.58 0 2.963 1.284 2.963 2.963 0 1.58-1.284 2.963-2.963 2.963h-.493v15.012h.493c1.58 0 2.963 1.284 2.963 2.963s-1.284 2.963-2.963 2.963H32.988c-1.58 0-2.963-1.284-2.963-2.963s1.284-2.963 2.963-2.963h.493V69.432h-.493c-1.58 0-2.963-1.284-2.963-2.963 0-1.58 1.284-2.963 2.963-2.963h.395l.098-14.914-.493.1c-1.58 0-2.963-1.285-2.963-2.964 0-1.58 1.284-2.963 2.963-2.963h.493V27.852h-.493c-1.58 0-2.963-1.284-2.963-2.963 0-1.58 1.284-2.963 2.963-2.963h33.58"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M61.53 52.148c-5.036-5.926-5.53-8.099-5.629-8.395 0 .296-.494 2.47-5.63 8.395-5.925 6.914-2.468 15.605 5.532 16h.098c8.198-.395 11.556-8.988 5.63-16"
    />
  </svg>
);
export default SvgPictogramContainers051;

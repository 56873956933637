import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramNumbers06 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 226.77 226.77"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M141.21 133.15c0 4.33-.67 8.28-2.01 11.83s-3.25 6.59-5.72 9.1-5.39 4.44-8.77 5.78-7.11 2.02-11.18 2.02-7.82-.65-11.24-1.95-6.37-3.16-8.84-5.59-4.4-5.37-5.78-8.84c-1.39-3.47-2.08-7.41-2.08-11.83q0-5.85 1.95-11.31t5.07-11.7l21.45-43.68h14.3l-20.15 40.43c1.21-.52 2.54-.89 3.97-1.1q2.145-.33 4.35-.33c3.38 0 6.59.65 9.62 1.95s5.66 3.12 7.87 5.46 3.96 5.18 5.26 8.51c1.3 3.34 1.95 7.08 1.95 11.25Zm-13.52.26c0-4.68-1.26-8.56-3.77-11.63q-3.765-4.62-10.53-4.62c-6.765 0-7.8 1.43-10.4 4.29s-3.9 6.85-3.9 11.96 1.3 9.15 3.9 12.09c2.6 2.95 6.07 4.42 10.4 4.42s7.69-1.47 10.33-4.42q3.96-4.425 3.96-12.09Z"
    />
  </svg>
);
export default SvgPictogramNumbers06;

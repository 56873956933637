import cn from "classnames";
import * as React from "react";
import css from './style/grid.module.scss';

export type GridItemsAlignment = "flex-start" | "center" | "flex-end" | "stretch" | "baseline";

export type GridContentAlignment =
  | "stretch"
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "space-around";

export type GridDirection = "row" | "row-reverse" | "column" | "column-reverse";

const GridSpacingValues:Array<number> = [0,4,8,16,24,32,40,48,56,64,72,80,112];

export type GridSpacing = 0|1|2|3|4|5|6|7|8|9|10|11|12;

export type GridJustification =
  | "flex-start"
  | "center"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly";

export type GridWrap = "nowrap" | "wrap" | "wrap-reverse";

export type GridSize = "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type Breakpoint = "sm" | "md" | "lg";

export type GridProps =
  Partial<Record<Breakpoint, boolean | GridSize>> & {
    alignContent?: GridContentAlignment;
    alignItems?: GridItemsAlignment;
    container?: boolean;
    direction?: GridDirection;
    fullHeight?: boolean;
    item?: boolean;
    justify?: GridJustification;
    spacing?: GridSpacing;
    wrap?: GridWrap;
    zeroMinWidth?: boolean;
    children?: React.ReactNode;
    className?: string;
  };

const Grid = React.forwardRef(({
  alignContent = "stretch",
  alignItems = "stretch",
  container = false,
  direction = "row",
  fullHeight = false,
  item = false,
  justify = "flex-start",
  lg = false,
  md = false,
  sm = false,
  spacing = 0,
  wrap = "wrap",
  zeroMinWidth = false,
  className,
  ...other
}: GridProps, ref: React.Ref<HTMLDivElement>) => {
  const classNames = cn(className, {
    [css["snw-grid__container"]]: container,
    [css["snw-grid__full-height"]]: fullHeight,
    [css["snw-grid__item"]]: item,
    [css["snw-grid__zero-min-width"]]: zeroMinWidth,
    [(css as any)[`snw-grid__spacing-${String(GridSpacingValues[spacing])}`]]: container && spacing !== 0,
    [(css as any)[`snw-grid__direction-${String(direction)}`]]: direction !== "row",
    [(css as any)[`snw-grid__wrap-${String(wrap)}`]]: wrap !== "wrap",
    [(css as any)[`snw-grid__align-items-${String(alignItems)}`]]: alignItems !== "stretch",
    [(css as any)[`snw-grid__align-content-${String(alignContent)}`]]: alignContent !== "stretch",
    [(css as any)[`snw-grid__justify-${String(justify)}`]]: justify !== "flex-start",
    [(css as any)[`snw-grid__grid-sm-${String(sm)}`]]: sm !== false,
    [(css as any)[`snw-grid__grid-md-${String(md)}`]]: md !== false,
    [(css as any)[`snw-grid__grid-lg-${String(lg)}`]]: lg !== false,
  });

  return <div className={classNames} ref={ref} {...other} />;
});

export default Grid;

import cn from "classnames";
import React from "react";
import useBorderRadius, {
  BorderRadiusProps,
} from "../../hooks/ui/use-border-radius";
import useColors, { ColorProps } from "../../hooks/ui/use-colors";
import useFlex, { FlexProps } from "../../hooks/ui/use-flex";
import useSize, { SizesProps } from "../../hooks/ui/use-size";
import useSpacing, { SpacingProps } from "../../hooks/ui/use-spacing";
import useTextAlign, { TextAlignProps } from "../../hooks/ui/use-text-align";
import css from "./index.module.scss";

export type BoxAriaAttributes = Pick<
  React.AriaAttributes,
  "aria-labelledby" | "aria-hidden" | "aria-pressed" | "aria-label"
> &
  Pick<React.HTMLAttributes<HTMLElement>, "tabIndex" | "onKeyDown">;

type BoxTag =
  | "div"
  | "blockquote"
  | "article"
  | "section"
  | "span"
  | "nav"
  | "header"
  | "ul"
  | "li"
  | "tr"
  | "main"
  | "dialog";

export type BoxProps = BoxAriaAttributes &
  BorderRadiusProps &
  SpacingProps &
  SizesProps &
  ColorProps &
  TextAlignProps &
  FlexProps & {
    id?: string;
    component?: BoxTag;
    onClick?: (e: React.MouseEvent) => void;
    role?: string;
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
    customStyle?: React.CSSProperties;
    noOverflow?: boolean;
    positionRoot?: boolean;
    minHeightZero?: boolean;
  };

const Box = React.forwardRef<HTMLElement, BoxProps>(
  (
    {
      id,
      role,
      className,
      customStyle,
      component,
      children,
      noOverflow,
      minHeightZero,
      tabIndex,
      positionRoot,
      onClick,
      onKeyDown,
      ...props
    },
    ref
  ) => {
    const spacing = useSpacing(props);
    const textAlign = useTextAlign(props);
    const borderRadius = useBorderRadius(props);
    const flex = useFlex(props);
    const sizing = useSize(props);
    const colors = useColors(props);

    return React.createElement(
      component || "div",
      {
        "data-epi-edit": props["data-epi-edit"],
        className: cn(
          colors.className,
          sizing.className,
          textAlign.className,
          borderRadius.className,
          spacing.className,
          flex.className,
          className,
          {
            [css["hui-box--btn"]]: role === "button",
            [css["hui-box--no"]]: noOverflow,
            [css["hui-box--mhz"]]: minHeightZero,
            [css["hui-box--pr"]]: positionRoot,
          }
        ),
        style: {
          ...sizing.style,
          ...spacing.style,
          ...borderRadius.style,
          ...flex.style,
          ...customStyle,
        },
        id,
        ref,
        role,
        tabIndex,
        onClick,
        onKeyDown,
        "aria-label": props["aria-label"],
        "aria-labelledby": props["aria-labelledby"],
        "aria-hidden": props["aria-hidden"],
      },
      children
    );
  }
);

Box.displayName = "Box";
export default Box;

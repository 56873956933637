import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Chips from "@@/components/Chips";
import Box from "@@/elements/Box";
import { ArrowRight } from "@@/elements/Icons/icons";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import styles from "./styles.module.scss";

export type Props = {
  subject: string;
  header: string;
  dimensionsLabel: string;
  dimensions: string[];
  image: PictureData;
  link: LinkData;
  materialsLabel: string;
  materials: string[];
};

const ProductCard = ({
  subject,
  header,
  dimensionsLabel,
  dimensions,
  image,
  link,
  materialsLabel,
  materials,
} : Props) => (
  <a
    className={styles.card}
    href={link.href}
    target={link.target}
    title={link.title}
  >
    <Picture
      imageData={image}
      aspectRatio="16 / 9"
      sizes={[
        [200, 400, 600, 800, 1200],
        [200, 400, 600, 800, 1200],
        [200, 400, 600, 800, 1200],
      ]}
    />
    <Box pt={[6, 8, 8]} pb={8} px={[6, 10, 10]} flexDirection="column" justifyContent="space-between" height="full">
      <Box>
        <Typhography variant="link" color="ComplementaryCharcoalTint03">{subject}</Typhography>
        <Typhography variant="intro" mt={3} mb={[4, 6, 6]}>{header}</Typhography>
        {dimensions && (
          <Box mb={3}>
            <Typhography variant="link" color="PrimaryGreyTint01" mb={2}>{dimensionsLabel}</Typhography>
            <Box>
              {dimensions.map((dimension, i) => <Typhography key={i} variant="information" color="PrimaryGreyTint01">{dimension}</Typhography>)}
            </Box>
          </Box>
        )}
        {materials && (
        <>
          <Typhography variant="link" color="PrimaryGreyTint01" mb={2}>{materialsLabel}</Typhography>
          <Chips items={materials} overflowHidden />
        </>
        )}
      </Box>
      <Box mt={6}>
        <TextButtonIcon label={link.text} dense direction="right">
          <ArrowRight />
        </TextButtonIcon>
      </Box>
    </Box>
  </a>
);

export default ProductCard;

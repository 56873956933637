import cn from "classnames";
import { Token, TokenAlias } from "../../../design-tokens/colors";
import css from "../../../design-tokens/colors/index.classes.module.scss";

export type ForegroundColorProps = {
  /** Sets the foreground color to one of our design tokens */
  color?: Token;
};

export type BackgroundColorProps = {
  /** Sets the background color to one of our design tokens */
  backgroundColor?: Token;
};

export type ColorProps = ForegroundColorProps & BackgroundColorProps;

const colorClasses = ({
  color,
  backgroundColor,
}: ColorProps & BackgroundColorProps) => cn([
  color ? (css as any)[`ui-color-${TokenAlias[color]}`] : undefined,
  backgroundColor ? (css as any)[`ui-background-${TokenAlias[backgroundColor]}`] : undefined,
]);

const useColors = (props: ColorProps) => ({
  className: colorClasses(props),
});

export default useColors;
import Box from "@@/elements/Box";
import { CloseThin } from "@@/elements/Icons/icons";
import cn from "classnames";
import styles from "../styles.module.scss";

export type Props = {
  input: string;
  setInput: Function;
  onClose: Function;
  onSubmit: Function;
  placeholder: string;
};

const SearchInputMainMenu = ({
  input, setInput, onClose, placeholder, onSubmit,
} : Props) => {
  const onEnterDown = (e) => {
    if (e.key === "Enter") onSubmit();
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const inputClassName = cn(
    styles.input_wrapper, styles.input_wrapper__menu_search,
  );

  return (
    <Box
      className={inputClassName}
      alignItems="center">
      <input
        id="main_menu_search_input"
        placeholder={placeholder}
        value={input}
        onChange={onChange}
        onKeyDown={onEnterDown}
        type="search"
      />
      <button
        type="button"
        aria-label="reset"
        className={cn(styles.button__reset, styles.button)}
        onClick={() => onClose()}
      >
        <CloseThin />
      </button>
    </Box>
  );
};

export default SearchInputMainMenu;

import { ArrowLeftOutlined, ArrowRightOutlined } from "@@/elements/Icons/icons";
import { useContext, useState } from "react";
import cn from "classnames";
import ScrollerContext from "../context";
import css from "./index.module.scss";

export type NavButtonProps = {
  direction?: "left" | "right";
};

const defaultProps: Partial<NavButtonProps> = {
  direction: "left",
};

const NavButton = (props: NavButtonProps) => {
  const { direction } = props;
  const {
    isAtStart,
    isAtEnd,
    gotoNextItem,
    gotoPrevItem,
    items,
  } = useContext(ScrollerContext);

  const [pause, setPause] = useState(false);

  const hidden = (direction === "left" && isAtStart) || (direction === "right" && isAtEnd);

  const onClick = () => {
    const oneVisible = (
      items.filter((item) => item === "fully").length === 1 &&
      items.filter((item) => item === "partial").length === 0
    );
    if (hidden || pause) { return; }
    const fn = direction === "left"
      ? gotoPrevItem
      : gotoNextItem;
    fn();
    if (oneVisible) {
      setPause(true);
      setTimeout(() => setPause(false), 500);
    }
  };

  const renderIcon = () => {
    if (direction === "left") {
      return <ArrowLeftOutlined />;
    }
    return <ArrowRightOutlined />;
  };

  const className = cn(
    css.button,
    {
      [css.button__hidden]: hidden,
    },
  );

  return (
    <button className={className} onClick={onClick} aria-label={direction} type="button" tabIndex={0}>
      {renderIcon()}
    </button>
  );
};

NavButton.defaultProps = defaultProps;
export default NavButton;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgReset = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 15 15"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.406 3.214v.007a5.73 5.73 0 0 1 1.953 7.735 5.76 5.76 0 0 1-4.97 2.862 5.7 5.7 0 0 1-2.856-.764.707.707 0 0 1-.26-.966.72.72 0 0 1 .602-.353h.006c.124 0 .25.033.36.1.657.376 1.401.576 2.154.576 1.541 0 2.976-.83 3.744-2.16a4.33 4.33 0 0 0-3.74-6.483c-1.095 0-2.139.415-2.928 1.142H6.71a.705.705 0 0 1 .005 1.412H3.018a.705.705 0 0 1-.705-.706V1.925a.703.703 0 0 1 .7-.706h.006a.71.71 0 0 1 .7.71v1.765a5.725 5.725 0 0 1 6.543-.564zm-5.882 9.019a.42.42 0 0 0 .154.57.419.419 0 0 1-.154-.571m3.978.33a4.62 4.62 0 0 0 2.888-2.169A4.617 4.617 0 0 0 7.4 3.48a4.617 4.617 0 0 1 3.99 6.915 4.62 4.62 0 0 1-2.888 2.17M3.719 4.079a5 5 0 0 1-.288.286v-.001q.14-.149.288-.285M2.72 1.63za.42.42 0 0 0-.12.293v3.691c0 .229.185.418.418.418h3.696-3.697a.417.417 0 0 1-.417-.417V1.925c0-.11.045-.219.12-.294"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgReset;

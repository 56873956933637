import Scroller, { ScrollerItem } from "@@/components/Scroller";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { TokenValue as bps } from "../../../design-tokens/breakpoints";

import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiContent from "@@/integrations/optimizly/Content";
import EpiProperty from "@@/integrations/optimizly/Property";
import { useState, useEffect } from "react";
import {
  InfoCard,
  InfoCardProps,
  InfoCardVariant,
} from "../../../components/Card/Info";
import Typography from "../../../elements/Typography";
import styles from "../cardContainer.module.scss";

export type InfoCardContainerProperties = {
  header: string;
  intro?: string;
};

export type InfoCardContainerOwnProps = {
  variant: InfoCardVariant;
  cards: InfoCardProps[];
  sliderDesktop?: boolean;
  sliderMobile?: boolean;
};

export type InfoCardContainerProps = InfoCardContainerOwnProps &
  InfoCardContainerProperties;

const InfoCardContainer = ({
  header,
  intro,
  cards,
  variant,
  sliderDesktop,
  sliderMobile,
}: InfoCardContainerProps) => {
  const [slider, setSlider] = useState<boolean>(false);
  useEffect(() => {
    const checkIfSlider = () => {
      if (
        (sliderMobile && window.innerWidth < bps.Large) ||
        (sliderDesktop && window.innerWidth >= bps.Large)
      ) {
        setSlider(true);
      } else setSlider(false);
    };
    checkIfSlider();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIfSlider);

      return () => {
        window.removeEventListener("resize", checkIfSlider);
      };
    }
  }, []);
  return (
    <Box className={styles.block}>
      <Container component="div">
        <Box
          width="full"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          {header && (
            <EpiProperty<InfoCardContainerProperties> name="header">
              <Typography
                mb={intro ? 8 : [10, 12, 14]}
                variant="heading1"
                asElement="h2"
              >
                {header}
              </Typography>
            </EpiProperty>
          )}
          {intro && (
            <EpiProperty<InfoCardContainerProperties> name="intro">
              <Typography mb={[10, 12, 14]} variant="intro">
                {intro}
              </Typography>
            </EpiProperty>
          )}
        </Box>
        {!slider && (
          <EpiContent>
            <Box className={styles.info_grid}>
              {cards.map((card) => (
                <InfoCard
                  variant={variant}
                  body={card.body}
                  header={card.header}
                  color={card.color}
                  link={card.link}
                  image={card.image}
                />
              ))}
            </Box>
          </EpiContent>
        )}
        {slider && (
          <EpiContent>
            <Scroller>
              {cards.map((card) => (
                <ScrollerItem className={styles.info_card_scroller_item}>
                  <InfoCard
                    variant={variant}
                    body={card.body}
                    header={card.header}
                    color={card.color}
                    link={card.link}
                    image={card.image}
                  />
                </ScrollerItem>
              ))}
            </Scroller>
          </EpiContent>
        )}
      </Container>
    </Box>
  );
};

export type ProvidedInfoCardContainerProps =
  OptimizelyBlockProps<InfoCardContainerProperties> & InfoCardContainerProps;

const ProvidedInfoCardContainer = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedInfoCardContainerProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <InfoCardContainer {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedInfoCardContainer;

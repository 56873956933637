import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramMaterials08 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.37 45.706a1.5 1.5 0 0 1 1.181.576c2.741 3.507 7.816 5.999 13.784 6.107 5.967-.108 11.042-2.6 13.783-6.107a1.5 1.5 0 0 1 2.364 0c2.741 3.507 7.816 6 13.784 6.107 5.976-.108 11.042-2.6 13.783-6.107a1.5 1.5 0 0 1 2.364 0c2.802 3.586 8.045 6.11 14.187 6.11a1.5 1.5 0 0 1 0 3c-6.175 0-11.776-2.264-15.37-5.918-3.382 3.442-8.543 5.651-14.3 5.896a1.5 1.5 0 0 1-.26.023q-.203 0-.404-.004l-.405.003q-.133 0-.261-.022c-5.75-.245-10.915-2.455-14.3-5.896-3.384 3.441-8.55 5.65-14.3 5.896a1.5 1.5 0 0 1-.261.023q-.203 0-.404-.004l-.404.003q-.135 0-.262-.022c-5.749-.245-10.915-2.455-14.3-5.896-3.593 3.654-9.194 5.919-15.369 5.919a1.5 1.5 0 1 1 0-3c6.142 0 11.385-2.525 14.187-6.111a1.5 1.5 0 0 1 1.182-.576"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M66.997 80.357c0-5.908-4.789-10.687-10.687-10.687-5.899 0-10.688 4.789-10.688 10.687 0 5.899 4.79 10.688 10.688 10.688s10.687-4.79 10.687-10.688"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M44.122 80.357c0-6.727 5.46-12.187 12.188-12.187 6.726 0 12.187 5.45 12.187 12.187 0 6.727-5.46 12.188-12.187 12.188s-12.188-5.46-12.188-12.188M56.31 71.17c-5.07 0-9.188 4.117-9.188 9.187s4.118 9.188 9.188 9.188 9.187-4.118 9.187-9.188a9.186 9.186 0 0 0-9.187-9.187"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M37.952 80.357c0-5.908-4.789-10.687-10.687-10.687-5.899 0-10.688 4.789-10.688 10.687 0 5.899 4.79 10.688 10.688 10.688s10.687-4.79 10.687-10.688"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.077 80.357c0-6.727 5.46-12.187 12.188-12.187 6.726 0 12.187 5.45 12.187 12.187 0 6.727-5.46 12.188-12.187 12.188s-12.188-5.46-12.188-12.188m12.188-9.187c-5.07 0-9.188 4.117-9.188 9.187s4.118 9.188 9.188 9.188 9.187-4.118 9.187-9.188a9.186 9.186 0 0 0-9.187-9.187"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M96.042 80.357c0-5.908-4.789-10.687-10.697-10.687s-10.687 4.789-10.687 10.687c0 5.899 4.789 10.688 10.687 10.688 5.899 0 10.697-4.79 10.697-10.688"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.077 24.877a6.373 6.373 0 0 1 6.377-6.377 6.373 6.373 0 0 1 6.376 6.377 6.373 6.373 0 0 1-6.376 6.376 6.373 6.373 0 0 1-6.377-6.376m6.377-3.377a3.373 3.373 0 0 0-3.377 3.377 3.373 3.373 0 0 0 3.377 3.376 3.373 3.373 0 0 0 3.376-3.376 3.373 3.373 0 0 0-3.376-3.377m7.572 3.377a6.373 6.373 0 0 1 6.376-6.377 6.373 6.373 0 0 1 6.377 6.377 6.373 6.373 0 0 1-6.377 6.376 6.373 6.373 0 0 1-6.376-6.376m6.376-3.377a3.373 3.373 0 0 0-3.376 3.377 3.373 3.373 0 0 0 3.376 3.376 3.373 3.373 0 0 0 3.377-3.376 3.373 3.373 0 0 0-3.377-3.377m7.572 3.377A6.373 6.373 0 0 1 49.35 18.5a6.373 6.373 0 0 1 6.377 6.377 6.373 6.373 0 0 1-6.377 6.376 6.373 6.373 0 0 1-6.376-6.376M49.35 21.5a3.373 3.373 0 0 0-3.376 3.377 3.373 3.373 0 0 0 3.376 3.376 3.373 3.373 0 0 0 3.377-3.376A3.373 3.373 0 0 0 49.35 21.5m7.562 3.377A6.373 6.373 0 0 1 63.29 18.5a6.373 6.373 0 0 1 6.376 6.377 6.373 6.373 0 0 1-6.376 6.376 6.373 6.373 0 0 1-6.377-6.376M63.29 21.5a3.373 3.373 0 0 0-3.377 3.377 3.373 3.373 0 0 0 3.377 3.376 3.373 3.373 0 0 0 3.376-3.376A3.373 3.373 0 0 0 63.29 21.5m7.571 3.377a6.373 6.373 0 0 1 6.377-6.377 6.373 6.373 0 0 1 6.376 6.377 6.373 6.373 0 0 1-6.376 6.376 6.373 6.373 0 0 1-6.377-6.376m6.377-3.377a3.373 3.373 0 0 0-3.377 3.377 3.373 3.373 0 0 0 3.377 3.376 3.373 3.373 0 0 0 3.376-3.376 3.373 3.373 0 0 0-3.376-3.377m7.562 3.377a6.373 6.373 0 0 1 6.376-6.377 6.373 6.373 0 0 1 6.377 6.377 6.373 6.373 0 0 1-6.377 6.376 6.373 6.373 0 0 1-6.376-6.376m6.376-3.377a3.373 3.373 0 0 0-3.376 3.377 3.373 3.373 0 0 0 3.376 3.376 3.373 3.373 0 0 0 3.377-3.376 3.373 3.373 0 0 0-3.377-3.377M73.157 80.358c0-6.726 5.45-12.188 12.188-12.188 6.735 0 12.197 5.45 12.197 12.188 0 6.729-5.472 12.187-12.197 12.187-6.727 0-12.188-5.46-12.188-12.187m12.188-9.188a9.186 9.186 0 0 0-9.188 9.188c0 5.07 4.118 9.187 9.188 9.187 5.072 0 9.197-4.119 9.197-9.187 0-5.078-4.115-9.188-9.197-9.188"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramMaterials08;

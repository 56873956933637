import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramVehicles07 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.723 33.771a1.5 1.5 0 0 1 1.5-1.5h71.75a1.5 1.5 0 0 1 1.5 1.5v29.316a1.5 1.5 0 0 1-1.5 1.5H6.222a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v26.316h68.75V35.271zM29.119 69.338a3.695 3.695 0 1 0 0 7.39 3.695 3.695 0 0 0 0-7.39m-6.696 3.695a6.696 6.696 0 1 1 13.391 0 6.696 6.696 0 0 1-13.39 0M43.184 69.338a3.696 3.696 0 1 0 0 7.39 3.696 3.696 0 0 0 0-7.39m-6.695 3.695a6.696 6.696 0 1 1 13.39 0 6.696 6.696 0 0 1-13.39 0M88.64 69.338a3.696 3.696 0 1 0 0 7.391 3.696 3.696 0 0 0 0-7.391m-6.696 3.695a6.696 6.696 0 1 1 13.391 0 6.696 6.696 0 0 1-13.39 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M47.956 74.92a1.5 1.5 0 0 1 1.5-1.5h33.702a1.5 1.5 0 0 1 0 3H49.456a1.5 1.5 0 0 1-1.5-1.5M34.039 73.527a1.5 1.5 0 0 1 1.5-1.5h.73a1.5 1.5 0 0 1 0 3h-.73a1.5 1.5 0 0 1-1.5-1.5M86.78 42.745l-.116 1.496-.853-1.234a1.5 1.5 0 0 1 .968-.262m14.024 5.392-.002-.005a3.108 3.108 0 0 0-.373-.596c-.286-.354-.626-.605-1.022-.668-.827-.132-3.993-.413-7.051-.667a1062 1062 0 0 0-5.206-.418c-.085.08-.177.17-.266.271-.374.418-.517.76-.517.962v16.268a1.5 1.5 0 0 1-3 0V47.016c0-1.328.755-2.374 1.28-2.962a7.5 7.5 0 0 1 1.12-1.016l.027-.019.01-.007.004-.002.003-.003.853 1.234.115-1.496.486.038a1298 1298 0 0 1 5.339.428c2.981.248 6.305.54 7.275.694 1.402.224 2.342 1.074 2.886 1.75a6 6 0 0 1 .764 1.225l.044.1.014.033.005.013.002.005.001.004-1.393.554 1.394-.553.019.05 4.079 11.468q.087.243.087.503v5.828q0 .17-.039.337l-.623 2.698v5.469a1.5 1.5 0 0 1-1.5 1.5H94.151a1.5 1.5 0 1 1 0-3h9.968v-4.14q0-.171.039-.338l.623-2.698v-5.397zm-13.283-2.665.001-.001.002-.001zm13.292 2.684"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M90.546 46.643a1.5 1.5 0 0 1 1.5 1.5v6.5l8.844 1.19a1.5 1.5 0 0 1-.399 2.972l-10.144-1.363a1.5 1.5 0 0 1-1.3-1.486v-7.813a1.5 1.5 0 0 1 1.5-1.5M83.526 62.449a1.5 1.5 0 0 1 .936-.328h5.56c2.317 0 4.69 1.323 6.424 3.042 1.744 1.73 3.137 4.149 3.137 6.695v1.265a1.5 1.5 0 0 1-3 0v-1.265c0-1.483-.855-3.182-2.25-4.565-1.403-1.392-3.06-2.172-4.31-2.172h-5.036l-3.955 3.158a1.5 1.5 0 0 1-.936.328H51.659a1.5 1.5 0 1 1 0-3H79.57zM17.937 65.499a1.5 1.5 0 0 1 1.5 1.5v2.727h4.18a1.5 1.5 0 1 1 0 3h-5.68a1.5 1.5 0 0 1-1.5-1.5V67a1.5 1.5 0 0 1 1.5-1.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramVehicles07;

import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import { CardColors } from "@@/components/Card/CardProps";
import { ProcessCard, ProcessCardProps } from "@@/components/Card/Process";
import Scroller, { ScrollerItem } from "@@/components/Scroller";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import SvgArrowRight from "@@/elements/Icons/icons/ArrowRight";
import Typography from "@@/elements/Typography";
import { screenSizeIsSmall } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiContent from "@@/integrations/optimizly/Content";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { TokenValue as bps } from "../../../design-tokens/breakpoints";

export type ProcessCardContainerProperties = {
  header: string;
};

export type ProcessCardContainerOwnProps = {
  cards: ProcessCardProps[];
  sliderDesktop?: boolean;
  sliderMobile?: boolean;
  link?: LinkData;
  cardColor?: CardColors;
};

export type ProcessCardContainerProps = ProcessCardContainerOwnProps &
  ProcessCardContainerProperties;

const ProcessCardContainer = ({
  header,
  cards,
  sliderDesktop,
  sliderMobile,
  link,
  cardColor = "blue",
}: ProcessCardContainerProps) => {
  const [slider, setSlider] = useState<boolean>(false);

  useEffect(() => {
    const checkIfSlider = () => {
      if (
        (sliderMobile && window.innerWidth < bps.Large) ||
        (sliderDesktop && window.innerWidth >= bps.Large)
      ) {
        setSlider(true);
      } else setSlider(false);
    };
    checkIfSlider();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIfSlider);

      return () => {
        window.removeEventListener("resize", checkIfSlider);
      };
    }
  }, []);

  return (
    <Container>
      {header && (
        <Box
          container
          width="full"
          mb={[10, 12, 14]}
          className={styles.process_header}
        >
          <hr className={styles.line} />
          <EpiProperty<ProcessCardContainerProperties> name="header">
            <Box
              className={styles.header}
              textAlign="center"
              mx={2}
              justifyContent="center"
            >
              <Typography variant="heading4" asElement="h2">
                {header}
              </Typography>
            </Box>
          </EpiProperty>
          <hr className={styles.line} />
        </Box>
      )}
      {!slider && (
        <EpiContent>
          <Box
            className={
              cards.length < 4 ? styles.process_container : styles.process_grid
            }
          >
            {cards &&
              cards.map((card, index) => (
                <Box container flexDirection="column" alignItems="center">
                  <ProcessCard
                    key={index}
                    header={card.header}
                    icon={card.icon}
                    body={card.body}
                    color={cardColor}
                  />
                </Box>
              ))}
          </Box>
        </EpiContent>
      )}
      {slider && (
        <EpiContent>
          <Box>
            <Scroller scollerClassName={styles.cards_wrapper}>
              {cards?.map((card, index) => (
                <ScrollerItem className={styles["process-card__scroller-item"]}>
                  <ProcessCard
                    key={index}
                    header={card.header}
                    icon={card.icon}
                    body={card.body}
                    color={cardColor}
                  />
                </ScrollerItem>
              ))}
            </Scroller>
          </Box>
        </EpiContent>
      )}
      {link && link.text && (
        <Box container justifyContent={slider ? "center" : "flex-end"} mt={8}>
          <TextButtonIcon direction="right" link={link} label={link.text}>
            <SvgArrowRight />
          </TextButtonIcon>
        </Box>
      )}
    </Container>
  );
};

export type ProvidedProcessCardContainerProps =
  OptimizelyBlockProps<ProcessCardContainerProperties> &
    ProcessCardContainerProps;

const ProvidedProcessCardContainer = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedProcessCardContainerProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <ProcessCardContainer {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedProcessCardContainer;

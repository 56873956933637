import { TokenValue } from "@@/design-tokens/breakpoints";
import Box from "@@/elements/Box";
import { ChevronDown } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import ClearButton from "./Options/clearButton";
import DropdownOption, { DropdownOptionProps } from "./Options/option";
import styles from "./styles.module.scss";

type DropdownProps = {
  placeholder: string;
  clearButtonText: string;
  options: DropdownOptionProps[];
  selectedValues: string[];
  setSelectedValues: Function,
  disabled?: boolean;
  fullWidth?: boolean;
  singleSelect?: boolean;
  dense?: boolean;
  truncation?: boolean;
};

const Dropdown = ({
  placeholder,
  clearButtonText,
  options,
  selectedValues,
  setSelectedValues,
  disabled,
  fullWidth,
  singleSelect = false,
  dense,
  truncation,
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownOptionsRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [minWidth, setMinWidth] = useState("100px");
  const [maxWidth, setMaxWidth] = useState("400px");
  const [maxHeight, setMaxHeight] = useState("0px");


  const MIN_HEIGHT = 160;
  const MAX_HEIGHT = 600;

  const handleChange = (value : string) => {
    if (value === null) {
      setSelectedValues([]);
      return;
    }
    if (singleSelect) {
      setSelectedValues([value]);
      setOpen(false);
      return;
    }
    const newValues = [...selectedValues];
    if (newValues.includes(value)) {
      newValues.splice(newValues.indexOf(value), 1);
    } else {
      newValues.push(value);
    }
    setSelectedValues(newValues);
  };

  const updateHeight = () => {
    if (dropdownOptionsRef.current) {
      if (open) {
        const height = window.scrollY + window.innerHeight - (dropdownOptionsRef.current.offsetTop + 25);
        if (height < MIN_HEIGHT) setMaxHeight(`${(MIN_HEIGHT)}px`);
        else if (height > MAX_HEIGHT) setMaxHeight(`${(MAX_HEIGHT)}px`);
        else setMaxHeight(`${(height)}px`);
      } else {
        setMaxHeight("0px");
      }
    }
  };

  const toggleMenu = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  const handleKeyDown = (e : any) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      toggleMenu();
    }
  };

  useEffect(() => {
    const updateWidth = () => {
      if (dropdownRef.current) {
        setMinWidth(`${dropdownRef.current.offsetWidth}px`);
        if (dropdownRef.current.offsetWidth > 400 || window.innerWidth < TokenValue.Medium) {
          setMaxWidth(`${dropdownRef.current.offsetWidth}px`);
        } else setMaxWidth("400px");
      }
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", updateWidth);
    window.addEventListener("resize", updateHeight);
    window.addEventListener("scroll", updateHeight);
    updateWidth();
    updateHeight();
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", updateWidth);
    };
  }, [dropdownRef, open]);

  const dropdownClassName = cn(
    styles.dropdown,
    { [styles.dropdown__fullwidth]: fullWidth },
  );

  const dropdownButtonClassName = cn(
    styles.dropdown_button,
    { [styles.dropdown_button__disabled]: disabled },
  );

  const iconClassName = cn(
    styles.chevron_icon,
    { [styles.chevron_icon__open]: open },
  );

  const selectedValuesWithLabel = () => {
    const selectedValuesFromOptions = selectedValues.map((val) => {
      if (!options) return null;
      const match = options.find((option) => option.value === val);
      if (match) return match.label;
      return null;
    });

    const selectedValuesWithOptionsFiltered = selectedValuesFromOptions.filter((option) => !!option);
    return selectedValuesWithOptionsFiltered;
  };

  const amountSelectedString = `${selectedValuesWithLabel().length > 0 ? `(${selectedValuesWithLabel().length})` : ""}`;

  const showDropdown = (options && options.length) || disabled;

  if (!showDropdown) return <></>;

  return (
    <Box
      className={dropdownClassName}
      ref={dropdownRef}
    >
      <Box
        className={dropdownButtonClassName}
        py={dense ? 1 : 3}
        px={4}
        onClick={toggleMenu}
        onKeyDown={handleKeyDown}
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        role="button"
        tabIndex={0}
        aria-disabled={disabled}
      >
        <Box
          alignItems="center"
          width="full"
          className={cn(styles.dropdown_button__text, {
            [styles.dropdown_button__text_truncation]: truncation,
          })}
        >
          <Typhography
            variant="link"
            asElement="span"
            py={1}
            className={cn({
              [styles.dropdown_button__text__placeholder_truncation]: truncation,
              [styles.dropdown_button__text__placeholder_hidden]: selectedValuesWithLabel()?.length > 0,
            })}
          >
            {placeholder}
          </Typhography>
          <Box
            py={1}
            className={styles.dropdown_button__selectedvalues}>
            <Typhography
              variant="link"
              asElement="span"
            >
              {selectedValuesWithLabel().toString()}
            </Typhography>
            {!!amountSelectedString.length && <Box
              alignItems="center"
              width={4}>
              <Typhography
                className={styles.count}
                variant="link"
                asElement="div">
                {amountSelectedString}
              </Typhography>
            </Box>}
          </Box>
        </Box>
        
        <ChevronDown className={iconClassName} />
      </Box>
      <div
        className={styles.dropdown_menu_wrapper}
        style={{
          minWidth,
          maxWidth,
          maxHeight,
          borderBottomWidth: open ? "10px" : "0px",
        }}
        ref={dropdownOptionsRef}
      >
        {!disabled && (
          <Box
            flexDirection="column"
            role="listbox"
            aria-label="dropdown-list"
          >
            <ClearButton
              text={clearButtonText}
              handleChange={handleChange}
              disabled={selectedValues.length === 0} />
            {options.map(({
              label, value, count, disabled: optionDisabled,
            }) => (
              <DropdownOption
                key={value}
                label={label}
                value={value}
                count={count}
                disabled={optionDisabled}
                handleChange={handleChange}
                checked={selectedValues.includes(value)}
                singleSelect={singleSelect}
              />
            ))}
          </Box>
        )}
      </div>
    </Box>
  );
};

export default Dropdown;

import { RefObject, useEffect } from "react";
import useThrottledState from "../react/use-throttled-state";

const useVisible = <T extends HTMLElement>(ref: RefObject<T>) => {
  const [visible, setVisible] = useThrottledState<boolean>(false, 100);

  useEffect(() => {
    if (typeof (ResizeObserver) === "undefined") {
      return undefined;
    }

    const resizeObserverRef = new ResizeObserver((entries = []) => {
      // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        entries.forEach((entry: ResizeObserverEntry) => {
          const { offsetHeight } = entry.target as HTMLElement;
          setVisible(offsetHeight > 0);
        });
      });
    });
    if (ref.current && resizeObserverRef) {
      resizeObserverRef.observe(ref.current);
    }
    return () => {
      if (resizeObserverRef) {
        resizeObserverRef.disconnect();
      }
    };
  }, [ref]);
  return visible;
};

export default useVisible;

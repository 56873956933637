import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgProcessIcon2 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 82 82"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m79.383 47.973-9.71-12.747v-.086a1.56 1.56 0 0 0-.852-.603l-14.907-5.254c2.726-3.1 4.344-7.148 4.344-11.627C58.259 7.924 50.423 0 40.798 0 31.17 0 23.335 7.924 23.335 17.656c0 4.479 1.618 8.527 4.344 11.627l-14.907 5.254c-.34.086-.596.344-.852.603L2.21 47.973c-.341.43-.511 1.12-.341 1.636.17.603.596 1.034 1.193 1.206l8.432 3.014v16.365c0 .775.511 1.464 1.193 1.722l27.428 9.732c.17.087.426.087.596.087s.426 0 .597-.087l27.428-9.732c.766-.258 1.192-.947 1.192-1.722V53.829l8.433-3.014c.596-.172 1.022-.69 1.193-1.206.255-.603.17-1.206-.17-1.636M26.997 17.656c0-7.665 6.218-13.953 13.8-13.953 7.58 0 13.799 6.288 13.799 13.953s-6.218 13.953-13.8 13.953c-7.58 0-13.799-6.202-13.799-13.953M6.724 48.145l7.326-9.646 24.702 8.785-5.026 10.507zm32.198 29.197-23.68-8.44v-13.78l18.91 6.717c.17.086.426.086.597.086.681 0 1.363-.43 1.618-1.033l2.556-5.426zm1.875-33.245-21.892-7.751 11.84-4.22c2.811 2.066 6.303 3.186 10.052 3.186 3.747 0 7.155-1.206 10.05-3.187l11.84 4.22zM66.35 68.901l-23.766 8.441V55.466l2.556 5.426c.34.689.937 1.033 1.618 1.033.17 0 .426 0 .596-.086l18.996-6.718zm-18.57-11.11-5.025-10.507 24.702-8.785 7.41 9.646z" />
    <path d="M38.07 22.824c.341.344.853.602 1.364.602.51 0 1.022-.172 1.363-.602l7.24-7.666c.681-.775.681-1.895-.085-2.584-.767-.689-1.874-.689-2.556.087l-5.877 6.287-3.152-3.273a1.764 1.764 0 0 0-2.555-.086c-.767.689-.767 1.895-.085 2.584z" />
  </svg>
);
export default SvgProcessIcon2;

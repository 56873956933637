/* eslint-disable react/no-danger */
import Tags from "@@/components/Tags";
import Box from "@@/elements/Box";
import { FileThin, ImageMissingThin } from "@@/elements/Icons/icons";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import classNames from "classnames";
import Icon from "@@/elements/Icons/Icon";
import styles from "./styles.module.scss";

type Variant = "job" | "pdf";

export type Props = {
  header: string;
  subject: string;
  intro: string;
  categories: string[];
  image: PictureData;
  link: LinkData;
  variant: Variant;
};

const SearchCard = ({
  header,
  subject,
  intro,
  categories,
  image,
  link,
  variant,
}: Props) => {
  const renderImage = () => {
    if (variant === "pdf") {
      return <FileThin />;
    }
    if (variant === "job") {
      return <ImageMissingThin />;
    }
    return (
      <Picture
        imageData={image}
        aspectRatio="3/2"
        sizes={[200, 400, 600, 800, 1200]}
      />
    );
  };

  const imageClassName = classNames(
    styles.image_wrapper,
    { [styles.icon]: variant },
    { [styles[`icon_${variant}`]]: variant }
  );

  return (
    <Box className={styles.wrapper}>
      <a href={link.href} target={link.target} title={link.title}>
        <Box flexDirection="row" gap={6} py={6}>
          <Box className={imageClassName}>{renderImage()}</Box>
          <Box width="full" noOverflow pr={3}>
            {subject && (
              <Typhography
                variant="link"
                asElement="div"
                color="ComplementaryCharcoalTint02"
                mb={2}
              >
                {subject}
              </Typhography>
            )}
            <Typhography variant="intro" asElement="h3">
              <span
                dangerouslySetInnerHTML={{ __html: header }}
                className={styles.text_wrapper}
              />
            </Typhography>
            {intro && (
              <Typhography
                variant="information"
                asElement="p"
                mt={2}
                className={styles.intro}
                color="ComplementaryCharcoalTint02"
              >
                <span
                  dangerouslySetInnerHTML={{ __html: intro }}
                  className={styles.text_wrapper}
                />
              </Typhography>
            )}
            {categories && categories.length && (
              <Box mt={4}>
                <Tags tags={categories} />
              </Box>
            )}
          </Box>
        </Box>
        <Box className={styles.divider} />
      </a>
    </Box>
  );
};

export default SearchCard;

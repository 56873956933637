import ErrorAlert from "@@/components/Error/ErrorAlert";
import Pagination from "@@/components/Pagination";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import useUpdateEffect from "@@/hooks/useUpdateEffect";
import {
  useEffect, useState,
} from "react";
import { scroller } from "react-scroll";
import CareerCardsContainer from "./Cards";
import ListingCareerFilter from "./Filter/filter";
import useFetchCareerData from "./useFetchCareerData";

export type SearchRequest = {
  language: string;
  parentpageid: number;
  page: number;
  companies: string[];
  countries: string[];
  cities: string[];
  occupations: string[];
};

type Props = {
  filterLabel: string;
  resetLabel: string;
  totalMatchesLabel: string;
  companyLabel: string;
  countryLabel: string;
  cityLabel: string;
  occupationLabel: string;
  searchRequest: SearchRequest;
  noResultMessage: string;
  errorMessage: string;
  errorActionMessage: string;
};

const PARAMETERS = {
  PAGE: "p",
  COMPANIES: "companies",
  COUNTRIES: "countries",
  CITIES: "cities",
  OCCUPATIONS: "occupations",
  LANGUAGE: "l",
};

const ListingCareerContainer = ({
  filterLabel,
  resetLabel,
  totalMatchesLabel,
  companyLabel,
  countryLabel,
  cityLabel,
  occupationLabel,
  searchRequest,
  noResultMessage,
  errorMessage,
  errorActionMessage,
} : Props) => {
  const [selectedPage, setSelectedPage] = useState(searchRequest.page ?? 1);
  const [selectedCompanies, setSelectedCompanies] = useState(searchRequest.companies ?? []);
  const [selectedCountries, setSelectedCountries] = useState(searchRequest.countries ?? []);
  const [selectedCities, setSelectedCities] = useState(searchRequest.cities ?? []);
  const [selectedOccupations, setSelectedOccupations] = useState(searchRequest.occupations ?? []);

  const { fetchData, loading, error } = useFetchCareerData(
    selectedPage,
    selectedCompanies,
    selectedCountries,
    selectedCities,
    selectedOccupations,
    searchRequest,
  );

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);

    if (selectedPage > 1) {
      params.set(PARAMETERS.PAGE, selectedPage.toString());
    } else {
      params.delete(PARAMETERS.PAGE);
    }

    if (selectedCompanies?.length > 0) {
      params.set(PARAMETERS.COMPANIES, selectedCompanies.toString());
    } else {
      params.delete(PARAMETERS.COMPANIES);
    }
    if (selectedCountries?.length > 0) {
      params.set(PARAMETERS.COUNTRIES, selectedCountries.toString());
    } else {
      params.delete(PARAMETERS.COUNTRIES);
    }
    if (selectedCities?.length > 0) {
      params.set(PARAMETERS.CITIES, selectedCities.toString());
    } else {
      params.delete(PARAMETERS.CITIES);
    }
    if (selectedOccupations?.length > 0) {
      params.set(PARAMETERS.OCCUPATIONS, selectedOccupations.toString());
    } else {
      params.delete(PARAMETERS.OCCUPATIONS);
    }
    
    let url = window.location.pathname;
    if (params.size > 0) {
      url = `?${params.toString()}`;
    }
    window.history.replaceState(null, null, url);
  }, [selectedPage, selectedCompanies, selectedCountries, selectedCities, selectedOccupations]);

  useUpdateEffect(() => {
    scroller.scrollTo("listing-page-scroll", { duration: 200, smooth: true, offset: -100 });
  }, [selectedPage]);

  useUpdateEffect(() => {
    setSelectedPage(1);
  }, [selectedCompanies, selectedCountries, selectedCities, selectedOccupations]);

  const reset = () => {
    setSelectedCompanies([]);
    setSelectedCountries([]);
    setSelectedCities([]);
    setSelectedOccupations([]);
  };

  const resetDisabled = !selectedCompanies?.length && !selectedCountries?.length && !selectedCities?.length && !selectedOccupations?.length;

  const showPagination = fetchData && !!fetchData.items.length && fetchData.totalPage > 1;

  if (error) {
    return (
      <ErrorAlert
        label={`${totalMatchesLabel}: 0`}
        message={errorMessage}
        actionText={errorActionMessage}
        action={reset}
      />
    );
  }

  return (
    <Container
      pageGutterX={0}
      size="full"
      id="listing-page-scroll"
      customStyle={{ scrollMargin: "500px" }}>
      <ListingCareerFilter
        filterLabel={filterLabel}
        companyFacets={fetchData?.companyFacets}
        selectedCompanies={selectedCompanies}
        setSelectedCompanies={setSelectedCompanies}
        companyLabel={companyLabel}
        countryFacets={fetchData?.countryFacets}
        selectedCountries={selectedCountries}
        setSelectedCountries={setSelectedCountries}
        countryLabel={countryLabel}
        cityFacets={fetchData?.cityFacets}
        selectedCities={selectedCities}
        setSelectedCities={setSelectedCities}
        occupationFacets={fetchData?.occupationFacets}
        selectedOccupations={selectedOccupations}
        setSelectedOccupations={setSelectedOccupations}
        occupationLabel={occupationLabel}
        cityLabel={cityLabel}
        clearFilterLabel={resetLabel}
        loading={loading && !fetchData}
        reset={reset}
        resetDisabled={resetDisabled}
      />
      <Box
        backgroundColor="PrimaryBlueTint03"
        width="full"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        pt={[8, 20, 25]}
        pb={[10, 13, 16]}
      >
        <Container pageGutterY={0}>
          <CareerCardsContainer
            cards={fetchData?.items}
            label={`${totalMatchesLabel}: ${fetchData?.totalMatching}`}
            loading={loading}
            noResultMessage={noResultMessage}
            resetMessage={errorActionMessage}
            reset={reset}
          />
          {showPagination && (
            <Box mt={[7, 10, 10]}>
              <Pagination
                selectedValue={selectedPage}
                totalPages={fetchData?.totalPage}
                setSelectedValue={setSelectedPage}
                loading={loading && !fetchData}
              />
            </Box>
          )}
        </Container>
      </Box>
    </Container>
  );
};

export default ListingCareerContainer;

import useBreakpoint, { ScreenSizes } from "./use-breakpoint";
import ResponsiveValue, { arrayFromResponiveValue } from "../../base/responsive-value";

export const useCurrentResponsiveValue = <T>(value?: ResponsiveValue<T>) => {
  const screenSize = useBreakpoint();
  const values = arrayFromResponiveValue(value);
  let index = ScreenSizes.indexOf(screenSize);
  while (index >= 0) {
    const currentValue = values[index];
    if (typeof (currentValue) !== "undefined") {
      return currentValue;
    }
    index -= 1;
  }
  return undefined;
};

export default useCurrentResponsiveValue;

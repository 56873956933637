import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramOffice04 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M34.199 20.57a1.5 1.5 0 0 1 1.06-.44h45.334a1.5 1.5 0 1 1 0 3H35.88l-9.59 9.59v59.311h52.802v-44.82a1.5 1.5 0 0 1 3 0v46.32a1.5 1.5 0 0 1-1.5 1.5H24.79a1.5 1.5 0 0 1-1.5-1.5V32.1c0-.398.158-.78.44-1.06z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.556 20.821a1.5 1.5 0 0 1 1.5 1.5v9.877a1.5 1.5 0 0 1-1.5 1.5h-9.778a1.5 1.5 0 1 1 0-3h8.278V22.32a1.5 1.5 0 0 1 1.5-1.5M85.234 16.97a1.5 1.5 0 0 1 1.061.439l11.753 11.753a1.5 1.5 0 0 1 .002 2.12l-41.482 41.58a1.5 1.5 0 0 1-.561.355l-17.58 6.222a1.5 1.5 0 0 1-1.928-1.877l5.827-17.975a1.5 1.5 0 0 1 .365-.597l41.482-41.58a1.5 1.5 0 0 1 1.06-.44m.002 3.622L45.069 60.854l-4.78 14.743 14.396-5.095 40.183-40.278z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M89.3 30.7a1 1 0 0 1 0 1.415L54.732 66.683a1 1 0 0 1-1.414-1.415l34.568-34.567a1 1 0 0 1 1.414 0M85.153 26.553a1 1 0 0 1-.002 1.414L50.484 62.535a1 1 0 1 1-1.412-1.416l34.666-34.568a1 1 0 0 1 1.415.002"
      clipRule="evenodd"
    />
    <path fill="currentColor" d="m40 70.42 5.333 5.333-7.11 1.976z" />
  </svg>
);
export default SvgPictogramOffice04;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInspireIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 23 27"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.044 8.29c2.139 2.698 2.035 6.394 1.737 8.533-1.933-2.255-5.24-4.204-6.773-5.024a22 22 0 0 1-3.47-2.252q.299.53.658 1.013.491.56.994 1.117c.503.511 1.03 1.002 1.566 1.486q.543.415 1.165.85a31 31 0 0 1 5.28 4.709q.336.374.653.751a21.7 21.7 0 0 1 3.008 4.546c.086.19.104.407.049.61a.83.83 0 0 1-.345.483l-.951.63a.54.54 0 0 1-.684-.107.6.6 0 0 1-.131-.226c-.666-1.93-1.93-3.889-3.357-5.609-7.666 3.99-12.5-2.454-13.813-5.205C-.27 8.516 1.583 1 1.583 1c4.232 4.314 11.54 2.341 15.461 7.29"
    />
  </svg>
);
export default SvgInspireIcon;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramMiscellaneous05 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.5 52.4c0-10.666 8.4-19.38 18.86-19.38h.19c10.45 0 18.86 8.723 18.86 19.38v.19c0 2.797-1.2 6.53-2.831 10.386-1.657 3.915-3.856 8.17-6.039 12.086a246 246 0 0 1-6.004 10.152 241 241 0 0 1-2.683 4.205l-.039.059-.013.02-1.251-.828 1.25.828c-.277.42-.747.672-1.25.672h-.19a1.5 1.5 0 0 1-1.25-.672l1.25-.828-1.25.828-.014-.02-.039-.059a135 135 0 0 1-.704-1.081c-.478-.742-1.16-1.811-1.979-3.124a246 246 0 0 1-6.004-10.152c-2.183-3.916-4.382-8.17-6.039-12.086C17.7 59.121 16.5 55.387 16.5 52.59zm18.955 33.658a243.445 243.445 0 0 1-7.465-12.457c-2.157-3.87-4.298-8.02-5.896-11.794-1.623-3.835-2.594-7.079-2.594-9.217v-.19c0-9.093 7.14-16.38 15.86-16.38h.19c8.71 0 15.86 7.297 15.86 16.38v.19c0 2.138-.97 5.382-2.594 9.217-1.598 3.775-3.739 7.924-5.896 11.794a244 244 0 0 1-5.93 10.026c-.593.952-1.114 1.773-1.535 2.431"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.46 45.73a5.51 5.51 0 1 0 0 11.02 5.51 5.51 0 0 0 0-11.02m-7.51 5.51a7.51 7.51 0 1 1 15.02 0 7.51 7.51 0 0 1-15.02 0M64.72 38.12c0-8.323 6.551-15.12 14.71-15.12h.15c8.149 0 14.71 6.807 14.71 15.12v.15c0 2.182-.936 5.094-2.208 8.101-1.293 3.054-3.008 6.372-4.71 9.426a192 192 0 0 1-6.776 11.198l-.038.057-.002.004-.976-.646.976.646a1.17 1.17 0 0 1-.976.524h-.15a1.17 1.17 0 0 1-.975-.524l.975-.646-.975.646-.003-.004-.038-.058-.062-.094-.053-.08c-.1-.154-.248-.38-.434-.67a192.143 192.143 0 0 1-6.227-10.354c-1.702-3.053-3.417-6.37-4.71-9.425-1.273-3.007-2.208-5.919-2.208-8.101zm14.785 26.254a189.786 189.786 0 0 1-5.823-9.717c-1.683-3.018-3.353-6.253-4.599-9.198-1.266-2.99-2.023-5.521-2.023-7.189v-.15c0-7.097 5.569-12.78 12.37-12.78h.15c6.791 0 12.37 5.693 12.37 12.78v.15c0 1.668-.757 4.198-2.023 7.19-1.246 2.944-2.916 6.18-4.599 9.198a190 190 0 0 1-5.823 9.716"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M79.5 32.91a4.3 4.3 0 1 0 0 8.6 4.3 4.3 0 0 0 0-8.6m-5.86 4.3a5.86 5.86 0 1 1 11.72 0 5.86 5.86 0 0 1-11.72 0M39.76 88.52a1 1 0 0 1 1-1h37.7a1 1 0 1 1 0 2h-37.7a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M77.46 77.62a1 1 0 0 1 1-1 6.45 6.45 0 1 1 0 12.9 1 1 0 1 1 0-2 4.45 4.45 0 1 0 0-8.9 1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M58.61 77.61a1 1 0 0 1 1-1h18.85a1 1 0 1 1 0 2H59.61a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M59.61 67.71a4.45 4.45 0 1 0 0 8.9 1 1 0 1 1 0 2 6.45 6.45 0 1 1 0-12.9 1 1 0 1 1 0 2"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M58.61 66.71a1 1 0 0 1 1-1h14.88a1 1 0 1 1 0 2H59.61a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramMiscellaneous05;

import throttle from "lodash/throttle";
import { useState } from "react";

const useThrottledState = <T>(value: T, delay: number = 200) => {
  const [state, setState] = useState(value);
  const throttledSetState = throttle(setState, delay, { leading: true });
  return [state, throttledSetState] as const;
};

export default useThrottledState;

import VideoItem from "@@/components/VideoItem";
import Container from "@@/elements/Container";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import { LinkData } from "@@/models/linkdata";

export type VideoComponentsProperties = {
  header?: string;
  body?: string;
  link?:LinkData;
  videoUrl:string;
  responsive?:boolean;
};

export type VideoComponentProps =
  VideoComponentsProperties;

const VideoComponent = ({
  header,
  body,
  link,
  videoUrl,
  responsive=true

}: VideoComponentProps) => (

  <Container className={!header && !body && (!link || !link?.text) ? "top-section-image" : "top-section-video"} size="large">
    <VideoItem responsive={responsive} isScrollItem={false} videoUrl={videoUrl} body={body} header={header} backgroundColor={undefined} link={link} />
  </Container>

);

export type ProvidedVideoComponentProps = OptimizelyBlockProps<VideoComponentProps> &
VideoComponentsProperties;

const ProvidedVideoComponent = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedVideoComponentProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <VideoComponent {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedVideoComponent;

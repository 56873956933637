import RelatedCard from "@@/components/Card/Related";
import Scroller, { ScrollerItem } from "@@/components/Scroller";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import SkeletonCard from "@@/components/Loader/Skeleton/Card/skeletonCard";
import ErrorAlert from "@@/components/Error/ErrorAlert";
import axios from "axios";
import { useEffect, useState } from "react";
import styles from "../latest-news/index.module.scss";

type SearchRequest = {
  page: number;
  language: string;
  pageSize: number;
  categories: number[];
  excludedPages: number[];
};
export type LatestNewsProps = {
  header: string;
  searchRequest: SearchRequest;
  errorMessage: string;
};

export type SearchResponseType = {
  page: number;
  pageSize: number;
  totalPage: number;
  totalMatching: number;
  language: string;
  items: HitItem[];
  categoryFacets: FacetItem[];
  yearFacets: FacetItem[];
};

type HitItem = {
  publishdate: string;
  header: string;
  intro: string;
  image: PictureData;
  link: LinkData;
  categories: string[];
};

type FacetItem = {
  name: string;
  count: number;
  id: number;
};
const RelatedContent = ({
  searchRequest,
  header,
  errorMessage = "We are sorry, there is a connection problem...",
}: LatestNewsProps) => {
  const [responseData, setResponseData] = useState<SearchResponseType>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState<boolean>(true);

  const skeletonCards = new Array(7).fill([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.post(
          "/api/search/relatedcontent",
          searchRequest,
        );
        setResponseData(data);
      } catch (errorData) {
        setError(errorData);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (error) {
    return <ErrorAlert message={errorMessage} />;
  }

  if (responseData && !responseData.items.length) return <></>;

  return (
    <Container size="full" className={styles.container}>
      <Box pb={[10, 12, 14]} width="full" textAlign="center">
        <Typography asElement="h2" variant="heading1">
          {header}
        </Typography>
      </Box>
      <Container size="xlarge" pageGutter={0}>
        {loading && (
          <Scroller scollerClassName={styles.scroller_wrapper}>
            {skeletonCards.map(() => (
              <ScrollerItem className={styles.scroller_loading}>
                <SkeletonCard variant="latestnews" />
              </ScrollerItem>
            ))}
          </Scroller>
        )}
        {!loading && (
          <Scroller scollerClassName={styles.scroller_wrapper}>
            {responseData.items.map((card, index) => (
              <ScrollerItem className={styles.scroller_item}>
                <RelatedCard
                  key={index}
                  header={card.header}
                  categories={card.categories}
                  image={card.image}
                  link={card.link}
                />
              </ScrollerItem>
            ))}
          </Scroller>
        )}
      </Container>
    </Container>
  );
};

export default RelatedContent;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramPeople10 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M42.907 28.416a5.45 5.45 0 0 0-5.453 5.444 5.45 5.45 0 0 0 5.453 5.444 5.443 5.443 0 1 0 0-10.888m-8.453 5.444c0-4.662 3.784-8.444 8.453-8.444 4.67 0 8.454 3.782 8.454 8.444 0 4.673-3.786 8.444-8.454 8.444s-8.453-3.782-8.453-8.444m32.999-5.444a5.45 5.45 0 0 0-5.454 5.444 5.45 5.45 0 0 0 5.454 5.444 5.443 5.443 0 1 0 0-10.888m-8.454 5.444c0-4.662 3.784-8.444 8.454-8.444s8.453 3.782 8.453 8.444c0 4.673-3.785 8.444-8.453 8.444-4.67 0-8.454-3.782-8.454-8.444M33.091 52.676c0-5.31 4.06-9.777 9.263-9.777h1.107c5.204 0 9.263 4.467 9.263 9.777a1.5 1.5 0 1 1-3 0c0-3.836-2.894-6.777-6.263-6.777h-1.107c-3.37 0-6.263 2.94-6.263 6.777v9.058a1.5 1.5 0 0 1-3 0zm24.545 0c0-5.31 4.06-9.777 9.264-9.777h1.106c5.204 0 9.264 4.467 9.264 9.777v9.058a1.5 1.5 0 0 1-3 0v-9.058c0-3.836-2.894-6.777-6.264-6.777H66.9c-3.37 0-6.264 2.94-6.264 6.777a1.5 1.5 0 1 1-3 0M10.251 73.358v2.186h91.34v-2.186zm-3-1.144c0-1.026.83-1.856 1.856-1.856h93.628c1.026 0 1.856.83 1.856 1.856v4.474c0 1.026-.83 1.856-1.856 1.856H9.107a1.854 1.854 0 0 1-1.856-1.856z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M16.278 71.275h-5.186a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.588 2.588M22.303 71.275h-5.185a2.584 2.584 0 0 1 2.587-2.588 2.584 2.584 0 0 1 2.588 2.588M28.338 71.275h-5.185a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.587 2.588M34.373 71.275h-5.185a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.587 2.588M40.409 71.275h-5.186a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.587 2.588M46.444 71.275h-5.186a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.588 2.588M52.479 71.275h-5.186a2.59 2.59 0 0 1 2.588-2.588 2.59 2.59 0 0 1 2.588 2.588M58.514 71.275h-5.186a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.588 2.588M64.549 71.275h-5.186a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.588 2.588M70.584 71.275h-5.186a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.588 2.588M76.619 71.275h-5.186a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.588 2.588M82.654 71.275H77.47a2.584 2.584 0 0 1 2.587-2.588 2.584 2.584 0 0 1 2.588 2.588M94.715 71.275h-5.186a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.588 2.588M100.75 71.275h-5.186a2.593 2.593 0 0 1 2.598-2.588 2.584 2.584 0 0 1 2.588 2.588M88.69 71.275h-5.186a2.593 2.593 0 0 1 2.597-2.588 2.584 2.584 0 0 1 2.588 2.588"
    />
  </svg>
);
export default SvgPictogramPeople10;

// import { ColorTokenValue as colors } from "@@/design-tokens";
import VideoPlayer from "@@/components/VideoPlayer";
import { Token } from "@@/design-tokens/colors";
import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import { LinkData } from "@@/models/linkdata";
import cn from "classnames";
import PrimaryButton from "../Button/PrimaryButton";
import RichText from "../RichText";
import styles from "./index.module.scss";

export type VideoItenProperties = {
  body?: string;
  header?: string;
  videoUrl: string;
  isScrollItem?: boolean;
  link?: LinkData;
  backgroundColor?: string;
  responsive?: boolean;
};

export type VideoItemProps = VideoItenProperties;

const VideoItem = ({
  header,
  videoUrl,
  body,
  link,
  isScrollItem = false,
  backgroundColor,
}: VideoItemProps) => (
  <Box
    backgroundColor={
      header || body || link?.href ? (backgroundColor as Token) : null
    }
    className={cn(styles.videoItem, {
      [styles.isScrollItem]: isScrollItem === true,
    })}
    noOverflow
  >
    {!isScrollItem && header && (
      <Box alignItems="flex-start" flexDirection="column" mb={6}>
        <Typhography variant="heading3">{header}</Typhography>
      </Box>
    )}
    <VideoPlayer videoUrl={videoUrl} />
    {isScrollItem && (header || body || link?.href) && (
      <Box p={6}>
        {header && (
          <Box alignItems="flex-start" flexDirection="column" mb={3}>
            <Typhography variant="heading5" asElement="h3">
              {header}
            </Typhography>
          </Box>
        )}
        {body && (
          <Box alignItems="flex-start" flexDirection="column">
            <RichText body={body} />
          </Box>
        )}
        {link && link.href && (
          <Box mt={6}>
            <PrimaryButton
              label={link.text}
              href={link.href}
              target={link.target}
            />
          </Box>
        )}
      </Box>
    )}
    {!isScrollItem && (body || link?.href) && (
      <Box>
        {body && (
          <Box mt={6} alignItems="flex-start" flexDirection="column">
            <RichText body={body} />
          </Box>
        )}
        {link?.href && (
          <Box mt={6}>
            <PrimaryButton
              label={link.text}
              href={link.href}
              target={link.target}
            />
          </Box>
        )}
      </Box>
    )}
  </Box>
);

export type ProvidedVideoItemProps = OptimizelyBlockProps<VideoItemProps> &
  VideoItenProperties;

const ProvidedVideoItem = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedVideoItemProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <VideoItem {...rest} {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedVideoItem;

// large, medium, small

import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import { ArrowRight, Mail } from "@@/elements/Icons/icons";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import classNames from "classnames";

import styles from "../index.module.scss";

export type VariantType = "large" | "medium" | "small";

export type PersonContactCardProps = {
  showImage: boolean
  image?: PictureData;
  name: string;
  title: string;
  phoneNumber?: string;
  emailLink?: LinkData;
  contactPageLink?: LinkData;
  department?: string;
  variant: VariantType;
};

const PersonContactCard = ({
  showImage,
  image,
  name,
  title,
  phoneNumber,
  emailLink,
  contactPageLink,
  department,
  variant,
}: PersonContactCardProps) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);
  const cardClassName = classNames(styles.card, {
    [(styles as any)[`card_${variant}`]]: variant,
  });

  let emailblock;
  if (emailLink && emailLink.href && emailLink.text) {
    emailblock = (
      <TextButtonIcon
        download
        email
        dense
        label={emailLink.text}
        link={emailLink}
      >
        <Mail />
      </TextButtonIcon>
    );
  } else if (emailLink && emailLink.text && !isSmall) {
    emailblock = <Box height={6} />;
  } else {
    emailblock = <Box height={0} />;
  }
  return (
    <Box className={showImage ? cardClassName : classNames(styles.card, { [(styles as any)[`noimage_card_${variant}`]]: variant })} backgroundColor="ComplementaryBeigeTint04">
      {showImage && (
      <Box width="full">
        <Picture
          imageData={image}
          aspectRatio="1 / 1"
          sizes={[[250, 350, 550, 650, 1000], []]}
        />
      </Box>
      )}
      <Box
        className={styles.text_wrapper}
        py={showImage && 4}
        mr={3}
        ml={showImage ? 10 : 3}
        width="full"
        height="full"
        alignItems="space-between"
        justifyContent={
         showImage && (isSmall || variant === "small") ? "flex-start" : "center"
        }
        flexDirection="column"
      >
        <Box className={styles.text_wrapper_description} flexDirection="column" alignItems="center" flexGrow={1}>
          {department && (
            <Box>
              <Typography textAlign="center" variant="link">
                {department}
              </Typography>
              <hr className={styles.separator} />
            </Box>
          )}
          <Typography
            mb={2}
            textAlign="center"
            variant={isSmall ? "heading3" : "heading5"}
            asElement="h3"
          >
            {name}
          </Typography>
          <Typography
            textAlign="center"
            mb={contactPageLink ? 3 : 6}
            asElement="span"
            variant="information"
          >
            {title}
          </Typography>
          {contactPageLink && contactPageLink.text && (
            <TextButtonIcon
              direction="right"
              dense
              label={contactPageLink.text}
              link={contactPageLink}
            >
              <ArrowRight />
            </TextButtonIcon>
          )}
        </Box>
        <Box className={styles.text_wrapper_information} flexGrow={2} flexShrink={2}>
          {phoneNumber && !isSmall && (
          <Typography
            my={5}
            asElement="p"
            color="PrimaryGrey"
            textAlign="center"
            variant={isSmall ? "heading5" : "button"}
          >
            {phoneNumber}
          </Typography>
          )}
          {phoneNumber && isSmall && (
          <a className={styles.phonenumber} href={`tel:${phoneNumber}`}>
            <Typography
              my={5}
              asElement="p"
              color="PrimaryGrey"
              textAlign="center"
              variant={isSmall ? "heading5" : "button"}
            >
              {phoneNumber}
            </Typography>
          </a>
          )}
          {emailblock}
        </Box>
      </Box>
    </Box>
  );
};

export default PersonContactCard;

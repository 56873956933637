import Tags from "@@/components/Tags";
import { BackgroundColor } from "@@/containers/Cards/service-description";
import Box from "@@/elements/Box";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";

import cardStyles from "../cardStyles.module.scss";
import styles from "./index.module.scss";

export type RelatedCardProps = {
  header: string;
  image: PictureData;
  categories?: string[];
  date?: string;
  link: LinkData;
  newsmodule?: boolean;
  slider?: boolean;
  backgroundColor?: BackgroundColor;
};

const RelatedCard = ({
  header,
  image,
  categories,
  date,
  link,
  newsmodule = false,
  slider = false,
  backgroundColor,
}: RelatedCardProps) => {
  const cardClassname = cn(cardStyles.card, styles.card, {
    [styles.full_height]: slider || !newsmodule,
    [styles[`card_${backgroundColor}`]]: backgroundColor,
    [cardStyles.card__link]: link && link.href,
  });

  return (
    <a href={link.href} className={cardClassname}>
      <Box width="full" className={styles.cardimage_wrapper}>
        <Picture
          className={styles.card_image}
          imageData={image}
          aspectRatio="3 / 2"
          sizes={[200, 400, 600, 800, 1200, 1600]}
        />
        {categories && (
          <div className={styles.categories_wrapper}>
            <Tags autohideTags tags={categories} />
          </div>
        )}
      </Box>
      <Box width="full" pb={6} pt={6} px={6}>
        {date && (
          <Box pb={3}>
            <Typography variant="link">{date}</Typography>
          </Box>
        )}
        <Box className={styles.ellipse}>
          <Typography textcolor="dark" variant="intro" asElement="h3">
            {header}
          </Typography>
        </Box>
      </Box>
    </a>
  );
};

export default RelatedCard;

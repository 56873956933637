import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { User } from "@@/elements/Icons/icons";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { PictureData } from "@@/models/pictureData";
import { FC } from "react";
import styles from "./authorStyles.module.scss";

type AuthorOwnProps = {
  image?: PictureData;
};

type AuthorEditableProps = {
  label: string;
  authorName: string;
  description?: string;
};

type AuthorProps = AuthorOwnProps & AuthorEditableProps;

const Author: FC<AuthorProps> = ({
  label, authorName, image, description,
}) => {
  const hasImage = !!image;
  const renderImage = () => {
    if (hasImage) {
      return (
        <Picture
          circular
          imageData={image}
          className={styles.image_wrapper_picture}
          imgClassName={styles.image_wrapper_img}
          aspectRatio="1 / 1"
          sizes={[[100, 200, 300, 400], []]}
        />
      );
    }
    return (
      <Box container width="full" height="full" justifyContent="center">
        <User />
      </Box>
    );
  };
  return (
    <Container customStyle={{ paddingTop: "40px" }}>
      <Box flexDirection="row">
        <div className={styles.image_wrapper}>{renderImage()}</div>
        <Box alignItems="center" ml={8}>
          <Box flexDirection="column">
            <EpiProperty<AuthorEditableProps> name="label">
              <Typhography variant="label" uppercase mb={2}>
                {label}
              </Typhography>
            </EpiProperty>
            <EpiProperty<AuthorEditableProps> name="authorName">
              <Typhography asElement="p" variant="heading5">{authorName}</Typhography>
            </EpiProperty>
            <EpiProperty<AuthorEditableProps> name="description">
              <Typhography variant="body">{description}</Typhography>
            </EpiProperty>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export type ProvidedAuthorProps = OptimizelyBlockProps<AuthorProps> &
AuthorProps;

const ProvidedAuthor = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedAuthorProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <Author {...rest} {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedAuthor;

import { useEffect, useState } from "react";
import axios from "axios";
import useUpdateEffect from "@@/hooks/useUpdateEffect";

const ENDPOINT_SEARCH = "/api/search/global";
const ENDPOINT_SUGGESTION = "/api/stats/didyoumean";

type UseFetchSearchDataReturn = {
  items: any[];
  page: number;
  categoryFacets: any[];
  totalMatching: number;
  totalPage: number;
};

function useFetchSearchData(
  currentPage : number,
  query : string,
  categories : number[],
  searchRequest,
) {
  const [fetchData, setFetchData] = useState<UseFetchSearchDataReturn>(null);
  const [suggestionData, setSuggestionData] = useState<string[]>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const params = {
    ...searchRequest,
    page: currentPage,
    query,
    categories,
  };

  const fetchCareerData = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.post(ENDPOINT_SEARCH, params);
      setFetchData(response.data);
      if (query) {
        const suggestionResponse = await axios.get(`${ENDPOINT_SUGGESTION}?q=${query}&l=${searchRequest.language}`);
        setSuggestionData(suggestionResponse.data);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useUpdateEffect(() => {
    if (currentPage === 1) fetchCareerData();
  }, [categories, query]);

  useEffect(() => {
    fetchCareerData();
  }, [currentPage]);

  const data = fetchData ? { ...fetchData, suggestions: suggestionData } : null;

  return {
    data, loading, error,
  };
}
export default useFetchSearchData;

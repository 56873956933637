import Box from "@@/elements/Box";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import Accordion, { Colors } from "..";
import styles from "../accordionStyles.module.scss";
import Downloads from "./downloads";
import Links from "./links";

interface Download {
  image: PictureData,
  text: string;
  link: LinkData;
}

type LinksOwnProps = {
  backgroundColor: Colors;
};

type LinksEditableProps = {
  header: string;
  links: LinkData[];
  downloads: Download[];
};

type LinksProps = LinksOwnProps & LinksEditableProps;

const AccordionLinks = ({
  header,
  backgroundColor,
  links,
  downloads,
}:LinksProps) => {
  const hasDivider = (!!links && links.length > 0) && (!!downloads && downloads.length > 0);
  return (
    <Accordion header={header} backgroundColor={backgroundColor}>
      <Box>
        <EpiProperty<LinksEditableProps> name="links">
          <Box>
            {!!links && <Links links={links} />}
          </Box>
        </EpiProperty>
        {hasDivider && <div className={styles.accordion_divider} />}
        <EpiProperty<LinksEditableProps> name="downloads">
          <Box>
            {!!downloads && <Downloads downloads={downloads} />}
          </Box>
        </EpiProperty>
      </Box>
    </Accordion>
  );
};

export type ProvidedLinksProps =
  & OptimizelyBlockProps<LinksProps> &
  LinksProps;

const ProvidedAccordionLinks = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedLinksProps) => (
  <OptimizelyBlock blockId={blockId} inEditMode={inEditMode} properties={properties}>
    {(optimizlyProperties) => (
      <AccordionLinks {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedAccordionLinks;

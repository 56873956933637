import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";

type ErrorAlertProps = {
  label?: string;
  message: string;
  action?: () => void;
  actionText?: string;
};

const ErrorAlert = ({
  label, message, action, actionText,
} : ErrorAlertProps) => {
  const showButton = action && actionText;

  return (
    <Container
      pageGutterX={0}
      size="xlarge"
      backgroundColor="ComplementaryBeigeTint04">
      <Container component="div">
        <Box
          justifyContent="center"
          px={5}
          flexDirection="column"
          width="full">
          {label && (
            <Typhography
              variant="label"
              asElement="div"
              mb={3}
              uppercase>
              {label}
            </Typhography>
          )}
          <Box
            backgroundColor="White"
            justifyContent="center"
            alignItems="center"
            px={10}
            pt={16}
            pb={12}
            flexDirection="column"
          >
            <Typhography variant="information">
              {message}
            </Typhography>
            {showButton && (
              <Box mt={5}>
                <TextButtonIcon
                  onClick={action}
                  label={actionText} />
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Container>
  );
};

export default ErrorAlert;

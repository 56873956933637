import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramVehicles12 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 49.07a1.5 1.5 0 0 1 1.5-1.5h98a1.5 1.5 0 0 1 1.306 2.237L105 49.07l1.306.737-.002.005-.005.01-.021.036-.08.14-.307.53a146.991 146.991 0 0 1-5.22 8.24c-3.322 4.868-7.794 10.737-12.202 14.311a1.5 1.5 0 0 1-1.89-2.33c4.045-3.28 8.311-8.832 11.614-13.672a144 144 0 0 0 4.181-6.507H8.5v1.626a23 23 0 0 0 .072.65 62.411 62.411 0 0 0 1.563 8.264c.628 2.384 1.458 4.848 2.537 6.955 1.09 2.128 2.36 3.748 3.793 4.626a1.5 1.5 0 1 1-1.568 2.558c-2.109-1.292-3.694-3.47-4.895-5.817-1.213-2.37-2.11-5.06-2.768-7.559a65.4 65.4 0 0 1-1.722-9.408l-.004-.042-.001-.012v-.004L7 52.268l-1.494.139a2 2 0 0 1-.006-.139z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.27 39.614a1.5 1.5 0 0 1 1.436-1.068h49.457a1.5 1.5 0 0 1 1.167.557l6.739 8.338a1.5 1.5 0 0 1-2.334 1.886l-6.288-7.78H21.82l-2.191 7.27a1.5 1.5 0 0 1-2.873-.865z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.422 32.818a1.5 1.5 0 0 1 1.452-1.125h34.494a1.5 1.5 0 0 1 1.238.653l4.455 6.51a1.5 1.5 0 0 1-2.476 1.695l-4.008-5.858H28.036l-1.423 5.5a1.5 1.5 0 1 1-2.904-.75z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M45.492 23.713c0-.946.767-1.713 1.713-1.713h7.881c.946 0 1.714.767 1.714 1.713v8.453a1.713 1.713 0 0 1-3.427 0v-6.74h-4.455v6.854a1.713 1.713 0 0 1-3.426 0zM31.557 23.713c0-.946.767-1.713 1.713-1.713h7.767c.947 0 1.714.767 1.714 1.713v8.453a1.713 1.713 0 0 1-3.427 0v-6.74h-4.34v6.854a1.713 1.713 0 0 1-3.427 0zM20.364 89.16c0-.946.767-1.713 1.713-1.713h59.737a1.713 1.713 0 1 1 0 3.427H22.077a1.713 1.713 0 0 1-1.713-1.713M8.37 82.08c0-.947.768-1.714 1.714-1.714h83.722a1.713 1.713 0 0 1 0 3.427H10.084a1.713 1.713 0 0 1-1.714-1.714"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramVehicles12;

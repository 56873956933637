import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramEnergy11 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M51.514 17.668a1.5 1.5 0 0 1 1.325-.797h24.198a1.5 1.5 0 0 1 1.262 2.311L61.272 45.658l15.91-2.692a1.5 1.5 0 0 1 1.357 2.49L33.404 94.84a1.5 1.5 0 0 1-2.473-1.633l16.255-35.742-15.178 2.096a1.5 1.5 0 0 1-1.53-2.188zm2.228 2.203L34.5 56.188l14.974-2.069a1.5 1.5 0 0 1 1.57 2.107L37.59 85.814 73.383 46.65l-14.96 2.532a1.5 1.5 0 0 1-1.512-2.29L74.29 19.87z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramEnergy11;

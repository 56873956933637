import { useEffect, useState } from "react";
import axios from "axios";
import { LinkData } from "@@/models/linkdata";

const ENDPOINT = "/api/search/globalquick";

type UseFetchSearchDataReturn = {
  items: any[];
  totalMatching: number;
  suggestion: LinkData;
};

function useFetchMainMenuSearchData(
  query,
  language,
) {
  const [data, setData] = useState<UseFetchSearchDataReturn>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [timer, setTimer] = useState(null);

  const fetchData = async () => {
    try {
      setError(false);
      const response = await axios.get(`${ENDPOINT}?q=${query}&l=${language}`);
      setData(response.data);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (timer) clearTimeout(timer);
    if (query.length > 2) {
      setLoading(true);
      setTimer(setTimeout(() => fetchData(), 250));
    } else {
      setLoading(false);
      setData(null);
    }
  }, [query]);

  return {
    data, loading, error,
  };
}
export default useFetchMainMenuSearchData;

import useUpdateEffect from "@@/hooks/useUpdateEffect";
import axios from "axios";
import { useEffect, useState } from "react";

export enum ErrorCodes {
  NO_RESULT = "NO_RESULT",
  OTHER = "OTHER",
}

const ENDPOINT = "/api/search/careeritems";

type UseFetchCareerDataReturn = {
  items: any[];
  page: number;
  companyFacets: any[];
  countryFacets: any[];
  cityFacets: any[];
  occupationFacets: any[];
  totalMatching: number;
  totalPage: number;
};

function useFetchCareerData(
  currentPage : number,
  companies : string[],
  countries : string[],
  cities: string[],
  occupations: string[],
  searchRequest,
) {
  const [fetchData, setFetchData] = useState<UseFetchCareerDataReturn>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const url = ENDPOINT;

  const params = {
    ...searchRequest,
    page: currentPage,
    companies,
    countries,
    cities,
    occupations,
  };

  const fetchCareerData = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.post(url, params);
      setFetchData(response.data);
    } catch (e) {
      if (e?.response?.status === 404) {
        setError(ErrorCodes.NO_RESULT);
      } else {
        setError(ErrorCodes.OTHER);
      }
    } finally {
      setLoading(false);
    }
  };

  useUpdateEffect(() => {
    if (currentPage === 1) fetchCareerData();
  }, [companies, countries, cities, occupations]);

  useEffect(() => {
    fetchCareerData();
  }, [currentPage]);

  return {
    fetchData, loading, error,
  };
}
export default useFetchCareerData;

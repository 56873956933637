import Box from "@@/elements/Box";
import Icon from "@@/elements/Icons/Icon";
import { ArrowRight } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import TextButtonIcon from "../Button/TextButtonIcon";
import { CardColors } from "../Card/CardProps";
import RichText from "../RichText";

export type CardProps = {
  icon?: typeof Icon,
  header?: string,
  body?: string,
  link?: LinkData,
  color: CardColors,
  centered: boolean,
};

const BranchInfoCard = ({
  icon, header, body, link, color, centered,
}) => {
  const getColor = (foregroundColor: CardColors) => {
    switch (foregroundColor) {
      case "green":
        return "PrimaryGreen";
      case "grey":
        return "PrimaryGrey";
      case "blue":
        return "PrimaryBlue";
      case "orange":
        return "ComplementaryOrange";
      case "beige":
        return "ComplementaryCharcoal";
      default:
        return "PrimaryGreen";
    }
  };

  const getBackgroundColor = (backgroundColor: CardColors) => {
    switch (backgroundColor) {
      case "green":
        return "PrimaryGreenTint03";
      case "grey":
        return "PrimaryGreyTint03";
      case "blue":
        return "PrimaryBlueTint03";
      case "orange":
        return "ComplementaryOrangeTint04";
      case "beige":
        return "ComplementaryBeigeTint03";
      default:
        return "PrimaryGreenTint03";
    }
  };

  return (
    <Box br={1} width="full" height="full" p={[6, 10, 10]} flexDirection="column" alignItems={centered ? "center" : "flex-start"} backgroundColor={getBackgroundColor(color)}>
      {icon && (
      <Box mb={6} color={getColor(color)}>
        <Icon height={56} name={icon} />
      </Box>
      )}
      {header && (
      <Typhography textAlign={centered ? "center" : "left"} mb={6} color={getColor(color)} variant="heading5" asElement="div">{header}</Typhography>
      )}
      {body && (
        <Box customStyle={{ maxWidth: "560px" }} flexDirection="column" textAlign={centered ? "center" : "left"} height="full" mb={6}>
          <RichText body={body} />
        </Box>
      )}
      {link && link.text && (
      <TextButtonIcon label={link.text} link={link} dense direction="right">
        <ArrowRight />
      </TextButtonIcon>
      )}

    </Box>
  );
};

export default BranchInfoCard;

import Accordion from "@@/components/Accordion";
import Categories from "@@/components/ListingPage/Shared/Categories";
import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import FilterLoader from "../../Shared/FilterLoader";
import ResetButton from "../../Shared/ResetButton";
import withoutCountZero from "../../Shared/filterHelpers";

const SearchFilter = ({
  categories,
  selectedCategories,
  setSelectedCategories,
  filterLabel,
  reset,
  clearFilterLabel,
  disabled,
  loading,
}) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);

  const categoriesWithoutZero = withoutCountZero(categories);

  const renderFilter = () => (
    <>
      {!isSmall && (
      <Typhography variant="label" mb={3} uppercase>
        { `${filterLabel}:` }
      </Typhography>
      )}
      <Categories options={categoriesWithoutZero} selectedValues={selectedCategories} setSelectedValues={setSelectedCategories} />
      <ResetButton onClick={reset} disabled={disabled} label={clearFilterLabel} />
    </>
  );

  if (loading) {
    return (
      <Box mb={12}>
        <FilterLoader />
      </Box>
    );
  }
  if (!categoriesWithoutZero?.length) {
    return <></>;
  }

  return (
    <Box mb={[8, 12, 12]}>
      {isSmall && <Accordion header={filterLabel} backgroundColor="green">{ renderFilter() }</Accordion>}
      {!isSmall && renderFilter()}
    </Box>
  );
};

export default SearchFilter;

import isEqual from "lodash/isEqual";
import throttle from "lodash/throttle";
import { useEffect, useRef, useState } from "react";
import { TokenValue as bps } from "../../design-tokens/breakpoints";

// TODO: This isn't needed, should refactor to use the Token file
export enum ScreenSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE"
}

export const ScreenSizes = Object.keys(ScreenSize);

export const screenSizeIsExtraLarge = () =>
  window.matchMedia(`(min-width: ${bps.ExtraLarge}px)`).matches;
export const screenSizeIsLarge = () =>
  window.matchMedia(`(min-width: ${bps.Large}px) and (max-width: ${bps.ExtraLarge - 1}px)`).matches;
export const screenSizeIsMedium = () =>
  window.matchMedia(`(min-width: ${bps.Medium}px) and (max-width: ${bps.Large - 1}px)`).matches;
export const screenSizeIsSmall = () =>
  window.matchMedia(`(max-width: ${bps.Medium - 1}px)`).matches;

const getScreenSizeFromClientWidth = () => {
  if (screenSizeIsExtraLarge()) { return ScreenSize.EXTRA_LARGE; }
  if (screenSizeIsLarge()) { return ScreenSize.LARGE; }
  if (screenSizeIsMedium()) { return ScreenSize.MEDIUM; }
  return ScreenSize.SMALL;
};

const useBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<ScreenSize>(ScreenSize.SMALL);
  const currentBreakpointRef = useRef<ScreenSize>(currentBreakpoint);

  const fetchAndUpdateCurrentBreakpoint = () => {
    const newBreakpoint = getScreenSizeFromClientWidth();
    if (!isEqual(currentBreakpointRef.current, newBreakpoint)) {
      currentBreakpointRef.current = newBreakpoint;
      setCurrentBreakpoint(newBreakpoint);
    }
  };

  useEffect(() => {
    fetchAndUpdateCurrentBreakpoint();

    const fn = throttle(() => {
      fetchAndUpdateCurrentBreakpoint();
    }, 1000 / 60);

    window.addEventListener("resize", fn, { passive: true });

    return () => {
      window.removeEventListener("resize", fn);
    };
  }, []);

  return currentBreakpoint;
};

const isBreakpointAndAbove = (current: ScreenSize, target: ScreenSize) => {
  for (let i = 0; i < ScreenSizes.length; i++) {
    const size = ScreenSizes[i];
    if (size === target) { return true; }
    if (size === current) { return false; }
  }
  return false;
};

const isBreakpointOrBelow = (current: ScreenSize, target: ScreenSize) => {
  for (let i = ScreenSizes.length - 1; i >= 0; i--) {
    const size = ScreenSizes[i];
    if (size !== current && size !== target) { continue; }
    if (size === target) { return true; }
    if (size === current) { return false; }
  }
  return false;
};

export const useIsBreakpointOrBelow = (size: ScreenSize) => {
  const breakpoint = useBreakpoint();
  return breakpoint && isBreakpointOrBelow(breakpoint, size);
};

export const useIsBreakpointAndAbove = (size: ScreenSize) => {
  const breakpoint = useBreakpoint();
  return breakpoint && isBreakpointAndAbove(breakpoint, size);
};

export default useBreakpoint;

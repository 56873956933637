import RichText from "@@/components/RichText";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Picture from "@@/elements/Picture";
import { PictureData } from "@@/models/pictureData";

type ProductTopSectionProps = {
    image: PictureData;
    body: string;
}

const ProductTopSection = ({image, body}: ProductTopSectionProps) => {
return (
<Container pageGutter={0}><Box><Picture br={1} imageData={image} aspectRatio={"16/9"}/></Box>
<Box py={14}>
 <RichText body={body}/></Box></Container>
)
}

export default ProductTopSection;
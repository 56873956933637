import React, { useContext } from "react";
import OptimizlyContext from "../context";

export type EpiContentProps = {
  children: React.ReactNode,
};

const EpiContent = ({
  children,
}: EpiContentProps) => {
  const { inEditMode } = useContext(OptimizlyContext);

  if (!inEditMode) {
    return (
      <>
        {children}
      </>
    );
  }

  // Verifies that children has only one child (a React element) and returns it. Otherwise this method throws an error.
  React.Children.only(children);

  const newProps : Partial<unknown> = { "data-epi-edit": inEditMode ? "content" : null };

  // add epi attribute to children.
  return (
    <>
      { React.Children.map(children, (Child) => {
        if (React.isValidElement(Child)) {
          return (
            <>
              <OptimizlyContext.Provider value={{ inEditMode: false }}>
                {React.cloneElement(Child, newProps)}
              </OptimizlyContext.Provider>
            </>
          );
        }
        return <></>;
      })}
    </>
  );
};

export default EpiContent;

// import { ColorTokenValue as colors } from "@@/design-tokens";
import Box from "@@/elements/Box";
import { useEffect, useRef } from "react";
import styles from "./index.module.scss";

export type VideoPlayerProps = {
  videoUrl: string;
};

const VideoPlayer = ({
  videoUrl,

}: VideoPlayerProps) => {
  const videoPlayerContainer = useRef(null);
  useEffect(() => {
    const Vimeo = require("@vimeo/player");
    new Vimeo.default(videoPlayerContainer.current, {
      id: videoUrl,
      controls: true,
      transparent: false,
      dnt: true,
    });
  });

  
  return (
    <Box
      ref={videoPlayerContainer}
      className={styles.videoplayer}
    />
  );
};

export default VideoPlayer;

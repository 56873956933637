import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramEnergy05 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M27.5 39.62c0-15.53 12.603-28.12 28.14-28.12 15.475 0 28.028 12.489 28.13 27.93q.01.09.01.18a28 28 0 0 1-4.845 15.787c-.649 1.085-1.316 2.15-1.98 3.212-1.305 2.083-2.599 4.148-3.718 6.31-1.672 3.228-2.807 6.417-2.807 9.661v.39a2.39 2.39 0 0 1-2.39 2.39h-24.8a2.39 2.39 0 0 1-2.39-2.39v-.39c0-3.05-1.064-6.144-2.664-9.322-1.161-2.309-2.547-4.556-3.942-6.82-.53-.858-1.06-1.72-1.58-2.587A28.03 28.03 0 0 1 27.5 39.62m53.28.169a2 2 0 0 1-.01-.169c0-13.871-11.248-25.12-25.13-25.12S30.5 25.75 30.5 39.62a25.03 25.03 0 0 0 4.643 14.541q.033.047.064.098a237 237 0 0 0 1.526 2.499c1.419 2.301 2.909 4.719 4.133 7.151 1.655 3.29 2.933 6.8 2.983 10.451H67.43c.05-3.854 1.407-7.47 3.142-10.821 1.173-2.265 2.585-4.522 3.93-6.67a174 174 0 0 0 1.927-3.124 25 25 0 0 0 4.35-13.956M41.25 81.79a1.5 1.5 0 0 1 1.5-1.5h25.77a1.5 1.5 0 0 1 0 3H42.75a1.5 1.5 0 0 1-1.5-1.5M41.25 87.61a1.5 1.5 0 0 1 1.5-1.5h25.77a1.5 1.5 0 1 1 0 3H42.75a1.5 1.5 0 0 1-1.5-1.5M47.33 93.39a1.5 1.5 0 0 1 1.5-1.5h13.61a1.5 1.5 0 0 1 1.5 1.5c0 4.518-3.751 8.11-8.3 8.11h-.01c-4.537 0-8.3-3.591-8.3-8.11m3.234 1.5c.662 2.07 2.657 3.61 5.066 3.61h.01c2.416 0 4.407-1.539 5.067-3.61z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramEnergy05;

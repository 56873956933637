import Box from "@@/elements/Box";
import { CloseThin, SearchIcon } from "@@/elements/Icons/icons";
import cn from "classnames";
import styles from "./styles.module.scss";

type Icon = "search";

type Props = {
  input: string;
  setInput: (arg: string) => void;
  onSubmit?: () => void;
  onReset?: () => void;
  placeholder?: string;
  disabledSubmit?: boolean;
  icon?: Icon;
  id?: string;
};

const TextInput = ({
  // TODO: Disabled text input state
  input, setInput, onSubmit, onReset, placeholder, /* disabled */ disabledSubmit, icon, id,
}:Props) => {
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const onResetClick = () => {
    if (onReset) onReset();
    else setInput("");
  };

  const renderIcon = () => {
    switch (icon) {
    case "search":
      return <SearchIcon />;
    default:
      return <SearchIcon />;
    }
  };

  return (
    <Box
      className={styles.input_wrapper} 
      alignItems="center">
      {onSubmit && (
        <button
          type="submit"
          aria-label="search"
          className={cn(styles.button__search, styles.button)}
          onClick={!disabledSubmit ? () => onSubmit() : () => {}}
          disabled={disabledSubmit}
        >
          {renderIcon()}
        </button>
      )}
      <input
        placeholder={placeholder}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={onKeyDown}
        type="search"
        id={id}
        autoComplete="off"
      />
      {!!input.length && (
        <button
          type="button"
          aria-label="reset"
          className={cn(styles.button__reset, styles.button)}
          onClick={onResetClick}
        >
          <CloseThin />
        </button>
      )}
    </Box>
  );
};

export default TextInput;

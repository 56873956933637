import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgEssentialsIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 22"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.302 9.684s-3.81-6.45-3.873-6.565a4.1 4.1 0 0 0-1.519-1.55 4.18 4.18 0 0 0-4.216 0 4.1 4.1 0 0 0-1.518 1.55c-.064.114-1.64 2.812-1.64 2.812M10.82 21s7.576-.025 7.709-.021a4.2 4.2 0 0 0 2.12-.524 4.1 4.1 0 0 0 1.553-1.514 4.03 4.03 0 0 0-.049-4.153c-.072-.11-1.653-2.804-1.653-2.804M5.437 8.173S1.67 14.65 1.6 14.76a4.03 4.03 0 0 0-.046 4.154 4.1 4.1 0 0 0 1.556 1.513c.647.358 1.38.538 2.121.52h3.294"
    />
  </svg>
);
export default SvgEssentialsIcon;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPlay = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 60 60"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#play_svg__a)">
      <circle cx={30} cy={30} r={28.5} stroke="currentColor" strokeWidth={3} />
      <path fill="currentColor" d="M40 30 25 40.825v-21.65z" />
    </g>
    <defs>
      <clipPath id="play_svg__a">
        <path fill="currentColor" d="M0 0h60v60H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPlay;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramEnergy01 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M50.927 10.341A1.4 1.4 0 0 1 52.327 9h6.347a1.4 1.4 0 0 1 1.399 1.341l1.46 34.479a11.35 11.35 0 0 1 4.052 4.455l32.705 18.53a1.4 1.4 0 0 1 .558 1.852l-2.894 5.694a1.4 1.4 0 0 1-1.82.643L63.546 62.29a11.3 11.3 0 0 1-2.222 1.756l2.044 36.808h6.6a1.4 1.4 0 0 1 0 2.8H40.938a1.4 1.4 0 0 1 0-2.8h6.693l2.044-36.787a11.2 11.2 0 0 1-2.246-1.767L16.867 75.994a1.4 1.4 0 0 1-1.821-.643l-2.894-5.694a1.4 1.4 0 0 1 .558-1.853l32.682-18.516a11.2 11.2 0 0 1 4.077-4.495zm.219 36.71c.245-.056.467-.177.645-.345a8.56 8.56 0 0 1 3.71-.834c1.343 0 2.62.315 3.755.877.151.13.33.227.526.283a8.48 8.48 0 0 1 4.213 7.335c0 4.65-3.77 8.494-8.495 8.494a8.44 8.44 0 0 1-8.495-8.494c0-3.136 1.65-5.845 4.14-7.316m7.529-3.525a11.454 11.454 0 0 0-6.349-.01L53.669 11.8h3.663zm-14.406 9.616L15.255 69.581l1.683 3.313 28.755-12.885a11.3 11.3 0 0 1-1.488-5.642q0-.621.064-1.225m8.145 12.1-1.978 35.612h10.129l-1.98-35.618a11.458 11.458 0 0 1-6.172.006m12.874-5.241 28.774 12.893 1.683-3.313-29.015-16.44q.065.605.065 1.226c0 2.043-.547 3.97-1.507 5.634m-19.87-36.105a1.4 1.4 0 0 1-.8 1.812C33.09 30.176 24.882 41.342 24.882 54.367a1.4 1.4 0 0 1-2.8 0c0-14.232 8.967-26.403 21.524-31.27a1.4 1.4 0 0 1 1.811.8m20.165 0a1.4 1.4 0 0 1 1.811-.8c12.557 4.868 21.524 17.039 21.524 31.27a1.4 1.4 0 0 1-2.8 0c0-13.024-8.208-24.19-19.735-28.658a1.4 1.4 0 0 1-.8-1.812M55.767 54.54a.38.38 0 0 0 .106-.267.38.38 0 0 0-.106-.266.38.38 0 0 0-.267-.107.38.38 0 0 0-.267.107.38.38 0 0 0-.106.267c0 .115.048.208.106.266a.38.38 0 0 0 .267.107.38.38 0 0 0 .267-.107m-2.694-.267a2.436 2.436 0 0 1 2.427-2.427 2.436 2.436 0 0 1 2.427 2.428A2.436 2.436 0 0 1 55.5 56.7a2.436 2.436 0 0 1-2.427-2.427"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramEnergy01;

import Box from "@@/elements/Box";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import { CardColors } from "../CardProps";
import cardStyles from "../cardStyles.module.scss";
import styles from "./index.module.scss";

export type LinkCardProperties = {
  header: string;
  image?: PictureData;
};

export type LinkCardOwnProps = {
  link?: LinkData;
  color?: CardColors;
};

export type LinkCardProps = LinkCardOwnProps & LinkCardProperties;

export const LinkCard = ({
  header, image, color, link,
}: LinkCardProps) => {
  const variant = image ? "image" : "color";
  const classNames = cn(cardStyles.card, styles.link_card, {
    [(cardStyles as any)[`card_${color}`]]: color,
    [(styles as any)[`link_card_${variant}`]]: variant,
    [cardStyles.card__link]: link && link.href,
  });

  const Element = link && link.href ? "a" : "div";

  return (
    <>
      {image ? (
        <Element
          href={link.href}
          target={link.target}
          title={link.title}
          className={classNames}
        >
          <Box width="full">
            <EpiProperty<LinkCardProperties> name="image">
              <Picture
                imageData={image}
                aspectRatio={["3 / 2", "1 / 1", "3 / 2"]}
                sizes={[
                  [200, 400, 600, 800, 1200],
                  [200, 400, 600, 800, 1200],
                  [200, 400, 600, 800, 1200],
                ]}
              />
            </EpiProperty>
          </Box>
          <Box
            textAlign="center"
            py={6}
            px={[10, 6, 10]}
            width="full"
            color="Black"
          >
            <EpiProperty<LinkCardProperties> name="header">
              <Typography textcolor="dark" asElement="h3" variant="heading5">
                {header}
              </Typography>
            </EpiProperty>
          </Box>
        </Element>
      ) : (
        <Element
          href={link.href}
          target={link.target}
          title={link.title}
          className={classNames}
        >
          <EpiProperty<LinkCardProperties> name="header">
            <Typography
              asElement="h3"
              textcolor="light"
              variant="heading5"
              p={[10, 6, 10]}
            >
              {header}
            </Typography>
          </EpiProperty>
        </Element>
      )}
    </>
  );
};

export type ProvidedLinkCardProps = OptimizelyBlockProps<LinkCardProperties> &
LinkCardProps;

const ProvidedLinkCard = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedLinkCardProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <LinkCard {...rest} {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedLinkCard;

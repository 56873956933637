import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { CheckCircle, ShareIcon } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { useState } from "react";
import TextButtonIcon from "../Button/TextButtonIcon";
import styles from "./shareBox.module.scss";

type ShareBoxOwnProps = {
  link: string;
};
type ShareBoxEditableProps = {
  header: string;
};
type ShareBoxProps = ShareBoxOwnProps & ShareBoxEditableProps;

const ShareBox = ({ header, link }: ShareBoxProps) => {
  const [hasCopied, setHasCopied] = useState(false);
  const copyUrl = () => {
    if (hasCopied) return;
    navigator.clipboard.writeText(link).then(
      () => {
        setHasCopied(true);
        setTimeout(() => {
          setHasCopied(false);
        }, 2000);
      },
      () => {},
    );
  };

  return (
    <Container>
      <Box textAlign="center">
        <EpiProperty<ShareBoxEditableProps> name="header">
          <Typhography variant="heading4" asElement="div" mb={8}>
            {header}
          </Typhography>
        </EpiProperty>
        <Box
          className={styles.share_wrapper}
          backgroundColor="ComplementaryBlueTint04"
          justifyContent="space-between"
          alignItems="center"
          py={5}
          pr={5}
          pl={11}
        >
          <Box className={styles.link_wrapper}>
            <Typhography variant="button">{link}</Typhography>
          </Box>
          <Box className={styles.icon_wrapper}>
            <TextButtonIcon direction="left" label="" title="copy" onClick={copyUrl} dense>
              <ShareIcon
                style={{
                  fillOpacity: hasCopied ? 0 : 1,
                  strokeOpacity: hasCopied ? 0 : 1,
                }}
              />
              <CheckCircle
                style={{
                  fill: "transparent",
                  fillOpacity: hasCopied ? 1 : 0,
                  strokeOpacity: hasCopied ? 1 : 0,
                }}
              />
            </TextButtonIcon>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export type ProvidedShareBoxProps = OptimizelyBlockProps<ShareBoxProps> &
ShareBoxProps;

const ProvidedShareBox = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedShareBoxProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <ShareBox {...rest} {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedShareBox;

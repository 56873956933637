import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import styles from "./styles.module.scss";

export type LinkGroup = {
  header: string;
  links: LinkData[];
  isCategory: boolean;
};

type Props = {
  items: LinkGroup[]
};

const LinkModule = ({
  items,
}: Props) => (
  <Box flexDirection={["column", "row", "row"]} gap={10} flexWrap="wrap">
    {items.map(({ header, links, isCategory }, groupIndex) => (
      <Box key={groupIndex} className={styles.column_wrapper} component="section">
        <Typhography variant="link" asElement="h4" mb={[header?.length ? 3 : 0, 3, 3]} color="PrimaryGrey" className={styles.header}>
          {header}
        </Typhography>
        <Box flexDirection="column" gap={isCategory ? 3 : 5}>
          {links.map(({
            text, href, title, target,
          }, itemIndex) => (
            <a key={itemIndex} href={href} title={title} target={target} className={styles.link}>
              <Typhography variant={isCategory ? "link" : "body"} asElement="div" color="ComplementaryBlue" className={isCategory ? styles.category : ""}>
                {text}
              </Typhography>
            </a>
          ))}
        </Box>
      </Box>
    ))}
  </Box>
);

export default LinkModule;

import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import { ArrowRight, CrossMark } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import cn from "classnames";
import styles from "./styles.module.scss";

export interface Position {
  position?: {
    lat: number;
    lng: number;
  };
}

interface Props {
  name?: string;
  locality?: string;
  address?: string;
  additionalAddresses?: string[];
  postalCode?: string;
  primaryPhone?: string;
  primaryPhoneLabel?: string;
  additionalPhones?: string[];
  additionalPhonesLabel?: string;
  link?: any;
  id?: string;
  setHoveredLocation?: (args) => void;
  onReset?: () => void;
  distance?: number;
  variant?: "default" | "single";
}

export type Location = Props & Position;

const BranchCard = ({
  name,
  address,
  locality,
  additionalAddresses,
  postalCode,
  primaryPhone,
  primaryPhoneLabel,
  additionalPhones,
  additionalPhonesLabel,
  link,
  id,
  onReset,
  setHoveredLocation,
  distance,
  variant = "default",
}: Props) => {
  const Element = link && variant === "default" ? "a" : "div";
  const distanceString =
    distance < 1000
      ? `${Math.round(distance)} m`
      : `${Math.round(distance / 1000)} km`;
  return (
    <Element
      className={cn(styles.branch_card, {
        [styles.branch_card__hover]: variant === "default",
        [styles.branch_card__single]: variant === "single",
      })}
      onMouseEnter={() => (setHoveredLocation ? setHoveredLocation(id) : null)}
      onMouseLeave={() =>
        setHoveredLocation ? setHoveredLocation(null) : null
      }
      href={link && link.href}
      target={link && link.target}
      title={link && link.title}
    >
      <Typhography
        variant="heading5"
        asElement="div"
        mb={4}
        data-ga-office={name}
      >
        {name}
      </Typhography>
      {address && <Typhography variant="information">{address}</Typhography>}
      {additionalAddresses && !!additionalAddresses.length && (
        <Box flexDirection="column">
          {additionalAddresses.map((additionalAddress, i) => (
            <Typhography key={i} variant="information">
              {additionalAddress}
            </Typhography>
          ))}
        </Box>
      )}
      {postalCode && locality && (
        <Typhography variant="information">{`${postalCode} ${locality}`}</Typhography>
      )}
      {primaryPhone && primaryPhoneLabel && variant === "default" && (
        <Box
          flexDirection="row"
          gap={1}
          alignItems="baseline"
          mt={4}
          flexWrap="wrap"
        >
          <Typhography variant="link">{`${primaryPhoneLabel}:`}</Typhography>
          <Typhography variant="information">{primaryPhone}</Typhography>
        </Box>
      )}
      {additionalPhones &&
        !!additionalPhones.length &&
        additionalPhonesLabel &&
        variant === "default" && (
          <Box
            flexDirection="row"
            gap={1}
            alignItems="baseline"
            flexWrap="wrap"
          >
            <Typhography variant="link">{`${additionalPhonesLabel}:`}</Typhography>
            <Box flexDirection="column">
              {additionalPhones.map((additionalPhone, i) => (
                <Typhography key={i} variant="information">
                  {additionalPhone +
                    (i < additionalPhones.length - 1 ? "," : "")}
                </Typhography>
              ))}
            </Box>
          </Box>
        )}
      <Box mt={3} justifyContent="space-between" alignItems="center">
        {link && (
          <TextButtonIcon
            direction="right"
            link={variant === "default" ? null : link}
            label={link.text}
            dense
          >
            <ArrowRight />
          </TextButtonIcon>
        )}
        {distance && (
          <Box
            backgroundColor="White"
            color="PrimaryGreyTint01"
            br={25}
            px={4}
            py={1}
            customStyle={{ whiteSpace: "nowrap" }}
          >
            <Typhography variant="information">{distanceString}</Typhography>
          </Box>
        )}
      </Box>
      {variant === "single" && (
        <button
          type="button"
          tabIndex={0}
          className={styles.reset_button}
          onClick={onReset}
        >
          <CrossMark />
        </button>
      )}
    </Element>
  );
};

export default BranchCard;

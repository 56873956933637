import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramFinance01 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.599 56C6.599 28.702 28.702 6.599 56 6.599s49.401 22.103 49.401 49.4c0 27.298-22.103 49.402-49.401 49.402S6.599 83.297 6.599 56M56 9.599c-25.64 0-46.401 20.76-46.401 46.4 0 25.642 20.76 46.402 46.401 46.402 25.64 0 46.401-20.76 46.401-46.401 0-25.64-20.76-46.401-46.401-46.401"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M56 37.154c-15.127 0-28.671-3.701-37.767-9.731l1.657-2.5c8.485 5.624 21.41 9.231 36.11 9.231 14.5 0 27.43-3.606 35.922-9.14l1.638 2.514c-9.09 5.923-22.627 9.626-37.56 9.626M17.507 84.996c9.013-6.35 22.972-10.25 38.493-10.25 15.73 0 29.784 4.102 38.79 10.447l-1.728 2.453c-8.377-5.902-21.78-9.9-37.062-9.9-15.097 0-28.396 3.806-36.766 9.702zM104 57.5H8v-3h96z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M37.699 21.803C42.129 13.058 48.518 7.093 56 7.093s13.872 5.965 18.301 14.71C78.774 30.634 81.5 42.728 81.5 56s-2.726 25.365-7.199 34.196c-4.43 8.745-10.819 14.711-18.301 14.711s-13.872-5.966-18.301-14.711C33.226 81.366 30.5 69.272 30.5 56s2.726-25.366 7.199-34.197m2.676 1.356c-4.219 8.33-6.875 19.94-6.875 32.84 0 12.902 2.656 24.512 6.875 32.842 4.262 8.415 9.873 13.066 15.625 13.066s11.363-4.651 15.625-13.066C75.844 80.51 78.5 68.9 78.5 56s-2.656-24.511-6.875-32.841C67.363 14.744 61.752 10.092 56 10.092S44.637 14.744 40.375 23.16"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M57.5 8v96h-3V8z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramFinance01;

import Box from "@@/elements/Box";
import { SizesProps } from "@@/hooks/ui/use-size";
import { SpacingProps } from "@@/hooks/ui/use-spacing";
import styles from "./styles.module.scss";

type SkeletonProps = SizesProps & SpacingProps;

const SkeletonBox = (props : SkeletonProps) => (
  <Box {...props} className={styles.loading} />
);

export default SkeletonBox;

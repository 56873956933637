import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSearchIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 29 29"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="m27.833 25.81-7.526-7.525a10.903 10.903 0 1 0-2.022 2.022l7.525 7.526a1.434 1.434 0 0 0 2.022 0l.001-.001a1.434 1.434 0 0 0 0-2.021ZM11.665 19.7a8.035 8.035 0 1 1 0-16.07 8.035 8.035 0 0 1 0 16.07Z"
    />
  </svg>
);
export default SvgSearchIcon;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgNewsKnowledge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 13 17"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M12.717 6.86a6.36 6.36 0 0 1-2.461 5.023v1.36a.796.796 0 0 1-.796.796H3.257a.796.796 0 0 1-.796-.796v-1.36A6.358 6.358 0 1 1 12.717 6.86m-3.794 8H3.795a.82.82 0 1 0 0 1.64h5.128a.82.82 0 0 0 0-1.64"
    />
  </svg>
);
export default SvgNewsKnowledge;

import Box from "@@/elements/Box";
import SkeletonBox from "@@/components/Loader/Skeleton";
import { Size } from "@@/hooks/ui/use-size";
import styles from "../styles.module.scss";

type Variant = "latestnews" | "newsmodule";

interface SkeletonProps {
  variant: Variant;
}

const SkeletonCard = ({ variant }: SkeletonProps) => {
  const skeletonImageHeight : Size = variant === "latestnews" ? [80, 80, 95] : 70;

  return (
    <Box br={1} width="full" className={styles.card}>
      <SkeletonBox width="full" height={skeletonImageHeight} />
      <Box width="full" pb={4} pt={6} px={6}>
        <SkeletonBox width={20} height={4} />
        <SkeletonBox mt={4} mr={6} width="full" height={14} />
      </Box>
    </Box>
  );
};

export default SkeletonCard;

import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import cn from "classnames";
import { useRef } from "react";
import BranchCard, { Location } from "./card";
import styles from "./styles.module.scss";

type Props = {
  locations: Location[];
  setLocality: (args) => void;
  setHoveredLocation: (args) => void;
  selectedLocations?: string[];
  noHitsLabel: string;
  error: google.maps.places.PlacesServiceStatus;
};

const BranchList = ({
  locations,
  setLocality,
  setHoveredLocation,
  selectedLocations,
  noHitsLabel,
  error,
} : Props) => {

  const ref = useRef(null);
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);

  const getBranchListData = () => {
    const branchMap = new Map();

    const compareLocations = (a, b) => {
      if (a.locality < b.locality) {
        return -1;
      }
      if (a.locality > b.locality) {
        return 1;
      }
      return 0;
    };

    const sortedLocations = [...locations.sort(compareLocations)];

    sortedLocations.sort((a : any, b : any) => a.locality - b.locality).forEach((location) => {
      const firstCharacter = location.locality.charAt(0);

      if (branchMap.has(firstCharacter)) {
        if (branchMap.get(firstCharacter).has(location.locality)) {
          branchMap.get(firstCharacter).get(location.locality).push(location);
        } else {
          branchMap.get(firstCharacter).set(location.locality, [location]);
        }
      } else {
        branchMap.set(firstCharacter, new Map());
        branchMap.get(firstCharacter).set(location.locality, [location]);
      }
    });

    return branchMap;
  };

  const locationsWithOrder = () => {
    if (selectedLocations && selectedLocations.length === 1) {
      const searchedLocation = locations.find((location) => location.id === selectedLocations[0]);
      if (searchedLocation) return [searchedLocation, ...locations.filter((location) => location.id !== selectedLocations[0])];
    }
    return locations;
  };

  const setLocalityAndScroll = (key) => {
    setLocality(key);
    var observer = new MutationObserver(function () {
      if (document.contains(ref.current)) {
        if (isSmall) ref.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        else ref.current.scrollTop = 0;
        observer.disconnect();
      }
    });
   
    observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
  };



  if (error === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) return <Typhography variant="intro">{noHitsLabel}</Typhography>;

  if (locations.length < 10) {
    return (
      <Box
        width="full"
        flexDirection="column"
        gap={3}
        pr={[0, 0, 2]}
        ref={ref}
        customStyle={{ scrollMargin: "140px" }}
      >
        {locationsWithOrder().map((location, i) => <BranchCard
          key={i}
          {...location}
          setHoveredLocation={setHoveredLocation} />)}
      </Box>
    );
  }

  return (
    <Box
      width="full"
      flexDirection="column"
    >
      {Array.from(getBranchListData()).map(([letterKey, letterValue], i) => (
        <Box
          width="full"
          flexDirection="column"
          key={i}>
          <Box
            width="full"
            backgroundColor="ComplementaryBeigeTint04">
            <Typhography
              variant="heading5"
              asElement="div"
              px={5}
              py={2}
              color="PrimaryGrey"
            >
              {letterKey}
            </Typhography>
          </Box>
          <Box className={styles.branchgroup}>
            {Array.from(letterValue).map(([key, branches] : [string, Location[]], j) => {
              if (branches.length > 1) {
                return (
                  <Box key={j}>
                    <button
                      className={styles.branchgroup__locality}
                      type="button"
                      onClick={() => setLocalityAndScroll(key)}
                    >
                      <Box
                        className={cn(styles.branchgroup__item, styles.branchgroup__item__border)}
                        px={5}
                        py={2}
                      >

                        <Typhography
                          variant="link"
                          color="PrimaryGrey"
                        >
                          {key}
                        </Typhography>
                        <Typhography
                          ml={1}
                          variant="link"
                          color="ComplementaryCharcoalTint03"
                        >
                          {`(${branches.length})`}
                        </Typhography>
                      </Box>
                    </button>
                    { branches.map((branch, branchIndex) => (
                      <a
                        href={branch.link.href}
                        target={branch.link.target}
                        title={branch.link.title}
                        key={branchIndex}
                        onMouseEnter={() => setHoveredLocation(branch.id)}
                        onMouseLeave={() => setHoveredLocation(null)}
                      >
                        <Typhography
                          className={cn(styles.branchgroup__item,
                            { [styles.branchgroup__item__border]: (j !== Array.from(letterValue).length - 1 || branchIndex !== branches.length - 1) })}
                          key={branchIndex}
                          uppercase
                          px={7}
                          py={2}
                          variant="information"
                          color="PrimaryGrey"
                          data-ga-office={branch.name}
                        >
                          {branch.name}
                        </Typhography>
                      </a>
                    )) }
                  </Box>
                );
              }
              if (branches.length === 1) {
                return (
                  <a
                    href={branches[0].link.href}
                    target={branches[0].link.target}
                    title={branches[0].link.title}
                    key={j}
                    onMouseEnter={() => setHoveredLocation(branches[0].id)}
                    onMouseLeave={() => setHoveredLocation(null)}
                  >
                    <Typhography
                      className={cn(styles.branchgroup__item,
                        { [styles.branchgroup__item__border]: (j !== Array.from(letterValue).length - 1) })}
                      uppercase
                      px={5}
                      py={2}
                      variant="information"
                      color="PrimaryGrey"
                      data-ga-office={branches[0].name}
                    >
                      {branches[0].locality}
                    </Typhography>
                  </a>
                );
              }
              return <></>;
            })}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default BranchList;

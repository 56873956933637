import Box from "@@/elements/Box";
import { ArrowUp } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

type Props = {
  items: string[];
  overflowHidden?: boolean;
};

const Chips = ({ items, overflowHidden = false } : Props) => {
  const ref = useRef(null);
  const [overflow, setOverflow] = useState(null);
  const [buttonPosition, setButtonPosition] = useState([0, 0]);
  const [showAll, setShowAll] = useState(false);

  const handleOverflow = () => {
    const wrapperBottomPosition = ref.current.getBoundingClientRect().bottom;
    let lastVisibleElement = null;
    let itemsOverflow = 0;
    [...ref.current.children].forEach((child) => {
      const childTopPosition = child.getBoundingClientRect().top;
      if (wrapperBottomPosition < childTopPosition) {
        itemsOverflow += 1;
      } else {
        lastVisibleElement = child;
      }
    });

    if(itemsOverflow > 0) setOverflow(itemsOverflow);
    const showAllButtonPosition = lastVisibleElement.offsetLeft + Math.round(lastVisibleElement.getBoundingClientRect().width);
    const lastChild = [...ref.current.children].pop();
    const hideButtonPosition = lastChild.offsetLeft + Math.round(lastChild.getBoundingClientRect().width);
    setButtonPosition([showAllButtonPosition, hideButtonPosition]);
  };

  useEffect(() => {
    if (overflowHidden && ref.current) {
      handleOverflow();
      window.addEventListener("resize", handleOverflow)
    }
    return () => window.removeEventListener("resize", handleOverflow)
  }, [ref, showAll]);

  const buttonVisible = (overflow !== null && overflowHidden) ;

  const onClickButton = (e) => {
    e.preventDefault();
    setShowAll(!showAll);
  };

  return (
    <Box className={styles.relative}>
      <Box
        ref={ref}
        className={cn(styles.chip_wrapper, {
          [styles.chip_wrapper__overflow_hidden]: overflowHidden && !showAll,
        })}
        flexDirection="row"
        gap={1}
        flexWrap="wrap"
        pr={overflowHidden ? 8 : 0}
      >
        { items.map((item, i) => (
          <Box
            key={i}
            className={styles.chip}
            backgroundColor="ComplementaryBeigeTint04"
            py={1}
            px={2}
            br={0.5}
            flexWrap="wrap"
          >
            <Typhography variant="information" color="PrimaryGrey">
              { item }
            </Typhography>
          </Box>
        ))}
      </Box>
      {buttonVisible && (
      <button
        type="button"
        className={styles.overflow_button}
        style={{ left: `${!showAll ? buttonPosition[0] : buttonPosition[1]}px` }}
        onClick={onClickButton}
      >
        {!showAll && (
        <Typhography variant="link">
          {`+${overflow}`}
        </Typhography>
        )}
        {showAll && <ArrowUp height={10} />}
      </button>
      )}
    </Box>
  );
};

export default Chips;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramVehicles08 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g fill="currentColor" clipPath="url(#pictogram_vehicles_08_svg__a)">
      <path d="M28.754 80c-3.712 0-6.728-2.987-6.728-6.664s3.016-6.664 6.728-6.664c3.713 0 6.729 2.987 6.729 6.664S32.467 80 28.754 80m0-10.37c-2.06 0-3.742 1.665-3.742 3.706s1.682 3.707 3.742 3.707 3.743-1.666 3.743-3.707-1.683-3.707-3.743-3.707M42.927 80c-3.712 0-6.728-2.987-6.728-6.664s3.016-6.664 6.728-6.664c3.713 0 6.729 2.987 6.729 6.664S46.64 80 42.927 80m0-10.37c-2.06 0-3.742 1.665-3.742 3.706s1.682 3.707 3.742 3.707 3.743-1.666 3.743-3.707-1.682-3.707-3.743-3.707M88.731 80c-3.712 0-6.728-2.987-6.728-6.664s3.016-6.664 6.728-6.664c3.713 0 6.728 2.987 6.728 6.664S92.443 80 88.731 80m0-10.37c-2.06 0-3.742 1.665-3.742 3.706s1.682 3.707 3.742 3.707 3.742-1.666 3.742-3.707-1.682-3.707-3.742-3.707" />
      <path d="M83.207 76.698h-33.96c-.826 0-1.492-.66-1.492-1.479 0-.818.666-1.479 1.492-1.479h33.96c.826 0 1.493.66 1.493 1.479 0 .818-.667 1.479-1.493 1.479M35.96 75.308h-.736c-.826 0-1.493-.661-1.493-1.48 0-.817.667-1.478 1.493-1.478h.736c.826 0 1.493.66 1.493 1.479 0 .818-.667 1.479-1.493 1.479M105.84 75.17H94.285c-.826 0-1.493-.661-1.493-1.48 0-.817.667-1.478 1.493-1.478h10.062v-4.15c0-.109.01-.227.04-.335l.627-2.692v-5.392l-4.021-11.199s-.498-1.094-1.403-1.232c-1.354-.217-8.888-.818-12.362-1.085-.398.375-.796.888-.796 1.252v16.207c0 .818-.667 1.479-1.493 1.479s-1.493-.66-1.493-1.479V47.37c0-2.238 2.2-3.815 2.458-3.983.279-.197.617-.286.966-.256.458.039 11.346.867 13.197 1.153 2.091.325 3.325 2.11 3.733 3.115l4.13 11.495c.06.157.09.325.09.493v5.816c0 .108-.01.227-.04.335l-.627 2.691v5.462c0 .818-.667 1.478-1.493 1.478z" />
      <path d="M100.874 59.13c-.07 0-.13 0-.199-.01l-10.222-1.36a1.49 1.49 0 0 1-1.294-1.469v-7.798c0-.818.667-1.478 1.493-1.478s1.493.66 1.493 1.478V55l8.928 1.193a1.49 1.49 0 0 1 1.284 1.666c-.1.739-.747 1.281-1.473 1.281zM23.111 75.268c-.1 0-.21 0-.309-.03l-3.523-.729a1.53 1.53 0 0 1-.985-.7 1.48 1.48 0 0 1-.13-1.193l1.961-6.092c.06-.197.17-.375.309-.532.119-.129 2.956-3.224 8.25-3.224 3.823 0 6.082 1.479 7.097 2.376 1.065-.907 3.504-2.465 7.664-2.465a13 13 0 0 1 8.63 3.273h27.52l3.99-3.154c.27-.208.598-.326.936-.326h5.603c4.579 0 9.615 5.077 9.615 9.7v1.262c0 .819-.667 1.48-1.493 1.48s-1.493-.661-1.493-1.48v-1.261c0-3.254-3.891-6.743-6.629-6.743H85.05l-3.992 3.154a1.54 1.54 0 0 1-.935.325H51.467c-.428 0-.836-.177-1.115-.492-.1-.109-2.597-2.78-6.907-2.78s-5.912 1.922-5.982 2c-.288.326-.716.543-1.154.543h-1.075a1.49 1.49 0 0 1-1.195-.592c-.05-.059-1.552-1.863-5.354-1.863-3.255 0-5.216 1.499-5.823 2.05l-1.343 4.19 1.9.395a1.474 1.474 0 0 1 1.155 1.745c-.15.7-.766 1.173-1.463 1.173z" />
      <path d="M6.868 75.436c-1.881 0-3.235-.286-3.335-.306A1.49 1.49 0 0 1 2.4 74.046l-2.35-9.287c-.178-.72.2-1.459.897-1.735l1.522-.591.18-11.741c0-.404.179-.799.487-1.065l11.347-10.242A1.52 1.52 0 0 1 15.487 39h65.152c.826 0 1.493.66 1.493 1.479v21.54c0 .818-.667 1.479-1.493 1.479s-1.493-.661-1.493-1.48v-20.06H16.064l-10.44 9.424-.19 12.086a1.45 1.45 0 0 1-.945 1.35l-1.234.484 1.791 7.068c1.284.148 3.733.256 6.002-.562 3.294-1.193 4.897-2.78 4.917-2.8a1.51 1.51 0 0 1 2.11-.03c.587.572.607 1.499.04 2.08-.08.08-2.05 2.08-6.042 3.52-1.821.66-3.692.848-5.215.848z" />
      <path d="M22.434 63.044h-.14c-.815-.079-1.422-.799-1.343-1.607l1.642-17.202c.03-.336.18-.651.418-.898l3.653-3.687a1.503 1.503 0 0 1 2.11-.02c.587.572.597 1.509.02 2.09l-3.284 3.313-1.593 16.68a1.486 1.486 0 0 1-1.483 1.34zM6.022 71.059H3.185c-.826 0-1.493-.66-1.493-1.479 0-.818.667-1.479 1.493-1.479h2.12l11.794-14.58 1.842-12.756c.119-.809.875-1.37 1.692-1.252a1.48 1.48 0 0 1 1.264 1.676l-1.901 13.16a1.56 1.56 0 0 1-.309.72l-12.5 15.438a1.51 1.51 0 0 1-1.165.552" />
      <path d="M18.742 52.516H4.468c-.826 0-1.493-.66-1.493-1.48 0-.817.667-1.478 1.493-1.478H18.74c.827 0 1.494.66 1.494 1.479 0 .818-.667 1.479-1.494 1.479" />
    </g>
    <defs>
      <clipPath id="pictogram_vehicles_08_svg__a">
        <path fill="#fff" d="M0 39h108v41H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPictogramVehicles08;

import cn from "classnames";
import { useEffect, useState } from "react";

import { SnwButtonType } from "@@/components/Button/buttonProps";
import SnwButtonContainer from "@@/components/Button/SnwButtonContainer";
import RichText from "@@/components/RichText";
import { BackgroundColor } from "@@/containers/Cards/service-description";
import Box from "@@/elements/Box";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import {
  screenSizeIsMedium,
  screenSizeIsSmall,
} from "@@/hooks/device/use-breakpoint";
import EpiProperty from "@@/integrations/optimizly/Property";
import { PictureData } from "@@/models/pictureData";
import styles from "./index.module.scss";

export type ServiceDescriptionCardProperties = {
  header: string;
  image: PictureData;
  body: string;
};

export type ServiceDescriptionOwnProps = {
  size: "small" | "large";
  buttons?: SnwButtonType[];
  backgroundColor: BackgroundColor;
  slider: boolean;
};

export type ServiceDescriptionCardProps = ServiceDescriptionCardProperties &
ServiceDescriptionOwnProps;

export const colorMapperCard = (color) => {
  switch (color) {
    case "beige":
      return "White";
    case "white":
      return "ComplementaryBeigeTint03";
    default:
      return "White";
  }
};

const ServiceDescriptionCard = ({
  backgroundColor,
  header,
  image,
  body,
  size,
  buttons,
  slider,
}: ServiceDescriptionCardProps) => {
  const [small, setSmall] = useState<boolean>(false);
  const [medium, setMedium] = useState<boolean>(false);

  useEffect(() => {
    if (size === "small" || screenSizeIsSmall()) {
      setSmall(true);
    }
    if (size === "small" && screenSizeIsMedium() && !slider) {
      setMedium(true);
    }
  }, []);

  const setAspectRatio = () => {
    if (small && (!screenSizeIsMedium() || slider)) {
      return "5 / 3";
    }
    if (small && screenSizeIsMedium() && !slider) {
      return "1 /1";
    }
    return "21 /9";
  };

  const cardClassNames = cn(styles.card, {
    [styles.card_large]: !small,
    [styles.card_medium]: medium,
    [styles.card_small]: small && !medium,
  });

  return (
    <Box
      height={slider ? "full" : null}
      width="full"
      backgroundColor={colorMapperCard(backgroundColor)}
      className={cardClassNames}
    >
      <EpiProperty<ServiceDescriptionCardProperties> name="image">
        <Picture
          className={styles.image}
          imageData={image}
          aspectRatio={setAspectRatio()}
          sizes={[216, 360, 560, 720, 900, 1200, 1600]}
        />
      </EpiProperty>
      <Box className={styles.text_container} pb={10}>
        <Box>
          <EpiProperty<ServiceDescriptionCardProperties> name="header">
            <Typhography
              pt={10}
              px={small ? 7 : 15}
              mb={4}
              textAlign="left"
              asElement="h3"
              variant="heading5"
            >
              {header}
            </Typhography>
          </EpiProperty>
          <Box px={small ? 7 : 15}>
            <EpiProperty<ServiceDescriptionCardProperties> name="body">
              <RichText body={body} />
            </EpiProperty>
          </Box>
        </Box>
        {buttons && (
          <Box
            className={buttons.length > 1 ? styles.button_wrapper : null}
            px={small ? 7 : 15}
            mt={6}
            gap={3}
            width="full"
            flexWrap="wrap"
            justifyContent={
              buttons && buttons.length === 1 ? "flex-end" : "space-between"
            }
            alignItems="flex-end"
            flexDirection={small ? "column-reverse" : "row"}
          >
            <SnwButtonContainer buttons={buttons} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ServiceDescriptionCard;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramArrows03 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M51.61.44a1.5 1.5 0 0 1 2.12 0l8.88 8.88a1.5 1.5 0 0 1 0 2.121l-8.88 8.87a1.5 1.5 0 0 1-2.12-2.122l7.818-7.81-7.819-7.818a1.5 1.5 0 0 1 0-2.122M91.163 74.22a1.5 1.5 0 0 1 1.836 1.063l2.853 10.68 10.68-2.862a1.5 1.5 0 1 1 .776 2.898l-12.13 3.25a1.5 1.5 0 0 1-1.837-1.062l-3.24-12.13a1.5 1.5 0 0 1 1.062-1.836"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.031 57.218C7.99 29.62 31.144 8.022 58.743 8.99a1.5 1.5 0 1 1-.106 2.998c-25.941-.91-47.707 19.39-48.608 45.333a47.3 47.3 0 0 0 1.027 11.602 1.5 1.5 0 1 1-2.932.632A50.3 50.3 0 0 1 7.03 57.219zM68.323 12.85a1.5 1.5 0 0 1 1.888-.968 50.4 50.4 0 0 1 11.234 5.226c23.423 14.629 30.536 45.475 15.907 68.897a1.5 1.5 0 0 1-2.544-1.59c13.751-22.018 7.064-51.012-14.953-64.763a47.4 47.4 0 0 0-10.566-4.914 1.5 1.5 0 0 1-.966-1.889M11.986 78.925a1.5 1.5 0 0 1 2.028.62c12.182 22.919 40.643 31.621 63.562 19.43a47.4 47.4 0 0 0 9.538-6.688 1.5 1.5 0 0 1 2.012 2.225 50.4 50.4 0 0 1-10.142 7.112c-24.381 12.969-54.66 3.712-67.618-20.67a1.5 1.5 0 0 1 .62-2.029"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.39 76.591a1.5 1.5 0 0 1 1.138-.15l12.12 3.25a1.5 1.5 0 1 1-.776 2.898L12.2 79.728l-2.862 10.68a1.5 1.5 0 0 1-2.898-.776l3.25-12.13a1.5 1.5 0 0 1 .7-.911"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramArrows03;

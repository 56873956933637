import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramMiscellaneous06 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 113 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M27.359 41.396c0-16.452 12.967-29.868 29.048-29.868h.296c16.081 0 29.048 13.416 29.048 29.868v.306c0 4.246-1.836 10.028-4.423 16.14-2.612 6.171-6.084 12.89-9.54 19.09-3.46 6.205-6.916 11.914-9.507 16.071a381 381 0 0 1-4.013 6.3l-.154.234-.079.12-.06.091-.02.032-1.252-.828 1.251.828c-.278.419-.748.672-1.25.672h-.297a1.5 1.5 0 0 1-1.25-.672l1.25-.828-1.25.828-.022-.032-.06-.09-.233-.356c-.203-.31-.5-.768-.88-1.354a380 380 0 0 1-3.132-4.945c-2.591-4.157-6.048-9.866-9.507-16.07-3.457-6.2-6.929-12.92-9.541-19.092-2.587-6.111-4.423-11.893-4.423-16.138zm29.196 55.036-.071-.11a377 377 0 0 1-3.108-4.906c-2.575-4.13-6.005-9.795-9.433-15.945-3.43-6.154-6.846-12.768-9.398-18.8-2.579-6.09-4.186-11.382-4.186-14.968v-.307c0-14.879 11.706-26.868 26.048-26.868h.296c14.342 0 26.048 11.99 26.048 26.868v.306c0 3.587-1.607 8.879-4.185 14.97-2.553 6.031-5.968 12.645-9.399 18.8-3.428 6.149-6.858 11.815-9.432 15.944a378 378 0 0 1-3.18 5.016"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M56.555 30.717a8.842 8.842 0 1 0 0 17.684 8.842 8.842 0 0 0 0-17.684M44.713 39.56c0-6.54 5.302-11.842 11.842-11.842S68.397 33.02 68.397 39.56 63.095 51.4 56.555 51.4s-11.842-5.302-11.842-11.842"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramMiscellaneous06;

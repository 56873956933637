import { FC } from "react";
import Box from "@@/elements/Box";
import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import { ArrowRight, ArrowDown } from "@@/elements/Icons/icons";
import { LinkProps } from "../AccordionProps.model";

const Links: FC<LinkProps> = ({
  links,
}) => {
  const getIcon = (href) => {
    if (href && href.includes("#")) return <ArrowDown />;
    return <ArrowRight />;
  };
  return (
    <>
      {links.map((link) => (
        <Box mt={3} key={link.href}>
          <TextButtonIcon label={link.text} link={link} dense>
            { getIcon(link.href) }
          </TextButtonIcon>
        </Box>
      ))}
    </>
  );
};

export default Links;

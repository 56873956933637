import RichText from "@@/components/RichText";
import Box from "@@/elements/Box";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import { PictureData } from "@@/models/pictureData";
import Accordion from "..";
import styles from "../accordionStyles.module.scss";

interface accessory {
  name: string;
  image: PictureData;
  body: string;
}
type AccessoriesProps = {
  header: string;
  accessories: accessory[];
};

const AccordionAccessories = ({ header, accessories }: AccessoriesProps) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);

  return (
    <Accordion noPaddingX backgroundColor="light-blue" header={header}>
      {accessories.map((accessory) => (
        <Box
          className="sidebar"
          mb={2}
          br={1}
          backgroundColor="ComplementaryBeigeTint04"
          p={6}
          flexDirection={isSmall ? "column" : "row"}
        >
          <Box mr={[0, 6, 6]} mb={[6, 0, 0]} className={styles.accessories}>
            <Picture
              fallbackImg="accessory"
              imageData={accessory.image}
              aspectRatio="3/2"
            />
          </Box>
          <Box width="full">
            <Typhography uppercase mb={4} variant="heading6">
              {accessory.name}
            </Typhography>
            <RichText body={accessory.body} />
          </Box>
        </Box>
      ))}
    </Accordion>
  );
};
export default AccordionAccessories;

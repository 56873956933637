import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFind = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 18"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.922.5c-1.78 0-3.487.714-4.745 1.985A6.8 6.8 0 0 0 .21 7.275c0 6.576 6.502 9.935 6.569 9.969a.32.32 0 0 0 .284 0c.067-.034 6.569-3.388 6.569-9.968 0-1.797-.707-3.52-1.966-4.791A6.68 6.68 0 0 0 6.922.5m-1.31 4.921A2.5 2.5 0 1 1 8.388 9.58 2.5 2.5 0 0 1 5.61 5.42"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFind;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramElectronics01 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.265 15.111c0-4.68 3.832-8.512 8.513-8.512h44.444c4.68 0 8.513 3.832 8.513 8.512v81.778c0 4.68-3.833 8.512-8.513 8.512H33.778c-4.68 0-8.513-3.832-8.513-8.512zm8.513-5.512c-3.024 0-5.513 2.489-5.513 5.512v81.778c0 3.023 2.49 5.512 5.513 5.512h44.444c3.024 0 5.513-2.489 5.513-5.512V15.11c0-3.023-2.49-5.512-5.513-5.512z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M33.47 15.802a1 1 0 0 1 1-1h43.16a1 1 0 0 1 1 1v73.185a1 1 0 0 1-1 1H34.47a1 1 0 0 1-1-1zm2 1v71.185h41.16V16.802z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M56 93.037a3.54 3.54 0 0 1 3.556 3.556A3.54 3.54 0 0 1 56 100.148a3.54 3.54 0 0 1-3.556-3.555c0-1.877 1.58-3.556 3.556-3.556"
    />
  </svg>
);
export default SvgPictogramElectronics01;

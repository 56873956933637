import Typography from "@@/elements/Typography";
import cn from "classnames";
import { TertiaryButtonProps } from "./buttonProps";
import styles from "./buttonStyles.module.scss";

const TertiaryButton = ({
  disabled,
  onClick,
  label,
  color = "blue",
  href,
  target = "_self",
  title,
}: TertiaryButtonProps) => {
  const classNames = cn(styles.base_button, styles.tertiary, {
    [styles[`tertiary_${color}`]]: color,
  });
  if (href) {
    return (
      <a href={href} className={classNames} title={title} target={target}>
        <Typography variant="button">{label}</Typography>
      </a>
    );
  }
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames}
      type="button"
    >
      <Typography variant="button">{label}</Typography>
    </button>
  );
};
export default TertiaryButton;

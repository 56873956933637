import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramNumbers09 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 226.77 226.77"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M141.21 94.28c0 3.9-.65 7.69-1.95 11.38q-1.95 5.52-5.07 11.76l-21.45 43.68h-14.3l20.15-40.43c-1.21.52-2.56.89-4.03 1.11s-2.95.33-4.42.33c-3.38 0-6.56-.65-9.56-1.95q-4.485-1.95-7.8-5.46c-3.315-3.51-3.96-5.2-5.27-8.58q-1.95-5.07-1.95-11.31c0-6.24.67-8.26 2.01-11.77s3.25-6.52 5.72-9.04c2.47-2.51 5.42-4.44 8.84-5.78s7.17-2.02 11.25-2.02 7.8.65 11.18 1.95 6.3 3.17 8.77 5.59c2.47 2.43 4.4 5.37 5.79 8.84s2.08 7.37 2.08 11.7Zm-13.52.26c0-5.03-1.32-9.01-3.96-11.96s-6.09-4.42-10.33-4.42-7.8 1.47-10.4 4.42-3.9 6.93-3.9 11.96c0 4.68 1.23 8.58 3.7 11.7s6 4.68 10.6 4.68c4.24 0 7.69-1.43 10.33-4.29q3.96-4.29 3.96-12.09"
    />
  </svg>
);
export default SvgPictogramNumbers09;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramNumbers03 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 226.77 226.77"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M141.34 134.71c0 4.51-.76 8.47-2.28 11.89s-3.6 6.26-6.24 8.51-5.72 3.97-9.23 5.14-7.21 1.75-11.11 1.75-7.41-.52-10.79-1.56q-5.07-1.56-9.03-4.74c-2.65-2.12-4.77-4.85-6.37-8.19-1.6-3.33-2.45-7.3-2.54-11.9h13.39c.35 5.03 2.02 8.67 5 10.92 2.99 2.25 6.44 3.38 10.33 3.38 4.42 0 8.08-1.37 10.98-4.1s4.36-6.56 4.36-11.5-1.28-8.64-3.83-11.38c-2.56-2.73-6.52-4.09-11.9-4.09h-2.21v-11.83h2.21c4.94 0 8.56-1.3 10.85-3.9s3.44-6.07 3.44-10.4c0-4.77-1.34-8.39-4.03-10.85-2.69-2.47-5.98-3.71-9.88-3.71-4.16 0-7.45 1.21-9.88 3.64s-3.81 5.72-4.16 9.88H84.9c.17-3.99 1-7.56 2.47-10.73 1.47-3.16 3.44-5.85 5.92-8.06s5.33-3.9 8.58-5.07 6.78-1.75 10.59-1.75 7.67.63 11.05 1.88c3.38 1.26 6.28 3.06 8.71 5.4 2.42 2.34 4.31 5.11 5.65 8.32s2.02 6.76 2.02 10.66c0 5.03-1.06 9.19-3.19 12.48-2.12 3.29-5 5.85-8.64 7.67 4.07 1.73 7.3 4.4 9.68 8s3.58 8.34 3.58 14.24Z"
    />
  </svg>
);
export default SvgPictogramNumbers03;

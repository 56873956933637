import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramMaterials02 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M54.511 17.104a1.5 1.5 0 0 1 1.484-1.279h.01a1.5 1.5 0 0 1 1.486 1.297c.066.482 1.312 7.005 17.346 25.8 9.678 11.345 11.832 24.277 8.07 34.638-3.768 10.379-13.393 17.938-26.699 18.603q-.037.002-.075.002h-.276q-.037 0-.075-.002c-13.31-.665-22.939-8.224-26.707-18.603-3.761-10.362-1.605-23.293 8.079-34.638C53.19 24.135 54.439 17.592 54.51 17.104m1.486 4.569c-1.938 4.042-6.375 11.263-16.561 23.196-9.084 10.642-10.884 22.459-7.54 31.667 3.332 9.18 11.855 16.009 23.999 16.63h.2c12.139-.621 20.66-7.449 23.992-16.63 3.343-9.209 1.546-21.025-7.533-31.667-10.177-11.93-14.619-19.148-16.557-23.196"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M36.072 65.11a1 1 0 0 1 1 1c0 11.062 8.43 19.93 18.696 19.93a1 1 0 1 1 0 2c-11.484 0-20.696-9.88-20.696-21.93a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramMaterials02;

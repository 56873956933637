import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInstagram = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 35 35"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M17.5 20.563a3.057 3.057 0 0 1-3.062-3.063 3.057 3.057 0 0 1 3.062-3.062 3.057 3.057 0 0 1 3.063 3.062c0 1.64-1.422 3.063-3.063 3.063"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.219 10.063H13.78c-.875.109-1.312.218-1.64.328-.438.109-.766.328-1.094.656-.26.26-.382.52-.53.833q-.059.125-.126.26-.026.077-.058.162c-.114.313-.27.74-.27 1.48v7.437c.109.875.218 1.312.328 1.64.109.438.328.766.656 1.094.26.26.52.382.833.53q.125.058.26.126.077.026.162.058c.313.114.74.27 1.48.27h7.437c.875-.109 1.312-.218 1.64-.328.438-.109.766-.328 1.094-.656.26-.26.382-.52.53-.833q.058-.125.126-.26.026-.077.058-.162c.114-.313.27-.74.27-1.48v-7.437c-.109-.875-.218-1.312-.328-1.64-.109-.438-.328-.766-.656-1.094-.26-.26-.52-.382-.833-.53q-.125-.059-.26-.126l-.162-.058c-.313-.114-.74-.27-1.48-.27M17.5 12.797a4.67 4.67 0 0 0-4.703 4.703 4.67 4.67 0 0 0 4.703 4.703 4.67 4.67 0 0 0 4.703-4.703 4.67 4.67 0 0 0-4.703-4.703m5.906-.11a1.094 1.094 0 1 1-2.187 0 1.094 1.094 0 0 1 2.187 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 17.5C0 7.835 7.835 0 17.5 0S35 7.835 35 17.5 27.165 35 17.5 35 0 27.165 0 17.5m13.781-9.078h7.438c.984.11 1.64.219 2.187.437.657.329 1.094.547 1.64 1.094a4.8 4.8 0 0 1 1.095 1.64c.218.548.437 1.204.437 2.188v7.438c-.11.984-.219 1.64-.437 2.187-.328.657-.547 1.094-1.094 1.64a4.8 4.8 0 0 1-1.64 1.095 5.5 5.5 0 0 1-2.188.437H13.78c-.984-.11-1.64-.219-2.187-.437-.656-.328-1.094-.547-1.64-1.094a4.8 4.8 0 0 1-1.095-1.64 5.5 5.5 0 0 1-.437-2.188V13.78c.11-.984.219-1.64.437-2.187.329-.656.547-1.094 1.094-1.64a4.8 4.8 0 0 1 1.64-1.095 5.5 5.5 0 0 1 2.188-.437"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInstagram;

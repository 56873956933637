import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import {
  ScreenSize,
  useIsBreakpointAndAbove,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";

export type ClockProps = {
  date: string;
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  color?: string;
};

const CountdownClock = ({
  date,
  days,
  hours,
  minutes,
  seconds,
  color,
}: ClockProps) => {
  const isLarge = useIsBreakpointAndAbove(ScreenSize.LARGE);
  const calculateTimeLeft = (date: string) => {
    const targetDate = new Date(date);

    let difference = +new Date(targetDate) - +new Date();

    const formatTime = (value) => {
      if (value < 0) {
        return "00";
      }
      if (value < 10) {
        return `0${value}`;
      }
      return value;
    };
    const days = formatTime(Math.floor(difference / (1000 * 60 * 60 * 24)));
    const hours = formatTime(Math.floor((difference / (1000 * 60 * 60)) % 24));
    const minutes = formatTime(Math.floor((difference / 1000 / 60) % 60));
    const seconds = formatTime(Math.floor((difference / 1000) % 60));

    return { days, hours, minutes, seconds };
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date));

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft(date));
    }, 1000);
  });
  const shouldShowDays = timeLeft.days > 0;
  const shouldShowHours = shouldShowDays || timeLeft.hours > 0;
  const shouldShowMinutes = shouldShowHours || timeLeft.minutes > 0;
  const shouldShowSeconds = shouldShowMinutes || timeLeft.seconds > 0;
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);
  return (
    <Box className={styles.clock_wrapper}>
      {/* days */}

      <Box
        className={cn(styles.date_wrapper, {
          [styles.date_wrapper_hidden]: !shouldShowHours && isSmall,
          [styles.date_wrapper_disabled_beige]:
            !shouldShowDays && color === "beige",
          [styles.date_wrapper_disabled]: !shouldShowDays,
        })}
        flexDirection="column"
        alignItems="center"
      >
        <Box mb={5} flexDirection="row">
          {String(timeLeft.days)
            .split("")
            .map((value, i) => (
              <Box key={i} className={styles.number_box}>
                <p>{value}</p>
              </Box>
            ))}
        </Box>

        <Typhography asElement="p" variant={isLarge ? "heading5" : "link"}>
          {days}
        </Typhography>
      </Box>
      {/* hours */}
      <Box
        className={cn(styles.date_wrapper, {
          [styles.date_wrapper_hidden]: !shouldShowHours && isSmall,
          [styles.date_wrapper_disabled_beige]:
            !shouldShowHours && color === "beige",
          [styles.date_wrapper_disabled]: !shouldShowHours,
        })}
        flexDirection="column"
        alignItems="center"
      >
        <Box mb={5} flexDirection="row">
          {String(timeLeft.hours)
            .split("")
            .map((value, i) => (
              <Box key={i} className={styles.number_box}>
                <p>{value}</p>
              </Box>
            ))}
        </Box>

        <Typhography asElement="p" variant={isLarge ? "heading5" : "link"}>
          {hours}
        </Typhography>
      </Box>
      {/* minutes */}
      <Box
        className={cn(styles.date_wrapper, {
          [styles.date_wrapper_disabled_beige]:
            !shouldShowMinutes && color === "beige",
          [styles.date_wrapper_disabled]: !shouldShowMinutes,
        })}
        flexDirection="column"
        alignItems="center"
      >
        <Box mb={5} flexDirection="row">
          {String(timeLeft.minutes)
            .split("")
            .map((value, i) => (
              <Box key={i} className={styles.number_box}>
                <p>{value}</p>
              </Box>
            ))}
        </Box>
        <Typhography asElement="p" variant={isLarge ? "heading5" : "link"}>
          {minutes}
        </Typhography>
      </Box>
      {/* seconds */}
      <Box
        className={cn(styles.date_wrapper, {
          [styles.date_wrapper_disabled_beige]:
            !shouldShowSeconds && color === "beige",
          [styles.date_wrapper_disabled]: !shouldShowSeconds,
        })}
        flexDirection="column"
        alignItems="center"
      >
        <Box mb={5} flexDirection="row">
          {String(timeLeft.seconds)
            .split("")
            .map((value, i) => (
              <Box key={i} className={styles.number_box}>
                <p>{value}</p>
              </Box>
            ))}
        </Box>
        <Typhography asElement="p" variant={isLarge ? "heading5" : "link"}>
          {seconds}
        </Typhography>
      </Box>
    </Box>
  );
};

export default CountdownClock;

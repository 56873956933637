import Box from "@@/elements/Box";
import { CrossMark } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import styles from "./styles.module.scss";

type Props = {
  onClick: Function;
  disabled?: boolean;
  label: string;
};

const ResetButton = ({ onClick, disabled, label } : Props) => (
  <Box alignItems="center" mt={6}>
    <button
      onClick={() => onClick()}
      className={styles.reset_button}
      type="button"
      disabled={disabled}
    >
      <CrossMark />
      <Typhography variant="link" asElement="div">
        { label }
      </Typhography>
    </button>
  </Box>
);

export default ResetButton;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramTools04 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.825 17.42a1.5 1.5 0 0 1 1.731-.281l12.14 6.064a1.5 1.5 0 0 1 .752.868l3.033 9.097q.027.082.045.167L54.28 48.09l7.94-7.94c-1.802-6-.336-12.768 4.396-17.522l.006-.006a17.65 17.65 0 0 1 18.87-3.938c.212.083.396.21.543.366a1.5 1.5 0 0 1-.018 2.1l-9.363 9.39 6.949 6.949 9.366-9.34a1.5 1.5 0 0 1 .75-.424 1.5 1.5 0 0 1 1.742.948c2.444 6.286 1.146 13.72-3.952 18.839l-.004.005c-4.753 4.73-11.516 6.196-17.516 4.397l-4.742 4.743 21.44 21.44c3.935 3.935 3.933 10.316.002 14.259l-.002.002c-3.935 3.935-10.316 3.932-14.259.001L54.987 70.917l-.882.883c1.802 6 .337 12.768-4.396 17.523l-.006.006c-5.127 5.095-12.586 6.388-18.868 3.938a1.5 1.5 0 0 1-.546-.368 1.5 1.5 0 0 1 .02-2.098l9.363-9.39-6.95-6.95-9.366 9.34a1.502 1.502 0 0 1-2.492-.525 17.65 17.65 0 0 1 3.95-18.836l.007-.006c4.752-4.73 11.516-6.196 17.515-4.398l3.996-3.997-14.937-14.937-8.983-2.994a1.5 1.5 0 0 1-.868-.753l-6.065-12.14a1.5 1.5 0 0 1 .282-1.73zm12.353 22.223 14.276 14.275 3.706-3.706-14.275-14.276zm2.158-6.42-.009.029L31.577 38l-7.627-2.543-5.304-10.616 4.536-4.537L33.8 25.61zm5.685 29.863q.196.105.41.147a14.6 14.6 0 0 1 5.155 3.325 14.6 14.6 0 0 1 3.305 5.104q.044.266.182.505a14.63 14.63 0 0 1-3.487 15.037 14.67 14.67 0 0 1-13.39 3.946l8.657-8.681a1.5 1.5 0 0 0-.001-2.12l-9.067-9.068a1.5 1.5 0 0 0-2.12-.001l-8.677 8.652a14.65 14.65 0 0 1 3.952-13.375 14.63 14.63 0 0 1 15.081-3.47m10.862 5.694a17.7 17.7 0 0 0-3.174-4.341l-.005-.005a17.7 17.7 0 0 0-4.347-3.177l18.087-18.086a17.7 17.7 0 0 0 3.173 4.34l.005.005a17.7 17.7 0 0 0 4.347 3.177L54.248 67.415a1.5 1.5 0 0 0-.642.641zm4.226.016 21.438 21.439c2.774 2.765 7.257 2.763 10.018.003 2.766-2.774 2.764-7.258.002-10.02l-21.44-21.44zm17.196-19.932a1.5 1.5 0 0 0-.409-.147 14.6 14.6 0 0 1-5.156-3.325 14.6 14.6 0 0 1-3.304-5.104 1.5 1.5 0 0 0-.183-.505 14.63 14.63 0 0 1 3.487-15.036 14.65 14.65 0 0 1 13.392-3.949l-8.66 8.684a1.5 1.5 0 0 0 .002 2.12l9.068 9.067a1.5 1.5 0 0 0 2.12.002l8.677-8.653a14.62 14.62 0 0 1-3.952 13.375 14.63 14.63 0 0 1-15.082 3.471"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramTools04;

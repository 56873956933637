import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import { LinkData } from "@@/models/linkdata";
import cn from "classnames";
import {
  useEffect, useLayoutEffect, useRef, useState,
} from "react";
import PrimaryButton from "../Button/PrimaryButton";
import styles from "./index.module.scss";

type SpecificationListProps = {
  items?: SpecificationItem[];
  applyLink?: LinkData;
  showMoreText: string;
  showLessText: string;
};

type SpecificationItem = {
  label: string;
  value: string;
};

const SpecificationList = ({
  items, applyLink, showMoreText, showLessText,
}: SpecificationListProps) => {
  const [sticky, setSticky] = useState<boolean>(false);
  const ref = useRef(null);
  const listRef = useRef(null);
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);
  const [showAll, setShowAll] = useState(false);
  const [visibleMaxHeight, setVisibleMaxHeight] = useState("");
  const [maxHeight, setMaxHeight] = useState("");

  useLayoutEffect(() => {
    if (listRef.current) {
      let childrenHeight = 0;
      [...listRef.current.children].slice(0, 4).forEach((child) => { childrenHeight += child.clientHeight; });
      setVisibleMaxHeight(`${childrenHeight}px`);
      if (isSmall) setMaxHeight(`${childrenHeight}px`);
      else setMaxHeight("");
    }
  }, [isSmall]);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setSticky(entry.isIntersecting);
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.9,
  };
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref, options]);

  const showFade = !showAll && isSmall && items?.length > 4;

  return (
    <Box
      mt={[14, 18, 18]}
      id="specification_list"
      width="full"
      backgroundColor="ComplementaryBeigeTint04"
    >
      <Container pageGutterX={isSmall && 0}>
        <ul
          className={styles.list}
          ref={listRef}
          style={{ maxHeight }}>
          { items &&
                items.map((item, index) => (
                  <li
                    key={index}
                    className={cn(styles.item, { [styles.item__fade]: index >= 3 && showFade })}
                  >
                    <Typography
                      mb={1}
                      variant="button">
                      {item.label}
                    </Typography>
                    <Typography
                      mb={8}
                      variant="body">
                      {item.value}
                    </Typography>
                  </li>
                ))}
        </ul>

        {(items.length > 4 && isSmall) && (
          <button
            type="button"
            onClick={
              () => {
                setShowAll(!showAll);
                setMaxHeight(!showAll ? "2000px" : visibleMaxHeight);
              }
            }
            className={styles.show_more_button}
          >
            <Typography
              variant="link"
              color="ComplementaryBlue"
              className={cn(styles.show_more_button__text, { [styles.show_more_button__text__active]: !showAll })}
            >
              {`${showMoreText} +`}
            </Typography>
            <Typography
              variant="link"
              color="ComplementaryBlue"
              className={cn(styles.show_more_button__text, { [styles.show_more_button__text__active]: showAll })}
            >
              {`${showLessText} -`}
            </Typography>
          </button>
        )}

        {applyLink && !isSmall && (
          <Box width="full">
            <PrimaryButton
              title={applyLink.title}
              href={applyLink.href}
              target={applyLink.target}
              label={applyLink.text}
            />
          </Box>
        )}

        {applyLink && isSmall && (
          <Box
            ref={ref}
            id="apply_link"
            width="full"
            px={sticky ? 0 : 5 }
            className={
              sticky ? styles.button_wrapper_sticky : styles.button_wrapper
            }
          >
            <PrimaryButton
              title={applyLink.title}
              href={applyLink.href}
              target={applyLink.target}
              label={applyLink.text}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default SpecificationList;

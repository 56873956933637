import Icon, { IconTypes } from "@@/elements/Icons/Icon";
import Typhography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import cn from "classnames";
import styles from "./styles.module.scss";

export type ItemProps = {
  item: {
    icon?: IconTypes;
    iconAltText: string;
    link: LinkData;
  };
  active: boolean;
  overflow?: boolean;
  hasIcon?: boolean;
};

const Item = ({ item, active, overflow, hasIcon }: ItemProps) => {
  const { icon, iconAltText, link } = item;
  const trimmedIcon = icon?.trim();

  const className = cn(
    styles.item,
    { [styles.item__active]: active },
    { [styles.item__overflow]: overflow },
    // True if any item has icon
    { [styles.item__has_icon]: hasIcon },
    // Only true if current item has no icon
    { [styles.item__current_no_icon]: !trimmedIcon || !trimmedIcon.length }
  );

  const hash = `${link.href}#s`;

  return (
    <a
      className={className}
      tabIndex={0}
      role="option"
      aria-selected={active}
      href={hash}
    >
      <Icon
        className={styles.icon}
        name={icon}
        title={iconAltText}
        id={`icon-${icon}`}
        height="32px"
      />
      <Typhography
        variant="button"
        asElement="span"
        textAlign="center"
        className={styles.item__text}
      >
        {link.text}
      </Typhography>
    </a>
  );
};
export default Item;

import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointAndAbove,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import { LinkData } from "@@/models/linkdata";
import TextButtonIcon from "../Button/TextButtonIcon";
import CountdownClock, { ClockProps } from "./countdownClock";

import SvgArrowRight from "@@/elements/Icons/icons/ArrowRight";
import TertiaryButton from "../Button/TertiaryButton";
import styles from "./styles.module.scss";

type CountdownProps = {
  header?: string;
  clockProps?: ClockProps;
  link?: LinkData;
  backgroundColor?: "beige" | "white";
};
const Countdown = ({
  header,
  clockProps,
  link,
  backgroundColor = "white",
}: CountdownProps) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);
  const isLarge = useIsBreakpointAndAbove(ScreenSize.LARGE);

  const getbgColor = () => {
    switch (backgroundColor) {
    case "white":
      return "White";
    case "beige":
      return "ComplementaryBeigeTint03";

    default:
      return "White";
    }
  };
  return (
    <Container
      size="full"
      pageGutterX={0}
      backgroundColor={getbgColor()}
      className={styles.countdown_container}
    >
      <Container
        pageGutterY={0}
        className={styles.content_wrapper}>
        <Typhography variant={isLarge ? "heading3" : "heading5"}>
          {header}
        </Typhography>
        <Box
          px={5}
          py={18}>
          <CountdownClock
            date={clockProps.date}
            days={clockProps.days}
            hours={clockProps.hours}
            minutes={clockProps.minutes}
            seconds={clockProps.seconds}
            color={backgroundColor}
          />
        </Box>
        {link && link.href && !isSmall && (
          <Box
            justifyContent="flex-end"
            width="full">
            <TextButtonIcon
              direction="right"
              link={link}
              label={link.text}>
              <SvgArrowRight />
            </TextButtonIcon>
          </Box>
        )}
        {link && link.href && isSmall && (

          <Box
            justifyContent="center"
            width="full">
            <TertiaryButton
              label={link.text}
              href={link.href} />
          </Box>
        )}
      </Container>
    </Container>
  );
};

export default Countdown;

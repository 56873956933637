import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramBuildings02 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M30.858 16.436a1.5 1.5 0 0 1 1.5-1.5h47.264a1.5 1.5 0 0 1 1.5 1.5v79.138a1.5 1.5 0 0 1-1.5 1.5H32.358a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v76.138h44.264V17.936z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.653 95.564a1.5 1.5 0 0 1 1.5-1.5h57.684a1.5 1.5 0 1 1 0 3H27.153a1.5 1.5 0 0 1-1.5-1.5M39.142 25.355a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1h-7.19a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM51.4 25.355a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1H52.4a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM63.648 25.355a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1h-7.19a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM39.142 38.325a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1h-7.19a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM51.4 38.325a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1H52.4a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM63.648 38.325a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1h-7.19a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM39.142 51.294a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1h-7.19a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM51.4 51.294a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1H52.4a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM63.648 51.294a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1h-7.19a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM39.142 64.253a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1h-7.19a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM51.4 64.253a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1H52.4a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556zM63.648 64.253a1 1 0 0 1 1-1h7.19a1 1 0 0 1 1 1v7.556a1 1 0 0 1-1 1h-7.19a1 1 0 0 1-1-1zm2 1v5.556h5.19v-5.556z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M47.469 79.533a1 1 0 0 1 1-1h15.063a1 1 0 0 1 1 1v15.754a1 1 0 0 1-1 1H48.469a1 1 0 0 1-1-1zm2 1v13.754h13.063V80.533z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M55.995 78.533a1 1 0 0 1 1 1v15.24a1 1 0 0 1-2 0v-15.24a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramBuildings02;

import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import styles from "./itemStyles.module.scss";

type CategoryItemOwnProps = {
  checked: boolean;
  handleChange: Function;
};

export type CategoryItemProps = {
  label: string;
  value: number;
  count?: number;
  disabled?: boolean;
};

type CategoryItemAllProps = CategoryItemOwnProps & CategoryItemProps;

const CategoryItem = ({
  label, value, count, checked, disabled, handleChange,
} : CategoryItemAllProps) => {
  const id = `dropdown-item-${value}`;
  const countString = `(${count})`;

  const handleKeyDown = (e : any) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleChange(value);
    }
  };

  return (
    <div
      className={styles.category_item}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      role="option"
      aria-selected={checked}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={() => {
          handleChange(value);
        }}
        tabIndex={-1}
      />
      <label
        className={styles.container}
        htmlFor={id}>
        <Box className={styles.label}>
          <Typhography
            variant="link"
            asElement="span">
            {label}
          </Typhography>
          <Typhography
            variant="label"
            asElement="span"
            className={styles.count}
          >
            {countString}
          </Typhography>
        </Box>
      </label>
    </div>
  );
};

export default CategoryItem;

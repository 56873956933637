import Box from "@@/elements/Box";
import { ArrowDown } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import TagsProps from "./TagsProps";
import styles from "./tags.module.scss";

type Css = {
  autohideTags?: boolean;
};

const Tags = ({ autohideTags = false, tags }: TagsProps & Css) => {
  const ref = useRef(null);
  const [hasmoreTags, setHasmoreTags] = useState<boolean>(false);
  const [showTags, setShowTags] = useState<boolean>(false);
  const [numberofTags, setNumberofTags] = useState<number>(0);

  const handleResize = () => {
    setShowTags(false);
    const nodes: any = ref.current.querySelectorAll(`.${styles.tag}`);
    let hiddenTags: number = 0;
    const tagContainerWidth = ref.current.clientWidth - 55;
    let tagsTotalWidth = 0;
    nodes.forEach((tag) => {
      const tagWidth = tag.clientWidth;
      tagsTotalWidth += tagWidth;
      if (tagsTotalWidth > tagContainerWidth) {
        tag.classList.add(styles["tag--hidden"]);
        hiddenTags += 1;
        setHasmoreTags(true);
      } else {
        tag.classList.remove(styles["tag--hidden"]);
      }
      setNumberofTags(hiddenTags);
    });
  };

  const showMoreTagsClick = (event) => {
    event.preventDefault();
    setShowTags(!showTags);
  };

  useEffect(() => {
    if (autohideTags === true) {
      handleResize();
    }
  }, [autohideTags, ref?.current?.clientWidth]);

  return (
    <div
      ref={ref}
      className={cn(styles.tagcontainer, { [styles.showallTags]: showTags })}
    >
      {hasmoreTags && autohideTags && numberofTags > 0 && (
        <button
          onClick={showMoreTagsClick}
          className={styles.tagcontainer__showmore}
          type="button"
        >
          <span className={styles.tagcontainer__showmore__text}>
            {!showTags ? <>+{numberofTags}</> : <ArrowDown height={10} />}
          </span>
        </button>
      )}
      <div
        className={
          (cn(styles.tagcontainer__tags), hasmoreTags && styles.hidden_tags)
        }
      >
        <Box
          className={cn(styles.tagcontainer__tagscont)}
          flexDirection="row"
          flexWrap={
            showTags === false && autohideTags === true ? "nowrap" : "wrap"
          }
          gap={1}
        >
          {tags.map((tag, index) => (
            <span className={styles.tag} key={index}>
              <Typography textcolor="light" variant="link">
                {tag}
              </Typography>
            </span>
          ))}
        </Box>
      </div>
    </div>
  );
};

export default Tags;

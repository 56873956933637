import { ContentSavedMessage } from "@@/epiMessages";
import React, { useEffect, useState } from "react";
import OptimizlyContext from "../context";

// Test the custom events
//  let message = {
//    contentLink: "test",
//    properties: [
//       {
//         name: "name",
//         value: "value"
//       }
//    ]
//  }
//  let customEvent = new CustomEvent("epiChange", { detail: message });
//  window.dispatchEvent(customEvent);

export type OptimizelyBlockProps<T> = {
  blockId?: string;
  inEditMode?: boolean;
  properties?: T;
  children?: (properties: T) => JSX.Element,
};

const OptimizelyBlock = <T extends Record<string, any>>({
  blockId,
  inEditMode,
  properties,
  children,
}: OptimizelyBlockProps<T>) => {
  if (!inEditMode) {
    return children(properties);
  }

  const [optimizlyProperties, setOptimizlyProperties] = useState<T>(properties);

  useEffect(() => {
    if (inEditMode) {
      const contentSaved = (event: CustomEvent<ContentSavedMessage>) => {
        // Updated state etc
        const { contentLink, properties } = event.detail;
        // Removed the version from the blockId / contentLink
        if (blockId.split("_")[0] === contentLink.split("_")[0]) {
          const mutations = {};
          properties.forEach((prop) => { mutations[prop.name] = prop.value; });

          setOptimizlyProperties((state) => ({
            ...state,
            ...mutations,
          }));
        }
      };
      window.addEventListener("epiChange", contentSaved);

      return () => { window.removeEventListener("epiChange", contentSaved); };
    }
  }, [inEditMode]);

  return (
    <OptimizlyContext.Provider value={{ inEditMode }}>
      {inEditMode ? children(optimizlyProperties) : children(properties)}
    </OptimizlyContext.Provider>
  );
};

export default OptimizelyBlock;

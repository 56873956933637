import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import CareerContactPersonCard, { CareerContactPersonCardProps } from "./card";
import styles from "./index.module.scss";

type CareerContactsEditableProps = {
  header?: string;
  intro?: string;
};

type CareerContactsOwnProps = {
  contacts: CareerContactPersonCardProps[];
};
type CareerContactsProps = CareerContactsEditableProps & CareerContactsOwnProps;

const CareerContacts = ({ header, intro, contacts }: CareerContactsProps) => (
  <Container className={styles.container}>
    <Box className={styles.header_wrapper} textAlign="center">
      {header && (
        <EpiProperty<CareerContactsEditableProps> name="header">
          <Typography variant="heading2">{header}</Typography>
        </EpiProperty>
      )}
      {intro && (
        <EpiProperty<CareerContactsEditableProps> name="intro">
          <Typography mt={header && 4} variant="intro">
            {intro}
          </Typography>
        </EpiProperty>
      )}
    </Box>
    <Box
      mt={10}
      className={contacts.length > 2 ? styles.cards_grid : styles.cards_flex}
    >
      {contacts &&
        contacts.map((contact) => (
          <CareerContactPersonCard
            name={contact.name}
            email={contact.email}
            title={contact.title}
            phoneNumber={contact.phoneNumber}
          />
        ))}
    </Box>
  </Container>
);

export type ProvidedCareerContactsProps =
  OptimizelyBlockProps<CareerContactsEditableProps> & CareerContactsProps;

const ProvidedCareerContacts = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedCareerContactsProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <CareerContacts {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);
export default ProvidedCareerContacts;

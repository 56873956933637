import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCalender = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect
      width={13}
      height={11}
      x={1.5}
      y={3.5}
      stroke="currentColor"
      rx={1.5}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M4.5 1.5v3M11.5 1.5v3"
    />
    <path stroke="currentColor" d="M2 7.5h12" />
  </svg>
);
export default SvgCalender;

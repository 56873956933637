import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import SearchCard, { Props as CardProps } from "./card";
import SearchCardLoader from "./loader";
import styles from "./styles.module.scss";

type Props = {
  items: CardProps[];
  label: string;
  searchQuery: string;
  noSearchMadeMessage: string;
  totalMatches: number;
  loading: boolean;
  loaders?: number;
};

const SearchCardsContainer = ({
  items,
  label,
  searchQuery,
  totalMatches,
  noSearchMadeMessage,
  loading,
  loaders,
} : Props) => {
  const matchesText = `${label} '${searchQuery}':`;

  if (!searchQuery.length && !items?.length) {
    return (
      <Box>
        <Typhography variant="menu" color="ComplementaryCharcoalTint02">
          { noSearchMadeMessage }
        </Typhography>
        <Box className={styles.divider} mt={6} />
      </Box>
    );
  }

  if (loading) {
    return (
      <SearchCardLoader amount={loaders || 12} />
    );
  }

  return (
    <Box>
      {(!!items?.length) && (
      <Box flexDirection="row">
        <Typhography variant="link" asElement="div" color="ComplementaryCharcoalTint02">
          {matchesText}
          <Typhography variant="menu" asElement="div" ml={1} color="Black" className={styles.total_matches}>
            {totalMatches}
          </Typhography>
        </Typhography>
      </Box>
      )}
      {!!items?.length && (
      <Box flexDirection="column" mt={2} id="search_card_container">
        {items.map((item, i) => (
          <SearchCard {...item} key={i} />
        ))}
      </Box>
      )}
    </Box>
  );
};

export default SearchCardsContainer;

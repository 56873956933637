import ResponsiveValue from "@@/base/responsive-value";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import { useEffect, useState } from "react";
import Lightbox from "../Lightbox";
import styles from "../styles.module.scss";

export type MediaVariant = "fullwidth-landscape" | "landscape" | "square" | "portrait";

type MediaProperties = {
  image: PictureData;
  variant: MediaVariant;
  caption?: string;
  zoom?: boolean;
  closeLabel?: string;
  closeTitle?: string;
  zoomTitle?: string;
  zoomOutTitle?: string;
  zoomInTitle?: string;
};

type AspectRatio = ResponsiveValue< "21 / 9" | "16 / 9" | "2 / 1" | "4 / 1" | "1 / 1" | "2 / 3" | "4 / 5"
>;

type MediaProps = MediaProperties;

const Media = ({ image, caption, variant, zoom, closeLabel, closeTitle, zoomTitle, zoomOutTitle, zoomInTitle }: MediaProps) => {
  const [aspectRatio, setAspectRatio] = useState<AspectRatio>(null);
  const [gutter, setGutter] = useState(null);
  const variantStyle: string = cn(styles.media, {
    [styles.media__narrow]: variant === "portrait" || variant === "square",
    [styles.media__fullwidth]: variant === "fullwidth-landscape",
    [styles.media__landscape]: variant === "landscape",
  });

  useEffect(() => {
    switch (variant) {
    case "fullwidth-landscape":
      setAspectRatio(["16 / 9", "21 / 9", "4 / 1"]);
      setGutter(0);
      break;
    case "landscape":
      setAspectRatio(["16 / 9", "21 / 9", "21 / 9"]);
      setGutter([0, 10, 10]);
      break;
    case "square":
      setAspectRatio("1 / 1");
      setGutter(null);
      break;
    case "portrait":
      setAspectRatio("4 / 5");
      setGutter(null);
      break;
    default:
      setAspectRatio("4 / 5");
      setGutter(null);
      break;
    }
  }, [variant]);

  return (
    <Container
      className={`top-section-image ${variant === "portrait" ? "top-section-portrait" : ""}`}
      size={variant === "fullwidth-landscape" ? "full" : null}
      pageGutterX={gutter}
      pageGutterY={variant === "fullwidth-landscape" ? 0 : 10}
    >
      <EpiProperty<MediaProperties> name="image">
        <Box className={`image ${variantStyle}`}>
          {zoom && 
          <Lightbox
            closeTitle={closeTitle}
            zoomTitle={zoomTitle}
            zoomOutTitle={zoomOutTitle}
            zoomInTitle={zoomInTitle}
            closeLabel={closeLabel}
            variant={variant}
          >
            <Picture
              imageData={image}
              aspectRatio={aspectRatio}
              sizes={[
                [700, 1080, 1920],
                [700, 1080, 1920],
                [700, 1080, 1920],
              ]}
            />
          </Lightbox>
          }
          {!zoom && <Picture
            imageData={image}
            aspectRatio={aspectRatio}
            sizes={[
              [700, 1080, 1920],
              [700, 1080, 1920],
              [700, 1080, 1920],
            ]}
          />}
          {caption && (
            <Box
              width="full"
              backgroundColor="PrimaryGreyTint03">
              <Typhography
                variant="information"
                color="Black"
                pt={3}
                pb={2}
                px={5}
              >
                {caption}
              </Typhography>
            </Box>
          )}
        </Box>
      </EpiProperty>
    </Container>
  );
};

export type ProvidedMediaCardProps = OptimizelyBlockProps<MediaProperties> &
MediaProperties;

const ProvidedMedia = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedMediaCardProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <Media
      {...rest}
      {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedMedia;

import Box from "@@/elements/Box";
import Icon from "@@/elements/Icons/Icon";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import cn from "classnames";
import { CardColors } from "../CardProps";
import styles from "../cardStyles.module.scss";

export type ProcessCardProperties = {
  header: string;
  body?: string;
  icon: "globe" | "manufacturing" | "recycling" | "raw-materials";
};

export type ProcessCardOwnProps = {
  color?: CardColors;
};

export type ProcessCardProps = ProcessCardOwnProps & ProcessCardProperties;

export const ProcessCard = ({
  header,
  icon,
  body,
  color = "green",
}: ProcessCardProps) => {
  const classNames = cn(styles.outer_circle, {
    [styles[`outer_circle_${color}`]]: color,
  });

  // className={inEditMode && styles.card_edit}
  return (
    <Box>
      <div className={styles.process_card}>
        <div className={classNames}>
          <EpiProperty<ProcessCardProperties> name="icon">
            <div className={styles.inner_circle}>
              <Box className={styles.process_card__svg}>
                <Icon name={icon} />
              </Box>
            </div>
          </EpiProperty>
        </div>
        <div className={styles.container}>
          <EpiProperty<ProcessCardProperties> name="header">
            <Typography
              asElement="h3"
              variant="button">
              {header}
            </Typography>
          </EpiProperty>
          {body && (
            <EpiProperty<ProcessCardProperties> name="body">
              <Typography variant="body">{body}</Typography>
            </EpiProperty>
          )}
        </div>
      </div>
    </Box>
  );
};

export type ProvidedProcessCardProps =
  OptimizelyBlockProps<ProcessCardProperties> & ProcessCardProps;

const ProvidedProcessCard = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedProcessCardProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <ProcessCard
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedProcessCard;

import RichText from "@@/components/RichText";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import React from "react";

type TopSectionOwnProps = {
  intro?: string;
  header?: string;
  body?: string;
};

type TopsectionEditableProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  topSectionLeft: TopSectionOwnProps;
  // For now we will add epi edit for whole component since epi couldn't handle the on
  // page edit for components thats not placed inside a content block.
};

type TopsectionProps = TopSectionOwnProps;

export const Topsection = ({ intro, header, body }: TopsectionProps) => (
  <EpiProperty<TopsectionEditableProps> name="topSectionLeft">
    <Container
      pageGutterY={0}
      customStyle={{ paddingTop: "40px", paddingBottom: "40px" }}
    >
      <Box>
        <div>
          {/* <EpiProperty<TopsectionProperties> name="header"> */}
          {header && header.length && (
            <Typography variant="heading3" asElement="h2" mb={6}>
              {header}
            </Typography>
          )}
          {/* </EpiProperty> */}
        </div>
        {/* <EpiProperty<TopsectionProperties> name="intro"> */}
        {intro && intro.length && (
          <Typography variant="intro" mb={10}>
            {intro}
          </Typography>
        )}
        {/* </EpiProperty> */}
        {/* <EpiProperty<TopsectionProperties> name="body"> */}
        <RichText body={body} />
        {/* </EpiProperty> */}
      </Box>
    </Container>
  </EpiProperty>
);

export type ProvidedTopsectionProps =
  OptimizelyBlockProps<TopsectionEditableProps> & TopsectionProps;

const ProvidedTopSection = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedTopsectionProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <Topsection {...rest} {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedTopSection;

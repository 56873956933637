/* eslint-disable quote-props */

// -----------------------------------------------------
// This file was generated, do not modify this directly.
// change the design token-file and run 'npm run generate:tokens'
// -------------------------------------------------------------------

export const allTokens = [
  "Small",
  "Medium",
  "Large",
  "ExtraLarge",
] as const;

type tokenTuple = typeof allTokens;
export type Token = tokenTuple[number];

export const TokenAlias: Record<Token, string> = {
  "Small": "small",
  "Medium": "medium",
  "Large": "large",
  "ExtraLarge": "extra-large",
};

export const TokenValue: Record<Token, number> = {
  "Small": 0,
  "Medium": 600,
  "Large": 900,
  "ExtraLarge": 1200,
};

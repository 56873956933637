import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import SvgArrowRight from "@@/elements/Icons/icons/ArrowRight";
import Picture from "@@/elements/Picture";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import Typography from "../../elements/Typography";
import styles from "./index.module.scss";

export type BackgroundColors = "grey" | "green" | "blue" | "orange" | "beige";

export type QuoteProperties = {
  quote: string;
  image: PictureData;
  signature: string;
};

export type QuoteOwnProps = {
  backgroundColor: BackgroundColors;
  overlapBackgroundColor?: BackgroundColors;
  circularMask?: boolean;
  link?: LinkData;
};

export type QuoteProps = QuoteOwnProps & QuoteProperties;

const Quote = ({
  backgroundColor,
  quote,
  image,
  signature,
  link,
  overlapBackgroundColor,
  circularMask,
}: QuoteProps) => {
  const translateColor = (c: string) => {
    switch (c) {
    case "grey":
      return "PrimaryGreyTint03";
    case "green":
      return "PrimaryGreenTint03";
    case "blue":
      return "PrimaryBlueTint03";
    case "orange":
      return "ComplementaryOrangeTint04";
    case "beige":
      return "ComplementaryBeigeTint03";
    default:
      return "White";
    }
  };

  return (
    <Container
      size="full"
      pageGutterX={0}>
      <Box
        flexDirection="column"
        pt={21}
        backgroundColor={translateColor(overlapBackgroundColor)}
      >
        <Box
          container
          justifyContent="center"
          className={styles.image_wrapper}>
          <Box
            container
            justifyContent="center"
            className={cn(styles.image_container, {
              [styles["image_container--round"]]: circularMask,
              [styles["image_container--logo"]]: !circularMask,
            })}
          >
            <EpiProperty<QuoteProperties> name="image">
              <Picture
                circular={circularMask}
                imageData={image}
                className={styles.image}
                contain={!circularMask}
                imgClassName={cn({
                  [styles["image--round"]]: circularMask,
                  [styles["image--logo"]]: !circularMask,
                })}
                aspectRatio={circularMask ? "1/1" : "7/2"}
                sizes={[100, 200, 400, 600, 900]}
              />
            </EpiProperty>
          </Box>
        </Box>
        <Box
          container
          width="full"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          pt={[23, 23, 31]}
          pb={[10, 10, 18]}
          backgroundColor={translateColor(backgroundColor)}
        >
          <div className={styles.text_container}>
            <EpiProperty<QuoteProperties> name="quote">
              <Typography
                variant="heading4"
                asElement="p"
                mb={5}>
                {quote}
              </Typography>
            </EpiProperty>
            <EpiProperty<QuoteProperties> name="signature">
              <Typography variant="body">{signature}</Typography>
            </EpiProperty>
          </div>
          {link && link.text && (
            <div className={styles.button_container}>
              <TextButtonIcon
                direction="right"
                link={link}
                label={link.text}>
                <SvgArrowRight />
              </TextButtonIcon>
            </div>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export type ProvidedQuoteCardProps = OptimizelyBlockProps<QuoteProperties> &
QuoteProps;

const ProvidedQuoteCard = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedQuoteCardProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <Quote
      {...rest}
      {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedQuoteCard;

import React, { useContext } from "react";
import OptimizlyContext from "../context";
import { OptimizlyProperties } from "../types";

export type EpiPropertyProps<T extends OptimizlyProperties > = {
  name: keyof T,
  children: React.ReactNode,
};

const EpiProperty = <T extends OptimizlyProperties>({
  name,
  children,
}: EpiPropertyProps<T>) => {
  const { inEditMode } = useContext(OptimizlyContext);

  if (!inEditMode) {
    return (
      <>
        {children}
      </>
    );
  }

  const newProps : Partial<unknown> = { "data-epi-edit": inEditMode ? name : null };

  // add epi attribute to child.
  return (
    <>
      { React.Children.map(children, (Child) => {
        if (React.isValidElement(Child)) {
          return (
            <>
              {React.cloneElement(Child, newProps)}
            </>
          );
        }
        return <></>;
      })}
    </>
  );
};

export default EpiProperty;

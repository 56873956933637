import Accordion from "@@/components/Accordion";
import Dropdown from "@@/components/Dropdown";
import Categories from "@@/components/ListingPage/Shared/Categories";
import SkeletonBox from "@@/components/Loader/Skeleton";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import ResetButton from "../../Shared/ResetButton";
import withoutCountZero from "../../Shared/filterHelpers";
import styles from "./styles.module.scss";

const ListingNewsFilter = ({
  filterLabel,
  clearFilterLabel,
  categories,
  selectedCategories,
  setSelectedCategories,
  years,
  selectedYears,
  setSelectedYears,
  yearLabel,
  resetYearLabel,
  loading,
  reset,
}) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);

  const categoriesWithoutZero = withoutCountZero(categories);
  const yearsWithoutZero = withoutCountZero(years);

  const renderFilter = () => (
    <Box>
      {(!isSmall && !!categoriesWithoutZero?.length) && (
        <Typhography
          variant="label"
          mb={3}
          uppercase>
          { `${filterLabel}:` }
        </Typhography>
      )}
      <Box
        className={styles.filter_wrapper}
      >
        <Categories
          options={categoriesWithoutZero ?? []}
          selectedValues={selectedCategories}
          setSelectedValues={setSelectedCategories}
        />
        <Dropdown
          options={yearsWithoutZero}
          selectedValues={selectedYears}
          setSelectedValues={setSelectedYears}
          placeholder={yearLabel}
          clearButtonText={resetYearLabel}
          dense
        />
      </Box>
      <ResetButton
        onClick={reset}
        label={clearFilterLabel}
        disabled={!selectedCategories.length && !selectedYears.length}
      />
    </Box>
  );

  if (loading) {
    return (
      <Container
        pageGutterY={0}
        mb={[6, 10, 12]}>
        <Box width={["full", "full", 175]}>
          {!isSmall && <SkeletonBox
            height={3}
            width={15}
            mb={4} />}
          <SkeletonBox
            height={10}
            width="full" />
          {!isSmall && <SkeletonBox
            height={4}
            width={30}
            mt={6} />}
        </Box>
      </Container>
    );
  }

  if (!categoriesWithoutZero?.length && !yearsWithoutZero?.length) return <></>;

  return (
    <Container
      pageGutterY={0}
      mb={[6, 10, 12]}>
      {isSmall && (
        <Accordion
          header={filterLabel}
          backgroundColor="green">
          {renderFilter()}
        </Accordion>
      )}
      {!isSmall && renderFilter()}
    </Container>
  );
};

export default ListingNewsFilter;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMenuIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 33 25"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M31.195.5H10.254c-.726 0-1.305.58-1.305 1.286 0 .705.579 1.285 1.305 1.285h20.94c.727 0 1.306-.58 1.306-1.285S31.92.5 31.195.5Zm0 21.429H14.587c-.726 0-1.306.58-1.306 1.285s.58 1.286 1.306 1.286h16.608c.726 0 1.305-.58 1.305-1.286 0-.705-.58-1.285-1.305-1.285Zm0-10.715H2.31c-.726 0-1.305.581-1.305 1.286s.579 1.286 1.305 1.286h28.884c.726 0 1.305-.581 1.305-1.286s-.58-1.286-1.305-1.286Z"
    />
  </svg>
);
export default SvgMenuIcon;

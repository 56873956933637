import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFindOutlined = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 20"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M3.286 3.638A6.64 6.64 0 0 1 8 1.667c1.768 0 3.464.709 4.714 1.971a6.76 6.76 0 0 1 1.952 4.76c0 6.536-6.458 9.868-6.525 9.902a.32.32 0 0 1-.283 0c-.066-.034-6.525-3.37-6.525-9.903 0-1.785.702-3.497 1.953-4.759Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.564 6.185a2.583 2.583 0 1 1 2.87 4.296 2.583 2.583 0 0 1-2.87-4.296Z"
    />
  </svg>
);
export default SvgFindOutlined;

import SkeletonBox from "@@/components/Loader/Skeleton";
import Box from "@@/elements/Box";
import { ReactNode } from "react";
import styles from "./styles.module.scss";

export const multiRender = (amount: number, element: ReactNode) => [...Array(amount)].map((_, i) => <Box key={i}>{element}</Box>);

const SearchCardLoader = ({ amount }) => (
  <Box flexDirection="column" gap={10} pt={12} width="full">
    {multiRender(amount,
      <Box flexDirection="row" gap={6} height={30}>
        <Box className={styles.image_wrapper}>
          <SkeletonBox height={30} />
        </Box>
        <Box flexDirection="column" gap={3} width="full">
          <SkeletonBox height={8} width={40} />
          <SkeletonBox height={8} width="full" />
          <SkeletonBox height={8} width="full" />
        </Box>
      </Box>)}
  </Box>
);

export default SearchCardLoader;

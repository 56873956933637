import cn from "classnames";
import ResponsiveValue, {
  arrayFromResponiveValue,
} from "../../../base/responsive-value";
import css from "./use-flex.module.scss";

type FlexContainerProps = {
  alignItems?: ResponsiveValue<
  | "baseline"
  | "center"
  | "flex-end"
  | "flex-start"
  | "stretch"
  | "inherit"
  | "initial"
  | "space-between"
  | "unset"
  >;
  alignContent?: ResponsiveValue<
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "stretch"
  >;
  justifyContent?: ResponsiveValue<
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly"
  >;
  flexWrap?: ResponsiveValue<"nowrap" | "wrap" | "wrap-reverse">;
  flexDirection?: ResponsiveValue<
  "row" | "row-reverse" | "column" | "column-reverse"
  >;
  gap?: ResponsiveValue<Number>;
};

type FlexItemProps = {
  flexGrow?: ResponsiveValue<0 | 1 | 2 | 3 | 4>;
  flexShrink?: ResponsiveValue<0 | 1 | 2 | 3 | 4>;
  flexBasis?: ResponsiveValue<0 | "auto">;
  justifySelf?: ResponsiveValue<
  "center" | "flex-end" | "flex-start" | "stretch"
  >;
  alignSelf?: ResponsiveValue<"center" | "flex-end" | "flex-start" | "stretch">;
  order?: ResponsiveValue<0 | 1 | 2 | 3 | 4 | 5 | 6>;
};

type ContainerProps = {
  container?: boolean;
};

export type FlexProps = FlexContainerProps & FlexItemProps & ContainerProps;
type CssKeys = keyof FlexProps;
type CssKeyKebabCase = string;

const containerPropNames = [
  ["alignItems", "align-items"],
  ["alignContent", "align-content"],
  ["justifyContent", "justify-content"],
  ["flexWrap", "flex-wrap"],
  ["flexDirection", "flex-direction"],
] as unknown as [[CssKeys, CssKeyKebabCase]];

const itemPropNames = [
  ["flexGrow", "flex-grow"],
  ["flexShrink", "flex-shrink"],
  ["flexBasis", "flex-basis"],
  ["justifySelf", "justify-self"],
  ["alignSelf", "align-self"],
] as unknown as [[CssKeys, CssKeyKebabCase]];

const stylePropNames = ["order", "gap"] as unknown as [CssKeys];

const propNames = [...containerPropNames, ...itemPropNames];

const flexClasses = (props: FlexProps) => {
  const { container } = props;
  const propClasses = ([propName, propNameKebabCase]: [
    CssKeys,
    CssKeyKebabCase
  ]) => {
    const prop = props[propName];
    if (typeof prop === "undefined") {
      return null;
    }

    const values = arrayFromResponiveValue(prop);

    return cn({
      [(css as any)[`ui-flex--sm-${propNameKebabCase}-${values[0]}`]]:
        values[0] !== undefined,
      [(css as any)[`ui-flex--md-${propNameKebabCase}-${values[1]}`]]:
        values[1] !== undefined,
      [(css as any)[`ui-flex--lg-${propNameKebabCase}-${values[2]}`]]:
        values[2] !== undefined,
    });
  };

  const styleClasses = (propName: CssKeys) => {
    const prop = props[propName];
    if (typeof prop === "undefined") {
      return null;
    }

    const values = arrayFromResponiveValue(prop);
    return cn({
      [(css as any)[`ui-flex--sm-${propName}`]]: values[0] !== undefined,
      [(css as any)[`ui-flex--md-${propName}`]]: values[1] !== undefined,
      [(css as any)[`ui-flex--lg-${propName}`]]: values[2] !== undefined,
    });
  };

  // eslint-disable-next-line max-len
  const isContainer =
    container ||
    containerPropNames.some(
      (propNamePair) => props[propNamePair[0]] !== undefined,
    );

  return cn(propNames.map(propClasses), stylePropNames.map(styleClasses), {
    [css["ui-flex--container"]]: isContainer,
  });
};

const bp = ["sm", "md", "lg"];
const flexStyles = (props: FlexProps) =>
  Object.fromEntries(
    stylePropNames.reduce((acc, prop) => {
      const values = arrayFromResponiveValue(props[prop]);
      for (let i = 0; i < bp.length; i += 1) {
        if (values[i] !== undefined) {
          acc.push([`--ui-${bp[i]}-${prop}`, values[i]]);
        }
      }
      return acc;
    }, [] as Array<[PropertyKey, any]>),
  );

const useFlex = (props: FlexProps) => ({
  className: flexClasses(props),
  style: flexStyles(props),
});

export default useFlex;

import { ChevronDown } from "@@/elements/Icons/icons";
import cn from "classnames";
import styles from "./Language/styles.module.scss";

type Props = {
  open: boolean;
  variant: "header" | "footer";
};

const SelectIcon = ({ open, variant } : Props) => {
  const classNames = cn(styles.icon, {
    [styles.icon__header]: variant === "header",
    [styles.icon__header__open]: variant === "header" && open,
    [styles.icon__footer]: variant === "footer",
    [styles.icon__footer__open]: variant === "footer" && open,
  });

  return <ChevronDown width={8} height={4} className={classNames} />;
};

export default SelectIcon;

import SkeletonBox from "@@/components/Loader/Skeleton";
import Box from "@@/elements/Box";

const FilterLoader = () => (
  <Box width={["full", "full", 175]}>
    <SkeletonBox height={[0, 3, 3]} width={15} mb={4} />
    <SkeletonBox height={10} width="full" />
    <SkeletonBox height={[0, 4, 4]} width={30} mt={6} />
  </Box>
);

export default FilterLoader;

import { ArrowRight, ChevronRight } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import cn from "classnames";
import { AccordionProps } from "../headerProps";

import styles from "./index.module.scss";

const Accordion = ({
  menuChoice, company, onClick, open,
}: AccordionProps) => {
  const listingClassNames = cn(styles.listing_item, {
    [styles[`listing_item_${company}`]]: company,
  });

  const accordionTitleClassNames = cn(
    styles.menu_items,
    styles.chevron_transform,

    {
      [styles[`menu_items_${company}`]]: company,
      [styles.chevron_transform_open]: open,
    },
  );

  const active = cn(styles.menu_items_active, {
    [styles[`accordion_title_${company}`]]: company,
  });

  const accordionSubTitleClassNames = cn(
    styles.menu_items,
    styles.chevron_transform,
    styles.menu_items_submenu1,

    {
      [styles[`menu_items_${company}`]]: company,
      [styles.chevron_transform_open]: open,
    },
  );

  const accordionContentClassNames = cn(
    styles.accordion_content,

    {
      [styles[`accordion_content_${company}`]]: company,
    },
  );

  return (
    <div className={styles.accordion}>
      <button
        type="button"
        className={`${open && active} ${accordionTitleClassNames}`}
        onClick={() => onClick()}
      >
        <Typography variant="submenu1">{menuChoice.name}</Typography>
        {menuChoice.children.length !== 0 && <ChevronRight />}
      </button>
      <div
        className={
          open && menuChoice.children.length !== 0
            ? accordionContentClassNames
            : styles.hidden
        }
      >
        {menuChoice.children.map((child, index) => {
          if (index === 0) {
            return (
              <a href={child.link} className={listingClassNames}>
                <Typography variant="submenu2">{child.name}</Typography>
                <ArrowRight />
              </a>
            );
          }

          return (
            <a
              className={`${
                child.active && styles.current_page
              } ${accordionSubTitleClassNames}`}
              href={child.link}
            >
              <Typography variant="submenu2">{child.name}</Typography>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Accordion;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramFinance05 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.667 63.077a1 1 0 0 1 1-1h8.336a1 1 0 0 1 1 1v24.061a1 1 0 0 1-1 1h-8.336a1 1 0 0 1-1-1zm2 1v22.061h6.336v-22.06zM39.873 52.538a1 1 0 0 1 1-1h8.336a1 1 0 0 1 1 1v34.59a1 1 0 0 1-1 1h-8.336a1 1 0 0 1-1-1zm2 1v32.59h6.336v-32.59zM53.079 51.135a1 1 0 0 1 1-1h8.336a1 1 0 0 1 1 1V87.13a1 1 0 0 1-1 1H54.08a1 1 0 0 1-1-1zm2 1V86.13h6.336V52.135zM66.285 46.602a1 1 0 0 1 1-1h8.336a1 1 0 0 1 1 1v40.537a1 1 0 0 1-1 1h-8.336a1 1 0 0 1-1-1zm2 1v38.537h6.336V47.601zM79.491 38.285a1 1 0 0 1 1-1h8.337a1 1 0 0 1 1 1v48.853a1 1 0 0 1-1 1H80.49a1 1 0 0 1-1-1zm2 1v46.853h6.337V39.285zM80.202 11.545a1.5 1.5 0 0 1 1.624-1.364l12.524 1.086a1.5 1.5 0 0 1 1.365 1.627l-1.106 12.504a1.5 1.5 0 0 1-2.989-.264l.974-11.008-11.027-.957a1.5 1.5 0 0 1-1.365-1.624"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M93.578 13.512a1.5 1.5 0 0 1-.177 2.114L63.038 41.297a1.5 1.5 0 0 1-2.131-.198l-8.478-10.411-29.187 24.685a1.5 1.5 0 1 1-1.937-2.291L51.658 27.41a1.5 1.5 0 0 1 2.132.198l8.478 10.41 29.196-24.684a1.5 1.5 0 0 1 2.114.177"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramFinance05;

import SnwButton from "@@/components/Button/SnwButton";
import React from "react";
import { SnwButtonContainerProps } from "./buttonProps";

const SnwButtonContainer = ({ buttons }: SnwButtonContainerProps) => {

  return (
    <>
      {
        buttons && React.Children.toArray(buttons.map((button, index) => {
          const { link, ...prop } = button;
          return (
            <SnwButton
              key={index}
              link={link}
              {...prop}  />
          );
        },
        ))
      }
    </>
  ); 
};
export default SnwButtonContainer;
      
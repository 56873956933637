import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramBuildings03 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M32.87 20.148a1.5 1.5 0 0 1 1.5-1.5h42.766a1.5 1.5 0 0 1 1.5 1.5v71.605a1.5 1.5 0 0 1-1.5 1.5H34.37a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v68.605h39.766V21.648z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.13 91.753a1.5 1.5 0 0 1 1.5-1.5h52.148a1.5 1.5 0 0 1 0 3H29.63a1.5 1.5 0 0 1-1.5-1.5M40.383 28.247a1 1 0 0 1 1-1H47.9a1 1 0 0 1 1 1v6.814a1 1 0 0 1-1 1h-6.518a1 1 0 0 1-1-1zm2 1v4.814H46.9v-4.814zM51.445 28.247a1 1 0 0 1 1-1h6.518a1 1 0 0 1 1 1v6.814a1 1 0 0 1-1 1h-6.519a1 1 0 0 1-1-1zm2 1v4.814h4.518v-4.814zM62.605 28.247a1 1 0 0 1 1-1h6.519a1 1 0 0 1 1 1v6.814a1 1 0 0 1-1 1h-6.519a1 1 0 0 1-1-1zm2 1v4.814h4.519v-4.814zM40.383 40a1 1 0 0 1 1-1H47.9a1 1 0 0 1 1 1v6.815a1 1 0 0 1-1 1h-6.518a1 1 0 0 1-1-1zm2 1v4.815H46.9V41zM51.445 40a1 1 0 0 1 1-1h6.518a1 1 0 0 1 1 1v6.815a1 1 0 0 1-1 1h-6.519a1 1 0 0 1-1-1zm2 1v4.815h4.518V41zM62.605 40a1 1 0 0 1 1-1h6.519a1 1 0 0 1 1 1v6.815a1 1 0 0 1-1 1h-6.519a1 1 0 0 1-1-1zm2 1v4.815h4.519V41zM40.383 51.753a1 1 0 0 1 1-1H47.9a1 1 0 0 1 1 1v6.815a1 1 0 0 1-1 1h-6.518a1 1 0 0 1-1-1zm2 1v4.815H46.9v-4.815zM51.445 51.753a1 1 0 0 1 1-1h6.518a1 1 0 0 1 1 1v6.815a1 1 0 0 1-1 1h-6.519a1 1 0 0 1-1-1zm2 1v4.815h4.518v-4.815zM62.605 51.753a1 1 0 0 1 1-1h6.519a1 1 0 0 1 1 1v6.815a1 1 0 0 1-1 1h-6.519a1 1 0 0 1-1-1zm2 1v4.815h4.519v-4.815zM40.383 63.506a1 1 0 0 1 1-1H47.9a1 1 0 0 1 1 1v6.815a1 1 0 0 1-1 1h-6.518a1 1 0 0 1-1-1zm2 1v4.815H46.9v-4.815zM51.445 63.506a1 1 0 0 1 1-1h6.518a1 1 0 0 1 1 1v6.815a1 1 0 0 1-1 1h-6.519a1 1 0 0 1-1-1zm2 1v4.815h4.518v-4.815zM62.605 63.506a1 1 0 0 1 1-1h6.519a1 1 0 0 1 1 1v6.815a1 1 0 0 1-1 1h-6.519a1 1 0 0 1-1-1zm2 1v4.815h4.519v-4.815z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M47.889 77.333a1 1 0 0 1 1-1h13.63a1 1 0 0 1 1 1v14.222a1 1 0 0 1-1 1h-13.63a1 1 0 0 1-1-1zm2 1v12.222h11.63V78.333z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M55.704 76.333a1 1 0 0 1 1 1v13.728a1 1 0 1 1-2 0V77.333a1 1 0 0 1 1-1M5.809 49.086a1.5 1.5 0 0 1 1.5-1.5h26.864a1.5 1.5 0 0 1 0 3H8.809v39.667h25.66a1.5 1.5 0 0 1 0 3H7.31a1.5 1.5 0 0 1-1.5-1.5z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.056 91.753a1.5 1.5 0 0 1 1.5-1.5h41.876a1.5 1.5 0 0 1 0 3H3.556a1.5 1.5 0 0 1-1.5-1.5M10.51 54.617a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.845v-3.043zM17.917 54.617a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.345a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.845v-3.043zM25.324 54.617a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.846v-3.043zM10.51 62.519a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.845v-3.043zM17.917 62.519a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.345a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.845v-3.043zM25.324 62.519a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.846v-3.043zM10.51 70.32a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.845v-3.043zM17.917 70.32a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.345a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.845v-3.043zM25.324 70.32a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.846v-3.043zM10.51 78.222a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.845v-3.043zM17.917 78.222a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.345a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.845v-3.043zM25.324 78.222a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.846v-3.043zM75.735 43.852a1.5 1.5 0 0 1 1.5-1.5h28.049a1.5 1.5 0 0 1 1.5 1.5v47.9a1.5 1.5 0 0 1-1.5 1.5h-28.05a1.5 1.5 0 0 1 0-3h26.55v-44.9h-26.55a1.5 1.5 0 0 1-1.5-1.5"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M71.883 91.753a1.5 1.5 0 0 1 1.5-1.5h34.963a1.5 1.5 0 0 1 0 3H73.383a1.5 1.5 0 0 1-1.5-1.5M80.534 49.185a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.846v-3.043zM87.941 49.185a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.846v-3.043zM95.448 49.185a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.845v-3.043zM80.534 57.086a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.846v-3.044zM87.941 57.086a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.846v-3.044zM95.448 57.086a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.845v-3.044zM80.534 64.987a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.846v-3.044zM87.941 64.987a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.846v-3.044zM95.448 64.987a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.845v-3.044zM80.534 72.79a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.846V73.54zM87.941 72.79a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.846V73.54zM95.448 72.79a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.845V73.54z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M80.534 72.79a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.846V73.54zM87.941 72.79a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.846V73.54zM95.448 72.79a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.543a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.043h2.845V73.54zM80.534 80.69a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.846v-3.043zM87.941 80.69a.75.75 0 0 1 .75-.75h4.346a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.846v-3.043zM95.448 80.69a.75.75 0 0 1 .75-.75h4.345a.75.75 0 0 1 .75.75v4.544a.75.75 0 0 1-.75.75h-4.346a.75.75 0 0 1-.75-.75zm1.5.75v3.044h2.845v-3.043z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramBuildings03;

import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Tags from "@@/components/Tags";
import Box from "@@/elements/Box";
import { ArrowRight } from "@@/elements/Icons/icons";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import styles from "./styles.module.scss";

export type NewsCardProps = {
  publishdate?: string;
  subject?: string;
  header: string;
  image: PictureData;
  link: LinkData,
  categories?: string[];
};

const NewsCard = ({
  publishdate, subject, header, image, link, categories,
}: NewsCardProps) => {
  const preHeader = subject || publishdate;

  return (
    <Box className={styles.item} backgroundColor="White" flexDirection="column" customStyle={{ cursor: "pointer" }}>
      {link && (
      <a href={link.href} title={link.title} target={link.target}>
        <span className={styles.link} />
      </a>
      )}
      <div>
        <Picture
          imageData={image}
          aspectRatio="2/1"
          sizes={[200, 400, 600, 800, 1200, 1600]}
        />
      </div>
      <Box pt={8} pb={6} px={[6, 6, 10]} height="full" flexDirection="column">
        {preHeader && (
        <Typhography variant="link" color="ComplementaryCharcoalTint03">
          {preHeader}
        </Typhography>
        )}
        {header && (
        <Typhography variant="intro" asElement="h3" mt={3}>
          {header}
        </Typhography>
        )}
        {link && (
        <Box mt={6}>
          <TextButtonIcon dense link={link} direction="right">
            <ArrowRight />
          </TextButtonIcon>
        </Box>
        )}
        {categories?.length && (
        <Box mt={11} height="full" flexDirection="column" justifyContent="flex-end">
          <Tags tags={categories} />
        </Box>
        )}
      </Box>
    </Box>
  );
};

export default NewsCard;

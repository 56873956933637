import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramContainers04 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.881 22.153a1.5 1.5 0 0 1 1.39-.937h75.457a1.5 1.5 0 0 1 1.394.944l6.222 15.605a1.5 1.5 0 0 1-1.393 2.056h-.476v8.596l7.645 4.407a1.5 1.5 0 0 1 .75 1.3v13.234a1.5 1.5 0 0 1-.762 1.306l-7.633 4.31v8.606c0 1.347-.915 2.416-2.181 2.641l-.8 5.192a1.5 1.5 0 0 1-1.482 1.272H84.938a1.5 1.5 0 0 1-1.482-1.272l-.792-5.148H29.435l-.792 5.148a1.5 1.5 0 0 1-1.483 1.272H17.087a1.5 1.5 0 0 1-1.482-1.272l-.8-5.194c-1.319-.247-2.18-1.446-2.18-2.639v-8.46L4.88 68.658a1.5 1.5 0 0 1-.75-1.3V54.124a1.5 1.5 0 0 1 .75-1.3l7.744-4.463v-8.54h-.673a1.5 1.5 0 0 1-1.39-2.062zM15.623 39.82v41.444h80.852V39.821zm82.115-3-5.026-12.605h-73.43L14.177 36.82zm1.737 15.059v17.649l5.395-3.047V54.99zm-5.223 32.385h-8.553l.526 3.42h7.5zm-67.852 0h-8.553l.526 3.42h7.5zM12.624 51.823 7.13 54.99V66.49l5.494 3.167zm16.323-25.84a1.5 1.5 0 0 1 .773 1.976l-2.765 6.321a1.5 1.5 0 1 1-2.749-1.202l2.765-6.321a1.5 1.5 0 0 1 1.976-.773m54.205 0a1.5 1.5 0 0 1 1.975.774l2.766 6.32a1.5 1.5 0 0 1-2.749 1.203l-2.765-6.321a1.5 1.5 0 0 1 .773-1.975m-35.622-.09a1.5 1.5 0 0 1 1.145 1.785L47.292 34a1.5 1.5 0 1 1-2.93-.64l1.383-6.322a1.5 1.5 0 0 1 1.785-1.145m16.94 0a1.5 1.5 0 0 1 1.785 1.144l1.383 6.321a1.5 1.5 0 1 1-2.93.641l-1.383-6.32a1.5 1.5 0 0 1 1.145-1.787M22.5 51.752a1.5 1.5 0 0 1 1.5-1.5h64.099a1.5 1.5 0 1 1 0 3H24a1.5 1.5 0 0 1-1.5-1.5m0 16.691a1.5 1.5 0 0 1 1.5-1.5h64.099a1.5 1.5 0 0 1 0 3H24a1.5 1.5 0 0 1-1.5-1.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramContainers04;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgFileThin = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 58 74"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.5 2c.828 0 1.5.677 1.5 1.512v9.977c0 .835-.672 1.511-1.5 1.511h-10C2.672 15 2 14.325 2 13.49s.672-1.512 1.5-1.512H12V3.512c0-.835.672-1.511 1.5-1.511"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.756.434a1.46 1.46 0 0 1 1.039-.433h44.743a1.463 1.463 0 0 1 0 2.929H12.405l-9.48 9.583v58.559h52.15V2.93s1.537-.039 2.345-.039c.58-1.156.58-1.735.58 0v69.645c0 .809-.655 1.465-1.462 1.465H1.462A1.463 1.463 0 0 1 0 72.536V11.911c0-.386.152-.757.423-1.03z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFileThin;

import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import { ScreenSize, screenSizeIsSmall, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import EpiContent from "@@/integrations/optimizly/Content";
import { useState, useEffect } from "react";
import Box from "@@/elements/Box";
import styles from "./index.module.scss";
import Scroller, { ScrollerItem } from "../Scroller";
import BranchInfoCard, { CardProps } from "./card";
import { CardColors } from "../Card/CardProps";

type BranchInfoCardsProps = {
  header?: string,
  color: CardColors,
  cards: CardProps[],
  sliderDesktop?: boolean;
  sliderMobile?: boolean;

};

const BranchInfoCards = ({
  header, cards, color, sliderDesktop, sliderMobile,
}: BranchInfoCardsProps) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);
  const [slider, setSlider] = useState<boolean>(false);
  useEffect(() => {
    if (
      (sliderMobile && screenSizeIsSmall()) ||
        (sliderDesktop && !screenSizeIsSmall() && cards.length > 3)
    ) {
      setSlider(true);
    }
  }, []);

  return (
    <Container>
      {header && (<Typography mb={[10, 12, 14]} textAlign="center" variant="heading1" asElement="h2">{header}</Typography>)}
      {cards.length === 1 && (
        <Box>
          {cards.map((card) => (
            <BranchInfoCard icon={card.icon} header={card.header} body={card.body} link={card.link} color={color} centered={!isSmall} />
          ))}

        </Box>
      )}
      {slider && cards.length > 1 && (
      <EpiContent>
        <Scroller>
          {cards.map((card) => (
            <ScrollerItem className={styles.scroller_item}>
              <BranchInfoCard icon={card.icon} header={card.header} body={card.body} link={card.link} color={color} centered={false} />
            </ScrollerItem>
          ))}
        </Scroller>
      </EpiContent>
      )}
      {!slider && cards.length > 1 && (
      <EpiContent>
        <Box className={(cards.length === 2 || cards.length === 4) ? styles.grid : styles.cards_grid}>
          {cards.map((card) => (
            <Box component="span">
              <BranchInfoCard icon={card.icon} header={card.header} body={card.body} link={card.link} color={color} centered={false} />
            </Box>
          ))}
        </Box>
      </EpiContent>
      )}
    </Container>
  );
};

export default BranchInfoCards;

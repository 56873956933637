import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import {
  useIsBreakpointOrBelow,
  ScreenSize,
} from "@@/hooks/device/use-breakpoint";
import Scroller, { ScrollerItem } from "../Scroller";
import SidebarLinkCard, { LinkCardProps } from "./card";
import styles from "./index.module.scss";

type SidebarLinkCardContainerProps = {
  cards: LinkCardProps[];
};

const SidebarLinkCardContainer = ({ cards }: SidebarLinkCardContainerProps) => {
  const isMediumOrBelow = useIsBreakpointOrBelow(ScreenSize.MEDIUM);

  return (
    <Container
      className={!isMediumOrBelow && styles.border}
      pageGutter={!isMediumOrBelow && 0}
      backgroundColor="PrimaryGreyTint03"
    >
      {isMediumOrBelow ? (
        <Scroller>
          {cards &&
            cards.map((card) => (
              <ScrollerItem className={styles.scroller_item}>
                <SidebarLinkCard
                  header={card.header}
                  image={card.image}
                  link={card.link}
                />
              </ScrollerItem>
            ))}
        </Scroller>
      ) : (
        <Container className={styles.container_grid} component="div">
          {cards &&
            cards.map((card) => (
              <Box>
                <SidebarLinkCard
                  header={card.header}
                  image={card.image}
                  link={card.link}
                />
              </Box>
            ))}
        </Container>
      )}
    </Container>
  );
};

export default SidebarLinkCardContainer;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramArrows05 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g
      fill="currentColor"
      fillRule="evenodd"
      clipPath="url(#pictogram_arrows_05_svg__a)"
      clipRule="evenodd"
    >
      <path d="M37.315 14.005C40.752 7.527 47.608 3.123 55.64 3.123c8.025 0 14.878 4.386 18.308 10.85a24 24 0 0 0 .233.405l.611 1.055a30284.054 30284.054 0 0 0 14.486 24.92l2.793 4.803-1.297.754-1.296.754-2.794-4.802-6.168-10.605a6828 6828 0 0 1-9.203-15.851l-.009-.016c-2.914-5.501-8.759-9.267-15.664-9.267-6.902 0-12.749 3.774-15.674 9.287-.172.325-2.111 3.7-3.974 6.936l-2.575 4.471-1.16 2.013-1.3-.749-1.3-.749 1.16-2.013a10489 10489 0 0 0 5.202-9.04 765 765 0 0 0 1.265-2.215l.03-.056zm-6.36 14.076-1.299-.749a1.5 1.5 0 1 0 2.6 1.498zm59.818 17.83-1.296.753a1.5 1.5 0 1 0 2.593-1.508z" />
      <path d="M95.36 33.942a1.5 1.5 0 0 1 1.06 1.837L93.21 47.76a1.5 1.5 0 0 1-1.837 1.061L79.4 45.611a1.5 1.5 0 0 1 .777-2.898l10.523 2.822 2.822-10.532a1.5 1.5 0 0 1 1.837-1.06M95.623 55.546a1.5 1.5 0 0 1 2.05.547l-1.05.608 1.05-.608 1.163 2.011a7182 7182 0 0 0 6.151 10.605l.369.628.026.043.009.015c3.894 6.212 4.278 14.351.263 21.308-4.013 6.955-11.23 10.694-18.552 10.43l-.055-.001h-.063l-.178-.001h-.167l-1.219.001a24389.708 24389.708 0 0 0-28.824.082l-5.554.018-.005-1.5-.005-1.5 5.555-.018 12.268-.039c8.917-.027 17.995-.052 18.341-.04h.007c6.228.226 12.4-2.949 15.853-8.931 3.451-5.981 3.106-12.933-.209-18.218l-.002-.004c-.18-.289-2.137-3.662-4.02-6.91l-2.585-4.465-1.164-2.01 1.055-.612-1.055.611a1.5 1.5 0 0 1 .547-2.05M51.037 99.732l-.005-1.5a1.5 1.5 0 0 0 .01 3z" />
      <path d="M58.686 90.197a1.5 1.5 0 0 1 0 2.121l-7.702 7.701 7.702 7.711a1.5 1.5 0 1 1-2.123 2.12l-8.76-8.771a1.5 1.5 0 0 1 0-2.121l8.761-8.761a1.5 1.5 0 0 1 2.121 0M24.98 100.626c-7.328.263-14.57-3.472-18.586-10.428-4.012-6.95-3.64-15.078.242-21.28a25 25 0 0 0 .235-.404l.608-1.057 2.078-3.618A28838 28838 0 0 0 21.82 42.452l2.762-4.82 1.301.745 1.301.746-2.761 4.82-6.101 10.645a6810 6810 0 0 1-9.125 15.896l-.01.015c-3.307 5.275-3.646 12.22-.194 18.2 3.451 5.977 9.643 9.153 15.88 8.93.368-.014 4.26-.022 7.995-.026l5.159-.006 2.323-.002.002 1.5v1.5l-2.323.002a6177 6177 0 0 0-10.428.015l-1.827.007-.558.004-.167.002-.064.001zm15.37-1.53.002 1.499a1.5 1.5 0 1 0-.003-3zM25.883 38.376l1.301.746a1.5 1.5 0 1 0-2.602-1.49z" />
      <path d="M13.247 40.428a1.5 1.5 0 0 1 1.06-1.837l11.981-3.21a1.5 1.5 0 0 1 1.838 1.06l3.205 11.973a1.5 1.5 0 1 1-2.898.776l-2.817-10.524-10.532 2.823a1.5 1.5 0 0 1-1.837-1.061" />
    </g>
    <defs>
      <clipPath id="pictogram_arrows_05_svg__a">
        <path fill="#fff" d="M0 0h112v112H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPictogramArrows05;

import { TextCard, TextCardProps } from "@@/components/Card/Text";
import Scroller, { ScrollerItem } from "@@/components/Scroller";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import { screenSizeIsSmall } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiContent from "@@/integrations/optimizly/Content";
import EpiProperty from "@@/integrations/optimizly/Property";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { TokenValue as bps } from "../../../design-tokens/breakpoints";

export type TextCardContainerProperties = {
  header: string;
};

export type TextCardContainerOwnProps = {
  cards: TextCardProps[];
  sliderDesktop?: boolean;
  sliderMobile?: boolean;
};

export type TextCardContainerProps = TextCardContainerOwnProps &
  TextCardContainerProperties;

const TextCardContainer = ({
  header,
  cards,
  sliderDesktop,
  sliderMobile,
}: TextCardContainerProps) => {
  const [slider, setSlider] = useState<boolean>(false);
  useEffect(() => {
    const checkIfSlider = () => {
      if (
        (sliderMobile && window.innerWidth < bps.Large) ||
        (sliderDesktop && window.innerWidth >= bps.Large)
      ) {
        setSlider(true);
      } else setSlider(false);
    };
    checkIfSlider();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIfSlider);

      return () => {
        window.removeEventListener("resize", checkIfSlider);
      };
    }
  }, []);

  return (
    <Container>
      {header && (
        <EpiProperty<TextCardContainerProperties> name="header">
          <Typography
            mb={[14, 14, 18]}
            variant="heading1"
            asElement="h2"
            textAlign="center"
          >
            {header}
          </Typography>
        </EpiProperty>
      )}
      {!slider && (
        <EpiContent>
          <Box className={styles.container}>
            {cards?.map((card, index) => (
              <TextCard
                key={index}
                reference={card.reference}
                referenceLink={card.referenceLink}
                header={card.header}
                color={card.color}
                variant={cards.length > 2 ? "heading4" : "heading2"}
              />
            ))}
          </Box>
        </EpiContent>
      )}
      {slider && (
        <EpiContent>
          <Box>
            <Scroller>
              {cards?.map((card, index) => (
                <ScrollerItem key={index}>
                  <Box className={styles["textCardContainer__scroller-item"]}>
                    <TextCard
                      reference={card.reference}
                      referenceLink={card.referenceLink}
                      header={card.header}
                      color={card.color}
                      variant="heading4"
                    />
                  </Box>
                </ScrollerItem>
              ))}
            </Scroller>
          </Box>
        </EpiContent>
      )}
    </Container>
  );
};

export type ProvidedTextCardContainerProps =
  OptimizelyBlockProps<TextCardContainerProperties> & TextCardContainerProps;

const ProvidedTextCardContainer = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedTextCardContainerProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <TextCardContainer {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedTextCardContainer;

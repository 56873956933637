/* eslint-disable react/no-array-index-key */
import ResponsiveValue, {
  arrayFromResponiveValue,
} from "@@/base/responsive-value";
import Box from "@@/elements/Box";
import useAspectRatio from "@@/hooks/ui/use-aspect-ratio";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import React, { useEffect, useRef, useState } from "react";
import MissingImageSvg from "../../assets/image-missing-thin.svg";
import MissingAccessory from "../../assets/missing-img-accessory.svg";
import useBorderRadius, {
  BorderRadiusProps,
} from "../../hooks/ui/use-border-radius";
import Typhography from "../Typography";
import { getAspectRatioHeight } from "./helpers";
import styles from "./index.module.scss";
import Source from "./source";


export type PictureSizes = number[];

// PictureData & RoundedCornerProps
export type PictureProps = BorderRadiusProps & {
  imageData?: PictureData;
  sourcesSizes?: string;
  sizes?: ResponsiveValue<PictureSizes>;
  aspectRatio: ResponsiveValue<string>;
  circular?: boolean;
  className?: string;
  imgClassName?: string;
  loading?: "eager" | "lazy";
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  lazySizes?: boolean;
  contain?: boolean;
  fallbackImg?: "accessory" | "missing";
};

const Picture = ({
  contain = false,
  imageData,
  sizes,
  className,
  circular,
  imgClassName,
  onLoad,
  loading = "lazy",
  aspectRatio,
  lazySizes = true,
  sourcesSizes,
  fallbackImg = "missing",
  ...rest
}: PictureProps) => {
  const borderRadius = useBorderRadius(rest);
  const aspectRatios = useAspectRatio(aspectRatio);
  const [hasImage, setHasImage] = useState<boolean>(!!imageData);
  const [isLoading, setIsLoading] = useState(lazySizes || loading === "lazy");
  const img = useRef<HTMLImageElement>(null);
 
  const imageAspectTypes = arrayFromResponiveValue(aspectRatio);

  let convertedSizes = sizes;
  // if sizes is sent in as [100,200,300] we need to convert it to be [[100,200,300]]
  if (sizes && !Array.isArray(sizes[0])) {
    convertedSizes = [sizes] as any;
  }

  const getFallBackImg = () => {
    switch (fallbackImg) {
    case "missing":
      return MissingImageSvg;
    case "accessory":
      return MissingAccessory;
    default:
      return MissingImageSvg;
    }
  };
  // Reverse to make sources render in the correct order.
  const reversedSizesToArray =
    arrayFromResponiveValue(convertedSizes).reverse();

  const reversedImageAspectTypes = imageAspectTypes.slice().reverse();

  const pictureClasses = cn(
    styles["stena-picture"],
    className,
    {
      [styles["stena-picture--loading"]]: hasImage && isLoading,
      [styles["stena-picture--loading--circular"]]: circular,
    },
    aspectRatios.className,
  );

  const imgClasses = cn(
    styles["stena-picture__img"],
    imgClassName,
    {
      [styles["stena-picture__img--loading"]]: hasImage && isLoading,
      lazyload: hasImage && lazySizes,
      [styles["stena-picture__img--missing"]]: !hasImage,
    },
    borderRadius.className,
  );

  const getFallbackImage = () => {
    let fallback = imageData?.imageUrl;
    const width = 100;
    const quality = 80;
    fallback += `?width=${width}&height=${getAspectRatioHeight(
      width,
      imageAspectTypes[0],
    )}`;
    fallback += `&quality=${quality}`;

    return fallback;
  };

  const imageLoaded = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    setIsLoading(false);
    onLoad?.(event);
  };

  useEffect(() => {
    if (lazySizes) {
      return;
    }
    if (img.current?.complete) {
      setIsLoading(false);
    }
  }, [lazySizes, img.current?.complete]);

  return (
    <>
      <picture
        className={pictureClasses}
        style={aspectRatios.style}
        {...rest}>
        {hasImage && (
          <>
            {reversedImageAspectTypes.map((imageAspect, index) => (
              <Source
                key={index}
                imageData={imageData}
                aspectRatio={imageAspect}
                sizes={reversedSizesToArray[index]}
                index={index}
                lazySizes={lazySizes}
                webp={imageData?.webP}
                contain={contain}
                sourcesSizes={sourcesSizes}
              />
            ))}
            <img
              onError={() => {
                setHasImage(false);
              }}
              ref={img}
              data-src={lazySizes ? imageData?.imageUrl : undefined}
              src={lazySizes ? undefined : getFallbackImage()}
              alt={imageData?.imageAlt || ""}
              className={imgClasses}
              onLoad={imageLoaded}
              loading={lazySizes ? undefined : loading}
              data-sizes={lazySizes ? "auto" : undefined}
              style={borderRadius.style}
            />
          </>
        )}
        {!hasImage && (
          <img
            src={getFallBackImg()}
            alt=""
            className={imgClasses} />
        )}
      </picture>
    
      {imageData?.caption && (
        <Box backgroundColor="Black">
          <Typhography
            variant="information"
            color="White"
            pt={3}
            pb={2}
            px={5}>
            {imageData?.caption}
          </Typhography>
        </Box>
      )}
    </>
  );
};

export default Picture;

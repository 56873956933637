import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Picture from "@@/elements/Picture";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import styles from "../styles.module.scss";

export interface MediaGalleryProps {
  mediaGalleryItems: PictureData[];
  size: "large" | "medium" | "small";
}
type MediaProps = MediaGalleryProps;

const MediaGallery = ({ mediaGalleryItems, size = "large" }: MediaProps) => {
  
  const isEven = (number) => number % 2 === 0;

  // If size is medium and when items are 4 the grid should be 3x3 but only show 2 each row.
  const breakRow = size === "medium" && mediaGalleryItems.length === 4;



  return (
    <Container
      pageGutterY={10}
      className="top-section-image">
      <Box
        className={cn(styles.mediagallery, {
          [styles.mediagallery__large]: size === "large",
          [styles.mediagallery__medium]: size === "medium",
          [styles.mediagallery__small]: size === "small",
        })}
        flexDirection="row"
        gap={[4, 4, 5]}>
        {mediaGalleryItems &&
        mediaGalleryItems.map((m, index) => (
          <Box
            positionRoot
            key={index}
            className={cn(styles.mediagallery__item, {
              [styles.mediagallery__item__even]: breakRow && isEven(index),
            })}
          >
            <Picture
              imageData={m}
              aspectRatio="1 / 1"
              loading="eager"
              lazySizes
              sizes={[300, 600, 900, 1200, 1600]}
              br={0}
            />
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export type ProvidedMediaCardProps = OptimizelyBlockProps<MediaGalleryProps> &
  MediaGalleryProps;

const ProvidedMedia = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedMediaCardProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <MediaGallery
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedMedia;

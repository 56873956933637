import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramCommunication08 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M56.921 30.31c.8 0 1.448.649 1.448 1.448v24.128h32.524c.214 0 .418.047.6.13.65.142 1.137.721 1.137 1.414v26.444a1.448 1.448 0 0 1-2.895 0V58.783H58.369v25.093a1.448 1.448 0 1 1-2.896 0V58.781h-32.91v25.093a1.448 1.448 0 1 1-2.895 0V57.43c0-.605.371-1.124.899-1.34.217-.13.47-.204.741-.204h34.165V31.758c0-.8.648-1.447 1.448-1.447"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M26.23 91.885c0 2.799-2.316 5.115-5.115 5.115S16 94.684 16 91.885s2.316-5.115 5.115-5.115c2.896-.096 5.115 2.22 5.115 5.115M61.94 91.885c0 2.799-2.317 5.115-5.115 5.115-2.8 0-5.116-2.316-5.116-5.115s2.317-5.115 5.115-5.115c2.8-.096 5.116 2.22 5.116 5.115M96.298 91.885c0 2.799-2.317 5.115-5.115 5.115-2.8 0-5.116-2.316-5.116-5.115s2.317-5.115 5.116-5.115c2.798-.096 5.115 2.22 5.115 5.115M64.449 21.624c0 4.247-3.474 7.625-7.624 7.625a7.58 7.58 0 0 1-7.625-7.625c0-4.246 3.474-7.624 7.624-7.624s7.625 3.378 7.625 7.624"
    />
  </svg>
);
export default SvgPictogramCommunication08;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgProcessIcon1 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 72 73"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M65.445 6.555C58.973.075 54.11 1.046 54.11 1.046L31.436 23.718 5.523 49.628.993 71.001l21.375-4.532 48.59-48.579c-.001 0 .969-4.857-5.513-11.336M21.095 63.9l-7.29 1.569c-.702-1.318-1.549-2.629-3.097-4.183-1.549-1.549-2.867-2.398-4.185-3.1l1.568-7.29 2.11-2.1s3.97.077 8.438 4.555c4.477 4.48 4.562 8.44 4.562 8.44zM15.25 33.794l3.062-3.062-13.9-13.9L16.83 4.411l2.87 2.87-3.87 3.87a2.16 2.16 0 0 0 0 3.06 2.16 2.16 0 0 0 3.062 0l3.869-3.869 5.291 5.289-3.87 3.872a2.16 2.16 0 0 0 .002 3.06c.464.466 1.084.65 1.692.603l7.915-7.916L19.616 1.077c-1.536-1.536-4.161-1.415-5.85.274L1.35 13.771c-1.687 1.687-1.81 4.31-.273 5.849zM70.922 52.382 56.756 38.216l-8.107 8.106c.052.471.228.935.59 1.298a2.16 2.16 0 0 0 3.06 0l3.87-3.87 5.29 5.292-3.87 3.87a2.163 2.163 0 0 0 0 3.06 2.16 2.16 0 0 0 3.063 0l3.869-3.869 3.065 3.066h-.001L55.169 67.59 41.277 53.697l-3.063 3.06 14.167 14.167c1.538 1.537 4.161 1.414 5.85-.271l12.417-12.419c1.691-1.69 1.811-4.314.273-5.852" />
  </svg>
);
export default SvgProcessIcon1;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramFinance02 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 113 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M43.118 37.1a1.5 1.5 0 0 1 1.5-1.5h23.39a1.5 1.5 0 0 1 1.5 1.5v61.862a1.5 1.5 0 0 1-1.5 1.5h-23.39a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v58.862h20.39V38.6zM13.299 52.637a1.5 1.5 0 0 1 1.5-1.5h23.38a1.5 1.5 0 0 1 1.5 1.5v46.335a1.5 1.5 0 0 1-1.5 1.5h-23.38a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v43.335h20.38V54.137zM72.652 13.028a1.5 1.5 0 0 1 1.5-1.5h23.39a1.5 1.5 0 0 1 1.5 1.5v85.934a1.5 1.5 0 0 1-1.5 1.5h-23.39a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v82.934h20.39V14.528z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramFinance02;

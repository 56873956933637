import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramEnergy07 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.821 30.32a1.5 1.5 0 0 1 1.5-1.5h83.358a1.5 1.5 0 0 1 1.5 1.5v58.668a1.5 1.5 0 0 1-1.5 1.5H14.321a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v55.668h80.358V31.82z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.685 24.494c0-1.619 1.363-2.982 2.982-2.982h15.21c1.618 0 2.981 1.363 2.981 2.982v5.037h-3v-5.008l-.005-.006-.006-.005H26.696l-.01.01v5.009h-3zM67.142 24.494c0-1.619 1.363-2.982 2.982-2.982h15.21c1.618 0 2.98 1.363 2.98 2.982v5.037h-3v-5.008l-.01-.01H70.153l-.01.01v5.008h-3z"
      clipRule="evenodd"
    />
    <path fill="currentColor" d="M60.05 39.309 45.63 62.32h10.074z" />
    <path fill="currentColor" d="m51.95 80 14.42-23.013H56.296z" />
  </svg>
);
export default SvgPictogramEnergy07;

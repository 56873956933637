import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import cn from "classnames";
import LargeCompanyContactCard, {
  LargeCompanyContactCardProps,
} from "./Card/large";
import SmallCompanyContactCard from "./Card/small";
import styles from "./index.module.scss";

type CompanyContactContainerEditableProps = {
  header?: string;
  intro?: string;
};

type CompanyContactContainerOwnProps = {
  cards: LargeCompanyContactCardProps[];
};

type CompanyContactContainerProps = CompanyContactContainerEditableProps &
  CompanyContactContainerOwnProps;

const CompanyContactContainer = ({
  header,
  intro,
  cards,
}: CompanyContactContainerProps) => (
  <Container py={14}>
    <Box
      width="full"
      className={styles.header_wrapper}
      alignItems="center"
      flexDirection="column"
      textAlign="center"
    >
      {header && (
        <EpiProperty<CompanyContactContainerEditableProps> name="header">
          <Typography
            mb={intro ? 8 : [10, 12, 14]}
            variant="heading1"
            asElement="h2"
          >
            {header}
          </Typography>
        </EpiProperty>
      )}
      {intro && (
        <EpiProperty<CompanyContactContainerEditableProps> name="intro">
          <Typography
            mb={[10, 12, 14]}
            variant="intro">
            {intro}
          </Typography>
        </EpiProperty>
      )}
    </Box>

    {cards.length === 1 && (
      <Box>
        {cards.map((card, i) => (
          <LargeCompanyContactCard
            key={i}
            label={card.label}
            header={card.header}
            image={card.image}
            address={card.address}
            locationLink={card.locationLink}
            postalAddressLabel={card.postalAddressLabel}
            postalAddress={card.postalAddress}
            phoneNumber={card.phoneNumber}
            emailLink={card.emailLink}
            websiteLink={card.websiteLink}
            openingHoursLabel={card.openingHoursLabel}
            openingHours={card.openingHours}
          />
        ))}
      </Box>
    )}
    {cards.length > 1 && (
      <Box className={cn(styles.cards_grid, {
        [styles.cards_grid__twocards]: cards.length === 2,
      })}>
        {cards.map((card, i) => (
          <SmallCompanyContactCard
            key={i}
            label={card.label}
            header={card.header}
            address={card.address}
            locationLink={card.locationLink}
            postalAddressLabel={card.postalAddressLabel}
            postalAddress={card.postalAddress}
            phoneNumber={card.phoneNumber}
            emailLink={card.emailLink}
            websiteLink={card.websiteLink}
          />
        ))}
      </Box>
    )}
  </Container>
);

export type ProvidedCompanyContactContainerProps =
  OptimizelyBlockProps<CompanyContactContainerEditableProps> &
    CompanyContactContainerProps;

const ProvidedCompanyContactContainer = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedCompanyContactContainerProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <CompanyContactContainer
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedCompanyContactContainer;

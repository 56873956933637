import Box from "@@/elements/Box";
import { Check } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import cn from "classnames";
import styles from "./optionStyles.module.scss";

type DropdownOptionOwnProps = {
  checked: boolean;
  disabled?: boolean;
  handleChange: Function;
  singleSelect?: boolean;
};

export type DropdownOptionProps = {
  label: string;
  value: string;
  count?: number;
  disabled?: boolean;
};

type DropdownOptionAllProps = DropdownOptionOwnProps & DropdownOptionProps;

const DropdownOption = ({
  label, value, count, checked, disabled = false, handleChange, singleSelect = false,
} : DropdownOptionAllProps) => {
  const id = `dropdown-option-${value}`;
  const countString = `(${count})`;

  const handleKeyDown = (e : any) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleChange(value);
    }
  };

  return (
    <div className={styles.option} tabIndex={0} onKeyDown={handleKeyDown} role="option" aria-selected={checked}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={() => {
          handleChange(value);
        }}
        tabIndex={-1}
      />
      <label className={styles.container} htmlFor={id}>
        <Box className={styles.label}>
          <Typhography
            variant="label"
            asElement="div"
            uppercase
            bold={singleSelect && checked}
          >
            {label}
          </Typhography>
          {count != null && (
          <Box alignItems="baseline" height="full" className={styles.count}>
            <Typhography variant="label" asElement="div">
              {countString}
            </Typhography>
          </Box>
          )}
        </Box>
        {!singleSelect && (
        <span className={cn(styles.icon_wrapper, styles.icon_wrapper__checkmark)}>
          {checked && (
          <Check className={styles.checkmark_icon} />
          )}
        </span>
        )}
      </label>
    </div>
  );
};

export default DropdownOption;

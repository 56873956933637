import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import classNames from "classnames";
import { useEffect, useState } from "react";
import PersonContactCard, { PersonContactCardProps, VariantType } from "./Card";
import styles from "./index.module.scss";

type PersonContactContainerEditableProps = {
  header?: string;
  intro?: string;
};

type PersonContactContainerOwnProps = {
  cards: PersonContactCardProps[];
  showImage: boolean;
};

type PersonContactContainerProps = PersonContactContainerEditableProps &
PersonContactContainerOwnProps;

const PersonContactContainer = ({
  header,
  intro,
  cards,
  showImage,
}: PersonContactContainerProps) => {
  const [variant, setVariant] = useState<VariantType>();
  useEffect(() => {
    if (cards.length === 1) {
      setVariant("large");
    }
    if (cards.length === 2) {
      setVariant("medium");
    }

    if (cards.length > 2) {
      setVariant("small");
    }
  }, []);

  const gridClassName = classNames(styles.grid, {
    [(styles as any)[`grid_${variant}`]]: variant,
    [styles.grid_small__3_card]: variant === "small" && cards.length === 3,
  });

  const gridNoImageClassName = classNames(styles.grid, {
    [(styles as any)[`grid_noimage_${variant}`]]: variant,
    [styles.grid_noimage_small__3_card]: variant === "small" && cards.length === 3,
  });
  return (
    <Container>
      <Box
        width="full"
        className={styles.header_wrapper}
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        {header && (
          <EpiProperty<PersonContactContainerEditableProps> name="header">
            <Typography
              mb={intro ? 8 : [10, 12, 14]}
              variant="heading2"
              asElement="h2"
            >
              {header}
            </Typography>
          </EpiProperty>
        )}
        {intro && (
          <EpiProperty<PersonContactContainerEditableProps> name="intro">
            <Typography
              mb={[10, 12, 14]}
              variant="intro">
              {intro}
            </Typography>
          </EpiProperty>
        )}
      </Box>
      <Box className={showImage ? gridClassName : gridNoImageClassName}>
        {cards.map((card, i) => (
          <PersonContactCard
            key={i}
            showImage={showImage}
            image={card.image}
            name={card.name}
            title={card.title}
            variant={variant}
            department={card.department}
            phoneNumber={card.phoneNumber}
            emailLink={card.emailLink}
            contactPageLink={card.contactPageLink}
          />
        ))}
      </Box>
    </Container>
  );
};

export type ProvidedPersonContactContainerProps =
  OptimizelyBlockProps<PersonContactContainerEditableProps> &
  PersonContactContainerProps;

const ProvidedPersonContactContainer = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedPersonContactContainerProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <PersonContactContainer
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedPersonContactContainer;

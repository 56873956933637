import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramVehicles02 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31.393 34.713a1.5 1.5 0 0 1 .748-.2h31.104c.332 0 .655.11.917.313L77.582 45.2l12.72 1.463.054.008c2.488.377 5.901 1.48 8.71 2.878 1.405.7 2.733 1.508 3.736 2.39.939.825 1.903 1.985 1.917 3.41v8.618a1.5 1.5 0 0 1-.337.947l-1.561 1.917c-.284.35-.712.552-1.163.552h-8.02a1.5 1.5 0 0 1 0-3h7.307l.774-.95v-8.057c-.003-.089-.112-.493-.898-1.184-.731-.644-1.806-1.318-3.092-1.957-2.561-1.274-5.662-2.266-7.797-2.595l-13.109-1.508a1.5 1.5 0 0 1-.746-.303L62.733 37.513H32.546a97 97 0 0 0-2.144 1.296c-1.418.883-3.112 1.989-4.34 2.949-1.34 1.05-3.032 2.21-4.368 3.099a128 128 0 0 1-2.184 1.418l-.135.086-.047.03h-.002l-.72-1.15.72 1.15a1.5 1.5 0 0 1-1.593-2.542l.043-.027.13-.081c.112-.072.277-.176.483-.309.412-.264.988-.638 1.644-1.074 1.32-.877 2.934-1.987 4.18-2.963 1.361-1.064 3.169-2.24 4.605-3.133a104 104 0 0 1 2.521-1.518l.04-.023zl.748 1.3z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.42 43.3a1.5 1.5 0 0 1 2.118-.099l3.226 2.938h44.46a1.5 1.5 0 1 1 0 3H31.183a1.5 1.5 0 0 1-1.01-.391l-3.655-3.329a1.5 1.5 0 0 1-.099-2.119"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M51.363 35.185a1.5 1.5 0 0 1 1.5 1.5v9.245a1.5 1.5 0 1 1-3 0v-9.245a1.5 1.5 0 0 1 1.5-1.5M33.643 38.316a1.5 1.5 0 0 1 1.5 1.5v6.173a1.5 1.5 0 0 1-3 0v-6.173a1.5 1.5 0 0 1 1.5-1.5M86.16 59.787a5.661 5.661 0 1 0 0 11.322 5.661 5.661 0 0 0 0-11.322m-8.66 5.66a8.661 8.661 0 1 1 17.322 0 8.661 8.661 0 0 1-17.323 0M27.44 59.787a5.661 5.661 0 1 0 0 11.322 5.661 5.661 0 0 0 0-11.322m-8.662 5.66a8.661 8.661 0 1 1 17.323 0 8.661 8.661 0 0 1-17.323 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.371 46.608h-.01l-.035.003a36 36 0 0 0-.68.044 65 65 0 0 0-1.828.157c-1.477.148-3.236.379-4.557.711l-.004.001c-.815.203-1.523.655-2.05 1.097a7 7 0 0 0-.48.445l-.555 9.238q.009.078.03.21c.036.211.097.505.2.831.217.683.564 1.342 1.076 1.749.796.634 1.726 1.019 2.494 1.244a9 9 0 0 0 1.17.256l.054.007h.007-.003.003q.06.007.12.017l6.667 1.195a1.5 1.5 0 1 1-.53 2.953l-6.616-1.186-.094-.011a11.594 11.594 0 0 1-1.623-.352c-.991-.291-2.317-.82-3.518-1.777-1.177-.936-1.767-2.243-2.067-3.19a9 9 0 0 1-.354-1.629l-.012-.117-.003-.037-.001-.013v-.007s0-.002 1.495-.11l-1.496.108a2 2 0 0 1-.001-.199l.593-9.877a1.5 1.5 0 0 1 .308-.824l1.189.914-1.19-.914.003-.004.003-.003.008-.01.022-.028.069-.084q.086-.105.239-.27c.202-.22.494-.513.867-.825.734-.615 1.843-1.357 3.25-1.708 1.543-.388 3.482-.636 4.987-.786a68 68 0 0 1 2.639-.211l.041-.003h.016l.079 1.497-.079-1.498a1.5 1.5 0 0 1 .157 2.996M33.752 65.952a1.5 1.5 0 0 1 1.5-1.5h43.293a1.5 1.5 0 1 1 0 3H35.252a1.5 1.5 0 0 1-1.5-1.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramVehicles02;

import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import LinkModule, { LinkGroup } from "@@/components/LinkModule";
import Container from "@@/elements/Container";
import RichText from "@@/components/RichText";
import { PictureData } from "@@/models/pictureData";
import Picture from "@@/elements/Picture";
import styles from "./styles.module.scss";

type Props = {
  header: string;
  errorCode: string;
  body: string;
  linkModule?: LinkGroup[];
  image?: PictureData;
};

const ErrorPage = ({
  header,
  errorCode,
  body,
  linkModule,
  image,
} : Props) => (
  <Container size="large">
    <Box
      mb={[20, 20, 30]}
      flexDirection={["column", "column", "row"]}
      justifyContent="space-between"
      gap={15}
    >
      <Box className={styles.content}>
        {errorCode && (
        <>
          <Typhography variant="heading5" asElement="span">
            {errorCode}
          </Typhography>
          <Box className={styles.divider} mt={3} mb={6} />
        </>
        )}
        <Typhography variant="heading1" asElement="h1" mb={8}>
          {header}
        </Typhography>
        <RichText body={body} />
      </Box>
      <Box className={styles.error_image}>
        {image ? (
          <Picture
            imageData={image}
            aspectRatio="1/1"
            sizes={[200, 400, 600, 800, 1200]}
          />
        ) : <img src="error-image.svg" alt="Error_image" />}
      </Box>
    </Box>
    <LinkModule items={linkModule} />
  </Container>
);

export default ErrorPage;

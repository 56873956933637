import Breadcrumbs from "@@/components/Breadcrumb";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import { LinkData } from "@@/models/linkdata";
import { ReactElement } from "react";
import styles from "./styles.module.scss";

type Props = {
  header: string;
  breadcrumbs: LinkData[];
  searchInput: ReactElement;
};

const SearchPageHero = ({
  header, breadcrumbs, searchInput,
} : Props) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);

  return (
    <Container size="full" pageGutter={0} component="header">
      <Container size="full" pageGutter={0} backgroundColor="PrimaryBlueTint03" className={styles.wrapper} component="div">
        <Container pageGutterY={0} component="div">
          <Typhography variant="heading1" asElement="h1" textAlign="center" py={[14, 36, 36]}>
            { header }
          </Typhography>
          {isSmall && (
            <Box mb={8} backgroundColor="White" br={1}>
              {searchInput}
            </Box>
          )}
        </Container>
        <Box px={3} width="full" className={styles.notch} justifyContent="center">
          <Box px={4} alignItems="center">
            <Breadcrumbs breadcrumbItems={breadcrumbs} />
          </Box>
        </Box>
      </Container>
      {!isSmall && (
      <Container pageGutterY={0} mt={10}>
        {searchInput}
      </Container>
      )}
    </Container>
  );
};

export default SearchPageHero;

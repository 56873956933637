import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramContainers01 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M69.626 85.152a7.637 7.637 0 1 0 0 15.273 7.637 7.637 0 0 0 0-15.273m-10.637 7.636c0-5.874 4.762-10.636 10.637-10.636 5.874 0 10.636 4.762 10.636 10.636 0 5.875-4.762 10.637-10.636 10.637-5.875 0-10.637-4.762-10.637-10.636"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m32.4 99.447-1.136-77.991 3-.044 1.135 77.992c.008.564.477 1.031 1.049 1.031h32.378v3H36.448a4.053 4.053 0 0 1-4.049-3.988"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.567 14.885a1.5 1.5 0 0 1 1.5-1.5h49.515a1.5 1.5 0 0 1 1.5 1.5v6.776a1.5 1.5 0 0 1-1.5 1.5H30.067a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v3.776h46.515v-3.776z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.313 10.065a1.5 1.5 0 0 1 1.5-1.5h36.023a1.5 1.5 0 0 1 1.5 1.5v4.76a1.5 1.5 0 0 1-1.5 1.5H36.813a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v1.76h33.023v-1.76z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M71.356 12.386a1.5 1.5 0 0 1 1.5-1.5h16.07a1.5 1.5 0 0 1 1.306 2.238l-12.78 22.63a1.5 1.5 0 0 1-2.613-1.476l11.517-20.392h-13.5a1.5 1.5 0 0 1-1.5-1.5"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m78.373 22.06-3.842 62.375-2.995-.184 3.843-62.376zM44.903 21.169a1.5 1.5 0 0 1 1.5 1.5v12.346a1.5 1.5 0 1 1-3 0V22.67a1.5 1.5 0 0 1 1.5-1.5M64.747 21.238a1.5 1.5 0 0 1 1.5 1.5v12.347a1.5 1.5 0 0 1-3 0V22.738a1.5 1.5 0 0 1 1.5-1.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramContainers01;

import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import { screenSizeIsSmall } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiContent from "@@/integrations/optimizly/Content";
import EpiProperty from "@@/integrations/optimizly/Property";
import { useEffect, useState } from "react";
import Scroller, { ScrollerItem } from "../Scroller";
import TestimonialItem, { TestimonialData } from "./item";
import styles from "./styles.module.scss";
import { TokenValue as bps } from "../../design-tokens/breakpoints";

export type TestimonialEditableProperties = {
  header?: string;
  showAllText: string;
  showLessText: string;
};

export type TestimonialOwnProps = {
  testimonials: TestimonialData[];
  sliderDesktop?: boolean;
  sliderMobile?: boolean;
};
type TestimonialProperties = TestimonialEditableProperties &
  TestimonialOwnProps;

const Testimonial = ({
  header,
  testimonials,
  sliderDesktop,
  sliderMobile,
  showAllText,
  showLessText,
}: TestimonialProperties) => {
  const multi = testimonials.length > 1;
  const [slider, setSlider] = useState<boolean>(false);

  useEffect(() => {
    const checkIfSlider = () => {
      if (
        (sliderMobile && window.innerWidth < bps.Large) ||
        (sliderDesktop && window.innerWidth >= bps.Large)
      ) {
        setSlider(true);
      } else setSlider(false);
    };
    checkIfSlider();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIfSlider);

      return () => {
        window.removeEventListener("resize", checkIfSlider);
      };
    }
  }, []);

  const renderContent = () => (
    <>
      <EpiProperty<TestimonialEditableProperties> name="header">
        {header && (
          <Box
            className={styles.header_wrapper}
            justifyContent="center"
            mb={multi ? 3 : [10, 12, 14]}
            px={[5, 10, 10]}
          >
            <Typhography textAlign="center" variant="heading1" asElement="h2">
              {header}
            </Typhography>
          </Box>
        )}
      </EpiProperty>
      {!slider && (
        <EpiContent>
          <Box
            gap={10}
            justifyContent="center"
            flexWrap="wrap"
            flexDirection="row"
            width="full"
          >
            {testimonials.map(
              (
                {
                  circularMask,
                  image,
                  header: itemHeader,
                  body,
                  buttons,
                  backgroundColor,
                },
                i
              ) => (
                <div
                  className={multi ? styles.multi_wrapper : styles.full_wrapper}
                  key={i}
                >
                  <TestimonialItem
                    circularMask={circularMask}
                    image={image}
                    header={itemHeader}
                    body={body}
                    multi={multi}
                    backgroundColor={backgroundColor}
                    buttons={buttons}
                    showAllText={showAllText}
                    showLessText={showLessText}
                  />
                </div>
              )
            )}
          </Box>
        </EpiContent>
      )}
      {slider && (
        <EpiContent>
          <Container pageGutterY={0}>
            <Scroller scollerClassName={styles.scroller_wrapper}>
              {testimonials.map(
                (
                  {
                    circularMask,
                    image,
                    header: itemHeader,
                    body,
                    buttons,
                    backgroundColor,
                  },
                  i
                ) => (
                  <ScrollerItem className={styles.scroller_item} key={i}>
                    <TestimonialItem
                      circularMask={circularMask}
                      image={image}
                      header={itemHeader}
                      body={body}
                      multi={multi}
                      backgroundColor={backgroundColor}
                      buttons={buttons}
                      showAllText={showAllText}
                      showLessText={showLessText}
                    />
                  </ScrollerItem>
                )
              )}
            </Scroller>
          </Container>
        </EpiContent>
      )}
    </>
  );

  if (multi) {
    return (
      <Container size="xlarge" pageGutterX={0}>
        {renderContent()}
      </Container>
    );
  }
  return (
    <Box width="full" py={14}>
      {renderContent()}
    </Box>
  );
};

export type ProvidedTestimonialProps =
  OptimizelyBlockProps<TestimonialEditableProperties> & TestimonialProperties;

const ProvidedTestimonial = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedTestimonialProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <Testimonial {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedTestimonial;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgShareIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 18 21"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.597 4.5H5.903c-.775 0-1.403.628-1.403 1.403v13.606c0 .775.628 1.403 1.403 1.403h10.694c.775 0 1.403-.628 1.403-1.403V5.903c0-.775-.628-1.403-1.403-1.403" />
    <path d="M16.597 4.5H5.903c-.775 0-1.403.628-1.403 1.403v13.606c0 .775.628 1.403 1.403 1.403h10.694c.775 0 1.403-.628 1.403-1.403V5.903c0-.775-.628-1.403-1.403-1.403" />
    <path d="M3.176 4.58A1.403 1.403 0 0 1 4.58 3.175H13.5V1.403A1.403 1.403 0 0 0 12.097 0H1.403A1.403 1.403 0 0 0 0 1.403v13.606a1.403 1.403 0 0 0 1.403 1.403h1.773z" />
  </svg>
);
export default SvgShareIcon;

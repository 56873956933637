import Box from "@@/elements/Box";
import Typography from "@@/elements/Typography";
import styles from "./index.module.scss";

type CategoriesProps = {
  categories: string[];
};
const Categories = ({ categories }:CategoriesProps) => (
  <Box flexWrap="wrap" p={0} component="ul">
    {categories.map((category, index) => (
      <Box br={1} className={styles.category} component="li" key={index} backgroundColor="ComplementaryBeigeTint04"><Typography variant="information" asElement="span">{category}</Typography></Box>
    ))}
  </Box>
);

export default Categories;

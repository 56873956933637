import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import cn from "classnames";
import { useEffect, useRef } from "react";
import { Item, SelectorVariant } from "../types";
import styles from "./styles.module.scss";

type Props = {
  items: Item[];
  open: boolean;
  variant: SelectorVariant;
  close: () => void;
  id: string;
};

const LanguageSelectorDropdown = ({
  items, open, variant, close, id,
} : Props) => {
  const ref = useRef(null);

  const classNames = cn(styles.wrapper_inner, {
    [styles.wrapper_inner__header]: variant === "header",
    [styles.wrapper_inner__footer]: variant === "footer",
    [styles.wrapper_inner__open]: open,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      const button = document.getElementById(id);
      if (button.contains(event.target)) return;
      if (ref.current && !ref.current.contains(event.target)) {
        close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <Box className={styles.wrapper} ref={ref}>
      <Box className={classNames}>
        <Box flexDirection="column" className={styles.list} component="ul">
          {items.map(({ active, link }) => {
            if (active) {
              return (
                <li aria-current="page">
                  <Typhography variant="toolbarMenu" color="ComplementaryCharcoalTint04">
                    {link.text}
                  </Typhography>
                </li>
              );
            }
            return (
              <li>
                <a href={link.href} target={link.target} title={link.title}>
                  <Typhography variant="toolbarMenu" color="PrimaryGrey">
                    {link.text}
                  </Typhography>
                </a>
              </li>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default LanguageSelectorDropdown;

import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import CardsLoader from "./cardsLoader";
import NewsCard, { NewsCardProps } from "./item";
import styles from "./styles.module.scss";

type Props = {
  items: NewsCardProps[];
  label: string;
  loading: boolean;
  noResultMessage: string;
  resetMessage: string;
  reset: () => void;
};

const NewsCards = ({
  items, label, loading, noResultMessage, resetMessage, reset,
}: Props) => {
  if (loading) return <CardsLoader />;
  if (!items.length) {
    return (
      <Box width="full">
        <Box justifyContent="center" px={5} flexDirection="column" width="full">
          {label && (
          <Typhography variant="label" asElement="div" mb={3}>
            {label}
          </Typhography>
          )}
          <Box
            backgroundColor="White"
            justifyContent="center"
            alignItems="center"
            p={15}
            flexDirection="column"
          >
            <Typhography variant="information">
              {noResultMessage}
            </Typhography>
            <Box mt={5}>
              <TextButtonIcon onClick={reset} label={resetMessage} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <>
      {(label) && <Typhography variant="label" asElement="div" mb={4} uppercase>{label}</Typhography>}
      <Box className={styles.cards_wrapper} gap={[4, 4, 10]}>
        {items.map((item, i) => (
          <NewsCard key={i} {...item} />
        ))}
      </Box>
    </>
  );
};

export default NewsCards;

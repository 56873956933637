import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramContainers05 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M32.988 23.426c-.819 0-1.463.678-1.463 1.463 0 .818.678 1.463 1.463 1.463h.493a1.5 1.5 0 0 1 1.5 1.5v14.913a1.5 1.5 0 0 1-1.5 1.5h-.493c-.819 0-1.463.679-1.463 1.463 0 .776.609 1.395 1.34 1.458l.322-.064a1.5 1.5 0 0 1 1.794 1.48l-.098 14.914a1.5 1.5 0 0 1-1.5 1.49h-.395c-.819 0-1.463.678-1.463 1.463 0 .818.678 1.463 1.463 1.463h.493a1.5 1.5 0 0 1 1.5 1.5v15.012a1.5 1.5 0 0 1-1.5 1.5h-.493c-.851 0-1.463.613-1.463 1.463 0 .819.678 1.463 1.463 1.463h46.024c.85 0 1.463-.612 1.463-1.463 0-.818-.678-1.463-1.463-1.463h-.493a1.5 1.5 0 0 1-1.5-1.5V69.432a1.5 1.5 0 0 1 1.5-1.5h.493c.819 0 1.463-.678 1.463-1.463 0-.818-.678-1.463-1.463-1.463h-.395a1.5 1.5 0 0 1-1.5-1.49l-.099-14.914a1.5 1.5 0 0 1 1.795-1.48l.322.064a1.473 1.473 0 0 0 1.34-1.458c0-.818-.678-1.463-1.463-1.463h-.493a1.5 1.5 0 0 1-1.5-1.5V27.852a1.5 1.5 0 0 1 1.5-1.5h.493c.819 0 1.463-.679 1.463-1.463 0-.819-.678-1.463-1.463-1.463zm0-3c-2.54 0-4.463 2.087-4.463 4.463 0 2.173 1.527 3.894 3.457 4.346v12.143c-2.025.465-3.457 2.3-3.457 4.35 0 2.17 1.522 3.889 3.447 4.344l-.08 12.069c-1.977.497-3.367 2.308-3.367 4.328 0 2.173 1.527 3.894 3.457 4.347v12.237c-2.006.444-3.457 2.194-3.457 4.354 0 2.54 2.087 4.463 4.463 4.463h46.024c2.508 0 4.463-1.955 4.463-4.463 0-2.173-1.527-3.894-3.456-4.346V70.819c2.024-.465 3.456-2.3 3.456-4.35 0-2.14-1.48-3.84-3.367-4.324l-.08-12.069c2.02-.468 3.447-2.3 3.447-4.348 0-2.173-1.527-3.894-3.456-4.346V29.239c2.024-.465 3.456-2.3 3.456-4.35 0-2.54-2.087-4.463-4.463-4.463z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M55.741 42.766a1 1 0 0 1 1.109.67l.016.055c.06.196.224.743.863 1.847.728 1.255 2.072 3.23 4.564 6.162l.002.003c3.155 3.733 3.923 8.005 2.656 11.47-1.272 3.48-4.533 5.959-9.002 6.174l-.048.001h-.099l-.049-.001c-4.385-.217-7.611-2.732-8.878-6.212-1.263-3.47-.528-7.745 2.637-11.438l.004-.004c2.542-2.933 3.907-4.905 4.631-6.155.362-.624.558-1.058.66-1.332.08-.212.094-.303.095-.303a1 1 0 0 1 .839-.937m.197 3.47-.06.105c-.807 1.392-2.256 3.468-4.849 6.46-2.759 3.22-3.258 6.747-2.275 9.45.978 2.686 3.471 4.71 7.074 4.897h.049c3.715-.187 6.223-2.2 7.196-4.862.98-2.683.465-6.213-2.305-9.491-2.545-2.994-3.966-5.068-4.77-6.454z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramContainers05;

import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import { Add, Close, Minus } from "@@/elements/Icons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";

import cn from "classnames";

import Typhography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import { PictureData } from "@@/models/pictureData";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";

interface LightboxModalProps {
  open?: boolean;
  close?: () => void;
  closeTitle: string;
  zoomOutTitle: string;
  zoomInTitle: string;
  closeLabel: string;
  image: PictureData;
}

const LightboxModal = ({
  open,
  close,
  closeLabel,
  image,
  zoomOutTitle,
  zoomInTitle,
  closeTitle,
}: LightboxModalProps) => {
  const [zoom, setZoom] = useState(1);
  const zoomRef = useRef<ReactZoomPanPinchRef | null>(null);
  const isSmallScreen = useIsBreakpointOrBelow(ScreenSize.SMALL);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close();
      }
    };
    if (open) {
      window.addEventListener("keydown", handleKeyPress);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [open, close]);

  const calculateZoomStep = useCallback(
    (currentScale: number, targetScale: number) => {
      const step = Math.log(targetScale / currentScale);

      return Math.abs(step);
    },
    [zoom],
  );

  const calculateZoomInTargetScale = useCallback(
    (currentZoom: number) => {
      let newZoom = Math.ceil((currentZoom + 0.5) * 2) / 2;
      if (newZoom > 3) {
        newZoom = 3;
      }
      return newZoom;
    },
    [zoom],
  );

  const calculateZoomOutTargetScale = useCallback(
    (currentZoom: number) => {
      let newZoom = Math.floor((currentZoom - 0.5) * 2) / 2;
      if (newZoom < 0.5) {
        newZoom = 0.5;
      }
      return newZoom;
    },
    [zoom],
  );

  return (
    <>
      {open && (
        <Box className={styles.container}>
          <Box className={styles.lightbox}>
            <TransformWrapper
              initialScale={0.5}
              minScale={0.5}
              maxScale={3}
              onTransformed={({ state: { scale } }) => {
                setZoom(scale);
              }}
              centerOnInit
              ref={zoomRef}
            >
              {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
                <>
                  <Box className={cn(styles.toolbar, styles.toolbar_top)}>
                    <TextButtonIcon
                      title={closeTitle}
                      onClick={close}
                      direction="right"
                      label={closeLabel}
                      dense
                    >
                      <Close />
                    </TextButtonIcon>
                  </Box>
                  <TransformComponent
                    wrapperStyle={{
                      maxWidth: "100%",
                      maxHeight: isSmallScreen
                        ? "calc(100dvh - 138px)"
                        : "calc(100svh - 200px)",
                      height: isSmallScreen ? "100%" : "auto",
                      minWidth: "70vw",
                      minHeight: "70vh",
                      cursor: "grab",
                    }}
                  >
                    <img
                      onLoad={() => centerView()}
                      src={image.imageUrl}
                      alt={image.imageAlt}
                    />
                  </TransformComponent>
                  <div className={cn(styles.toolbar, styles.toolbar_bottom)}>
                    <Box className={styles.zoom_text}>
                      <Typhography variant="link">
                        Zoom {Math.floor(zoom * 100)}%
                      </Typhography>
                    </Box>
                    <button
                      disabled={zoom === 0.5}
                      className={styles.zoom_btn}
                      title={zoomOutTitle}
                      onClick={() => {
                        const targetScale = calculateZoomOutTargetScale(zoom);
                        const step = calculateZoomStep(zoom, targetScale);
                        zoomOut(step);
                      }}
                    >
                      <Minus />
                    </button>
                    <button
                      disabled={zoom === 3}
                      className={cn(styles.zoom_btn, styles.zoom_btn_in)}
                      title={zoomInTitle}
                      onClick={() => {
                        const targetScale = calculateZoomInTargetScale(zoom);
                        const step = calculateZoomStep(zoom, targetScale);
                        zoomIn(step);
                      }}
                    >
                      <Add />
                    </button>
                  </div>
                </>
              )}
            </TransformWrapper>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LightboxModal;

import Box from "@@/elements/Box";
import cn from "classnames";
import {
  forwardRef,
} from "react";

import { DrawerProps } from "../headerProps";
import styles from "./index.module.scss";

const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  ({ children, open, className }: DrawerProps, ref) => {
    const classNames = cn(styles.drawer, className, {
      [styles.drawer_opened]: open,
    });

    return (

      <div ref={ref} className={classNames}>
        {children}
      </div>

    );
  },
);

export default Drawer;

import Box from "@@/elements/Box";
import { ChevronUp } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import EpiProperty from "@@/integrations/optimizly/Property";
import cn from "classnames";
import { ReactNode, useState } from "react";
import styles from "./accordionStyles.module.scss";

export type Colors =
  | "blue"
  | "dark-grey"
  | "light-grey"
  | "green"
  | "light-blue";
type AccordionOwnProps = {
  children?: ReactNode | Element;
  backgroundColor: Colors;
  noPaddingX?: boolean;
};
type AccordionEditableProps = {
  header: string;
};
type AccordionProps = AccordionOwnProps & AccordionEditableProps;

const Accordion = ({
  children,
  header,
  backgroundColor,
  noPaddingX,
}: AccordionProps) => {
  const [menuOpen, setMenuOpen] = useState(true);

  const colorMapper = (color: Colors) => {
    switch (color) {
    case "blue":
      return "PrimaryBlueTint01";
    case "dark-grey":
      return "PrimaryGrey";
    case "light-grey":
      return "PrimaryGreyTint01";
    case "green":
      return "PrimaryGreenTint01";
    default:
      return "PrimaryBlueTint01";
    }
  };

  /** Default is accordion is always open on load in desktop and closed in mobil/tablet
     * the "closed_on_load"-class overrides this and makes it always closed on load.
     */
  const contentClass = cn(styles.accordion_content, {
    [styles.accordion_content__open]: menuOpen,
    [styles.accordion_content__closed_on_load]: backgroundColor === "light-blue",
    [styles.accordion_content__closed_on_load__open]: backgroundColor === "light-blue" && !menuOpen,
  });
  const iconClass = cn(styles.accordion_icon, {
    [styles.accordion_icon__open]: menuOpen,
    [styles.accordion_icon__closed_on_load]: backgroundColor === "light-blue",
    [styles.accordion_icon__closed_on_load__open]: backgroundColor === "light-blue" && !menuOpen,
  });

  return (
    <Box
      mb={3}
      className={
        backgroundColor !== "light-blue"
          ? styles.accordion
          : styles.accordion_top_section
      }
      container
      flexDirection="column"
      backgroundColor={
        backgroundColor !== "light-blue" ? colorMapper(backgroundColor) : null
      }
    >
      <Box
        backgroundColor={
          backgroundColor === "light-blue" ? "PrimaryBlueTint03" : null
        }
        className={styles.accordion_heading}
        py={4}
        px={6}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setMenuOpen(!menuOpen);
          }
        }}
        onClick={() => setMenuOpen(!menuOpen)}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <EpiProperty<AccordionEditableProps> name="header">
          <Typhography
            className={styles.heading}
            variant="button"
            asElement="h4"
            textcolor={backgroundColor === "light-blue" ? "dark" : "light"}
          >
            {header}
          </Typhography>
        </EpiProperty>
        <Box
          customStyle={{ width: "15px" }}
          color={
            backgroundColor === "light-blue" ? "ComplementaryCharcoal" : "White"
          }
        >
          <ChevronUp className={iconClass} />
        </Box>
      </Box>
      <div className={contentClass}>
        <Box
          backgroundColor="White"
          py={6}
          px={noPaddingX ? 0 : 6}>
          {children}
        </Box>
      </div>
    </Box>
  );
};

/* export type ProvidedAccordionProps =
  & OptimizelyBlockProps<AccordionProps> &
  AccordionProps;

const ProvidedAccordion = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedAccordionProps) => (
  <OptimizelyBlock blockId={blockId} inEditMode={inEditMode} properties={properties}>
    {(optimizlyProperties) => (
      <Accordion {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedAccordion; */

export default Accordion;

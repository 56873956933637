import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPin = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 16"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M3.229 2.91A5.3 5.3 0 0 1 7 1.333c1.415 0 2.771.567 3.771 1.577s1.562 2.38 1.562 3.808c0 5.23-5.167 7.895-5.22 7.922a.25.25 0 0 1-.226 0c-.053-.027-5.22-2.697-5.22-7.922 0-1.428.562-2.798 1.562-3.808Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M5.935 5.073a1.917 1.917 0 1 1 2.13 3.187 1.917 1.917 0 0 1-2.13-3.187Z"
    />
  </svg>
);
export default SvgPin;

import ResponsiveValue from "@@/base/responsive-value";
import useBreakpoint, { ScreenSize } from "@@/hooks/device/use-breakpoint";
import { useCurrentResponsiveValue } from "@@/hooks/device/use-responsive-value";
import useElementSize from "@@/hooks/ui/use-element-size";
import { PaddingValue } from "@@/hooks/ui/use-spacing";
import cn from "classnames";
import React, {
  CSSProperties, useEffect, useRef, useState,
} from "react";
import ScrollerContainer from "./container";
import css from "./container/index.module.scss";
import ScrollerContext, {
  ScrollerContextValue, ScrollerItemVisibility,
} from "./context";
import SegmentedBar from "./segmented-bar";
import SolidBar from "./solid-bar";

const SEGMENT_MAX: Record<ScreenSize, number> = {
  [ScreenSize.SMALL]: 5,
  [ScreenSize.MEDIUM]: 18,
  [ScreenSize.LARGE]: 22,
  [ScreenSize.EXTRA_LARGE]: 30,
};

export type ScrollerProps = {
  scollerClassName?: string;
  contrast?: boolean;
  children: React.ReactNode | React.ReactNode[];
  fullWidthItems?: boolean;
  fullWidthItemsMobile?: boolean;
  startIndex?: number;
  fullPageStretch?: boolean;
  barPx?: PaddingValue;
  scrollToItemNr?: number,
  showBar?: ResponsiveValue<boolean>;
  autoChangeEvery?: number;
  onItemOverflowChanged?: (overflow: boolean) => void;
  onCurrentItemChanged?: (currentItem: number) => void;
};

const Scroller = ({
  contrast,
  scollerClassName,
  startIndex,
  children,
  fullWidthItems,
  fullWidthItemsMobile = true,
  fullPageStretch,
  barPx,
  scrollToItemNr,
  showBar = true,
  autoChangeEvery,
  onCurrentItemChanged,
  onItemOverflowChanged,
}: ScrollerProps) => {
  const itemCount = React.Children.toArray(children).length;
  const [items, setItems] = useState<ScrollerItemVisibility[]>([]);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [currentItem, setCurrentItem] = useState(startIndex || 0);
  const [targetItem, setTargetItem] = useState(startIndex || 0);
  const [hasItemOverflow, setHasItemOverflow] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const itemEls = useRef<HTMLLIElement[]>([]);
  const scrollingEl = useRef<HTMLOListElement>(null);
  const scrollerEl = useRef<HTMLDivElement>(null);
  const { clientWidth } = useElementSize(scrollerEl);
  const breakpoint = useBreakpoint() || ScreenSize.SMALL;
  const maxSegments = SEGMENT_MAX[breakpoint];
  const showBarInBreakpoint = useCurrentResponsiveValue(showBar);
  const [fullWidthItemsFinal, setFullWidthItemsFinal] = useState(false);

  useEffect(() => {
    setFullWidthItemsFinal((breakpoint === ScreenSize.SMALL && fullWidthItemsMobile) || fullWidthItems);
  }, [breakpoint]);

  const gotoNext = () => {
    const target = Math.min(items.length, currentItem + 1);
    setTargetItem(target);
  };

  const gottoPrev = () => {
    const target = Math.max(0, currentItem - 1);
    setTargetItem(target);
  };

  const context: ScrollerContextValue = {
    contrast,
    items,
    scrollLeft,
    currentItem,
    targetItem,
    hasItemOverflow,
    fullWidthItemsFinal,
    isAtStart,
    isAtEnd,
    fullPageStretch,
    autoChangeEvery,
    itemEls: itemEls.current,
    setItems,
    setIsAtStart,
    setIsAtEnd,
    setCurrentItem,
    setTargetItem,
    setScrollLeft,
    setHasItemOverflow,
    gotoNextItem: gotoNext,
    gotoPrevItem: gottoPrev,
  };

  useEffect(() => {
    onItemOverflowChanged?.(hasItemOverflow);
  }, [hasItemOverflow]);

  useEffect(() => {
    setItems((arr) => [...Array(itemCount).fill("hidden", 0).slice()]);
  }, [children]);

  const handleKeyDown = (e : React.KeyboardEvent) => {
    if (e.key === "ArrowRight") {
      gotoNext();
    }
    if (e.key === "ArrowLeft") {
      gottoPrev();
    }
  };

  return (
    <ScrollerContext.Provider value={context}>
      <div
        className={cn(css["hbd-scroller"], {
          [css["hbd-scroller--full-width-items"]]: fullWidthItemsFinal,
        })}
        ref={scrollerEl}
        style={{
          "--scroller-width": `${clientWidth || 0}px`,
        } as CSSProperties}
        onKeyDown={handleKeyDown}
        aria-label="horizontal-scroller"
        role="slider"
        aria-valuenow={currentItem}
        tabIndex={0}
      >
        {children && (
          <>
            <ScrollerContainer
              ref={scrollingEl}
              className={scollerClassName}
              onCurrentItemChanged={onCurrentItemChanged}
              scrollToItemNr={scrollToItemNr}
            >
              {children}
            </ScrollerContainer>
            {showBarInBreakpoint && hasItemOverflow && itemCount <= maxSegments && (
              <SegmentedBar barPx={barPx} />
            )}
            {showBarInBreakpoint && hasItemOverflow && itemCount > maxSegments && (
              <SolidBar scrollingEl={scrollingEl} barPx={barPx} />
            )}
          </>
        )}
      </div>
    </ScrollerContext.Provider>
  );
};

export default Scroller;
export { default as ScrollerItem } from "./item";

// eslint-disable-next-line max-len
export type ResponsiveArrayValue<T extends unknown> = [T] | [T, T] | [T, T, T];
export type ResponsiveValue<T extends unknown> = T | [(T | undefined), T] | [(T | undefined), (T | undefined), T];
export type FilledResponsiveValue<T extends unknown> = [T | undefined, T | undefined, T | undefined];

export function arrayFromResponiveValue<T>(value?: ResponsiveValue<T>) {
  const filled: FilledResponsiveValue<T> = [undefined, undefined, undefined];
  if (typeof value === "undefined") {
    return filled;
  }
  const values = Array.isArray(value) ? value : [value];
  for (let i = 0; i < 3; i += 1) {
    filled[i] = values[i];
  }
  return filled;
}

export default ResponsiveValue;

import Box from "@@/elements/Box";
import Typography from "@@/elements/Typography";
import cn from "classnames";
import { useEffect, useMemo, useRef } from "react";
import { TextButtonIconProps } from "../buttonProps";
import styles from "../buttonStyles.module.scss";

const TextButtonIcon = ({
  direction = "left",
  label,
  disabled,
  onClick,
  link,
  dense,
  download,
  email,
  color = "blue",
  children,
  title,
  textVariant,
}: TextButtonIconProps) => {
  const ref = useRef(null);
  const hasLabel = !!label?.length || !!link?.text?.length;

  const classNames = cn(styles.icon_container, {
    [styles[`icon_container_${direction}`]]: direction && hasLabel,
  });

  const checkIfMultiLine = () => {
    if (!ref.current) return false;
    const elHeight = ref.current.offsetHeight;
    const elLineHeight = parseInt(
      window.getComputedStyle(ref.current).lineHeight
    );
    const lines = elHeight / elLineHeight;
    return lines > 1;
  }

  const setButtonTextWidth = () => {
    if (ref.current && checkIfMultiLine()) {
      const textElement = ref.current.childNodes[1];
      textElement.style.maxWidth = "100%";
      const text = textElement.childNodes[0];
      const range = document.createRange();
      range.setStartBefore(text);
      range.setEndAfter(text);
      const textWidth = range.getBoundingClientRect().width;    
      textElement.style.maxWidth = textWidth + "px";
    }
  };

  useEffect(() => {
    if(!ref.current) return;
    
      const resizeObserver = new ResizeObserver(setButtonTextWidth);
      resizeObserver.observe(ref.current);
      return () => resizeObserver.disconnect();
  }, [ref, label]);

  useEffect(() => {
    // Sets the width of the texte to exactly the space it needs.
    // To make sure the icon has correct spacing from the text.
    if (children && hasLabel && ref.current) {
      
      
      
        setButtonTextWidth();
      // Needs to wait for fontface to load
      document.addEventListener("readystatechange", () => {
        
        if (document.readyState === "complete") {
          setButtonTextWidth();
        }
      });

      window.addEventListener("resize", setButtonTextWidth);
      return () => {
        window.removeEventListener("resize", setButtonTextWidth);
      };
    }
  }, [ref, label]);

  const renderIcon = () => {
    if (!children) {
      return <></>;
    }
    if (dense) {
      const marginRight = direction === "left" && hasLabel ? 3 : 0;
      const marginLeft = direction === "right" && hasLabel ? 3 : 0;
      return (
        <Box
          mr={marginRight}
          ml={marginLeft}
          className={cn(styles.icon__dense, {
            [styles.icon__dense_download]: download,
          })}
        >
          {children}
        </Box>
      );
    }
    return (
      <div className={classNames}>
        <div className={styles.icon_container_wrapper}>{children}</div>
      </div>
    );
  };

  if (link?.href) {
    return (
      <a
        ref={ref}
        href={email ? `mailto:${link.href}` : link.href}
        className={cn(styles.text_button, {
          [styles[`text_button_${direction}`]]: direction,
          [styles[`text_button--${color}`]]: color,
        })}
        title={link.title}
        target={link.target}
        download={download}
      >
        {renderIcon()}
        <Typography variant={textVariant || "button"}>{link.text}</Typography>
      </a>
    );
  }

  return (
    <button
      ref={ref}
      aria-label={title}
      title={title}
      type="button"
      className={cn(styles.text_button, {
        [styles[`text_button_${direction}`]]: direction,
        [styles[`text_button--${color}`]]: color,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {renderIcon()}
      <Typography variant={textVariant || "button"}>{label}</Typography>
    </button>
  );
};

export default TextButtonIcon;

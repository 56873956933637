import TertiaryButton from "@@/components/Button/TertiaryButton";
import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import { useIsBreakpointOrBelow, ScreenSize } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import { LinkData } from "@@/models/linkdata";
import Accordion, { Colors } from "..";
import styles from "../accordionStyles.module.scss";

type ContactOwnProps = {
  header: string;
  backgroundColor: Colors;
  contacts: Contact[];
};

type Contact = {
  name: string;
  description: string;
  phoneNumber: string;
  phoneNumberTitle: string;
  link: LinkData;
};

type ContactEditableProps = {
  header: string;
};

type ContactProps = ContactOwnProps & ContactEditableProps;

const AccordionContacts = ({
  header,
  backgroundColor,
  contacts,
}: ContactProps) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);
  return (
    <Accordion header={header} backgroundColor={backgroundColor}>
      {contacts.map(({
        name,
        description,
        phoneNumber,
        phoneNumberTitle,
        link,
      }, i) => (
        <Box key={i}>
          <Box flexDirection="column">
            <Typhography variant="heading5" asElement="h5">
              {name}
            </Typhography>
            <Typhography variant="label" uppercase mt={1}>
              {description}
            </Typhography>
          </Box>
          {phoneNumber && phoneNumberTitle && (
          <Box flexDirection="column" mt={4}>
            <Typhography variant="label" uppercase>
              {phoneNumberTitle}
            </Typhography>
            {!isSmall && (
            <Typhography variant="button" mt={2}>
              {phoneNumber}
            </Typhography>
            )}
            {isSmall && (
            <a href={`tel:${phoneNumber}`}>
              <Typhography color="ComplementaryCharcoal" variant="heading5" mt={2}>
                {phoneNumber}
              </Typhography>
            </a>
            )}

          </Box>
          )}
          {(link && link.href) && (
          <Box mt={4}>
            <TertiaryButton label={link.text} href={`mailto:${link.href}`} />
          </Box>
          )}
          {i < contacts.length - 1 && <div className={styles.accordion_divider} />}
        </Box>
      ))}
    </Accordion>
  );
};

export type ProvidedContactsProps =
  & OptimizelyBlockProps<ContactProps> &
  ContactProps;

const ProvidedAccordionContacts = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedContactsProps) => (
  <OptimizelyBlock blockId={blockId} inEditMode={inEditMode} properties={properties}>
    {(optimizlyProperties) => (
      <AccordionContacts {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedAccordionContacts;

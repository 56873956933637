import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramOffice09 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.191 18.864a1.5 1.5 0 0 1 1.5-1.5h62.223a1.5 1.5 0 0 1 1.5 1.5v74.272a1.5 1.5 0 0 1-2.605 1.014l-3.34-3.636-3.34 3.636a1.5 1.5 0 0 1-2.21 0l-3.339-3.636-3.34 3.636a1.5 1.5 0 0 1-2.209 0l-3.34-3.636-3.34 3.636a1.5 1.5 0 0 1-2.209 0l-3.34-3.636-3.34 3.636a1.5 1.5 0 0 1-2.209 0l-3.34-3.636-3.339 3.636a1.5 1.5 0 0 1-2.21 0l-3.34-3.636-3.339 3.636a1.5 1.5 0 0 1-2.21 0l-3.327-3.623-3.242 3.611a1.5 1.5 0 0 1-1.116.498h-.099a1.5 1.5 0 0 1-1.5-1.5zm3 1.5V89.33l1.829-2.036a1.5 1.5 0 0 1 2.22-.012l3.34 3.636 3.34-3.636a1.5 1.5 0 0 1 2.21 0l3.34 3.636 3.339-3.636a1.5 1.5 0 0 1 2.21 0l3.34 3.636 3.339-3.636a1.5 1.5 0 0 1 2.21 0l3.339 3.636 3.34-3.636a1.5 1.5 0 0 1 2.21 0l3.339 3.636 3.34-3.636a1.5 1.5 0 0 1 2.21 0l3.339 3.636 3.34-3.636a1.5 1.5 0 0 1 2.209 0l1.84 2.003v-68.92zm4.605 8.08a1.5 1.5 0 0 1 1.5-1.5h47.408a1.5 1.5 0 0 1 0 3H32.296a1.5 1.5 0 0 1-1.5-1.5m0 9.383a1.5 1.5 0 0 1 1.5-1.5h47.408a1.5 1.5 0 0 1 0 3H32.296a1.5 1.5 0 0 1-1.5-1.5m0 9.482a1.5 1.5 0 0 1 1.5-1.5h47.408a1.5 1.5 0 0 1 0 3H32.296a1.5 1.5 0 0 1-1.5-1.5m0 9.382a1.5 1.5 0 0 1 1.5-1.5h47.408a1.5 1.5 0 0 1 0 3H32.296a1.5 1.5 0 0 1-1.5-1.5m0 9.482a1.5 1.5 0 0 1 1.5-1.5h47.408a1.5 1.5 0 0 1 0 3H32.296a1.5 1.5 0 0 1-1.5-1.5m0 9.383a1.5 1.5 0 0 1 1.5-1.5h47.408a1.5 1.5 0 0 1 0 3H32.296a1.5 1.5 0 0 1-1.5-1.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramOffice09;

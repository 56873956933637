import RichText from "@@/components/RichText";
import Box from "@@/elements/Box";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import { useIsBreakpointOrBelow, ScreenSize } from "@@/hooks/device/use-breakpoint";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import classNames from "classnames";
import styles from "../index.module.scss";

export type LargeCompanyContactCardProps = {
  label: string;
  header: string;
  address?: string;
  locationLink?: LinkData;
  postalAddressLabel?: string;
  postalAddress?: string;
  phoneNumber?: string;
  emailLink?: LinkData;
  websiteLink?: LinkData;
  image: PictureData;
  openingHoursLabel?: string;
  openingHours?: string;
};

const LargeCompanyContactCard = ({
  label,
  header,
  address,
  locationLink,
  postalAddressLabel,
  postalAddress,
  phoneNumber,
  emailLink,
  websiteLink,
  image,
  openingHoursLabel,
  openingHours,
}: LargeCompanyContactCardProps) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);

  return (
    <Box
      className={classNames(styles.company_card, styles.company_card_large)}
      backgroundColor="ComplementaryBeigeTint04"
      width="full"
      height="full"
      br={1}
      color="PrimaryGrey"
      p={[0, 6, 14]}
    >
      <Box px={[10, 10, 0]} py={[10, 0, 0]}>
        <Box mb={6}>
          <Typography variant="link">{label}</Typography>
          <hr className={classNames(styles.card_line, styles.card_line_large)} />
          <Typography variant="heading2" asElement="h3">
            {header}
          </Typography>
        </Box>

        <Box mb={address || locationLink ? 6 : 0}>
          {address && <Typography variant="body">{address}</Typography>}
          {locationLink && (
          <a
            href={locationLink.href}
            title={locationLink.title}
            target={locationLink.target}
          >
            <Typography color="Info" variant="body">
              {locationLink.text}
            </Typography>
          </a>
          )}
        </Box>

        {openingHours && (
        <Box mb={6}>
          <Typography variant="link">{openingHoursLabel}</Typography>
          <RichText body={openingHours} />
        </Box>
        )}
        {postalAddress && (
        <Box mb={6}>
          <Typography variant="link">{postalAddressLabel}</Typography>
          <Typography variant="body">{postalAddress}</Typography>
        </Box>
        )}

        <Box>
          {phoneNumber && !isSmall && <Typography asElement="p" variant={isSmall ? "heading5" : "button"}>{phoneNumber}</Typography>}
          {phoneNumber && isSmall && <a className={styles.phonenumber} href={`tel:${phoneNumber}`}><Typography asElement="p" color="PrimaryGrey" variant={isSmall ? "heading5" : "button"}>{phoneNumber}</Typography></a>}
          {emailLink && (
          <a
            href={`mailto:${emailLink.href}`}
            title={emailLink.title}
            target={emailLink.target}
          >
            <Typography color="Info" variant="body">
              {emailLink.href}
            </Typography>
          </a>
          )}
          {websiteLink && (
          <a
            href={websiteLink.href}
            title={websiteLink.title}
            target={websiteLink.target}
          >
            <Typography color="Info" variant="body">
              {websiteLink.text}
            </Typography>
          </a>
          )}
        </Box>
      </Box>
      <Box className={styles.image_wrapper}>
        <Picture
          imageData={image}
          aspectRatio="4 / 3"
          sizes={[[350, 450, 550, 700, 1100], []]}
        />
      </Box>
    </Box>
  );
};
export default LargeCompanyContactCard;

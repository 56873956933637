import Box from "@@/elements/Box";
import { CloseThin, SearchIcon } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import styles from "../styles.module.scss";
import useFetchAutocomplete from "./useFetchAutocomplete";

export type Props = {
  input: string;
  setInput: Function;
  searchQuery: string;
  setSearchQuery: Function;
  resetInput: Function;
  disabled: boolean;
  placeholder: string;
  language: string;
};

const SearchInput = ({
  input, setInput, searchQuery, setSearchQuery, resetInput, disabled, placeholder, language,
} : Props) => {
  const [focus, setFocus] = useState(false);
  const ref = useRef(null);

  const onSubmit = () => {
    setSearchQuery(input);
  };

  const onKeyDownInput = (e) => {
    if (e.key === "Enter" && !disabled) onSubmit();
  };

  const onKeyDownAutocomplete = (e, value) => {
    if (e.key === "Enter") setSearchQuery(value);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
    setFocus(true);
  };

  const onAutocompleteClick = (value) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocus(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const { data, error } = useFetchAutocomplete(input, language, focus);

  const showResetButton = !!input.length;
  const hasAutocomplete = !!data?.length && !error && (input !== searchQuery) && focus;

  return (
    <Box ref={ref}>
      <Box
        className={styles.input_wrapper}
        alignItems="center">
        {/* TODO: Replace with TextInput */}
        <button
          type="submit"
          aria-label="search"
          className={cn(styles.button__search, styles.button)}
          onClick={() => onSubmit()}
          disabled={disabled}
        >
          <SearchIcon />
        </button>
        <input
          placeholder={placeholder}
          value={input}
          onChange={onChange}
          onKeyDown={onKeyDownInput}
          type="search"
          id="listing-page-search-input"
        />
        {showResetButton && (
          <button
            type="button"
            aria-label="reset"
            className={cn(styles.button__reset, styles.button)}
            onClick={() => resetInput()}
          >
            <CloseThin />
          </button>
        )}
      </Box>
      <Box className={styles.autocomplete_wrapper}>
        {hasAutocomplete && (
          <Box
            flexDirection="column"
            py={3}
            component="ul"
            role="listbox">
            { data.map((suggestion, i) => (
              <Box
                key={i}
                px={5}
                aria-label={`autocomplete-${suggestion}`}
                role="option"
                component="li"
                tabIndex={0}
                className={styles.autocomplete_item}
                onKeyDown={(e) => onKeyDownAutocomplete(e, suggestion)}
                onClick={() => onAutocompleteClick(suggestion)}
              >
                <Typhography 
                  variant="information"
                  color="PrimaryGrey"
                  py={[3, 3, 1]}>{suggestion}</Typhography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SearchInput;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramCommunication06 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 113 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M79.585 21.006a1.5 1.5 0 0 1 .598 1.198v54.899a1.5 1.5 0 0 1-1.916 1.441L30.12 64.648l-16.792-1.4a1.5 1.5 0 0 1-1.375-1.494v-24.19a1.5 1.5 0 0 1 1.374-1.495l16.792-1.41 48.148-13.896a1.5 1.5 0 0 1 1.318.243m-2.402 3.192L30.808 37.584a1.5 1.5 0 0 1-.29.053l-15.565 1.307v21.43l15.564 1.298q.149.012.291.054l46.375 13.384z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.8 35.955a1.5 1.5 0 0 1 1.5 1.5v24.397a1.5 1.5 0 0 1-3 0V37.455a1.5 1.5 0 0 1 1.5-1.5M5.424 43.708a1.5 1.5 0 0 1 1.5-1.5h5.828a1.5 1.5 0 0 1 0 3H8.424v8.902h4.495a1.5 1.5 0 0 1 0 3H6.924a1.5 1.5 0 0 1-1.5-1.5zM77.647 41.653a1.5 1.5 0 0 1 1.5-1.5 9.51 9.51 0 0 1 9.511 9.51 9.51 9.51 0 0 1-9.51 9.511 1.5 1.5 0 1 1 0-3 6.51 6.51 0 0 0 6.51-6.51 6.51 6.51 0 0 0-6.51-6.51 1.5 1.5 0 0 1-1.5-1.5M91.545 49.654a1.5 1.5 0 0 1 1.5-1.5h13.581a1.5 1.5 0 1 1 0 3H93.045a1.5 1.5 0 0 1-1.5-1.5M106.931 36a1.5 1.5 0 0 1-.795 1.967L93.631 43.27a1.5 1.5 0 1 1-1.172-2.762l12.505-5.304a1.5 1.5 0 0 1 1.967.795M91.664 56.832a1.5 1.5 0 0 1 1.967-.796l12.505 5.305a1.5 1.5 0 0 1-1.172 2.762l-12.505-5.305a1.5 1.5 0 0 1-.795-1.966"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.723 61.257a1.5 1.5 0 0 1 1.93.88l8.742 23.37a4.174 4.174 0 0 0 5.37 2.449 4.174 4.174 0 0 0 2.448-5.37l-6.796-18.174a1.5 1.5 0 1 1 2.81-1.05l6.796 18.174a7.174 7.174 0 0 1-4.209 9.23 7.174 7.174 0 0 1-9.229-4.208l-8.742-23.37a1.5 1.5 0 0 1 .88-1.93"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramCommunication06;

import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import CareerCard, { Props as CareerCardProps } from "./card";
import CareerCardsLoader from "./loader";
import styles from "./styles.module.scss";

type Props = {
  label: string;
  cards: CareerCardProps[];
  loading?: boolean;
  noResultMessage: string;
  resetMessage: string;
  reset: () => void,
};

const CareerCardsContainer = ({
  cards, label, loading, noResultMessage, resetMessage, reset,
} : Props) => {
  if (loading) {
    return (
      <CareerCardsLoader />
    );
  }
  if (!cards.length) {
    return (
      <Box width="full">
        <Box justifyContent="center" px={5} flexDirection="column" width="full">
          {label && (
          <Typhography variant="label" asElement="div" mb={3} uppercase>
            {label}
          </Typhography>
          )}
          <Box
            backgroundColor="White"
            justifyContent="center"
            alignItems="center"
            p={15}
            flexDirection="column"
          >
            <Typhography variant="information">
              {noResultMessage}
            </Typhography>
            <Box mt={5}>
              <TextButtonIcon onClick={reset} label={resetMessage} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box width="full">
      <Typhography variant="label" mb={4} uppercase>
        { label }
      </Typhography>
      <Box className={styles.cards_wrapper}>
        { cards.map((data, i) => <CareerCard key={i} {...data} />)}
      </Box>
    </Box>
  );
};

export default CareerCardsContainer;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramNumbers02 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 226.77 226.77"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M86.48 161.1v-12.09l36.27-43.94c1.73-2.25 2.99-4.31 3.77-6.18.78-1.86 1.17-4.01 1.17-6.44 0-4.25-1.17-7.69-3.51-10.33q-3.51-3.96-10.14-3.96c-1.82 0-3.55.26-5.2.78s-3.12 1.37-4.42 2.54-2.34 2.69-3.12 4.55-1.17 4.1-1.17 6.7H86.61c0-3.99.67-7.6 2.01-10.85s3.23-6.04 5.66-8.39c2.43-2.34 5.33-4.14 8.71-5.4s7.06-1.88 11.05-1.88 7.65.61 10.99 1.82c3.33 1.21 6.22 2.97 8.64 5.27 2.43 2.3 4.29 5.07 5.59 8.32s1.95 6.91 1.95 10.99-.74 7.71-2.21 10.66q-2.205 4.425-6.63 9.75l-29.51 36.01h38.35v12.09H86.48Z"
    />
  </svg>
);
export default SvgPictogramNumbers02;

import MapComponent from "@@/components/Map";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { ChevronUp } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import { LinkData } from "@@/models/linkdata";
import { useJsApiLoader } from "@react-google-maps/api";
import cn from "classnames";
import { useState } from "react";
import { Position } from "../BranchSearch/BranchList/card";
import TextButtonIcon from "../Button/TextButtonIcon";
import GoogleMapsConfig from "../Map/config";
import RichText from "../RichText";
import Categories from "./categories";
import styles from "./index.module.scss";

interface OpeningHours {
  day: string;
  hours: string;
  open?: boolean;
  closed?: boolean;
}

type ContactInfoProps = {
  header: string;
  primaryPhoneLabel?: string;
  primaryPhoneBody?: string;
  primaryPhone?: string;
  additionalPhoneLabel?: string;
  additionalPhoneBody?: string;
  additionalPhone?: string[];
  addressLabel?: string;
  address?: string;
  directions?: LinkData;
  openingHoursLabel?: string;
  openingHours?: OpeningHours[];
  specialHoursLabel?: string;
  specialHours?: OpeningHours[];
  closedLabel?: string;
  openLabel?: string;
  hideSpecialHoursText?: string;
  facilityTypeLabel?: string;
  facilityType?: string;
  materialsLabel?: string;
  materials?: string[];
  googleMapsAPIKey: string;
  googleMapsId: string;
  googleMapsAPILanguage: string;
} & Position;

const BranchContactInfo = ({
  header,
  primaryPhoneLabel,
  primaryPhoneBody,
  primaryPhone,
  additionalPhoneLabel,
  additionalPhoneBody,
  additionalPhone,
  addressLabel,
  address,
  directions,
  openingHoursLabel,
  openingHours,
  specialHoursLabel,
  specialHours,
  closedLabel,
  openLabel,
  hideSpecialHoursText,
  facilityTypeLabel,
  facilityType,
  materialsLabel,
  materials,
  position,
  googleMapsAPIKey,
  googleMapsId,
  googleMapsAPILanguage,
}:ContactInfoProps) => {
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);
  const [specialHoursOpen, setSpecialHoursOpen] = useState<boolean>(false);

  const googleMapsConfig = new GoogleMapsConfig();
  
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsAPIKey,
    libraries: googleMapsConfig.getLibraries(),
    language: googleMapsAPILanguage,
  });

  const toggleSpecialHours = () => {
    if (specialHoursOpen) {
      setSpecialHoursOpen(false);
    } else {
      setSpecialHoursOpen(true);
    }
  };

  const renderSpecialHours = (item: OpeningHours) => {
    if (item.closed) {
      return closedLabel;
    } else if (item.open) {
      return openLabel;
    } else return item.hours;
  };

  return (
    <Container>
      <Box
        width="full"
        flexDirection={["column-reverse", "row", "row"]}>
        <Box
          className={styles.contact_container}
          pr={[0, 10, 10]}>
          <Typography
            textcolor="dark"
            variant="heading3"
            mb={2}
            asElement="h2">
            {header}
          </Typography>
          {(primaryPhone || primaryPhoneBody) && (
            <Box>
              <Typography
                mb={2}
                variant="heading6"
                asElement="h3">
                {primaryPhoneLabel}
              </Typography>
              {primaryPhone && !isSmall && (
                <Typography
                  asElement="p"
                  variant="information">
                  {primaryPhone}
                </Typography>
              )}
              {primaryPhone && isSmall && (
                <a href={`tel:${primaryPhone}`}>
                  <Typography
                    asElement="p"
                    color="PrimaryGrey"
                    variant="information"
                  >
                    {primaryPhone}
                  </Typography>
                </a>
              )}
              <Box color="PrimaryGreyTint01">
                <RichText body={primaryPhoneBody} />
              </Box>
            </Box>
          )}
          {((additionalPhone && additionalPhone.length > 0) ||
            additionalPhoneBody) && (
            <Box>
              <Typography
                mb={2}
                variant="heading6"
                asElement="h3">
                {additionalPhoneLabel}
              </Typography>
              {additionalPhone &&
                additionalPhone.length > 0 &&
                additionalPhone.map((phone) => (
                  <>
                    {phone && !isSmall && (
                      <Typography
                        asElement="p"
                        variant="information">
                        {phone}
                      </Typography>
                    )}
                    {phone && isSmall && (
                      <a href={`tel:${phone}`}>
                        <Typography
                          asElement="p"
                          color="PrimaryGrey"
                          variant="information"
                        >
                          {phone}
                        </Typography>
                      </a>
                    )}
                  </>
                ))}
              {additionalPhoneBody && (
                <Box color="PrimaryGreyTint01">
                  <RichText body={additionalPhoneBody} />
                </Box>
              )}
            </Box>
          )}
          {address && (
            <Box>
              <Typography
                mb={2}
                variant="heading6"
                asElement="h3">
                {addressLabel}
              </Typography>
              <RichText body={address} />
              <a
                href={directions.href}
                target={directions.target}
                title={directions.title}
              >
                <Typography
                  className={styles.link}
                  color="ComplementaryBlue"
                  variant="information"
                >
                  {directions.text}
                </Typography>
              </a>
            </Box>
          )}
          {openingHours.length > 0 && (
            <Box
              p={6}
              br={1}
              my={2}
              backgroundColor="ComplementaryBeigeTint04">
              <Typography
                mb={2}
                variant="heading6"
                asElement="h3">
                {openingHoursLabel}
              </Typography>
              <Box>
                {openingHours.map((item, i) => (
                  <Box
                    key={i}
                    my={2}
                    flexDirection="column">
                    <Typography variant="information">{item.day}</Typography>
                    <Typography variant="information">{item.hours}</Typography>
                  </Box>
                ))}
              </Box>
              {(specialHours && !!specialHours.length) && (
                <TextButtonIcon
                  textVariant="link"
                  direction="right"
                  label={specialHoursLabel}
                  onClick={toggleSpecialHours}
                  dense
                >
                  <ChevronUp
                    width={12}
                    className={cn(styles.icon, {
                      [styles.icon_open]: specialHoursOpen,
                    })}
                  />
                </TextButtonIcon>
              )}
              {(specialHours && !!specialHours.length && specialHoursOpen) && (
                <Box>
                  {specialHours.map((item, i) => (
                    <Box
                      my={2}
                      key={i}
                      flexDirection="column">
                      <Typography variant="information">{item.day}</Typography>
                      <Typography variant="information">
                        {renderSpecialHours(item)}
                      </Typography>
                    </Box>
                  ))}
                  <TextButtonIcon
                    direction="right"
                    textVariant="link"
                    label={hideSpecialHoursText || specialHoursLabel}
                    onClick={toggleSpecialHours}
                    dense
                  >
                    <ChevronUp width={12} />
                  </TextButtonIcon>
                </Box>
              )}
            </Box>
          )}
          {facilityType && (
            <Box>
              <Typography
                mb={2}
                variant="heading6"
                asElement="h3">
                {facilityTypeLabel}
              </Typography>
              <RichText body={facilityType} />
            </Box>
          )}
          {!!materials.length && (
            <Box>
              <Typography
                mb={2}
                variant="heading6"
                asElement="h3">
                {materialsLabel}
              </Typography>
              <Categories categories={materials} />
            </Box>
          )}
        </Box>
        <Box
          mx={[0, 0, 10]}
          className={styles.map}>
          <MapComponent 
            locations={[{ position, id: "1" }]} 
            currentLocation={position} 
            googleMapsId={googleMapsId}
            isLoaded={isLoaded}
            error={!!loadError}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default BranchContactInfo;

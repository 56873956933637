import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import RelatedCard from "@@/components/Card/Related";
import ErrorAlert from "@@/components/Error/ErrorAlert";
import SkeletonCard from "@@/components/Loader/Skeleton/Card/skeletonCard";
import Scroller, { ScrollerItem } from "@@/components/Scroller";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { ArrowRight } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import axios from "axios";
import { useEffect, useState } from "react";
import { SearchResponseType } from "../latest-news";
import { BackgroundColor, colorMapper } from "../service-description";
import styles from "./index.module.scss";
import { TokenValue as bps } from "../../../design-tokens/breakpoints";

type SearchRequest = {
  pageSize: 2 | 4 | 6;
  language: string;
  page: number;
};
export type NewsModuleProps = {
  searchRequest: SearchRequest;
  header: string;
  link: LinkData;
  backgroundColor: BackgroundColor;
  errorMessage: string;
  sliderMobile?: boolean;
};

const NewsModule = ({
  searchRequest,
  header,
  link,
  backgroundColor,
  errorMessage = "We are sorry, there is a connection problem...",
  sliderMobile,
}: NewsModuleProps) => {
  const [responseData, setResponseData] = useState<SearchResponseType>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(null);
  const [slider, setSlider] = useState<boolean>(false);
  const skeletonCards = new Array(searchRequest.pageSize).fill([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.post(
          "/api/search/latestnews",
          searchRequest
        );
        setResponseData(data);
      } catch (errorData) {
        setError(errorData);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const checkIfSlider = () => {
      if (sliderMobile && window.innerWidth < bps.Large) {
        setSlider(true);
      } else setSlider(false);
    };
    checkIfSlider();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIfSlider);

      return () => {
        window.removeEventListener("resize", checkIfSlider);
      };
    }
  }, []);

  if (error) {
    return <ErrorAlert message={errorMessage} />;
  }

  return (
    <Container
      pageGutterX={0}
      size="full"
      backgroundColor={colorMapper(backgroundColor)}
      className={styles.container}
    >
      <Box pb={[10, 12, 14]} width="full" textAlign="center">
        <Typography asElement="h2" variant="heading1">
          {header}
        </Typography>
      </Box>
      {!slider && (
        <Box px={[5, 10, 10]} className={styles.grid}>
          {loading
            ? skeletonCards.map((c, i) => (
                <Box width="full" key={i}>
                  <SkeletonCard variant="newsmodule" />
                </Box>
              ))
            : null}

          {!loading
            ? responseData.items.map((card, index) => (
                <RelatedCard
                  key={index}
                  date={card.publishdate}
                  header={card.header}
                  categories={card.categories}
                  image={card.image}
                  link={card.link}
                  backgroundColor={backgroundColor}
                  newsmodule
                />
              ))
            : null}
        </Box>
      )}
      {slider && (
        <Box px={[5, 10, 10]} className={styles.scroller_container}>
          {loading && (
            <Scroller scollerClassName={styles.scroller}>
              {skeletonCards.map((c, i) => (
                <ScrollerItem key={i} className={styles.scroller_item}>
                  <SkeletonCard variant="latestnews" />
                </ScrollerItem>
              ))}
            </Scroller>
          )}
          {!loading && (
            <Scroller scollerClassName={styles.scroller}>
              {responseData.items.map((card, index) => (
                <ScrollerItem key={index} className={styles.scroller_item}>
                  <RelatedCard
                    key={index}
                    date={card.publishdate}
                    header={card.header}
                    categories={card.categories}
                    image={card.image}
                    link={card.link}
                    backgroundColor={backgroundColor}
                    newsmodule
                    slider
                  />
                </ScrollerItem>
              ))}
            </Scroller>
          )}
        </Box>
      )}

      <Box className={styles.button_wrapper} pt={14} justifyContent="flex-end">
        {!loading && (
          <TextButtonIcon label={link.text} link={link} direction="right">
            <ArrowRight />
          </TextButtonIcon>
        )}
      </Box>
    </Container>
  );
};

export default NewsModule;

import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import Box from "@@/elements/Box";
import { Download } from "@@/elements/Icons/icons";
import Picture from "@@/elements/Picture";
import Typhography from "@@/elements/Typography";
import { FC } from "react";
import { DownloadProps } from "../AccordionProps.model";
import styles from "../accordionStyles.module.scss";

const Downloads: FC<DownloadProps> = ({ downloads }) => (
  <>
    {downloads.map(({ image, text, link }, i) => (
      <Box key={i}>
        <Box flexDirection="row" gap={3}>
          <Box className={styles.accordion_downloads__column}>
            <Picture
              imageData={image}
              aspectRatio="3 / 2"
              sizes={[[70, 130, 180, 260], []]}
            />
          </Box>
          <Box justifyContent="space-between" flexDirection="column">
            <Typhography variant="link" asElement="h5">
              {text}
            </Typhography>
            <Box className={styles.accordion_downloads__link_wrapper}>
              <TextButtonIcon label={link.text} link={link} dense download>
                <Download />
              </TextButtonIcon>
            </Box>
          </Box>
        </Box>
        {i < downloads.length - 1 && (
          <div className={styles.accordion_divider} />
        )}
      </Box>
    ))}
  </>
);

export default Downloads;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgChevronRight = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M4.293 15.707a1 1 0 0 1 0-1.414L9.586 8 4.293 1.707A1 1 0 0 1 5.707.293l6 7a1 1 0 0 1 0 1.414l-6 7a1 1 0 0 1-1.414 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronRight;

import cn from "classnames";
import ResponsiveValue, { arrayFromResponiveValue } from "../../../base/responsive-value";
import css from "./use-size.module.scss";

export type Size = ResponsiveValue<"auto" | "full" | number>;

export type SizesProps = {
  width?: Size;
  height?: Size;
  size?: Size;
};

type SizeKey = keyof SizesProps;

const propKeys = [
  ["width", "w"],
  ["height", "h"],
  ["size", "s"],
] as unknown as [[SizeKey, string]];

const breakpointKeys = ["sm", "md", "lg"];

const sizeClasses = (props: SizesProps) => cn(
  propKeys.map(([propName]) => {
    const prop = props[propName];
    if (!prop) {
      return undefined;
    }

    const values = arrayFromResponiveValue(prop);
    return breakpointKeys.reduce((classNames, bp, index) => {
      const value = values[index];
      if (typeof (value) !== "undefined") {
        let className = `ui-size--${bp}-${propName}`;
        if (typeof (value) === "string") {
          className += `-${value}`;
        }
        classNames.push((css as any)[className]);
      }
      return classNames;
    }, [] as string[]);
  }),
);

const sizeStyles = (props: SizesProps) => Object.fromEntries(
  propKeys.reduce((entries, [propName, propKey]) => {
    const prop = props[propName];
    if (typeof (prop) !== "undefined") {
      const values = arrayFromResponiveValue(prop);
      for (let i = 0; i < breakpointKeys.length; i += 1) {
        if (typeof (values[i]) === "number") {
          entries.push([`--ui-${breakpointKeys[i]}-${propKey}`, values[i]]);
        }
      }
    }
    return entries;
  }, [] as Array<[PropertyKey, any]>),
);

const useSize = (props: SizesProps) => ({
  className: sizeClasses(props),
  style: sizeStyles(props),
});

export default useSize;

import RichText from "@@/components/RichText";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../Button/PrimaryButton";
import styles from "./styles.module.scss";

type HighlightSectionColors =
  | "grey"
  | "green"
  | "blue"
  | "orange"
  | "beige"
  | "white";

type HighlightSectionOwnProps = {
  backgroundColor?: HighlightSectionColors;
  link?: LinkData;
  alignment: "left" | "center";
};

type HighlightSectionEditableProps = {
  header: string;
  body: string;
};

type HighlightSectionProps = HighlightSectionOwnProps &
  HighlightSectionEditableProps;

const HighlightSection = ({
  header,
  body,
  backgroundColor = "white",
  link,
  alignment = "left",
}: HighlightSectionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    const setBackgroundBleedHeight = () =>
      setHeight(`${ref.current?.clientHeight}px`);
    setBackgroundBleedHeight();
    window.addEventListener("resize", setBackgroundBleedHeight);

    return () => window.removeEventListener("resize", setBackgroundBleedHeight);
  }, []);

  const translateColor = (c: string) => {
    switch (c) {
      case "grey":
        return "PrimaryGreyTint03";
      case "green":
        return "PrimaryGreenTint03";
      case "blue":
        return "PrimaryBlueTint03";
      case "orange":
        return "ComplementaryOrangeTint04";
      case "beige":
        return "ComplementaryBeigeTint03";
      case "white":
        return "White";
      default:
        return "White";
    }
  };

  return (
    <Container
      className="highlight-section"
      backgroundColor={translateColor(backgroundColor)}
      ref={ref}
    >
      <Box
        className={styles.background_bleed}
        backgroundColor={translateColor(backgroundColor)}
        customStyle={{ height }}
      />
      <Box
        width="full"
        flexDirection="column"
        alignItems={alignment === "center" ? "center" : "flex-start"}
      >
        <Box className={styles.content_wrapper}>
          <EpiProperty<HighlightSectionEditableProps> name="header">
            <Typhography variant="heading5" mb={[6, 8, 8]} asElement="h2">
              {header}
            </Typhography>
          </EpiProperty>
          <Box>
            <EpiProperty<HighlightSectionEditableProps> name="body">
              <RichText body={body} />
            </EpiProperty>
          </Box>

          {link && (
            <Box mt={8} width="full" className={styles.link}>
              <PrimaryButton
                title={link.title}
                href={link.href}
                target={link.target}
                label={link.text}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export type ProvidedHighlightSectionProps =
  OptimizelyBlockProps<HighlightSectionProps> & HighlightSectionProps;

const ProvidedHighlightSection = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedHighlightSectionProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <HighlightSection {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedHighlightSection;

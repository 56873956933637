import React, { SetStateAction } from "react";

export type ScrollerItemVisibility = "hidden" | "fully" | "partial";

export type ScrollerContextValue = {
  contrast?: boolean;
  items: ScrollerItemVisibility[];
  scrollLeft: number;
  currentItem: number;
  targetItem: number;
  hasItemOverflow: boolean;
  isAtStart: boolean;
  isAtEnd: boolean;
  fullWidthItemsFinal?: boolean;
  fullPageStretch?: boolean;
  itemEls: HTMLLIElement[];
  autoChangeEvery?: number;
  setItems: (value: SetStateAction<ScrollerItemVisibility[]>) => void;
  setScrollLeft: (value: SetStateAction<number>) => void;
  setIsAtStart: (value: SetStateAction<boolean>) => void;
  setIsAtEnd: (value: SetStateAction<boolean>) => void;
  setHasItemOverflow: (value: SetStateAction<boolean>) => void;
  setCurrentItem: (index: SetStateAction<number>) => void;
  setTargetItem: (index: SetStateAction<number>) => void;
  gotoNextItem: () => void;
  gotoPrevItem: () => void;
};

// Note: typescript requires a "valid" context as defaultValue.
const ScrollerContext = React.createContext<ScrollerContextValue>({
  items: [],
  scrollLeft: 0,
  currentItem: 0,
  targetItem: 0,
  hasItemOverflow: false,
  fullWidthItemsFinal: false,
  isAtStart: true,
  isAtEnd: false,
  itemEls: [],
  setScrollLeft: () => {},
  setIsAtStart: () => {},
  setIsAtEnd: () => {},
  setCurrentItem: () => {},
  setTargetItem: () => {},
  gotoNextItem: () => {},
  gotoPrevItem: () => {},
  setHasItemOverflow: () => {},
  setItems: () => {},
});

export default ScrollerContext;

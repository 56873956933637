import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgArrowRightOutlined = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle
      cx={20}
      cy={20}
      r={18}
      stroke="currentColor"
      strokeWidth={2}
      transform="rotate(-180 20 20)"
    />
    <path
      fill="currentColor"
      d="m28.106 21.065-5.793 5.551a1.44 1.44 0 0 1-1.958 0 1.3 1.3 0 0 1 0-1.875l3.42-3.297H11.123c-.77 0-1.384-.588-1.384-1.327s.613-1.326 1.384-1.326h12.654l-3.42-3.278a1.3 1.3 0 0 1 0-1.876 1.425 1.425 0 0 1 1.976 0l5.774 5.533c.257.246.415.587.415.947s-.139.701-.415.948"
    />
  </svg>
);
export default SvgArrowRightOutlined;

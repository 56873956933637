import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramEnergy06 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.926 23.607a1.5 1.5 0 0 1 1.5-1.5h37.139a1.5 1.5 0 0 1 1.5 1.5v72.135a1.5 1.5 0 0 1-1.5 1.5h-37.14a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v69.135h34.139V25.107z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M45.408 17.73a2.98 2.98 0 0 1 2.981-2.982H63.61a2.98 2.98 0 0 1 2.982 2.982v5.028h-3v-5.01H48.408v5.01h-3z"
      clipRule="evenodd"
    />
    <path fill="currentColor" d="m60.084 39.293-14.49 23.024H55.67z" />
    <path fill="currentColor" d="m51.916 80.057 14.49-23.025H56.33z" />
  </svg>
);
export default SvgPictogramEnergy06;

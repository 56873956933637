import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramSafety02 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M46.92 25.365a1.5 1.5 0 0 1 1.5-1.5h15.16a1.5 1.5 0 0 1 1.5 1.5v23.627a1.5 1.5 0 0 1-3 0V26.865H49.92v22.157a1.5 1.5 0 1 1-3 0zm-5.331 1.332a1.5 1.5 0 0 1 .744 1.296V41.12a1.5 1.5 0 0 1-3 0v-10.5c-4 2.474-7.412 5.233-9.936 8.583-2.958 3.927-4.754 8.743-4.754 15.044v5.255a1.5 1.5 0 0 1-.808 1.33l-4.24 2.207v4.345a1.5 1.5 0 0 1-3 0v-5.255a1.5 1.5 0 0 1 .808-1.33l4.24-2.208v-4.344c0-6.935 1.997-12.387 5.358-16.849 3.335-4.428 7.958-7.804 13.093-10.71a1.5 1.5 0 0 1 1.495.009m28.82.006a1.5 1.5 0 0 1 1.5.002c5.004 2.903 9.62 6.276 12.984 10.698 3.388 4.455 5.454 9.908 5.454 16.854V58.6l4.24 2.207a1.5 1.5 0 0 1 .808 1.33v5.256a1.5 1.5 0 1 1-3 0v-4.345l-4.24-2.207a1.5 1.5 0 0 1-.808-1.33v-5.255c0-6.29-1.85-11.105-4.842-15.038-2.54-3.34-5.94-6.09-9.848-8.558V41.13a1.5 1.5 0 0 1-3 0V28.002a1.5 1.5 0 0 1 .751-1.3M14.066 72.648a1.5 1.5 0 0 1 1.5-1.5h80.856a1.5 1.5 0 0 1 0 3H15.567a1.5 1.5 0 0 1-1.5-1.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramSafety02;

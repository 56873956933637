import RichText from "@@/components/RichText";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import styles from "./index.module.scss";

type RichTextContainerProps = {
  body?: string;
  alignment: "left" | "center";
};

export const RichtextContainer = ({
  body,
  alignment = "left",
}: RichTextContainerProps) => (
  <Container className="top-section-rte">
    <Box
      width="full"
      justifyContent={alignment === "center" ? "center" : "flex-start"}
    >
      <EpiProperty<RichTextContainerProps> name="body">
        <Box className={styles.content_wrapper}>
          <RichText body={body} />
        </Box>
      </EpiProperty>
    </Box>
  </Container>
);

export type ProvidedRichtextContainer =
  OptimizelyBlockProps<RichTextContainerProps> & RichTextContainerProps;

const ProvidedRichtext = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedRichtextContainer) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <RichtextContainer {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedRichtext;

import TextInput from "@@/components/TextInput";
import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import helpers from "@@/helpers/helpers";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { scroller } from "react-scroll";
import styles from "./styles.module.scss";

type PredictionType = "BRANCH" | "LOCALITY" | "PLACE";

type PredictionResult = {
  type: PredictionType;
  id?: string;
  description: string;
};

const BranchSearchInput = ({
  searchInput,
  setSearchInput,
  locations,
  setSelectedLocations,
  setSelectedLocality,
  setCurrentLocation,
  setMarkedLocation,
  setError,
  label,
  placeholder,
  branchHitLabel,
  localityHitLabel,
  placeHitLabel,
  googleMapsAPICountryRestriction,
  googleMapsAPILanguage,
  isLoaded,
  error,
}) => {
  const [autoCompletePredictions, setAutoCompletePredictions] = useState<
    PredictionResult[]
  >([]);
  const [autoCompleteService, setAutoCompleteService] = useState(null);
  const [openAutocomplete, setOpenAutocomplete] = useState(true);
  const [activeAutocomplete, setActiveAutocomplete] = useState(true);
  const [fixed, setFixed] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    if (isLoaded) {
      setAutoCompleteService(new google.maps.places.AutocompleteService());
    } else if (error) {
      setError("API_ERROR");
    }
  }, [isLoaded, error]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && ref.current.contains(event.target)) return;
      setOpenAutocomplete(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const getMatchingLocalities = (input) => {
    const uniqueMatches = [];
    locations.forEach((location) => {
      if (
        location.locality.toLowerCase().startsWith(input.toLowerCase()) &&
        !uniqueMatches.find((match) => match.description === location.locality)
      ) {
        const uniqueMatch: PredictionResult = {
          description: location.locality,
          type: "LOCALITY",
        };
        uniqueMatches.push(uniqueMatch);
      }
    });
    return uniqueMatches;
  };

  const getMatchingBranches = (input) => {
    const matches = locations.filter(
      ({ name, locality, address, postalCode }) => {
        const nameKeys = name ? name.split(" ") : [];
        const localityKeys = locality ? locality.split(" ") : [];
        const addressKeys = address ? address.split(" ") : [];
        const postalCodeKeys = postalCode ? postalCode.split(" ").join("") : [];
        const locationValues = nameKeys.concat(
          localityKeys,
          addressKeys,
          postalCodeKeys,
        );
        const searchKeys = input.split(" ").filter((key) => key !== "");

        const isMatch = searchKeys.every((key) =>
          locationValues.some((value) =>
            value.toLowerCase().startsWith(key.toLowerCase()),
          ),
        );

        return isMatch;
      },
    );
    const matchingBranches = matches.map(
      (match): PredictionResult => ({
        id: match.id,
        description: match.name,
        type: "BRANCH",
      }),
    );
    return matchingBranches;
  };

  const getAutoCompleteValues = async () => {
    if (isLoaded) {
      const matchingBranches = getMatchingBranches(searchInput);
      const matchingLocalities = getMatchingLocalities(searchInput);
      let predictedLocations = [];
      await autoCompleteService.getPlacePredictions(
        {
          input: searchInput,
          types: ["geocode"],
          language: googleMapsAPILanguage,
          componentRestrictions: { country: googleMapsAPICountryRestriction },
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const matchingPredictions = predictions.map(
              (prediction): PredictionResult => ({
                id: prediction.place_id,
                description: prediction.description,
                type: "PLACE",
              }),
            );
            predictedLocations = matchingPredictions;
          } else if (google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            // Do nothing
          } else {
            setError(status);
          }
        },
      );

      const matchingLocations = matchingBranches.concat(
        matchingLocalities,
        predictedLocations,
      );
      setAutoCompletePredictions(matchingLocations);
    }
  };

  const onClickAutoComplete = ({ id, description, type }: PredictionResult) => {
    setOpenAutocomplete(false);
    setActiveAutocomplete(false);
    setMarkedLocation(null);
    setSearchInput(description);

    if (type === "BRANCH") {
      setSelectedLocations([id]);
      const selectedLocation = locations.find((location) => location.id === id);
      setMarkedLocation(selectedLocation);
    } else if (type === "LOCALITY") setSelectedLocality(description);
    else if (type === "PLACE") {
      new google.maps.places.PlacesService(
        document.createElement("div"),
      ).getDetails({ placeId: id }, (result, placestatus) => {
        if (placestatus === google.maps.places.PlacesServiceStatus.OK) {
          setCurrentLocation(result.geometry.location);
        } else {
          setError(placestatus);
        }
      });
    }
    setAutoCompletePredictions(null);
  };

  const onKeyDown = (e, item) => {
    if (e.key === "Enter") {
      onClickAutoComplete(item);
    }
  };

  const onSubmit = () => {
    if (searchInput.length === 0) return;
    if (
      (activeAutocomplete && autoCompletePredictions === null) ||
      autoCompletePredictions === null
    )
      return;
    if (!autoCompletePredictions || !autoCompletePredictions.length)
      setError(google.maps.places.PlacesServiceStatus.ZERO_RESULTS);
    else {
      onClickAutoComplete(autoCompletePredictions[0]);
    }
  };

  const searchInputId = "branch-search-input";

  const onClickInput = () => {
    setOpenAutocomplete(true);
    if (helpers.iOS()) {
      document.getElementById(searchInputId).focus();
      setTimeout(() => {
        scroller.scrollTo("branch-search", { duration: 500, smooth: true, offset: -100 });
      }, 400);
    } else {
      if (fixed && ref.current) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        let timer = null;
        const focusInput = () => {
          if (timer !== null) {
            clearTimeout(timer);
          }
          timer = setTimeout(function () {
            document.getElementById(searchInputId).focus();
            window.removeEventListener("scroll", focusInput);
          }, 150);
        };
        window.addEventListener("scroll", focusInput, false);
      }
    }
  };

  useEffect(() => {
    if (searchInput && searchInput.length && activeAutocomplete) {
      setOpenAutocomplete(true);
      getAutoCompleteValues();
    } else if (activeAutocomplete) {
      setAutoCompletePredictions(null);
      setSelectedLocations(null);
    }
    setActiveAutocomplete(true);
  }, [searchInput]);

  useEffect(() => {
    const setFixedSearchInput = () => {
      const isVisible =
        ref.current.getBoundingClientRect().top - window.innerHeight + 126 < 0;
      setFixed(!isVisible);
    };
    setFixedSearchInput();
    window.addEventListener("scroll", setFixedSearchInput);
    return () => window.removeEventListener("scroll", setFixedSearchInput);
  }, [ref]);

  return (
    <Box
      ref={ref}
      id="branch-search"
      customStyle={{ scrollMarginTop: "50px" }}>
      <Box className={cn({ [styles.input__fixed]: fixed })}>
        <Typhography
          variant="label"
          mb={[2, 2, 3]}
          uppercase>
          {label}
        </Typhography>
        <button
          aria-label="search"
          className={cn(styles.scroll_button, {
            [styles.scroll_button__hidden]: !fixed,
          })}
          onClick={() => onClickInput()}
        />
        <TextInput
          onSubmit={onSubmit}
          input={searchInput}
          setInput={setSearchInput}
          placeholder={placeholder}
          id={searchInputId}
        />
        {!!autoCompletePredictions?.length && openAutocomplete && (
          <Box className={styles.autocomplete}>
            <Box
              className={styles.autocomplete__list}
              component="ul">
              {autoCompletePredictions.map(({ id, description, type }, i) => (
                <Box
                  key={i}
                  justifyContent="space-between"
                  component="li"
                  aria-label={`autocomplete-${description}`}
                  role="option"
                  tabIndex={0}
                  className={styles.autocomplete__list__option}
                  onClick={() => onClickAutoComplete({ id, description, type })}
                  onKeyDown={(e) => onKeyDown(e, { id, description, type })}
                >
                  <Typhography variant="information">{description}</Typhography>
                  <Typhography
                    variant="information"
                    color="PrimaryGreyTint01"
                    className={styles.autocomplete__list__type}
                  >
                    {type === "BRANCH" && branchHitLabel}
                    {type === "LOCALITY" && localityHitLabel}
                    {type === "PLACE" && placeHitLabel}
                  </Typhography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BranchSearchInput;

import Box from "@@/elements/Box";
import { ArrowRight } from "@@/elements/Icons/icons";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import { LinkData } from "@@/models/linkdata";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import TextButtonIcon from "../Button/TextButtonIcon";
import cardStyles from "../Card/cardStyles.module.scss";
import styles from "./index.module.scss";

export type LinkCardProps = {
  header: string;
  image: PictureData;
  link?: LinkData;
};

const SidebarLinkCard = ({ header, image, link }: LinkCardProps) => {
  const Element = link && link.href ? "a" : "div";

  const className = cn(cardStyles.card, styles.sidebar_card, { [cardStyles.card__link]: link && link.href });
  return (
    <Element
      href={link.href}
      target={link.target}
      title={link.title}
      className={className}
    >

      <Picture aspectRatio="5/3" imageData={image} />
      <Box backgroundColor="White" width="full" p={6} flexGrow={1}>
        <Typography textcolor="dark" variant="body">{header}</Typography>
        <Box mt={4}>
          {link && link.text && link.href && (
          <TextButtonIcon
            dense
            label={link.text}
            link={link}
            direction="right"
          >
            <ArrowRight />
          </TextButtonIcon>
          )}
        </Box>
      </Box>
    </Element>
  );
};
export default SidebarLinkCard;

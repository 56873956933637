import { SnwButtonType } from "@@/components/Button/buttonProps";
import SnwButton from "@@/components/Button/SnwButton";
import RichText from "@@/components/RichText";
import Box from "@@/elements/Box";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import Accordion, { Colors } from "..";

type CTASidebarOwnProps = {
  backgroundColor: Colors;
};

type CTASidebarEditableProps = {
  button?: SnwButtonType;
  header: string;
  body: string;
};

type CTASidebarProps = CTASidebarOwnProps & CTASidebarEditableProps;

const CTASidebar = ({
  header,
  backgroundColor,
  body,
  button,
}: CTASidebarProps) => (
  <Accordion header={header} backgroundColor={backgroundColor}>
    <EpiProperty<CTASidebarProps> name="body">
      <Box>
        <RichText body={body} />
      </Box>
    </EpiProperty>
    {(button && button.link.text && button.link.href) && (
    <Box mt={6} mb={3}>
      <EpiProperty<CTASidebarProps> name="button">
        <SnwButton link={button.link} {...button} />
      </EpiProperty>
    </Box>
    )}
  </Accordion>
);

export type ProvidedCTASidebarProps =
  & OptimizelyBlockProps<CTASidebarProps> &
  CTASidebarProps;

const ProvidedCTASidebar = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedCTASidebarProps) => (
  <OptimizelyBlock blockId={blockId} inEditMode={inEditMode} properties={properties}>
    {(optimizlyProperties) => (
      <CTASidebar {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedCTASidebar;

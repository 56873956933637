import cn from "classnames";
import React, { useContext, useEffect, useRef } from "react";
import ScrollerContext, { ScrollerItemVisibility } from "../context";
import css from "./index.module.scss";

export type ScrollerItemProps = {
  /** This prop will be assigned by parent <Scroller> */
  itemIndex?: number;
  /** This prop will be assigned by parent <Scroller> */
  visibility?: ScrollerItemVisibility;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
};

const ScrollerItem = ({
  visibility,
  itemIndex,
  className,
  children,
}: ScrollerItemProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const {
    itemEls,
    fullWidthItemsFinal,
    fullPageStretch,
  } = useContext(ScrollerContext);

  useEffect(() => {
    if (!ref.current || itemIndex === undefined) { return undefined; }
    itemEls[itemIndex] = ref.current;
    return () => { itemEls.splice(itemIndex, 1); };
  }, []);

  return (
    <li
      ref={ref}
      className={cn(css["hbd-scroller__item"], {
        [css["hbd-scroller__item--full-width"]]: fullWidthItemsFinal,
        [css["hbd-scroller__item--full-page-stretch"]]: fullPageStretch,
      }, className)}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      {children}
    </li>
  );
};

export default ScrollerItem;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramArrows07 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M89.27 31.528a1.5 1.5 0 0 1 1.623-1.365l15.547 1.343a1.5 1.5 0 0 1 1.365 1.627l-1.373 15.488a1.5 1.5 0 0 1-2.989-.265l1.241-13.99-14.05-1.214a1.5 1.5 0 0 1-1.365-1.624"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M105.32 34.044a1.5 1.5 0 0 1-.171 2.114L68.582 67.244a1.5 1.5 0 0 1-1.8.107L42.356 51.133 6.65 81.476a1.5 1.5 0 1 1-1.943-2.286l36.566-31.074a1.5 1.5 0 0 1 1.801-.107L67.5 64.226l35.706-30.353a1.5 1.5 0 0 1 2.114.171"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramArrows07;

import Box from "@@/elements/Box";
import Typography from "@@/elements/Typography";
import { TypographyVariants } from "@@/elements/Typography/TypographyProps";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import cn from "classnames";
import React from "react";
import { ExtendedCardColors } from "../CardProps";
import styles from "../cardStyles.module.scss";

export type TextCardProperties = {
  header: string,
};

export type TextCardOwnProps = {
  color: ExtendedCardColors,
  variant?: TypographyVariants,
  reference?: string
  referenceLink?: LinkData
};

export type TextCardProps = TextCardOwnProps & TextCardProperties;

export const TextCard = ({
  color,
  header,
  variant = "heading2",
  reference,
  referenceLink,
}: TextCardProps) => {
  const classNames = cn(styles.card, styles.text_card, {
    [(styles as any)[`card_${color}`]]: color,
  });

  // className={inEditMode && styles.card_edit}
  return (
    <Box height="full">
      <div className={classNames}>
        <EpiProperty<TextCardProperties> name="header">
          <Box height="full" alignItems="center">
            <Typography variant={variant} textcolor="light">
              {header}
            </Typography>
          </Box>
        </EpiProperty>
        {(reference || referenceLink?.text) && (
          <Box mt={2}>
            {referenceLink?.href ? (<a className={styles.text_link} href={referenceLink.href} target={referenceLink.target} title={referenceLink.title}><Typography variant="information">{referenceLink.text}</Typography></a>) : (<Typography variant="information">{reference || referenceLink.text}</Typography>)}
          </Box>
        )}
      </div>
    </Box>
  );
};

export type ProvidedTextCardProps =
  & OptimizelyBlockProps<TextCardProperties>
  & TextCardProps;

const ProvidedTextCard = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedTextCardProps) => (
  <OptimizelyBlock blockId={blockId} inEditMode={inEditMode} properties={properties}>
    {(optimizlyProperties) => (
      <TextCard {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedTextCard;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramElectronics11 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M43.636 38.42a1.5 1.5 0 0 1 1.5-1.5h35.16a1.5 1.5 0 0 1 1.013.393l4.642 4.247a1.5 1.5 0 0 1 .487 1.107V63.11a1.5 1.5 0 0 1-.487 1.107l-4.642 4.247a1.5 1.5 0 0 1-1.013.393h-35.16a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v25.938h33.078l3.724-3.407V43.328l-3.724-3.408z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M49.76 45.136a1.5 1.5 0 0 1 1.5-1.5h18.073a1.5 1.5 0 1 1 0 3H51.26a1.5 1.5 0 0 1-1.5-1.5M49.76 52.84a1.5 1.5 0 0 1 1.5-1.5h18.073a1.5 1.5 0 1 1 0 3H51.26a1.5 1.5 0 0 1-1.5-1.5M49.76 60.544a1.5 1.5 0 0 1 1.5-1.5h18.073a1.5 1.5 0 1 1 0 3H51.26a1.5 1.5 0 0 1-1.5-1.5M51.34 32.395a1.5 1.5 0 0 1 1.5-1.5h14.518a1.5 1.5 0 0 1 1.5 1.5v5.136a1.5 1.5 0 1 1-3 0v-3.636H54.339v3.636a1.5 1.5 0 1 1-3 0zM37.315 43.457a1.5 1.5 0 0 1 1.5-1.5h5.037a1.5 1.5 0 0 1 0 3h-3.537v15.765h3.537a1.5 1.5 0 1 1 0 3h-5.037a1.5 1.5 0 0 1-1.5-1.5z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M32.377 47.408a1.5 1.5 0 0 1 1.5-1.5h4.148a1.5 1.5 0 0 1 0 3h-2.648v7.963h2.648a1.5 1.5 0 1 1 0 3h-4.148a1.5 1.5 0 0 1-1.5-1.5z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.463 50.37a1.5 1.5 0 0 1 1.5-1.5h6.123a1.5 1.5 0 1 1 0 3h-4.623v1.939h4.623a1.5 1.5 0 1 1 0 3h-6.123a1.5 1.5 0 0 1-1.5-1.5zM46.5 74.47a1.5 1.5 0 0 1 1.5-1.5h24.593a1.5 1.5 0 0 1 1.5 1.5v5.036a1.5 1.5 0 0 1-1.5 1.5H48a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v2.036h21.593V75.97z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M52.562 67.753v6.321h-3v-6.32zM71.03 67.753v6.321h-3v-6.32z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M65.008 10.428a1.5 1.5 0 0 1 1.798-1.124C88.014 14.198 103.92 33.26 103.92 56c0 26.51-21.513 47.92-47.92 47.92a1.5 1.5 0 0 1 0-3c24.754 0 44.92-20.071 44.92-44.92 0-21.31-14.91-39.186-34.788-43.773a1.5 1.5 0 0 1-1.124-1.799M8.08 56C8.08 29.49 29.593 8.08 56 8.08a1.5 1.5 0 0 1 0 3c-24.754 0-44.92 20.072-44.92 44.92 0 21.31 14.91 39.186 34.788 43.773a1.5 1.5 0 1 1-.675 2.923C23.987 97.802 8.08 78.74 8.08 56"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M61.209 94.643a1.5 1.5 0 0 1 0 2.122l-5.747 5.746 5.739 5.656a1.5 1.5 0 1 1-2.106 2.136l-6.815-6.716a1.5 1.5 0 0 1-.007-2.129l6.814-6.815a1.5 1.5 0 0 1 2.122 0M50.783 1.713a1.5 1.5 0 0 1 2.122-.016l6.814 6.716a1.5 1.5 0 0 1 .008 2.13l-6.814 6.814a1.5 1.5 0 0 1-2.122-2.121l5.747-5.747-5.74-5.655a1.5 1.5 0 0 1-.015-2.121"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramElectronics11;

import SnwButtonContainer from "@@/components/Button/SnwButtonContainer";
import { SnwButtonType } from "@@/components/Button/buttonProps";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Picture from "@@/elements/Picture";
import Typography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointAndAbove,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import { PictureData } from "@@/models/pictureData";
import cn from "classnames";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";

export type HeroSplitProperties = {
  header?: string;
  body?: string;
  image: PictureData;
  label?: string;
};

export type HeroSplitOwnProps = {
  alignment: "left" | "right";
  buttons?: SnwButtonType[];
};

export type HeroSplitProps = HeroSplitOwnProps & HeroSplitProperties;

const HeroSplit = ({
  header,
  alignment = "left",
  image,
  body,
  buttons,
  label,
}: HeroSplitProps) => {
  const isXLargeAndAbove = useIsBreakpointAndAbove(ScreenSize.EXTRA_LARGE);
  const isSmall = useIsBreakpointOrBelow(ScreenSize.SMALL);
  const [updatedButtons, setUpdatedButtons] = useState<SnwButtonType[]>(buttons);
  const classNamesHero = cn(styles.hero_split, {
    [styles[`hero_split_${alignment}`]]: alignment,
  });
  const aspectRatio = () => {
    if (isXLargeAndAbove) {
      return "2 / 1";
    }
    return "16 / 9";
  };

  const updateButtonsForSmallScreen = (button: SnwButtonType): SnwButtonType => {
    if (button.variant === 'tertiary' && button.color === 'white') {
      return {
        ...button,
        color: 'charcoal',
      };
    }
    return button;
  };

  useEffect(() => {
      setUpdatedButtons(buttons.map(updateButtonsForSmallScreen));
    }, []);

  return (
    <Container
      mb={-10}
      size="full"
      pageGutter={0}
      className={styles.wrapper}
    >
      <Box className={classNamesHero}>
        <Picture
          className={styles.hero_split_picture}
          imageData={image}
          aspectRatio={aspectRatio()}
          loading="eager"
          lazySizes={false}
          sizes={[
            300, 600, 900, 1200, 1800,
          ]}
        />
        <Box
          width="full"
          height="full"
          className={styles.content_wrapper}>
          <Box
            pt={!body && (!buttons || buttons?.length < 1) ? 36 : 18}
            pb={!body && (!buttons || buttons?.length < 1) ? 46 : 28}
            className={styles.content}
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            width="full"
          >
            {label && label.trim() && (
              // <EpiProperty<HeroSplitProperties> name="label">
              <Box>
                <Typography
                  mt={0}
                  variant="pretitle"
                  asElement="div"
                  textcolor="dark"
                >
                  {label}
                </Typography>
                <hr />
              </Box>
              // </EpiProperty>
            )}
            <Box>
              {/* <EpiProperty<HeroSplitProperties> name="header"> */}
              <Typography
                textcolor="dark"
                variant="heading1">
                {header}
              </Typography>
              {/* </EpiProperty> */}
            </Box>

            <Box>
              {/* <EpiProperty<HeroSplitProperties> name="body"> */}
              <Typography
                mt={4}
                mb={9}
                textcolor="dark"
                variant="body">
                {body}
              </Typography>
              {/* </EpiProperty> */}
            </Box>
            {buttons && buttons.length > 0 && (
              <div className={styles.button_wrapper}>
                <SnwButtonContainer buttons={isSmall ? updatedButtons : buttons} />
              </div>
            )}

          </Box>
        </Box>
      </Box>
      <Box className={styles.notch} />
    </Container>
  );
};

export default HeroSplit;

import { LinkCard, LinkCardProps } from "@@/components/Card/LinkCard";
import Scroller, { ScrollerItem } from "@@/components/Scroller";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typography from "@@/elements/Typography";
import { screenSizeIsSmall } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiContent from "@@/integrations/optimizly/Content";
import EpiProperty from "@@/integrations/optimizly/Property";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import styles from "../cardContainer.module.scss";
import { TokenValue as bps } from "../../../design-tokens/breakpoints";

export type LinkCardContainerProperties = {
  header?: string;
  intro?: string;
};

export type LinkCardContainerOwnProps = {
  cards: LinkCardProps[];
  sliderDesktop?: boolean;
  sliderMobile?: boolean;
};

export type LinkCardContainerProps = LinkCardContainerOwnProps &
  LinkCardContainerProperties;

const LinkCardContainer = ({
  header,
  intro,
  cards,
  sliderDesktop,
  sliderMobile,
}: LinkCardContainerProps) => {
  const [cardLength, setCardLength] = useState<number>(3);
  const [slider, setSlider] = useState<boolean>(false);
  useEffect(() => {
    if (cards.length === 2) {
      setCardLength(2);
    } else setCardLength(3);

    const checkIfSlider = () => {
      if (
        (sliderMobile && window.innerWidth < bps.Large) ||
        (sliderDesktop && window.innerWidth >= bps.Large)
      ) {
        setSlider(true);
      } else setSlider(false);
    };
    checkIfSlider();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIfSlider);

      return () => {
        window.removeEventListener("resize", checkIfSlider);
      };
    }
  }, []);

  const classNames = cn(styles.container, {
    [(styles as any)[`container_${cardLength}`]]: cardLength,
  });
  return (
    <Box component="section" className={styles.block}>
      <Container component="div">
        <Box
          width="full"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          {header && (
            <EpiProperty<LinkCardContainerProperties> name="header">
              <Typography
                mb={intro ? 8 : [10, 12, 14]}
                variant="heading1"
                asElement="h2"
              >
                {header}
              </Typography>
            </EpiProperty>
          )}
          {intro && (
            <EpiProperty<LinkCardContainerProperties> name="intro">
              <Typography mb={[10, 12, 14]} variant="intro">
                {intro}
              </Typography>
            </EpiProperty>
          )}
        </Box>
        {!slider && (
          <EpiContent>
            <Box className={classNames}>
              {cards?.map((card) => (
                <LinkCard
                  header={card.header}
                  color={card.color}
                  image={card.image}
                  link={card.link}
                />
              ))}
            </Box>
          </EpiContent>
        )}
        {slider && (
          <EpiContent>
            <Box>
              <Scroller>
                {cards?.map((card) => (
                  <ScrollerItem>
                    <Box className={styles["product-card__scroller-item"]}>
                      <LinkCard
                        header={card.header}
                        color={card.color}
                        image={card.image}
                        link={card.link}
                      />
                    </Box>
                  </ScrollerItem>
                ))}
              </Scroller>
            </Box>
          </EpiContent>
        )}
      </Container>
    </Box>
  );
};

export type ProvidedLinkCardContainerProps =
  OptimizelyBlockProps<LinkCardContainerProperties> & LinkCardContainerProps;

const ProvidedLinkCardContainer = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedLinkCardContainerProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <LinkCardContainer {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedLinkCardContainer;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramTools05 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.263 21.16c11.549-11.547 30.274-11.546 41.822.002 11.55 11.548 11.55 30.276 0 41.825-11.548 11.548-30.276 11.548-41.825 0-11.545-11.546-11.553-30.374.003-41.828m39.701 2.123c-10.377-10.377-27.205-10.377-37.582 0l-.005.005c-10.368 10.274-10.375 27.197.005 37.577 10.377 10.377 27.205 10.377 37.582 0s10.377-27.205 0-37.582"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M42.173 21.687c-11.205 0-20.289 9.084-20.289 20.288 0 11.206 9.084 20.289 20.289 20.289S62.46 53.18 62.46 41.975c0-11.204-9.083-20.288-20.288-20.288M19.004 41.975c0-12.795 10.373-23.168 23.169-23.168 12.795 0 23.168 10.373 23.168 23.168S54.968 65.144 42.173 65.144 19.004 54.77 19.004 41.975"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M30.93 30.8a1 1 0 0 1 0 1.414c-5.483 5.482-5.69 13.933-.692 18.93a1 1 0 0 1-1.414 1.415c-5.867-5.866-5.481-15.587.691-21.76a1 1 0 0 1 1.414 0M64.225 57.209a1.5 1.5 0 0 1 2.122.004L94.495 85.46a1.5 1.5 0 0 1-.002 2.12l-6.815 6.814a1.5 1.5 0 0 1-2.123-.002L57.308 66.046a1.5 1.5 0 1 1 2.125-2.117L86.619 91.21l4.694-4.693L64.22 59.33a1.5 1.5 0 0 1 .004-2.121M96.494 88.08a1.5 1.5 0 0 1 1.06.44 6.33 6.33 0 0 1 0 8.936 6.33 6.33 0 0 1-8.936 0 1.5 1.5 0 0 1 0-2.122l6.815-6.815a1.5 1.5 0 0 1 1.06-.439m-.255 3.876-4.184 4.184a3.33 3.33 0 0 0 3.378-.806 3.33 3.33 0 0 0 .806-3.378"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramTools05;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCase = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M16.108 3.417h-3.166v-1.2a1.55 1.55 0 0 0-1.55-1.55H6.558A1.56 1.56 0 0 0 5 2.217v1.2H1.842A1.84 1.84 0 0 0 0 5.258v10.167a1.84 1.84 0 0 0 1.842 1.842h14.266a1.84 1.84 0 0 0 1.842-1.842V5.258a1.84 1.84 0 0 0-1.842-1.841m-9.833-1.2a.3.3 0 0 1 .292-.3h4.825a.3.3 0 0 1 .3.3v1.158H6.275zm-5 3.041a.59.59 0 0 1 .592-.591h14.241a.59.59 0 0 1 .592.591V8.7h-3.767v-.758a.625.625 0 1 0-1.25 0V8.7H6.267v-.758a.625.625 0 0 0-1.25 0V8.7H1.25zm15.392 10.167a.59.59 0 0 1-.592.592H1.842a.59.59 0 0 1-.592-.592V9.95H5v.758a.625.625 0 0 0 1.25 0V9.95h5.417v.758a.625.625 0 1 0 1.25 0V9.95h3.75z"
    />
  </svg>
);
export default SvgCase;

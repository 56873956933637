import TextButtonIcon from "@@/components/Button/TextButtonIcon";
import RichText from "@@/components/RichText";
import Box from "@@/elements/Box";
import { ArrowRight } from "@@/elements/Icons/icons";
import OptimizelyBlock, { OptimizelyBlockProps } from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import cn from "classnames";
import Accordion, { Colors } from "..";
import styles from "../accordionStyles.module.scss";

type RichtextSidebarOwnProps = {
  backgroundColor: Colors;
};

type RichtextSidebarEditableProps = {
  link?: LinkData;
  header: string;
  body: string;
};

type RichtectSidebarProps = RichtextSidebarOwnProps & RichtextSidebarEditableProps;

const RichtextSidebar = ({
  header,
  backgroundColor,
  body,
  link,
}: RichtectSidebarProps) => (
  <Accordion header={header} backgroundColor={backgroundColor}>
    <EpiProperty<RichtectSidebarProps> name="body">
      <Box>
        <RichText body={body} />
      </Box>
    </EpiProperty>
    {(link && link.text && link.href) && (
    <Box className={cn(styles.accordion_divider, styles.accordion_richtext__button)} mt={7} mb={0} pt={6}>
      <EpiProperty<RichtectSidebarProps> name="link">
        <TextButtonIcon dense label={link.text} link={link} direction="right">
          <ArrowRight />
        </TextButtonIcon>
      </EpiProperty>
    </Box>
    )}
  </Accordion>
);

export type ProvidedRichtextSidebarProps =
  & OptimizelyBlockProps<RichtectSidebarProps> &
  RichtectSidebarProps;

const ProvidedRichtextSidebar = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedRichtextSidebarProps) => (
  <OptimizelyBlock blockId={blockId} inEditMode={inEditMode} properties={properties}>
    {(optimizlyProperties) => (
      <RichtextSidebar {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedRichtextSidebar;

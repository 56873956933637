import Typography from "@@/elements/Typography";
import cn from "classnames";
import { SecondaryButtonProps } from "./buttonProps";
import styles from "./buttonStyles.module.scss";

const SecondaryButton = ({
  disabled,
  onClick,
  label,
  color = "charcoal",
  href,
  target = "_self",
  title,
}: SecondaryButtonProps) => {
  const classNames = cn(styles.base_button, styles.secondary, {
    [(styles as any)[`secondary_${color}`]]: color,
  });

  if (href) {
    return (
      <a href={href} className={classNames} title={title} target={target}>
        <Typography variant="button">{label}</Typography>
      </a>
    );
  }
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames}
      type="button"
    >
      <Typography variant="button">{label}</Typography>
    </button>
  );
};
export default SecondaryButton;

import Box from "@@/elements/Box";
import CategoryItem, { CategoryItemProps } from "./item";

type CategoriesProps = {
  options: CategoryItemProps[];
  selectedValues: number[];
  setSelectedValues: Function,
  center?: boolean;
};

const Categories = ({
  options, selectedValues, setSelectedValues, center,
} : CategoriesProps) => {
  const handleChange = (value : number) => {
    const newValues = [...selectedValues];
    if (newValues.includes(value)) {
      newValues.splice(newValues.indexOf(value), 1);
    } else {
      newValues.push(value);
    }
    setSelectedValues(newValues);
  };

  return (
    <Box
      role="listbox"
      flexWrap="wrap"
      gap={2}
      justifyContent={center ? "center" : "flex-start"}>
      {options.map(({
        label, value, count, disabled,
      }) => (
        <CategoryItem
          key={value}
          label={label}
          value={value}
          count={count}
          disabled={disabled}
          checked={selectedValues.includes(value)}
          handleChange={handleChange}
        />
      ))}
    </Box>
  );
};

export default Categories;

import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import SvgFacebook from "@@/elements/Icons/socialicons/Facebook";
import SvgInstagram from "@@/elements/Icons/socialicons/Instagram";
import SvgLinkdin from "@@/elements/Icons/socialicons/Linkdin";
import SvgYoutube from "@@/elements/Icons/socialicons/Youtube";
import cn from "classnames";
import Grid from "../../elements/Grid";
import Typography from "../../elements/Typography";
import { FooterProps } from "./FooterProps";
import styles from "./index.module.scss";

const Footer = ({
  columns = [],
  logoUrl,
  logoAltText,
  logoLink,
  socialMediaHeader,
  socialMediaSubHeader,
  socialMediaItems = [],
  newsLetter,
}: FooterProps) => {
  const Element = logoLink && logoLink.href ? "a" : "div";
  return (
    <div className={styles.footer}>
      <Box className={styles.gradient} />
      <Box className={styles["footer-notch"]} backgroundColor="White" />
      <Container pageGutterY={0} component="div">
        <Box py={[17, 26, 26]}>
          <Grid
            container
            className={cn(styles.footerrow)}
            spacing={2}
            justify="space-between"
          >
            <Grid item lg={4} md={6} sm={12}>
              <Element href={logoLink && logoLink.href ? logoLink.href : ""}>
                <img
                  src={logoUrl}
                  className={cn(styles.logo)}
                  alt={logoAltText}
                />
              </Element>
            </Grid>
            {newsLetter && (
              <Grid item lg={5} md={6} sm={12}>
                <div className={styles.signup}>
                  <Typography
                    asElement="div"
                    textcolor="light"
                    variant="heading5"
                  >
                    {newsLetter.header}
                  </Typography>
                  <Typography
                    asElement="div"
                    textcolor="light"
                    variant="information"
                  >
                    {newsLetter.subHeader}
                  </Typography>
                  <input type="text" placeholder={newsLetter.placeholder} />
                </div>
              </Grid>
            )}
            <Grid item lg={3} md={6} sm={12}>
              <Typography asElement="div" textcolor="light" variant="heading5">
                {socialMediaHeader}
              </Typography>
              <Typography
                asElement="div"
                textcolor="light"
                variant="information"
              >
                {socialMediaSubHeader}
              </Typography>
              <Box container color="White" mt={4}>
                {socialMediaItems.map((item) => (
                  <Box mr={3}>
                    <a
                      href={item.link.href}
                      className={styles.footer__link}
                      target={item.link.target}
                    >
                      {item.icon === "facebook" && (
                        <SvgFacebook
                          width="35px"
                          height="35px"
                          title={item.iconAltText}
                        />
                      )}
                      {item.icon === "instagram" && (
                        <SvgInstagram
                          width="35px"
                          height="35px"
                          title={item.iconAltText}
                        />
                      )}
                      {item.icon === "linkedin" && (
                        <SvgLinkdin
                          width="35px"
                          height="35px"
                          title={item.iconAltText}
                        />
                      )}
                      {item.icon === "youtube" && (
                        <SvgYoutube
                          width="35px"
                          height="35px"
                          title={item.iconAltText}
                        />
                      )}
                    </a>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className={cn(styles.footerrow)}
            justify="space-between"
          >
            {columns.map((column) => (
              <Grid
                item
                lg={3}
                md={6}
                sm={12}
                alignItems="stretch"
                justify="space-evenly"
              >
                <Typography
                  mt={0}
                  mb={3}
                  textcolor="light"
                  variant="heading5"
                  asElement="div"
                >
                  {column.header}
                </Typography>
                {column.linkItems.map((linkItem) => (
                  <a
                    href={linkItem.href}
                    title={linkItem.title}
                    target={linkItem.target}
                    className={styles.footer__link}
                  >
                    <Typography
                      textcolor="light"
                      variant="information"
                      asElement="div"
                    >
                      {linkItem.text}
                    </Typography>
                  </a>
                ))}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Footer;

import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { ArrowRight } from "@@/elements/Icons/icons";
import Typhography from "@@/elements/Typography";
import { screenSizeIsSmall } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiContent from "@@/integrations/optimizly/Content";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import { useEffect, useState } from "react";
import TextButtonIcon from "../Button/TextButtonIcon";
import { CardColors } from "../Card/CardProps";
import { ProcessCard, ProcessCardProperties } from "../Card/Process";
import Scroller, { ScrollerItem } from "../Scroller";
import styles from "./index.module.scss";
import { TokenValue as bps } from "../../design-tokens/breakpoints";

type USPCardsProperties = {
  header?: string;
  intro?: string;
  link?: LinkData;
};
type USPCardsOwnProps = {
  sliderDesktop?: boolean;
  sliderMobile?: boolean;
  cardColor: CardColors;
  cards: ProcessCardProperties[];
};

type USPCardsProps = USPCardsProperties & USPCardsOwnProps;

const USPCards = ({
  header,
  intro,
  link,
  sliderDesktop,
  sliderMobile,
  cardColor,
  cards,
}: USPCardsProps) => {
  const [slider, setSlider] = useState<boolean>(false);
  const hasLink = link && link.text && link.href;

  useEffect(() => {
    const checkIfSlider = () => {
      if (
        (sliderMobile && window.innerWidth < bps.Large) ||
        (sliderDesktop && window.innerWidth >= bps.Large)
      ) {
        setSlider(true);
      } else setSlider(false);
    };
    checkIfSlider();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIfSlider);

      return () => {
        window.removeEventListener("resize", checkIfSlider);
      };
    }
  }, []);
  const renderCards = () => {
    if (slider) {
      return (
        <Scroller scollerClassName={styles.cards_wrapper}>
          {cards.map(({ header: cardHeader, icon, body }) => (
            <ScrollerItem className={styles.card_wrapper}>
              <ProcessCard
                header={cardHeader}
                icon={icon}
                body={body}
                color={cardColor}
              />
            </ScrollerItem>
          ))}
        </Scroller>
      );
    }
    return (
      <Box
        className={cards.length < 4 ? styles.usp_container : styles.usp_grid}
      >
        {cards.map(({ header: cardHeader, icon, body }) => (
          <Box justifyContent="center">
            <ProcessCard
              header={cardHeader}
              icon={icon}
              body={body}
              color={cardColor}
            />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Container>
      <Box
        className={styles.heading_wrapper}
        flexDirection="column"
        alignItems="center"
        mb={[10, 12, 14]}
      >
        <EpiProperty<USPCardsProperties> name="header">
          {header && (
            <Typhography variant="heading1" asElement="h2" textAlign="center">
              {header}
            </Typhography>
          )}
        </EpiProperty>
        <EpiProperty<USPCardsProperties> name="intro">
          {intro && (
            <Typhography variant="intro" mt={8} textAlign="center">
              {intro}
            </Typhography>
          )}
        </EpiProperty>
      </Box>
      <EpiContent>{renderCards()}</EpiContent>

      <EpiProperty<USPCardsProperties> name="link">
        {hasLink && (
          <Box
            width="full"
            justifyContent={slider ? "center" : "flex-end"}
            mt={slider ? 12 : 5}
          >
            <TextButtonIcon link={link} direction="right">
              <ArrowRight />
            </TextButtonIcon>
          </Box>
        )}
      </EpiProperty>
    </Container>
  );
};

export type ProvidedUSPCardsProps = OptimizelyBlockProps<USPCardsProperties> &
  USPCardsProps;

const ProvidedUSPCards = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedUSPCardsProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => <USPCards {...rest} {...optimizlyProperties} />}
  </OptimizelyBlock>
);

export default ProvidedUSPCards;

import { SVGProps } from "react";
import * as Icons from "./icons";
import * as PictogramIcons from "./pictogram";

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const components = {
  // General icons
  essentials: Icons.EssentialsIcon,
  optimize: Icons.OptimizeIcon,
  inspire: Icons.InspireIcon,
  globe: Icons.ProcessIcon1,
  manufacturing: Icons.ProcessIcon2,
  recycling: Icons.ProcessIcon3,
  "raw-materials": Icons.ProcessIcon4,
  // Pictogram Icons
  ...PictogramIcons,
};

export type IconTypes = keyof typeof components;

type IconProps = {
  name: IconTypes;
};

const Icon = ({ name, ...props } : IconProps & SVGRProps & SVGProps<SVGSVGElement>) => {
  const IconComponent = components[name];

  if (!IconComponent) return <></>;

  return <IconComponent {...props} />;
};

export default Icon;

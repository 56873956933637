import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramMaterials05 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31.282 24.108a1 1 0 0 1 1 1c0 3.954 2.959 7.03 6.457 7.03a1 1 0 0 1 0 2c-4.74 0-8.457-4.113-8.457-9.03a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M39.312 13.51c-6.421 0-11.627 5.206-11.627 11.628s5.206 11.627 11.627 11.627S50.94 31.559 50.94 25.138 45.734 13.51 39.312 13.51M24.685 25.139c0-8.079 6.549-14.627 14.627-14.627 8.079 0 14.627 6.549 14.627 14.627s-6.548 14.627-14.627 14.627c-8.078 0-14.627-6.549-14.627-14.627M64.658 24.108a1 1 0 0 1 1 1c0 3.954 2.959 7.03 6.457 7.03a1 1 0 0 1 0 2c-4.74 0-8.457-4.113-8.457-9.03a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M72.688 13.51c-6.422 0-11.627 5.206-11.627 11.628s5.205 11.627 11.627 11.627c6.421 0 11.627-5.206 11.627-11.627S79.109 13.51 72.688 13.51M58.06 25.139c0-8.079 6.549-14.627 14.627-14.627s14.627 6.549 14.627 14.627-6.549 14.627-14.627 14.627S58.06 33.216 58.06 25.138M14.589 54.965a1 1 0 0 1 1 1c0 3.954 2.96 7.03 6.457 7.03a1 1 0 1 1 0 2c-4.74 0-8.457-4.113-8.457-9.03a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.62 44.368c-6.422 0-11.628 5.206-11.628 11.627s5.206 11.627 11.627 11.627 11.627-5.205 11.627-11.627c0-6.421-5.205-11.627-11.627-11.627M7.991 55.995c0-8.078 6.549-14.627 14.627-14.627 8.079 0 14.627 6.549 14.627 14.627 0 8.079-6.548 14.627-14.627 14.627-8.078 0-14.627-6.548-14.627-14.627M47.965 54.965a1 1 0 0 1 1 1c0 3.954 2.96 7.03 6.457 7.03a1 1 0 1 1 0 2c-4.74 0-8.457-4.113-8.457-9.03a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M55.995 44.368c-6.421 0-11.627 5.206-11.627 11.627s5.206 11.627 11.627 11.627 11.627-5.205 11.627-11.627c0-6.421-5.206-11.627-11.627-11.627M41.368 55.995c0-8.078 6.549-14.627 14.627-14.627s14.627 6.549 14.627 14.627c0 8.079-6.549 14.627-14.627 14.627s-14.627-6.548-14.627-14.627M81.35 54.965a1 1 0 0 1 1 1c0 3.954 2.96 7.03 6.458 7.03a1 1 0 1 1 0 2c-4.74 0-8.458-4.113-8.458-9.03a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M87.18 44.574c-6.306 1.214-10.434 7.31-9.22 13.616 1.215 6.306 7.311 10.433 13.617 9.219 6.305-1.215 10.433-7.31 9.218-13.616s-7.31-10.433-13.615-9.219M75.015 58.757c-1.528-7.932 3.665-15.601 11.597-17.129 7.933-1.527 15.602 3.665 17.129 11.597 1.528 7.933-3.664 15.602-11.597 17.13s-15.602-3.665-17.13-11.598M31.282 85.822a1 1 0 0 1 1 1c0 3.954 2.959 7.03 6.457 7.03a1 1 0 1 1 0 2c-4.74 0-8.457-4.114-8.457-9.03a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M39.312 75.225c-6.421 0-11.627 5.206-11.627 11.627S32.891 98.48 39.312 98.48 50.94 93.274 50.94 86.852c0-6.421-5.205-11.627-11.627-11.627M24.685 86.852c0-8.078 6.549-14.627 14.627-14.627 8.079 0 14.627 6.549 14.627 14.627s-6.548 14.627-14.627 14.627c-8.078 0-14.627-6.549-14.627-14.627M64.658 85.822a1 1 0 0 1 1 1c0 3.954 2.959 7.03 6.457 7.03a1 1 0 1 1 0 2c-4.74 0-8.457-4.114-8.457-9.03a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M72.688 75.225c-6.422 0-11.627 5.206-11.627 11.627S66.266 98.48 72.688 98.48c6.421 0 11.627-5.205 11.627-11.627 0-6.421-5.206-11.627-11.627-11.627M58.06 86.852c0-8.078 6.549-14.627 14.627-14.627s14.627 6.549 14.627 14.627-6.549 14.627-14.627 14.627S58.06 94.93 58.06 86.852"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramMaterials05;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramTools03 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M78.038 20.077a1.5 1.5 0 0 1 2.121 0l11.754 11.754a1.5 1.5 0 0 1 0 2.122L69.096 56.769a1.5 1.5 0 1 1-2.121-2.121l21.756-21.756-9.633-9.633-21.943 21.944a1.5 1.5 0 0 1-2.122-2.122zM25.558 74.489a1 1 0 0 1 1.414.002l3.546 3.555a1 1 0 0 1-1.416 1.413l-3.546-3.556a1 1 0 0 1 .002-1.414M31.455 68.592a1 1 0 0 1 1.414.002l3.546 3.556a1 1 0 0 1-1.416 1.412l-3.546-3.556a1 1 0 0 1 .002-1.414M37.35 62.696a1 1 0 0 1 1.415 0l3.546 3.546a1 1 0 0 1-1.414 1.414L37.35 64.11a1 1 0 0 1 0-1.414"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M62.696 37.35a1 1 0 0 1 1.414 0l3.546 3.547a1 1 0 0 1-1.414 1.414l-3.546-3.546a1 1 0 0 1 0-1.415M68.592 31.455a1 1 0 0 1 1.414-.002l3.556 3.546a1 1 0 1 1-1.412 1.416l-3.556-3.546a1 1 0 0 1-.002-1.415M74.489 25.558a1 1 0 0 1 1.414-.002l3.556 3.546a1 1 0 0 1-1.413 1.416l-3.555-3.546a1 1 0 0 1-.002-1.414M33.722 20.181a1.5 1.5 0 0 1 1.06.44l50.474 50.483a1.5 1.5 0 0 1 .366.6L91.44 89.7a1.5 1.5 0 0 1-1.93 1.875L71.94 85.332a1.5 1.5 0 0 1-.559-.353L20.907 34.496a1.5 1.5 0 0 1 0-2.121L32.66 20.62a1.5 1.5 0 0 1 1.06-.44m-9.633 13.254L73.262 82.62l14.389 5.111-4.772-14.76-49.158-49.167z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.706 33.894a1 1 0 0 1 1.414 0l43.53 43.53a1 1 0 0 1-1.415 1.413l-43.53-43.53a1 1 0 0 1 0-1.413M33.894 29.706a1 1 0 0 1 1.414 0l43.53 43.53a1 1 0 0 1-1.415 1.413l-43.53-43.53a1 1 0 0 1 0-1.413"
      clipRule="evenodd"
    />
    <path fill="currentColor" d="m87.919 82.595-5.324 5.324 7.141 1.955z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M45.39 54.846a1.5 1.5 0 0 1 0 2.12L23.536 78.823l9.632 9.633L55.122 66.5a1.5 1.5 0 0 1 2.121 2.121L34.23 91.637a1.5 1.5 0 0 1-2.121 0L20.354 79.882a1.5 1.5 0 0 1 0-2.12l22.915-22.916a1.5 1.5 0 0 1 2.121 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramTools03;

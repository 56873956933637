import { useEffect, useState } from "react";
import axios from "axios";

const ENDPOINT = "/api/stats/autocomplete";
const NUMBER_OF_CHARACTER = 2;

function useFetchAutocomplete(
  searchQuery: string,
  language: string,
  active: boolean,
) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(`${ENDPOINT}?q=${searchQuery}&l=${language}`);
        setData(response.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    if (timer) clearTimeout(timer);
    if (searchQuery.length > NUMBER_OF_CHARACTER && active) {
      setLoading(true);
      setTimer(setTimeout(() => fetchData(), 50));
    } else {
      setLoading(false);
      setData(null);
    }
  }, [searchQuery]);

  return {
    data, loading, error,
  };
}
export default useFetchAutocomplete;

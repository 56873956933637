import isEqual from "lodash/isEqual";
import throttle from "lodash/throttle";
import { useEffect, useRef, useState } from "react";

export type WindowDimensions = {
  innerWidth: number | null,
  innerHeight: number | null,
  outerWidth: number | null,
  outerHeight: number | null
};

// Our default window dimension is currently set to a
// iPhone X.
const defaultWindowDimensions: WindowDimensions = {
  innerWidth: 375,
  innerHeight: 812,
  outerWidth: 375,
  outerHeight: 812,
};

const useWindowDimensions = (): WindowDimensions => {
  // eslint-disable-next-line max-len
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(defaultWindowDimensions);

  const windowDimensionsRef = useRef<WindowDimensions>(windowDimensions);

  const fetchAndUpdateWindowDimensions = () => {
    const current: WindowDimensions = {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      outerWidth: window.outerWidth,
      outerHeight: window.outerHeight,
    };
    if (!isEqual(current, windowDimensionsRef.current)) {
      windowDimensionsRef.current = current;
      setWindowDimensions(current);
    }
  }

  useEffect(() => {
    fetchAndUpdateWindowDimensions();

    const fn = throttle(() => {
      fetchAndUpdateWindowDimensions();
    }, 1000 / 60);

    window.addEventListener("resize", fn, { passive: true });

    return () => {
      window.removeEventListener("resize", fn);
    };
  }, []);

  return windowDimensions;
}

export default useWindowDimensions;
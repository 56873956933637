/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-useless-path-segments */
import "./epiforms/datepicker.module.scss";
import "./epiforms/styles.module.scss";
import "./styles/index.scss";

import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import AccordionAccessories from "./components/Accordion/Accessories";
import AccordionCTA from "./components/Accordion/CTASidebar";
import AccordionContacts from "./components/Accordion/Contacts";
import AccordionLinks from "./components/Accordion/Links";
import AccordionMaterials from "./components/Accordion/Materials";
import AccordionRichText from "./components/Accordion/RichtextSidebar";
import AccordionSizeVolume from "./components/Accordion/SizeVolume";
import Author from "./components/Author";
import Banner from "./components/Banner/Banner";
import Box from "./components/Box/Box";
import BranchContactInfo from "./components/BranchContactInfo";
import BranchInfoCards from "./components/BranchInfoCards";
import BranchInfoCard from "./components/BranchInfoCards/card";
import BranchSearch from "./components/BranchSearch";
import PrimaryButton from "./components/Button/PrimaryButton";
import InfoCard from "./components/Card/Info";
import LinkCard from "./components/Card/LinkCard";
import ProcessCard from "./components/Card/Process";
import ServiceDescriptionCard from "./components/Card/Service-description";
import TextCard from "./components/Card/Text";
import CareerContacts from "./components/CareerContacts";
import CareerContactPersonCard from "./components/CareerContacts/card";
import CompanyContactContainer from "./components/CompanyContactContainer";
import LargeCompanyContactCard from "./components/CompanyContactContainer/Card/large";
import SmallCompanyContactCard from "./components/CompanyContactContainer/Card/small";
import Countdown from "./components/Countdown";
import EmbeddedForm from "./components/EmbeddedForm";
import ErrorContainer from "./components/Error/ErrorPage";
import Faq from "./components/Faq";
import FaqItem from "./components/Faq/faqItem";
import Footer from "./components/Footer";
import FooterBottomBar from "./components/Footer/bottomBar";
import FriendlyCaptcha from "./components/FriendlyCaptcha";
import Menu from "./components/Header/MainMenu/";
import TopMenu from "./components/Header/TopMenu";
import BranchPageHero from "./components/Hero/BranchPage";
import HeroContentPage from "./components/Hero/ContentPage";
import HeroEventImage from "./components/Hero/EventPage/Image";
import HeroEventVideo from "./components/Hero/EventPage/Video";
import HeroListingPage from "./components/Hero/ListingPage";
import HeroNewsPage from "./components/Hero/NewsPage";
import HeroFullWidth from "./components/Hero/StartPage/FullWidth";
import HeroSplit from "./components/Hero/StartPage/Split";
import HighlightSection from "./components/HighlightSection";
import LatestUpdate from "./components/LatestUpdate";
import CareerListContainer from "./components/ListingPage/Career";
import ArticleListContainer from "./components/ListingPage/News/Containers/listingArticles";
import NewsListContainer from "./components/ListingPage/News/Containers/listingNews";
import ProductsListContainer from "./components/ListingPage/Products";
import SearchListContainer from "./components/ListingPage/Search";
import Image from "./components/Media/Media";
import ImageGallery from "./components/Media/MediaGallery";
import ContactPersonCardContainer from "./components/PersonContactContainer";
import ProductTopSection from "./components/ProductTopSection";
import Quote from "./components/Quote";
import RichText from "./components/RichTextContainer";
import ShareArticle from "./components/ShareBox";
import SidebarLinkCardContainer from "./components/SidebarLinkCards";
import SidebarLinkCard from "./components/SidebarLinkCards/card";
import TopSection from "./components/Site/pagecomponents/Topsection";
import SpecificationList from "./components/SpecificationList";
import TabNavigation from "./components/TabNavigation";
import Tags from "./components/Tags";
import Testimonial from "./components/Testimonial";
import TestimonialItem from "./components/Testimonial/item";
import TextMedia from "./components/TextMediaBlock";
import USPCards from "./components/USPCards";
import Video from "./components/VideoComponent";
import VideoContainer from "./components/VideoContainer";
import InfoCardContainer from "./containers/Cards/Info";
import LatestNews from "./containers/Cards/latest-news";
import LinkCardContainer from "./containers/Cards/linkCard";
import NewsModule from "./containers/Cards/news-module";
import ProcessCardContainer from "./containers/Cards/process/";
import RelatedContent from "./containers/Cards/related-content";
import RelatedProducts from "./containers/Cards/related-products";
import ServiceDescriptionCardContainer from "./containers/Cards/service-description";
import SubPagesContainer from "./containers/Cards/sublevel";
import TextCardContainer from "./containers/Cards/text";

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

global.Components = {
  Faq,
  FaqItem,
  InfoCard,
  TextCard,
  LinkCard,
  Box,
  PrimaryButton,
  ProcessCard,
  InfoCardContainer,
  TextCardContainer,
  ProcessCardContainer,
  LinkCardContainer,
  HeroFullWidth,
  HeroSplit,
  HeroContentPage,
  HeroNewsPage,
  HeroListingPage,
  HeroEventImage,
  HeroEventVideo,
  Footer,
  FooterBottomBar,
  TopMenu,
  TextMedia,
  Quote,
  Menu,
  Tags,
  RichText,
  AccordionContacts,
  AccordionLinks,
  AccordionCTA,
  AccordionSizeVolume,
  ShareArticle,
  LatestNews,
  NewsModule,
  TopSection,
  Author,
  AccordionRichText,
  Testimonial,
  TestimonialItem,
  USPCards,
  ServiceDescriptionCard,
  ServiceDescriptionCardContainer,
  HighlightSection,
  NewsListContainer,
  ArticleListContainer,
  CareerListContainer,
  SearchListContainer,
  SubPagesContainer,
  ContactPersonCardContainer,
  Image,
  ImageGallery,
  RelatedContent,
  RelatedProducts,
  TabNavigation,
  CompanyContactContainer,
  LargeCompanyContactCard,
  SmallCompanyContactCard,
  SpecificationList,
  CareerContactPersonCard,
  CareerContacts,
  SidebarLinkCard,
  SidebarLinkCardContainer,
  VideoContainer,
  Video,
  ErrorContainer,
  AccordionAccessories,
  AccordionMaterials,
  ProductTopSection,
  BranchPageHero,
  BranchInfoCard,
  BranchInfoCards,
  BranchContactInfo,
  ProductsListContainer,
  BranchSearch,
  EmbeddedForm,
  Countdown,
  LatestUpdate,
  FriendlyCaptcha,
  Banner,
};

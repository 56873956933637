import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramNumbers05 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 226.77 226.77"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M141.47 129.64c0 5.03-.46 9.58-1.37 13.65s-3.1 7.84-6.56 11.31c-2.08 2.17-4.75 3.94-8 5.33-3.25 1.38-7.17 2.08-11.76 2.08s-8.58-.7-11.96-2.08c-3.38-1.39-6.11-3.12-8.19-5.2-2.52-2.51-4.27-5.29-5.27-8.32s-1.62-6.24-1.89-9.62h13.52c.52 4.16 1.86 7.39 4.03 9.68q3.255 3.45 9.75 3.45c1.99 0 3.81-.33 5.46-.98 1.64-.65 3.08-1.58 4.29-2.79 1.99-1.99 3.23-4.44 3.71-7.34.47-2.9.71-5.96.71-9.17 0-2.77-.2-5.37-.58-7.8-.39-2.43-1.11-4.53-2.15-6.31a11.23 11.23 0 0 0-4.22-4.16c-1.78-1-4.01-1.5-6.7-1.5-3.9 0-6.93.85-9.1 2.54s-3.6 3.7-4.29 6.04H88.55V66.97h50.83v11.96h-38.61v24.96c1.56-1.73 3.68-3.08 6.37-4.03s5.76-1.43 9.23-1.43c7.37 0 13 2.04 16.9 6.11 3.55 3.55 5.8 7.52 6.76 11.89a62 62 0 0 1 1.43 13.2Z"
    />
  </svg>
);
export default SvgPictogramNumbers05;

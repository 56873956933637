import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramElectronics021 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.648 23.803a7.907 7.907 0 0 1 7.92-7.92h74.765a7.907 7.907 0 0 1 7.92 7.92V77.53a7.907 7.907 0 0 1-7.92 7.92H18.568a7.907 7.907 0 0 1-7.92-7.92zm7.92-4.92a4.907 4.907 0 0 0-4.92 4.92V77.53a4.907 4.907 0 0 0 4.92 4.92h74.765a4.907 4.907 0 0 0 4.92-4.92V23.803a4.907 4.907 0 0 0-4.92-4.92z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.16 24.395a1 1 0 0 1 1-1h73.58a1 1 0 0 1 1 1v45.926a1 1 0 0 1-1 1H19.16a1 1 0 0 1-1-1zm2 1v43.926h71.58V25.395zM33.562 89.481a1.5 1.5 0 0 1 1.5-1.5h41.876a1.5 1.5 0 0 1 1.5 1.5v5.136a1.5 1.5 0 0 1-1.5 1.5H35.062a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v2.136h38.876v-2.136z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramElectronics021;

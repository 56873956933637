import useSpacing from "@@/hooks/ui/use-spacing";
import cn from "classnames";
import useColors from "../../hooks/ui/use-colors";
import useTextAlign from "../../hooks/ui/use-text-align";
import { TypographyProps } from "./TypographyProps";
import style from "./style/typography.module.scss";

const Typhography = (props: TypographyProps) => {
  const {
    className,
    variant,
    asElement,
    textcolor,
    uppercase,
    children,
    textAlign,
    italic,
    bold,
    ...rest
  } = props;
  let dynamicElement: string = "div";
  if (asElement) {
    dynamicElement = asElement.toString();
  } else if (variant === "heading1") {
    dynamicElement = "h1";
  } else if (variant === "heading2") {
    dynamicElement = "h2";
  } else if (variant === "heading3") {
    dynamicElement = "h3";
  } else if (variant === "heading4") {
    dynamicElement = "h4";
  } else if (variant === "heading5") {
    dynamicElement = "h5";
  } else if (variant === "heading6") {
    dynamicElement = "h6";
  } else if (variant === "body" || variant === "intro") {
    dynamicElement = "p";
  } else {
    dynamicElement = "div";
  }

  const spacing = useSpacing(props);
  const colors = useColors(props);
  const textAlignCss = useTextAlign({ textAlign });
  const CustomTag = `${dynamicElement}` as keyof JSX.IntrinsicElements;

  const typoClassNames = cn(
    className,
    spacing.className,
    textAlignCss.className,
    colors.className,
    {
      [style.typography]: true,
      [style[`${variant}`]]: true,
      [style[`typography--${textcolor}`]]: textcolor,
      [style["typography--uppercase"]]: uppercase,
      [style["typography--bold"]]: bold,
      [style["typography--italic"]]: italic,
    },
  );
  return (
    <CustomTag {...rest} className={typoClassNames} style={spacing.style}>
      {children}
    </CustomTag>
  );
};

export default Typhography;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramCommunication01 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 113 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M84.769 24.932q.085-.01.172-.01c6.073 0 11.022 4.998 11.022 11.091v38.384c0 6.093-4.949 11.09-11.022 11.09H83.27V96.78a1.5 1.5 0 0 1-2.376 1.217L63.517 85.488H27.829c-6.073 0-11.021-4.998-11.021-11.091V36.023c0-6.093 4.948-11.091 11.022-11.091zm.338 2.992a2 2 0 0 1-.156.008H27.83c-4.397 0-8.022 3.635-8.022 8.091v38.374c0 4.456 3.625 8.09 8.022 8.09H64a1.5 1.5 0 0 1 .877.283L80.27 93.851v-9.863a1.5 1.5 0 0 1 1.5-1.5h3.17c4.397 0 8.023-3.635 8.023-8.091V36.013c0-4.4-3.535-7.999-7.856-8.09M35.85 45.604a1.5 1.5 0 0 1 1.5-1.5H75.43a1.5 1.5 0 0 1 1.5 1.5v.03a1.5 1.5 0 0 1-1.5 1.5H37.35a1.5 1.5 0 0 1-1.5-1.5zm0 9.591a1.5 1.5 0 0 1 1.5-1.5H75.43a1.5 1.5 0 0 1 1.5 1.5v.03a1.5 1.5 0 0 1-1.5 1.5H37.35a1.5 1.5 0 0 1-1.5-1.5zm0 9.591a1.5 1.5 0 0 1 1.5-1.5H75.43a1.5 1.5 0 0 1 1.5 1.5v.03a1.5 1.5 0 0 1-1.5 1.5H37.35a1.5 1.5 0 0 1-1.5-1.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramCommunication01;

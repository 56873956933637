import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import Typhography from "@@/elements/Typography";
import {
  ScreenSize,
  useIsBreakpointOrBelow,
} from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import cn from "classnames";
import { useEffect, useRef } from "react";
import Item, { ItemProps } from "./item";
import styles from "./styles.module.scss";

type TabNavigationOwnProps = {
  links: ItemProps[];
  label?: string;
};

type TabNavigationEditableProps = {
  subheader?: string;
  intro?: string;
  link?: LinkData;
};

type TabNavigationProps = TabNavigationOwnProps & TabNavigationEditableProps;

const TabNavigation = ({
  links,
  label,
  subheader,
  intro,
  link,
}: TabNavigationProps) => {
  const ref = useRef(null);

  const isMedium = useIsBreakpointOrBelow(ScreenSize.MEDIUM);
  const overflow = links.length > 7 || (isMedium && links.length > 4);
  const hasIcon = links.some(({ item }) => item.icon && item.icon.length);

  useEffect(() => {
    const { hash } = window.location;
    if (ref.current && hash === "#s") {
      ref.current.scrollIntoView();
    }
  }, []);

  const className = cn(styles.wrapper, { [styles.wrapper__center]: overflow });

  return (
    <Container ref={ref} id="stena-tab-navigation">
      <Box flexDirection="column" width="full">
        <EpiProperty<TabNavigationEditableProps> name="subheader">
          {subheader && (
            <Typhography
              className={styles.text_wrapper}
              variant="intro"
              asElement="h2"
              mb={[10, 12, 14]}
              textAlign="center"
            >
              {subheader}
            </Typhography>
          )}
        </EpiProperty>
        {label && (
          <Typhography variant="label" asElement="div" mb={4} uppercase>
            {label}
          </Typhography>
        )}
        {links && (
          <Box role="listbox" aria-label="tabnavigation" className={className}>
            {links.map(({ item, active }, i) => (
              <Item
                key={i}
                item={item}
                active={active}
                overflow={overflow}
                hasIcon={hasIcon}
              />
            ))}
          </Box>
        )}
        <Box
          width="full"
          flexDirection="column"
          alignItems="center"
          mt={intro || link ? 8 : null}
        >
          <EpiProperty<TabNavigationEditableProps> name="intro">
            {intro && (
              <Typhography
                className={styles.text_wrapper}
                variant="body"
                textAlign="center"
              >
                {intro}
              </Typhography>
            )}
          </EpiProperty>
          <EpiProperty<TabNavigationEditableProps> name="link">
            {link && (
              <Typhography
                className={styles.text_wrapper}
                variant="body"
                textAlign="center"
              >
                <a href={link.href} title={link.title} target={link.target}>
                  {link.text}
                </a>
              </Typhography>
            )}
          </EpiProperty>
        </Box>
      </Box>
    </Container>
  );
};

export type ProvidedTabNavigationProps =
  OptimizelyBlockProps<TabNavigationProps> & TabNavigationProps;

const ProvidedTabNavigation = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedTabNavigationProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <TabNavigation {...rest} {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedTabNavigation;

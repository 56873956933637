import cn from "classnames";
import { useMemo } from "react";
import ResponsiveValue, { arrayFromResponiveValue, FilledResponsiveValue } from "../../../base/responsive-value";
import css from "./use-border-radius.module.scss";

export type BorderRadiusProps = {
  br?: ResponsiveValue<number>;
};

const breakpointAbbrevations = [
  "sm", 
  "md", 
  "lg"
];

const borderRadiusClasses = (br: FilledResponsiveValue<number>) => cn({
  [css["ui-sm-br"]]: br[0] !== undefined,
  [css["ui-md-br"]]: br[1] !== undefined,
  [css["ui-lg-br"]]: br[2] !== undefined,
});

const borderRadiusStyles = (br: FilledResponsiveValue<number>) =>
  Object.fromEntries(breakpointAbbrevations.reduce((acc, bp, index) => {
    if (br[index] === undefined) {
      return acc;
    }
    acc.push([`--ui-${bp}-br`, br[index]]);
    return acc;
  }, [] as Array<[PropertyKey, any]>));


const useBorderRadius = (props: BorderRadiusProps) => useMemo(() => {
  const values = arrayFromResponiveValue(props.br);
  return {
    className: borderRadiusClasses(values),
    style: borderRadiusStyles(values),
  }}, [props.br]);


export default useBorderRadius;
import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramNumbers04 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 226.77 226.77"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M133.93 146.93v14.17H120.8v-14.17H82.84v-12.48l33.15-67.47h14.56L97.4 134.45h23.4v-21.97h13.13v21.97h10.01v12.48z"
    />
  </svg>
);
export default SvgPictogramNumbers04;

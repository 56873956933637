import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramContainers0301 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M21.53 21.63h68.94"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M102.716 21.63H96.79l-2.37-5.827c-.198-.494-.692-.89-1.284-.89H18.864c-.592 0-1.086.297-1.284.89l-2.469 5.827H9.185c-.691 0-1.284.691-1.086 1.382l10.074 55.704c.099.79.79 1.383 1.58 1.383H92.05c.79 0 1.482-.593 1.58-1.383l10.075-55.704c.296-.79-.198-1.382-.988-1.382"
    />
    <path
      stroke="currentColor"
      strokeWidth={3}
      d="M32.593 97.086c3.259 0 5.926-2.666 5.926-5.926 0-3.259-2.667-5.926-5.926-5.926s-5.926 2.667-5.926 5.926 2.666 5.926 5.926 5.926ZM79.407 97.086c3.26 0 5.926-2.666 5.926-5.926 0-3.259-2.666-5.926-5.926-5.926-3.259 0-5.926 2.667-5.926 5.926s2.667 5.926 5.926 5.926Z"
    />
  </svg>
);
export default SvgPictogramContainers0301;

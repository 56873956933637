import SkeletonBox from "@@/components/Loader/Skeleton";
import Box from "@@/elements/Box";
import { ReactNode } from "react";
import styles from "./styles.module.scss";

export const multiRender = (amount: number, element: ReactNode) => [...Array(amount)].map((_, i) => <Box key={i}>{element}</Box>);

const CareerCardsLoader = () => (
  <Box className={styles.cards_wrapper} mt={7}>
    {multiRender(9, <SkeletonBox width="full" height={87} />)}
  </Box>
);

export default CareerCardsLoader;

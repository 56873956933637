import useWindowDimensions from "@@/hooks/device/use-window-dimensions";

import React, {
  RefObject, useCallback, useContext, useEffect, useRef, useState,
} from "react";
import { PaddingValue } from "@@/hooks/ui/use-spacing";
import Box from "@@/elements/Box";
import AccordionContext from "../context";
import css from "./index.module.scss";
import NavButtonWrapper from "../nav-button/buttonWrapper";

export type SolidBarProps = {
  scrollingEl: RefObject<HTMLOListElement>;
  barPx?: PaddingValue;
};

const SolidBar = ({
  barPx,
  scrollingEl,
}: SolidBarProps) => {
  const windowDimensions = useWindowDimensions();
  const trackEl = useRef<HTMLDivElement>(null);
  const barRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [left, setLeft] = useState(0);
  const {
    items, scrollLeft, fullWidthItemsFinal, setTargetItem,
  } = useContext(AccordionContext);

  useEffect(() => {
    if (!scrollingEl.current || !trackEl.current) { return; }
    const viewPercentage = scrollingEl.current.clientWidth / scrollingEl.current.scrollWidth;
    const newWidth = Math.max(trackEl.current.clientWidth * viewPercentage, 48);
    setWidth(newWidth);
  }, [windowDimensions.innerWidth, fullWidthItemsFinal]);

  useEffect(() => {
    if (!scrollingEl.current || !trackEl.current) { return; }
    const scrollPercentage = scrollLeft / scrollingEl.current.scrollWidth;
    const newLeft = Math.ceil(trackEl.current.clientWidth * scrollPercentage);
    setLeft(newLeft);
  }, [scrollLeft]);

  const barClick = useCallback((evt: React.MouseEvent) => {
    evt.preventDefault();
    if (!barRef.current || !trackEl.current) { return; }
    const clickX = evt.pageX - trackEl.current.offsetLeft;
    const clickPercentage = clickX / barRef.current.clientWidth - 0.1;
    const target = Math.round((items.length - 1) * clickPercentage);
    setTargetItem(() => target);
  }, []);

  return (
    <Box
      flexDirection="row"
      width="full"
      pt={6}
      flexWrap={["nowrap", "wrap", "wrap"]}
      justifyContent="space-between"
      alignItems="center"
      gap={[6, 9, 9]}
    >
      <Box
        container
        px={barPx}
        width="full"
        className={css["hbd-scroller__solid-bar"]}
        onClick={barClick}
        ref={barRef}
        order={[1, 0, 0]}
      >
        <Box
          ref={trackEl}
          className={css["hbd-scroller__bar-track"]}
          width="full"
          backgroundColor="PrimaryGreyTint03"
        >
          <div
            className={css["hbd-scroller__bar-current"]}
            style={{
              width,
              transform: `translateX(${left}px)`,
            }}
          />
        </Box>
      </Box>
      <NavButtonWrapper />
    </Box>
  );
};

export default SolidBar;

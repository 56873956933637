import Box from "@@/elements/Box";
import Typhography from "@@/elements/Typography";
import cn from "classnames";
import styles from "./optionStyles.module.scss";

type ClearButtonProps = {
  text: string;
  handleChange: Function;
  disabled?: boolean;
};

const ClearButton = ({ text, handleChange, disabled } : ClearButtonProps) => {
  const handleKeyDown = (e : any) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleChange(null);
    }
  };

  return (
    <Box className={styles.option} tabIndex={0} onKeyDown={handleKeyDown} aria-label={text} role="button">
      <input onClick={() => handleChange(null)} type="button" tabIndex={-1} disabled={disabled} />
      <Box className={cn(styles.container, styles.container__clearbutton)}>
        <Typhography variant="label" asElement="div" uppercase>
          {text}
        </Typhography>
      </Box>
    </Box>
  );
};

export default ClearButton;

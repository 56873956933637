import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgProcessIcon3 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 72 72"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M66.228 40.036c-.371 1.561-1.26 3.048-2.15 4.386-2.074 3.345-4.223 6.765-6.15 10.185-.963-4.163-3.26-7.806-5.336-11.523-2.223-3.866-4.446-7.731-6.447-11.746 4.372-2.453 8.67-5.055 12.45-7.285 2.149 3.494 3.853 7.285 5.854 10.928-.148-.223-.445-.892 0 0q.111.111 0 0c.89 1.635 2.223 3.122 1.779 5.055M50.369 23.83c-4.075.223-8.151 0-12.227.074.519-.297 1.112-.594 1.556-.892l.593-.446-.296-.669c-1.482-3.345-3.557-6.319-5.336-9.515 1.556-2.38 2.742-4.684 5.484-5.948 1.779-.817 3.705-1.04 5.558-.297 3.483 1.264 5.632 4.907 6.965 8.178l.297.817.815-.297c.963-.372 2-.892 2.964-1.487-1.704 3.048-3.63 6.096-5.483 9.07zm-27.566 4.014a694 694 0 0 0-12.524-7.21c1.927-3.643 4.372-6.989 6.447-10.482-.148.223-.444.817 0 0q.111-.112 0 0c.964-1.562 1.482-3.42 3.409-4.015 1.556-.446 3.26-.372 4.89-.372 3.928.15 7.93.298 11.857.223-3.112 2.9-5.113 6.765-7.262 10.408-2.223 3.791-4.372 7.731-6.817 11.448m-6.077-17.692q0-.112 0 0M26.73 54.308c0 7.955-2.593 10.63-2.593 10.63h-2.594s3.928-2.675 3.928-10.63c0-5.278-11.783-10.63-11.783-10.63s10.449 5.724 10.449 11.15C7.463 57.507-1.726 37.807 3.239 27.697 3.165 38.4 26.73 33.048 26.73 54.309m15.117-5.798.075.743.74.074c3.632.372 7.263.075 10.894.15 1.26 2.527 2.668 4.757 2.371 7.73-.222 1.933-.963 3.717-2.52 4.981-2.815 2.379-7.04 2.379-10.522 1.933l-.815-.074-.148.817a24.5 24.5 0 0 0-.223 3.346 161 161 0 0 1-5.039-9.293l-.815-1.487c1.853-3.642 4.076-7.136 6.077-10.704-.075.594-.075 1.189-.075 1.784"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgProcessIcon3;

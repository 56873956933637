import { useCallback } from "react";
import helpers from "../helpers/helpers";

// eslint-disable-next-line import/prefer-default-export
export const useScrollLock = () => {
  const lock = useCallback(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
    document.body.style.overflowY = "hidden";
    document.body.style.paddingRight = `${scrollBarCompensation}px`;
    
    if (helpers.iOS()) {
      document.body.style.left = "0";
      document.body.style.right = "0";
      document.body.style.position = "fixed";
      document.body.style.touchAction = "none";
    }
  }, []);

  const unlock = useCallback(() => {
    document.body.style.overflowY = "";
    document.body.style.paddingRight = "";

    document.body.style.position = "";
    document.body.style.left = "";
    document.body.style.right = "";
    document.body.style.touchAction = "";
  }, []);

  return {
    lock,
    unlock,
  };
};

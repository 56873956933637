import { useEffect, useState } from "react";

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(null);

  useEffect(() => {
    const updateScrollPosition = () => {
      const { scrollY } = window;
      setScrollPosition(scrollY);
    };
    window.addEventListener("scroll", updateScrollPosition); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollPosition); // clean up
    };
  }, [scrollPosition]); // run when scroll direction changes

  return scrollPosition;
};

export default useScrollPosition;

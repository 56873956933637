import cn from "classnames";
import ResponsiveValue, { arrayFromResponiveValue } from "../../../base/responsive-value";
import css from "./use-text-align.module.scss";

export type TypographyTextAlign = "center" | "inherit" | "justify" | "left" | "right";

export type TextAlignProps = {
  textAlign?: ResponsiveValue<TypographyTextAlign>;
};

export const textAlignClasses = ({ textAlign }: TextAlignProps) => {
  if (!textAlign) { return undefined; }
  const values = arrayFromResponiveValue(textAlign);
  return cn({
    [(css as any)[`ui-ta--sm-${values[0]}`]]: values[0] !== undefined,
    [(css as any)[`ui-ta--md-${values[1]}`]]: values[1] !== undefined,
    [(css as any)[`ui-ta--lg-${values[2]}`]]: values[2] !== undefined,
  });
};

const useTextAlign = (props: TextAlignProps) => ({
  className: textAlignClasses(props),
});

export default useTextAlign;
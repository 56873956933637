import Box from "@@/elements/Box";
import SkeletonCard from "../Card/skeletonCard";
import styles from "./styles.module.scss";

type SkeletonCardsProps = {
  amount?: number;
};

const SkeletonCards = ({ amount = 6 }:SkeletonCardsProps) => (
  <Box className={styles.wrapper}>
    {[...Array(amount)].map((i) => <SkeletonCard key={i} variant="newsmodule" />)}
  </Box>
);

export default SkeletonCards;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgProcessIcon4 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 72 73"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.678 44.255a1.947 1.947 0 1 0 0-3.893 1.947 1.947 0 0 0 0 3.893" />
    <path d="M64.612.053h-43.63v21.739H0v43.63h43.63v-21.74h20.982zm-3.397 9.295L43.63 26.934v-5.142h-6.467L55.353 3.6l-.15-.152h6.012zM40.233 43.574c-2.914-.491-7.44-1.043-9.068 4.44-.87 2.93-3.226 6.294-5.12 8.999-1.54 2.199-2.592 3.743-3.099 5.012h-5.379c.026-1.966.482-4.747 2.437-7.191.692-.865 1.6-1.493 2.562-2.158 2.206-1.526 4.953-3.425 5.794-8.807.715-4.577-.228-7.145-1.14-9.629-.872-2.374-1.7-4.629-1.216-9.052h4.974c.14.693.26 1.412.386 2.171.713 4.291 1.6 9.631 7.303 12.566.319.164.526.278.658.355.207.241.512.422.908.593zm-27.601 3.02c-.09-1.82-.162-3.256.336-5.175.507-1.957 1.349-3.221 2.324-4.686 1.643-2.468 3.5-5.262 3.771-11.545h3.529c-.48 4.992.533 7.755 1.44 10.223.832 2.267 1.551 4.226.972 7.933-.616 3.943-2.356 5.146-4.37 6.539-1.12.774-2.28 1.576-3.282 2.829-2.569 3.212-3.155 6.802-3.181 9.313h-3.549c2.362-8.312 2.168-12.239 2.01-15.431m27.601-9.683-.012-.006c-4.186-2.154-4.827-6.015-5.506-10.102a96 96 0 0 0-.282-1.615h5.8zM24.378 18.786 39.714 3.449h10.988L32.359 21.792h-7.98zM34.911 3.449 24.378 13.982V3.45zM8.673 25.19c-.47 2.333-1.741 5.36-3.17 8.758a206 206 0 0 0-2.107 5.142v-13.9zM3.396 51.11c.939-5.624 3.22-11.053 5.238-15.847 1.613-3.835 3.041-7.236 3.486-10.076h3.544c-.248 5.228-1.682 7.385-3.2 9.663-1.048 1.576-2.133 3.205-2.784 5.716-.628 2.421-.537 4.254-.44 6.195.15 3.054.337 6.84-2.155 15.263H3.396zm23.369 10.914c.582-.95 1.393-2.108 2.063-3.065 2.034-2.905 4.566-6.52 5.592-9.979.764-2.573 2.14-2.592 5.587-2l.226.038v15.006zM43.63 31.737l17.585-17.585v11.447L46.528 40.286H43.63zm7.7 8.549 9.885-9.884v9.884z" />
  </svg>
);
export default SvgProcessIcon4;

import axios from "axios";
import { useEffect, useState } from "react";

type UseFetchProductsDataReturn = {
  items: any[];
  page: number;
  categoryFacets: any[];
  yearFacets: any[];
  totalMatching: number;
  totalPage: number;
};

function useFetchProductsData(selectedPage: number, searchRequest) {
  const [fetchData, setFetchData] = useState<UseFetchProductsDataReturn>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const ENDPOINT = "/api/search/products";
  const params = {
    ...searchRequest,
    page: selectedPage,
  };

  const fetchProductsData = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.post(ENDPOINT, params);
      setFetchData(response.data);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductsData();
  }, [selectedPage]);

  return {
    fetchData, loading, error,
  };
}
export default useFetchProductsData;

import useWindowDimensions from "@@/hooks/device/use-window-dimensions";
import useIsFirstRender from "@@/hooks/react/use-is-first-render";
import usePrevious from "@@/hooks/react/use-previous";
import useVisible from "@@/hooks/ui/use-visible";
import cn from "classnames";
import throttle from "lodash/throttle";
import React, {
  useCallback,
  useContext, useEffect, useImperativeHandle, useRef, useState
} from "react";
import ScrollerContext from "../context";
import { ScrollerItemProps } from "../item";
import css from "./index.module.scss";

export type ScrollingContainerProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  scrollToItemNr?: number;
  onCurrentItemChanged?: (index: number) => void;
};

const ScrollingContainer = React.forwardRef<
HTMLOListElement,
ScrollingContainerProps>(({
  children,
  onCurrentItemChanged,
  scrollToItemNr,
  className,
}, ref) => {
  const {
    targetItem,
    items,
    scrollLeft,
    currentItem,
    fullWidthItemsFinal,
    fullPageStretch,
    itemEls,
    autoChangeEvery,
    hasItemOverflow,
    setCurrentItem,
    setScrollLeft,
    setItems,
    setIsAtStart,
    setIsAtEnd,
    setHasItemOverflow,
    setTargetItem,
  } = useContext(ScrollerContext);

  const isFirstRender = useIsFirstRender();
  const scrollingEl = useRef<HTMLOListElement>(null);
  const itemsElIsVisible = useVisible(scrollingEl);
  const windowDimensions = useWindowDimensions();
  const [smooth, setSmooth] = useState(false);
  const [hideContainer, setHideContainer] = useState(false);
  const prevItem = usePrevious(currentItem);
  const autoChangeInterval = useRef<number | undefined>(undefined);
  useImperativeHandle(ref, () => scrollingEl.current!);

  const autoChange = useCallback(() => {
    setTargetItem((old) => {
      if (old === items.length) { return 0; }
      return old + 1;
    });
  }, [items.length]);

  const scrollLeftForIndex = useCallback((index: number) => {
    const item = itemEls[index];
    if (!item) { return 0; }
    return item.offsetLeft || 0;
  }, []);

  /**
   * This function hides the container while setting left position.
   * This can be used when the distance of the scroll becomes substancial.
   * @param target the scroll left we should move to
   */
  const jumpToPosition = (target: number) => {
    setHideContainer(true);
    setTimeout(() => {
      if (!scrollingEl.current) { return; }
      scrollingEl.current.scrollLeft = target;
      setHideContainer(false);
    }, 300);
  };

  /**
   * this function sets the scroll left of the items element
   * by finding the position of the provided index going to it.
   * @param index the index to move to
   */
  const scrollToIndex = (index: number) => {
    if (!scrollingEl.current) { return; }
    const target = scrollLeftForIndex(index);
    const from = scrollLeftForIndex(prevItem || 0);
    const bigJump = Math.abs(target - from) > ((scrollingEl.current.clientWidth || 0) * 4);

    if (bigJump) {
      jumpToPosition(target);
    } else {
      scrollingEl.current.scrollLeft = target;
    }
  };

  // Used to control what scroll item that are active from outside the scroller component
  useEffect(() => {
    if (scrollToItemNr) {
      scrollToIndex(scrollToItemNr);
    }
  }, [scrollToItemNr]);

  useEffect(() => {
    if (isFirstRender || currentItem === -1) return;
    onCurrentItemChanged?.(currentItem);
  }, [currentItem]);

  useEffect(() => {
    const handler = throttle(() => {
      setScrollLeft(scrollingEl.current?.scrollLeft || 0);
    }, 1000 / 60, { leading: true, trailing: true });
    scrollingEl.current?.addEventListener("scroll", handler, { passive: true });
    return () => { scrollingEl.current?.removeEventListener("scroll", handler); };
  }, []);

  useEffect(() => {
    scrollToIndex(targetItem);
  }, [targetItem, windowDimensions.innerWidth]);

  useEffect(() => {
    if (!scrollingEl.current) { return; }
    setHasItemOverflow(scrollingEl.current.scrollWidth > scrollingEl.current.clientWidth);
    setIsAtStart(!Math.round(scrollLeft));
    setIsAtEnd(Math.round(scrollingEl.current.offsetWidth + scrollLeft) >= scrollingEl.current.scrollWidth);
  }, [
    scrollLeft,
    windowDimensions.innerWidth,
    itemsElIsVisible,
    fullWidthItemsFinal,
    itemEls?.length,
  ]);

  useEffect(() => {
    if (scrollingEl.current) {
      scrollingEl.current.scrollLeft = scrollLeftForIndex(targetItem);
    }
    setSmooth(() => true);
  }, []);

  const observer = useRef<IntersectionObserver | undefined>(undefined);
  useEffect(() => {
    if (typeof (IntersectionObserver) === "undefined") { return undefined; }
    if (!scrollingEl.current) { return undefined; }
    observer.current = new IntersectionObserver((entries) => {
      requestAnimationFrame(() => {
        let firstFullyVisible: number | undefined;
        setItems((currentItems) => {
          const newItems = [...currentItems];
          for (let i = 0; i < entries.length; i += 1) {
            const entry = entries[i];
            const index = itemEls.indexOf(entry.target as HTMLLIElement);

            if (entry.intersectionRatio >= 0.8) {
              newItems[index] = "fully";
              if (typeof (firstFullyVisible) === "undefined") {
                firstFullyVisible = index;
              }
            } else if (entry.intersectionRatio >= 0.1) {
              newItems[index] = "partial";
            } else {
              newItems[index] = "hidden";
            }
          }
          setCurrentItem(() => newItems.indexOf("fully") || 0);
          return newItems;
        });
      });
    }, {
      root: scrollingEl.current,
      threshold: [0.1, 0.5, 0.9],
    });
    return () => {
      observer.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!autoChangeEvery) { return undefined; }
    window.clearInterval(autoChangeInterval.current);
    autoChangeInterval.current = window.setInterval(autoChange, autoChangeEvery);
    return () => {
      window.clearInterval(autoChangeInterval.current);
    };
  }, [autoChangeEvery]);

  useEffect(() => {
    if (!observer.current) { return undefined; }
    if (!children) { return undefined; }
    itemEls.forEach((el) => observer.current?.observe(el));
    return () => {
      itemEls.forEach((el) => observer.current?.unobserve(el));
    };
  }, [children]);

  return (
    <>
      <div
        className={cn(css["hbd-scroller__wrapper"], {
          [css["hbd-scroller__wrapper--stretch"]]: fullPageStretch,
        })}
      >
        <ol
          ref={scrollingEl}
          className={cn(css["hbd-scroller__container"], {
            [css["hbd-scroller__container--center"]]: !hasItemOverflow,
            [css["hbd-scroller__container--smooth"]]: smooth,
            [css["hbd-scroller__container--hidden"]]: hideContainer,
          }, className)}
        >
          {React.Children.map(children as React.ReactElement[], (child: React.ReactElement<ScrollerItemProps>, index) => {
            if (React.isValidElement<ScrollerItemProps>(child)) {
              return (
                <child.type {...child.props} key={index} itemIndex={index} visibility={items[index]} />
              );
            }
            return <></>;
          })}
        </ol>
      </div>
    </>
  );
});

export default ScrollingContainer;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgImageMissingThin = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 66 58"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.798 3.724c1.048-.968 2.47-1.512 3.951-1.512h8.311c1.631 0 3.181.658 4.243 1.802l4.676 5.038c.53.572 1.305.902 2.121.902h9.049c4.63 0 8.382 3.466 8.382 7.741V42.21c0 .713-.625 1.29-1.397 1.29s-1.397-.577-1.397-1.29V17.695c0-2.85-2.502-5.161-5.588-5.161H48.1c-1.631 0-3.181-.658-4.243-1.802l-4.675-5.038a2.9 2.9 0 0 0-2.122-.902h-8.31c-.742 0-1.453.272-1.977.756l-1.167 1.078a1.48 1.48 0 0 1-1.976 0 1.222 1.222 0 0 1 0-1.824zm-15.946 8.81c-3.086 0-5.588 2.31-5.588 5.161v29.492c0 1.425 1.25 2.58 2.794 2.58h52.089v2.581H6.057c-3.085 0-5.587-2.31-5.587-5.16V17.694c0-4.275 3.753-7.741 8.382-7.741h5.708v2.58z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.057 20.464c-2.23 1.792-3.634 4.428-3.634 7.369 0 5.395 4.736 9.769 10.578 9.769 3.183 0 6.036-1.297 7.977-3.354l2.107 1.695c-2.45 2.596-6.06 4.24-10.084 4.24-7.385 0-13.372-5.53-13.372-12.35 0-3.718 1.78-7.053 4.592-9.315z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.854.378a1.48 1.48 0 0 1 1.976 0l60.005 55.42c.545.503.545 1.32 0 1.824a1.48 1.48 0 0 1-1.976 0L2.854 2.202a1.222 1.222 0 0 1 0-1.824"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgImageMissingThin;

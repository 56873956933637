import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramOffice06 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.525 12.346a1.5 1.5 0 0 1 1.5-1.5h67.95a1.5 1.5 0 0 1 1.5 1.5v87.308a1.5 1.5 0 0 1-1.5 1.5h-67.95a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v84.308h64.95V13.846z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M49.522 25.453c.67.677.665 1.769-.012 2.44L38.745 38.558a1.725 1.725 0 0 1-2.425.004l-6.617-6.52a1.725 1.725 0 1 1 2.421-2.457l5.403 5.323 9.555-9.467a1.725 1.725 0 0 1 2.44.01M55.981 28.544a1.5 1.5 0 0 1 1.5-1.5h23.803a1.5 1.5 0 1 1 0 3H57.48a1.5 1.5 0 0 1-1.5-1.5M55.981 35.457a1.5 1.5 0 0 1 1.5-1.5h23.803a1.5 1.5 0 0 1 0 3H57.48a1.5 1.5 0 0 1-1.5-1.5M49.522 49.453c.67.676.665 1.769-.012 2.44L38.745 62.558a1.725 1.725 0 0 1-2.434-.006l-6.617-6.617a1.725 1.725 0 0 1 2.44-2.44l5.402 5.404 9.546-9.458a1.725 1.725 0 0 1 2.44.01M55.981 52.544a1.5 1.5 0 0 1 1.5-1.5h23.803a1.5 1.5 0 1 1 0 3H57.48a1.5 1.5 0 0 1-1.5-1.5M55.981 59.457a1.5 1.5 0 0 1 1.5-1.5h23.803a1.5 1.5 0 0 1 0 3H57.48a1.5 1.5 0 0 1-1.5-1.5M49.522 73.453c.67.676.665 1.769-.012 2.44L38.745 86.558a1.725 1.725 0 0 1-2.434-.006l-6.617-6.617a1.725 1.725 0 0 1 2.44-2.44l5.402 5.404 9.546-9.458a1.725 1.725 0 0 1 2.44.01M55.981 76.544a1.5 1.5 0 0 1 1.5-1.5h23.803a1.5 1.5 0 1 1 0 3H57.48a1.5 1.5 0 0 1-1.5-1.5M55.981 83.457a1.5 1.5 0 0 1 1.5-1.5h23.803a1.5 1.5 0 0 1 0 3H57.48a1.5 1.5 0 0 1-1.5-1.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramOffice06;

import { FC } from "react";
import cn from "classnames";
import { BoxProps } from "./BoxProps.model";
import styles from "./boxStyles.module.scss";
const Box: FC<BoxProps> = ({
  children,
  flex,
  justifyContent,
  alignItems,
  flexDirection,
}) => {
  const classNames = cn({
    [styles.Box_flex]: flex,
    [styles[`Box_flex_direction${flexDirection}`]]: flexDirection,
    [styles[`Box_justify_content_${justifyContent}`]]: justifyContent,
    [styles[`Box_align_items_${alignItems}`]]: alignItems,
  });
  return <div className={classNames}>{children}</div>;
};
export default Box;

import { useEffect, useState } from "react";
import styles from "./index.module.scss";

export type RichTextProps = {
  body:string;
};

const RichText = ({ body }: RichTextProps) => {
  const [html, setHtml] = useState(body);

  const formatBody = () => {
    const template = document.createElement("div");
    template.innerHTML = body;

    const links = template.getElementsByTagName("a");
    for (let i = 0; i < links.length; i += 1) {
      const link = links.item(i);
      let deepestChild : Element = link;
      while (deepestChild.firstElementChild) {
        deepestChild = (deepestChild).firstElementChild;
      }
      if (deepestChild) {
        const newText = deepestChild.innerHTML.replaceAll("/", "<wbr>/").trim();
        deepestChild.innerHTML = newText;
      }
    }

    setHtml(template.innerHTML);
  };

  useEffect(() => {
    formatBody();
  }, [body]);

  if (!html) return <></>;

  return (
    <div
      className={styles.richtextblock}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default RichText;

import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPictogramProduction01 = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 112"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.84 15.705a1.5 1.5 0 0 1 1.5-1.5h81.31a1.5 1.5 0 0 1 1.5 1.5v20.278a1.5 1.5 0 0 1-1.5 1.5H15.34a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v17.278h78.31V17.205zM13.84 76.017a1.5 1.5 0 0 1 1.5-1.5h81.31a1.5 1.5 0 0 1 1.5 1.5v20.278a1.5 1.5 0 0 1-1.5 1.5H15.34a1.5 1.5 0 0 1-1.5-1.5zm3 1.5v17.278h78.31V77.517z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.748 34.493c11.88 0 21.511 9.63 21.511 21.512s-9.63 21.511-21.511 21.511v-3c10.224 0 18.511-8.287 18.511-18.511 0-10.225-8.287-18.512-18.511-18.512zM89.243 37.493c-10.225 0-18.512 8.287-18.512 18.512s8.287 18.511 18.512 18.511v3c-11.882 0-21.512-9.63-21.512-21.511s9.63-21.512 21.512-21.512z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictogramProduction01;

import { WidgetInstance } from "friendly-challenge";
import { useEffect, useRef } from "react";
import styles from "./friendlyCaptcha.module.scss";

type FriendlyCaptchaProps = {
  elementName: string;
  siteKey: string;
  startMode: "auto" | "focus" | "none";
  language: string;
  endpoint: string;
  validationElementId: string;
};

const FriendlyCaptcha = ({ elementName, siteKey, startMode = "focus", language, endpoint, validationElementId }: FriendlyCaptchaProps) => {
  const container = useRef();
  const widget = useRef<WidgetInstance>();

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, { 
        startMode: startMode,
      });
    }

    return () => {
      if (widget.current != undefined) widget.current.reset();
    };
  }, [container]);

  return (
    <div className={styles.container}>
      <fieldset>
        <div
          ref={container}
          className="frc-captcha"
          data-sitekey={siteKey}
          data-solution-field-name={elementName}
          data-start={startMode}
          data-lang={language}
          data-puzzle-endpoint={endpoint}
        />
      </fieldset>
      <span
        id={validationElementId}
        data-epiforms-linked-name={elementName}
        className="Form__Element__ValidationError"
        style={{ display: "none" }}>*</span>
    </div>
  );
};

export default FriendlyCaptcha;
import Typography from "@@/elements/Typography";
import { PrimaryButtonProps } from "./buttonProps";
import styles from "./buttonStyles.module.scss";

const PrimaryButton = ({
  disabled,
  onClick,
  label,
  title,
  href,
  target = "_self",
}: PrimaryButtonProps) => {

  if (href) {
    return (
      <a
        title={title}
        href={href}
        className={`${styles.base_button} ${styles.primary}`}
        target={target}
      >
        <Typography textcolor="light" variant="button">
          {label}
        </Typography>
      </a>
    );
  }
  return (
    <button
      type="button"
      title={title}
      disabled={disabled}
      onClick={onClick}
      className={`${styles.base_button} ${styles.primary}`}
    >
      <Typography textcolor="light" variant="button">
        {label}
      </Typography>
    </button>
  );
};
export default PrimaryButton;

import Breadcrumbs from "@@/components/Breadcrumb";
import Tags from "@@/components/Tags";
import Box from "@@/elements/Box";
import Container from "@@/elements/Container";
import { Calender, Clock } from "@@/elements/Icons/icons";
import Typography from "@@/elements/Typography";
import { ScreenSize, useIsBreakpointOrBelow } from "@@/hooks/device/use-breakpoint";
import OptimizelyBlock, {
  OptimizelyBlockProps,
} from "@@/integrations/optimizly/Block";
import EpiProperty from "@@/integrations/optimizly/Property";
import { LinkData } from "@@/models/linkdata";
import styles from "./index.module.scss";

type HeroNewsPageOwnProps = {
  pretitle: string;
  header: string;
  publishedDate: string;
  publishedTime: string;
  categories: string[];
  breadcrumbItems: LinkData[];
};

type HeroNewsPageEditableProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  hero: HeroNewsPageOwnProps;
  // For now we will add epi edit for whole component since epi couldn't handle the on
  // page edit for components thats not placed inside a content block.
};

type HeroNewsPageProps = HeroNewsPageOwnProps;

export const HeroNewsPage = ({
  header,
  pretitle,
  publishedDate,
  publishedTime,
  categories,
  breadcrumbItems,
}: HeroNewsPageProps) => {
  const isMobileDevice = useIsBreakpointOrBelow(ScreenSize.MEDIUM);

  return (
    <EpiProperty<HeroNewsPageEditableProps> name="hero">
      <Container
        size="full"
        pageGutter={0}>
        <Box
          className={styles.hero}
          backgroundColor="White">
          <Container
            pageGutterY={0}
            component="div">
            <Box
              className={styles.hero_content__wrapper}
              alignSelf="center"
              pt={18}
            >
              <Box mb={10}>
                <Breadcrumbs breadcrumbItems={breadcrumbItems} />
              </Box>
              <div className={styles.hero_content__pretitle}>
                <Box
                  flexDirection={isMobileDevice ? "column" : "row"}
                  mb={6}
                  className={styles.hero_content__br}>
                  <Box mr={4}>
                    <Typography
                      textcolor="dark"
                      variant="pretitle"
                      asElement="div"
                    >
                      {pretitle}
                    </Typography>
                    <hr className={styles.stroke} />
                  </Box>
                  <Box
                    flexDirection="row"
                    alignItems="flex-start"
                    flexWrap="wrap"
                    className={styles.published_date}
                    gap={3}
                    color="ComplementaryCharcoalTint02"
                  >
                    <Box
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Calender
                        height={16}
                        width={16} />
                      <Typography
                        variant={isMobileDevice ? "link" : "pretitle"}
                        asElement="div"
                        ml={1}
                        color="PrimaryGreyTint01"
                      >
                        {publishedDate}
                      </Typography>
                    </Box>
                    <Box
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Clock
                        height={16}
                        width={16} />
                      <Typography
                        variant={isMobileDevice ? "link" : "pretitle"}
                        asElement="div"
                        ml={1}
                        color="PrimaryGreyTint01">
                        {publishedTime}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </div>

              {/* <EpiProperty<HeroContentPageEditableProps> name="header"> */}
              <Typography
                textcolor="dark"
                variant="heading1"
                asElement="h1">
                {header}
              </Typography>
              {/* </EpiProperty> */}
              {categories && (
                <Box mt={5}>
                  <Tags tags={categories} />
                </Box>
              )}
            </Box>
          </Container>
        </Box>
      </Container>
    </EpiProperty>
  );
} ;

export type ProvidedHeroNewsPageProps =
  OptimizelyBlockProps<HeroNewsPageProps> & HeroNewsPageProps;

const ProvidedHeroNewsPage = ({
  blockId,
  inEditMode,
  properties,
  ...rest
}: ProvidedHeroNewsPageProps) => (
  <OptimizelyBlock
    blockId={blockId}
    inEditMode={inEditMode}
    properties={properties}
  >
    {(optimizlyProperties) => (
      <HeroNewsPage
        {...rest}
        {...optimizlyProperties} />
    )}
  </OptimizelyBlock>
);

export default ProvidedHeroNewsPage;
